import React, { useState } from "react";
import styles from "../../assets/css/services/service.module.css";
import { Catogories } from "../../components/Hero";
import ServiceForm from "./ServiceForm";

export const Service = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [formData, setFormData] = useState(null);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleTabClick = (value) => {
    setSelectedTab(value);
  };
  const serviceFormFields = {
    AMC: [
      { label: "Name", type: "text", placeholder: "Enter your full name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Amount (GST)", type: "number", placeholder: "12345" },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    CMC: [
      { label: "Name", type: "text", placeholder: "Enter your full name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Amount (GST)", type: "number", placeholder: "12345" },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    Delivery: [
      { label: "Agency Name", type: "text", placeholder: " Enter your Agency Name" },
      { label: "Provider Name", type: "text", placeholder: "Provider Name" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Repairing: [
      { label: "Equipment Name", type: "text", placeholder: " Enter your Equipment Name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Consumable: [
      { label: "Equipment Name", type: "text", placeholder: " Enter your Equipment Name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Parts: [
      { label: "Equipment Name", type: "text", placeholder: " Enter your Equipment Name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Shipping_and_Installation: [
      { label: "Agency Name", type: "text", placeholder: " Enter your Agency Name" },
      { label: "Provider", type: "text", placeholder: "Provider" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Software_Update: [
      { label: "Equipment Name", type: "text", placeholder: " Enter your Equipment Name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    Rental_Services: [
      { label: "Equipment Name", type: "text", placeholder: " Enter your Equipment Name" },
      { label: "Specialty", type: "text", placeholder: "Specialty" },
      { label: "Pricing", type: "text", placeholder: "Pricing" },
      { label: "Location", type: "date", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: " write yourDescription" },
    ],
    // Add more services and their respective fields here...
  };

  const handleApplyClick = () => {
    if (selectedTab) {
      // const selectedFormData = serviceFormFields[selectedTab];
      // setFormData(selectedFormData);
      // openPopup();
      openPopup();
    } else {
      alert("Please select a service before applying.");
    }
  };

  const sfilters = [
    "AMC",
    "CMC",
    "Delivery",
    "Repairing",
    "Consumable",
    "Parts",
    "Shipping_and_Installation",
    "Software_Update",
    "Rental_Services",
  ];

  return (
    <section className={styles.serviceCont}>
      <div className={styles.serviceSubCont}>
        <div className={styles.serviceRow1}>
          <i className="bi bi-house-door"></i>
          <i style={{ fontSize: "14px" }} className="bi bi-chevron-right"></i>
          <span>Services</span>
        </div>

        <div className={styles.serviceRow2}>
          <div className={styles.servCat}>
            <Catogories />
          </div>
          <div
            style={{ background: "none", boxShadow: "none" }}
            className={styles.serviceContent}
          >
            <div className={styles.serviceFilters}>
              {sfilters.map((value, index) => (
                <span
                style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                  key={index}
                  className={`${styles.sFilter} ${
                    selectedTab === value ? styles.selected : styles.unselect
                  }`}
                  onClick={() => handleTabClick(value)}
                >
                  {value.replace(/_/g, " ")}
                </span>
              ))}

              <span onClick={handleApplyClick} className={styles.sFilterApply}>
                Apply
              </span>
            </div>
            <div className={styles.serviceData}></div>
          </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <ServiceForm onClose={closePopup} fields={serviceFormFields[selectedTab]}
            serviceName={selectedTab.replace(/_/g, " ")} />
        </div>
      )}
    </section>
  );
};
