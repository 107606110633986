import React, { useEffect, useState } from "react";
import { postData1 } from "../../../services";
import { orderListImg } from "../../../assets/images";
import orderStyle from "../../../assets/css/user/buyer_seller/order.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";

const SheduleMeeting = () => {
  const buyerId = localStorage.getItem("uid");
  const [shedule, setShedule] = useState([]);
  const [checkType, setCheckType] = useState("buyer");
  const [loader, setLoader] = useState(true);

  const handleOrderLists = async () => {
    let data;
    if (checkType === "buyer") {
      data = {
        buyer_id: buyerId,
      };
    } else {
      data = {
        seller_id: buyerId,
      };
    }
    const res = await postData1("product/list_schedule_meeting/", data, true);
    if (res.status) {
      let fil = res?.data?.filter((date) => {
        return (
          new Date(date?.created_date).toLocaleDateString() <=
          new Date().toLocaleDateString()
        );
      });
      setShedule(fil);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleOrderLists();
  }, [checkType]);

  const handleType = (e) => {
    setCheckType(e.target.id);
  };

  return (
    <section className={orderStyle.myOrder}>
      <div className={orderStyle.orderCard}>
        <div className={orderStyle.meetingType}>
          <h1>Shedule Meeting List</h1>
          <div className={orderStyle.meetingDec}>
            <label>Buyer</label>
            <input
              type="radio"
              id="buyer"
              checked={checkType === "buyer" ? true : false}
              name="buyer"
              onClick={(e) => handleType(e)}
            />
            <label>Seller</label>
            <input
              type="radio"
              id="seller"
              name="seller"
              checked={checkType === "seller" ? true : false}
              onClick={(e) => handleType(e)}
            />
          </div>
        </div>
        <div className={orderStyle.orderTable}>
          <div className={orderStyle.tableHeading}>
            <li>
              <p>Meet Id</p>
            </li>
            <li>
              <p>{checkType === "buyer" ? "Buyer" : "Seller"}</p>
            </li>
            <li>
              <p>Schedule Date</p>
            </li>
            <li>
              <p>Start Time</p>
            </li>
            <li>
              <p>End Time</p>
            </li>
            <li>
              <p>Duration</p>
            </li>
            <li>
              <p>Meeting Link</p>
            </li>
          </div>
          <div>
            {shedule?.reverse()?.map((shedule, index) => {
              return (
                <SheduleListCard
                  id={index + 1}
                  shedule={shedule}
                  buyerId={buyerId}
                  checkType={checkType}
                />
              );
            })}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </section>
  );
};

const SheduleListCard = ({ shedule, id, buyerId, checkType }) => {
  const [formattedDuration, setFormattedDuration] = useState("");
  useEffect(() => {
    convertDuration();
  }, [shedule?.duration]);

  const convertDuration = () => {
    const parts = shedule.duration.split(":");
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    const formattedDuration = `${
      hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : ""
    } ${minutes > 0 ? `${minutes} minute${minutes !== 1 ? "s" : ""}` : ""} `;
    setFormattedDuration(formattedDuration);
  };
  return (
    <>
      <section className={orderStyle.orderList} style={{ padding: "10px" }}>
        <div className={orderStyle.orderListImg}>
          <div className={orderStyle.product}>{shedule?.length}</div>
        </div>
        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{shedule?.id}</h2>
          </div>
        </div>
        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{`${
              checkType === "buyer"
                ? shedule?.seller_firstname
                : shedule?.buyer_firstname
            } ${
              checkType === "buyer"
                ? shedule?.seller_lastname
                : shedule?.buyer_lastname
            }`}</h2>
          </div>
        </div>
        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{new Date(shedule?.date).toLocaleDateString()}</h2>
          </div>
        </div>
        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{shedule?.start_time}</h2>
          </div>
        </div>
        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{shedule?.end_time}</h2>
          </div>
        </div>

        <div className={orderStyle.orderListImg}>
          <div>
            <h2>{formattedDuration}</h2>
          </div>
        </div>
        <div className={orderStyle.orderListImg}>
          <h2 style={{ color: "#019C89" }} className={orderStyle.viewData}>
            {shedule?.link === "1" ? (
              "No Link"
            ) : (
              <NavLink to={shedule?.link}>{shedule?.link}</NavLink>
            )}
          </h2>
        </div>
      </section>
    </>
  );
};

export default SheduleMeeting;
