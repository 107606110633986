import React, { useEffect, useRef, useState } from "react";
import styles from "../assets/css/contactus.module.css";
import useClickOutside from "../customHooks/useClickOutside";
import { BackgroundBlur } from "../utils/Popups";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailSchema,
  minPrice,
  minPriceRange,
  minYear,
  mnumberSchema,
  stringFyName,
  typeValidation,
} from "../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProdAddData,
  setCategories,
  filterCategories,
  fetchCategories,
} from "../app/Slices/ProdAddSlice";
import { editpen, postDropdown } from "../assets/images/index";
import { postData1 } from "../services";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CompanyTitle } from "../assets/data/data";
import { handleCreateRequirement } from "../app/Slices/UserData";

export const CreateRequirement = () => {
  const [selectedCondition, setSelectedCondition] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryName = useSelector(
    (state) => state.addProd.prodAddData.categories
  );
  const categoriesList = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );
  const filterCategories = categoriesList?.filter((el) => {
    return el.name !== "adminTest";
  });
  const [filterCat, setFilterCat] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const ref = useRef(null);

  const [isOpen, setOpen] = useState(false);

  const closeCategories = () => {
    setOpen(false);
  };

  useClickOutside(ref, closeCategories);

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  const blockInvalidString = (e) => [" "].includes(e.key) && e.preventDefault();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contact: "",
      location: "",
      prodtype: "",
      prodCate: "",
      price_min: "1",
      price_max: "",
      year_min: "2015",
      year_max: "",
    },
    validationSchema: yup.object().shape({
      name: stringFyName,
      email: emailSchema,
      contact: mnumberSchema,
      location: stringFyName,
      year_min: minYear,
      year_max: yup.number().when(["prodtype", "year_min"], {
        is: (prodtype, year_min) => prodtype === 1 && year_min !== undefined,
        then: (value) =>
          yup
            .number()
            .min(
              yup.ref("year_min"),
              "Max year should be greater than or equal to Min year"
            )
            .validate(value),
      }),
      // year_max:  minYear.test(
      //   "max-year",
      //   "Max year should be greater than or equal to Min year",
      //   function (value) {
      //     const minYear = parseInt(formik.values.year_min, 10) || 0;
      //     const maxYear = parseInt(value, 10) || 0;
      //     return maxYear >= minYear;
      //   }
      // ),
      price_min: minPrice,
      price_max: yup.number().when(["prodtype", "price_min"], {
        is: (prodtype, price_min) => prodtype === 1 && price_min !== undefined,
        then: (value) =>
          yup
            .number()
            .min(
              yup.ref("price_min"),
              "Max Price should be greater than min price"
            )
            .validate(value),
      }),
      // price_max: minPrice.test(
      //   "Max-Price",
      //   "Max Price shouble be greater than min price",
      //   function (value) {
      //     const minPrice = parseInt(formik.values.price_min, 10) || 0;
      //     const maxPrice = parseInt(value, 10) || 0;
      //     return maxPrice > minPrice;
      //   }
      // ),
      prodtype: typeValidation,
    }),
    onSubmit: function (values) {
      handleReqForm(values);
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "") {
      formik.handleChange(e);
      formik.setFieldValue(name, value);
    } else {
      formik.setFieldValue(name, "");
    }
  };

  const filterCategoriesList = () => {
    const fill = filterCategories?.filter((el) => {
      if (
        el?.name?.toLowerCase().includes(formik.values.prodCate?.toLowerCase())
      ) {
        return el;
      }
    });
    setFilterCat(fill);
  };

  useEffect(() => {
    filterCategoriesList();
  }, [formik.values.prodCate]);

  const handleDropDown = () => {
    setOpen(!isOpen);
    setFilterCat(isOpen ? [] : filterCategories);
  };

  const handleCateChecked = (event, catgorie) => {
    dispatch(setCategories(Number(event.target.value)));
    if (event.target.checked) {
      setSelectedCat([...selectedCat, catgorie]);
    } else {
      let fill = selectedCat?.filter((el) => {
        return el !== catgorie;
      });
      setSelectedCat(fill);
    }
  };

  const handleReqForm = async (val) => {
    if (categoryName?.length > 0) {
      let data = {};
      if (val?.prodtype === "1") {
        data = {
          name: val?.name,
          email: val?.email,
          contact_no: val?.contact,
          location: val?.location,
          price_range: `${val?.price_min}-${val?.price_max}`,
          year_range: `${val?.year_min}-${val?.year_max}`,
          condition: val?.prodtype,
          category_list: categoryName?.join(","),
        };
      } else {
        data = {
          name: val?.name,
          email: val?.email,
          contact_no: val?.contact,
          location: val?.location,
          condition: val?.prodtype,
          category_list: categoryName?.join(","),
        };
      }

      const res = await postData1("product/add_requirementform/", data);
      if (res?.status) {
        formik.handleReset();
        toast.success("Form submitted successfully !");
        dispatch(clearProdAddData());
        dispatch(handleCreateRequirement(false));
      } else {
        toast.error(res?.msg);
      }
    } else {
      toast.error("Category Field is Required !");
    }
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <React.Fragment>
      <ToastContainer />
      <BackgroundBlur />
      <div className={styles.reqContainer}>
        <div className={styles.SubreqContainer}>
          <div className={styles.closeReq}>
            <i
              class="bi bi-x name: val?.name,
                      email: val?.email,
                   contact_no: val?.contact,
                  location: val?.location,
               price_range: `${val?.price_min}-${val?.price_max}`,
                year_range: `${val?.year_min}-${val?.year_max}`,
                condition: val?.prodtype,"
              onClick={() => dispatch(handleCreateRequirement(false))}
            ></i>
          </div>
          <h2 className={styles.create_reuir}> Create Your Requirement</h2>
          <p style={{ fontSize: "18px", textAlign: "center" }}>
            {CompanyTitle} will find it for you
          </p>
          <form className={styles.reqForm} onSubmit={formik.handleSubmit}>
            <h3 className={styles.formtitle}>Personal Information</h3>
            <div className={styles.contRow}>
              <div className={styles.contField}>
                <span>Name</span>
                <input
                  type="text"
                  placeholder="Enter your  full name"
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <span
                    className={styles.errors}
                    style={{ color: "red", width: "100%" }}
                  >
                    {formik.errors.name}
                  </span>
                ) : null}
              </div>
              <div className={styles.contField}>
                <span>Email Id</span>
                <input
                  type="text"
                  placeholder="Enter your emal id"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <span
                    className={styles.errors}
                    style={{ color: "red", width: "100%" }}
                  >
                    {formik.errors.email}
                  </span>
                ) : null}
              </div>
            </div>
            <div className={styles.contRow}>
              <div className={styles.contField}>
                <span>Contact No.</span>
                <input
                  type="number"
                  placeholder="Enter your contact number"
                  name="contact"
                  id="contact"
                  onKeyDown={blockInvalidChar}
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.contact && formik.errors.contact ? (
                  <span
                    className={styles.errors}
                    style={{ color: "red", width: "100%" }}
                  >
                    {formik.errors.contact}
                  </span>
                ) : null}
              </div>
              <div className={styles.contField}>
                <span>Location</span>
                <input
                  type="text"
                  placeholder="Enter your Location"
                  name="location"
                  id="location"
                  value={formik.values.location}
                  onChange={handleInputChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.location && formik.errors.location ? (
                  <span
                    className={styles.errors}
                    style={{ color: "red", width: "100%" }}
                  >
                    {formik.errors.location}
                  </span>
                ) : null}
              </div>
            </div>

            <h3 className={styles.formtitle}>Equipment Information</h3>

            <div className={styles.contRow} ref={ref}>
              <div className={styles.contField}>
                <span className={styles.equip_cat}>Equipment Category</span>
                <div className={styles.catDrop}>
                  <input
                    type="text"
                    placeholder="Select equipment"
                    name="prodCate"
                    id="prodCate"
                    autoComplete="off"
                    value={
                      !isOpen
                        ? selectedCat?.length > 0
                          ? selectedCat?.map((el) => el?.slice(0, 40))
                          : ""
                        : formik.values.prodCate
                    }
                    onChange={handleInputChange}
                    onBlur={formik.handleBlur}
                  />
                  <img
                    src={postDropdown}
                    onClick={handleDropDown}
                    style={{ cursor: "pointer" }}
                    alt="..."
                  />
                </div>
                {isOpen && (
                  <div
                    style={{ marginTop: "10px" }}
                    className={
                      filterCat?.length > 0
                        ? styles.categoryListST
                        : styles.categoryListST1
                    }
                  >
                    {filterCat.length > 0 ? (
                      filterCat
                        ?.slice()
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map((value) => {
                          return (
                            <div className={styles.checkboxCont}>
                              <input
                                type="checkbox"
                                id="category"
                                value={value.id}
                                name="category"
                                autoComplete="off"
                                onChange={(e) =>
                                  handleCateChecked(e, value?.name)
                                }
                                checked={categoryName.includes(value?.id)}
                              />
                              <label for="checkbox1">{value.name}</label>
                            </div>
                          );
                        })
                    ) : (
                      <div className={styles.checkboxCont}>
                        <input
                          type="checkbox"
                          id="category"
                          value={1057}
                          name="category"
                          autoComplete="off"
                          onChange={(e) => handleCateChecked(e, "Other")}
                          checked={categoryName.includes(1057)}
                        />
                        <label for="checkbox1">Other</label>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.contField}>
                <span className={styles.condition_equi}>
                  Condition of Equipment
                </span>
                <div className={styles.reqRadio}>
                  {/* <div>
                    <input
                      type="radio"
                      name="prodtype"
                      id="prodtype"
                      value="1"
                      checked={formik.values.prodtype === "1"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span>Pre Owned</span>
                  </div> */}
                  <div>
                    <input
                      type="radio"
                      name="prodtype"
                      id="prodtype"
                      value="1"
                      checked={selectedCondition === "1"}
                      onChange={() => {
                        formik.handleChange("prodtype")("1");
                        setSelectedCondition("1");
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <span>Pre Owned</span>
                  </div>

                  {/* <div>
                    <input
                      type="radio"
                      name="prodtype"
                      id="prodtype"
                      value="2"
                      checked={formik.values.prodtype === "2"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <span>New</span>
                  </div> */}
                  <div>
                    <input
                      type="radio"
                      name="prodtype"
                      id="prodtype"
                      value="2"
                      checked={selectedCondition === "2"}
                      onChange={() => {
                        formik.handleChange("prodtype")("2");
                        setSelectedCondition("2");
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <span>New</span>
                  </div>
                </div>

                {/* --------- */}
                {formik.touched.prodtype && formik.errors.prodtype ? (
                  <span
                    className={styles.errors}
                    style={{ color: "red", width: "80%" }}
                  >
                    {formik.errors.prodtype}
                  </span>
                ) : null}
              </div>
            </div>

            <div className={styles.contRow}>
              {/* new price start  */}
              {selectedCondition === "1" && (
                <div className={styles.contField}>
                  <span>Price Range</span>
                  {/* ... existing code for price range */}
                  {/* <input
                            type="number"
                            placeholder="min price"
                            value={formik.values.price_min}
                            name="price_min"
                            id="price_min"
                            onKeyDown={blockInvalidChar}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.price_min && formik.errors.price_min ? (
                            <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                              {formik.errors.price_min}
                            </span>
                          ) : null} */}
                  <input
                    type="number"
                    placeholder="max price"
                    value={formik.values.price_max}
                    name="price_max"
                    id="price_max"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.prodtype === 1 &&
                  formik.touched.price_max &&
                  formik.errors.price_max ? (
                    <span
                      className={styles.errors}
                      style={{ color: "red", width: "95%" }}
                    >
                      {formik.errors.price_max}
                    </span>
                  ) : null}
                </div>
              )}

              {selectedCondition === "1" && (
                <div className={styles.contField}>
                  <span className={styles.equip_year_range}>
                    Equipment Year Range
                  </span>
                  {/* ... existing code for year range */}
                  {/* <input
                                    type="text"
                                    placeholder="min year"
                                    value={formik.values.year_min}
                                    name="year_min"
                                    id="year_min"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.year_min && formik.errors.year_min ? (
                                    <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                                      {formik.errors.year_min}
                                    </span>
                                  ) : null} */}
                  <input
                    type="text"
                    placeholder="max year"
                    value={formik.values.year_max}
                    name="year_max"
                    id="year_max"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.prodtype === 1 &&
                  formik.touched.year_max &&
                  formik.errors.year_max ? (
                    <span
                      className={styles.errors}
                      style={{ color: "red", width: "95%" }}
                    >
                      {formik.errors.year_max}
                    </span>
                  ) : null}
                </div>
              )}

              {/* 
              <div className={styles.contField}>
                <span>Price Range</span>
                <input
                  type="number"
                  placeholder="min price"
                  value={formik.values.price_min}
                  name="price_min"
                  id="price_min"
                  onKeyDown={blockInvalidChar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.price_min && formik.errors.price_min ? (
                  <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                    {formik.errors.price_min}
                  </span>
                ) : null}
                <input
                  type="number"
                  placeholder="max price"
                  value={formik.values.price_max}
                  name="price_max"
                  id="price_max"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.price_max && formik.errors.price_max ? (
                  <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                    {formik.errors.price_max}
                  </span>
                ) : null}
              </div>
              <div className={styles.contField}>
                <span>Equipment Year Range</span>
                <input
                  type="text"
                  placeholder="min year"
                  value={formik.values.year_min}
                  name="year_min"
                  id="year_min"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.year_min && formik.errors.year_min ? (
                  <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                    {formik.errors.year_min}
                  </span>
                ) : null}
                <input
                  type="text"
                  placeholder="max year"
                  value={formik.values.year_max}
                  name="year_max"
                  id="year_max"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.year_max && formik.errors.year_max ? (
                  <span className={styles.errors} style={{ color: "red", width: "95%" }}>
                    {formik.errors.year_max}
                  </span>
                ) : null}
              </div> */}
            </div>

            <div className={styles.contField} style={{ width: "100%" }}>
              <span className={styles.span_tag}>Description</span>
              <textarea
                style={{
                  border: "1px solid gray",
                  padding: "6px",
                  borderRadius: "5px",
                }}
                className={styles.text_area_part}
                name=""
                id=""
                cols="30"
                rows="4"
              ></textarea>
            </div>
            <p className={styles.before_final}>
              Note : Recheck all the details before final submission of the
              form.
            </p>

            <input type="submit" className={styles.contSubmit} value="SUBMIT" />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
