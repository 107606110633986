import { createSlice } from "@reduxjs/toolkit";
import { postData } from "../../services";

export const ProdListSlice = createSlice({
  name: "prodList",
  initialState: {
    prodListData: {
      user_plist: [],
      filter_catlist: [],
      order_list: [],
    },
  },
  reducers: {
    setProdList: (state, action) => {
      state.prodListData.user_plist = action.payload;
    },
    setFilterCatList: (state, action) => {
      state.prodListData.filter_catlist = action.payload;
    },
    setOrderList: (state, action) => {
      state.prodListData.order_list = action.payload;
    },
  },
});

export const { setProdList, setFilterCatList, setOrderList } =
  ProdListSlice.actions;

export const fetchUserPList = (id, page, limit) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("user", id);
    formData.append("_page", page);
    formData.append("_limit", limit);
    const res = await postData("product/plists/", formData);
    if (res?.status) {
      dispatch(setProdList(res?.data));
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const fetchProdCategories = (category_list) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("id", category_list);
    const res = await postData("product/category/menulist/", formData);
    if (res?.status) {
      dispatch(setFilterCatList(res?.data));
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export const fetchOrderList = (uid) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("buyer", uid);
    const res = await postData("product/order/lists/", formData, true);
    if (res?.status) {
      dispatch(setOrderList(res?.data));
    }
  } catch (error) {
    console.log(error, "error");
  }
};

export default ProdListSlice.reducer;
