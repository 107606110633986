/* eslint-disable react/prop-types */
import { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import { useSwipeable } from "react-swipeable";

const VideoSlider = ({videos}) => {

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(videos.length / itemsPerPage);

  // Get current page videos
  const currentVideos = videos.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Change page function
  const handleNext = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (prev === 0 ? totalPages - 1 : prev - 1));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventScrollOnSwipe: true,
    trackMouse: true, // Allows swiping with mouse on desktop as well
  });

  const [currentMobileIndex, setCurrentMobileIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const openPopup = (index) => {
    console.log("video clicked....");
    setCurrentMobileIndex(index);
    setIsPopUpOpen(true);
  };

  const closePopup = () => {
    setIsPopUpOpen(false);
  };

  return (
    <>
      {/* Mobile version */}
      <div className="relative w-full flex justify-center items-center md:hidden lg:hidden xl:hidden">
        <div className="relative overflow-hidden w-full max-w-lg">
          <div
            className={`flex transition-transform duration-300 ease-in-out ${
              isSliding ? "transform" : ""
            }`}
          >
            {videos.map((video, index) => (
              <div key={index} className="w-full flex-shrink-0">
                <video
                  onClick={() => openPopup(index)}
                  src={video.video_url}
                  className="w-full h-64 object-cover rounded-lg shadow-lg cursor-pointer"
                  controls
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Tablets and Desktop version */}
      <div
        className="hidden md:block md:relative md:w-full lg:block lg:relative lg:w-full xl:block xl:relative xl:w-full "
        {...swipeHandlers}
      >
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          {currentVideos.length === 0
            ? ""
            : currentVideos.map((video, index) => (
                <div
                  key={index}
                  className="bg-white shadow-md shadow-green-200 rounded-lg relative transition duration-500 ease-in-out hover:shadow-lg hover:-translate-y-1 hover:scale-110"
                >
                  <div className="flex flex-col">
                    <video
                      src={video.video_url}
                      className="h-40 w-full object-cover rounded-md m-2 cursor-pointer"
                      controls
                      onClick={() => openPopup(index)}
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>

      {isPopUpOpen && (
        <PopUpModel
          videos={videos}
          index={currentMobileIndex}
          close={closePopup}
        />
      )}
    </>
  );
};

const PopUpModel = ({ videos, index, close }) => {
  const [currentMobileIndex, setCurrentMobileIndex] = useState(index);
  const [isSliding, setIsSliding] = useState(false);

  const incrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex((prev) => (prev + 1) % videos.length);
      setIsSliding(false);
    }, 300);
  };

  const decrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex((prev) =>
        prev - 1 < 0 ? videos.length - 1 : prev - 1
      );
      setIsSliding(false);
    }, 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: incrementMobileIndex,
    onSwipedRight: decrementMobileIndex,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-[999]"
      {...handlers}
    >
      <div className="relative overflow-hidden w-full max-w-lg">
        <div
          className="flex transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentMobileIndex * 100}%)` }}
        >
          {videos.map((video, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <video
                src={video.video_url}
                className="w-full h-full object-cover rounded-lg shadow-lg"
                controls
              />
            </div>
          ))}
        </div>

        {/* Left arrow */}
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
          onClick={decrementMobileIndex}
        >
          <FaAngleLeft />
        </button>

        {/* Right arrow */}
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
          onClick={incrementMobileIndex}
        >
          <FaAngleRight />
        </button>

        <button
          className="absolute top-2 right-2 p-2 bg-red-500 rounded-full text-white"
          onClick={close}
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default VideoSlider;
