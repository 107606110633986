import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import styles from "../../../assets/css/buy/paymentHistory.module.css";
import { postData } from "../../../services";
import Pagination from "../../../utils/Pagination";
import ExcelButton from "../../../ExcelFormate/ExcelButton";
import { orderStatusImag3 } from "../../../assets/images";

export const PaymentHistory = () => {
  const userId = localStorage.getItem("uid");
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [filterHistory, setFilterHistory] = useState([]);
  const initialValue = { startDate: "", endDate: "", type: "", status: "" };
  const [filterData, setFilterData] = useState(initialValue);
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [loader, setloader] = useState(true);
  const [totalPage, setTotalPage] = useState({});
  const [pageNo, setPageNO] = useState(1);

  const handlePaymentHistory = async () => {
    const formData = new FormData();
    formData.append("user", userId);
    formData.append("_page", pageNo);
    formData.append("_limit", 20);
    const res = await postData("product/order/paymentlist/", formData, true);
    if (res.status) {
      setloader(false);
      setTotalPage({
        totalPage: res?.data?.total_pages,
        totalItem: res?.data?.total,
      });
      let reverseData = [...res?.data?.data].reverse();
      setPaymentHistory(reverseData);
    }
  };
  useEffect(() => {
    handlePaymentHistory();
  }, [pageNo]);

  useEffect(() => {
    handleFilterSearch();
  }, [search]);

  const handleFilterSearch = () => {
    if (search.length > 0) {
      let fill = paymentHistory?.filter((el) => {
        if (el?.uid?.toLowerCase().includes(search?.toLowerCase())) {
          return el;
        }
      });
      setFilterHistory(fill);
    } else {
      setFilterHistory([]);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.trim() !== "") {
      setSearch(value);
    } else {
      setSearch("");
    }
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitData = () => {
    let fill = paymentHistory.filter((el) => {
      let createDate = new Date(el.created_date).toISOString().split("T")[0];
      if (
        createDate >= filterData.startDate &&
        createDate <= filterData.endDate &&
        el.payment_status === Number(filterData.status) &&
        el.payment_type === Number(filterData.type)
      ) {
        setToggle(true);
        return el;
      } else {
        return false;
      }
    });
    setFilterHistory(fill);
  };

  const paymentType = (type) => {
    if (type === 1) {
      return "Paytm UPI ID";
    } else if (type === 2) {
      return "UPI";
    } else if (type === 3) {
      return "Credit/ Debit/ ATM Card";
    } else if (type === 4) {
      return "Net Banking";
    } else {
      return "EMI";
    }
  };

  const paymentStatus = (status) => {
    if (status === 1) {
      return "Pending";
    } else if (status === 2) {
      return "Token";
    } else {
      return "Completed";
    }
  };
  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=8595903939', '_blank');
  };


  return (
    <section className={styles.PaymentHistsec}>
      <div className={styles.paymentHist}>
        <h1>Payment History</h1>
        <div>
          <div className={styles.paymentHisTop}>
            <div>
              <h5>View your transaction history</h5>
            </div>
            <div className={styles.paymentHistsearch}>
              <span>
                <i class="bi bi-search"></i>
              </span>
              <input
                type="text"
                name="search"
                onChange={(e) => handleSearch(e)}
                placeholder="Invoice No."
              />
            </div>
          </div>
          <div className={styles.paymentHead}>
            <PaymentHisDate
              start={filterData?.startDate}
              end={filterData?.endDate}
              handle={handleChangeData}
            />
            <PaymentHisType type={filterData.type} handle={handleChangeData} />
            <PaymentHisStatus
              status={filterData.status}
              handle={handleChangeData}
            />

            {/* <PaymentHisUsers /> */}
            <ApplyBtn
              submit={handleSubmitData}
              setFilterData={setFilterData}
              setToggle={setToggle}
              data={paymentHistory}
              paymentType={paymentType}
              paymentStatus={paymentStatus}
            />
            {/* <div className={styles.productHead}> */}
              <button className={styles.productHead} onClick={handleWhatsAppClick}>
                {" "}
                <strong>
                  <img src={orderStatusImag3} alt="" />
                </strong>{" "}
                Need Help
              </button>
            {/* </div> */}
          </div>
          <div className={styles.paymentHisContent}>
            {loader && <Loader />}
            <PaymentHisTable
              filterHis={filterHistory}
              search={search}
              toggle={toggle}
              history={paymentHistory}
              filterData={filterData}
              paymentType={paymentType}
              paymentStatus={paymentStatus}
            />
          </div>
          {}
          {!filterHistory?.length && (
            <Pagination
              setPageNO={setPageNO}
              pageNo={pageNo}
              totalPage={totalPage?.totalPage}
              totalItem={totalPage?.totalItem}
            />
          )}
        </div>
      </div>
    </section>
  );
};

const PaymentHisDate = ({ handle, start, end }) => {
  return (
    <div className={styles.PaymentHisDate}>
      <div className={styles.formLabel}>
        <label htmlFor="">From</label>
        <input
          type="date"
          name="startDate"
          id="startDate"
          value={start}
          onChange={handle}
        />
      </div>
      <div className={styles.formLabel}>
        <label htmlFor="">To</label>
        <input
          type="date"
          name="endDate"
          id="endDate"
          value={end}
          onChange={handle}
        />
      </div>
    </div>
  );
};

const PaymentHisType = ({ handle, type }) => {
  return (
    <div className={styles.PaymentHisType}>
      <div className={styles.formLabel}>
        <label htmlFor="">Type</label>
        <select name="type" id="type" onChange={handle}>
          <option value="">All</option>
          <option value={1} selected={type === "1"}>
            Paytm UPI ID
          </option>
          <option value={2} selected={type === "2"}>
            UPI
          </option>
          <option value={3} selected={type === "3"}>
            Credit/ Debit/ ATM Card
          </option>
          <option value={4} selected={type === "4"}>
            Net Banking
          </option>
          <option value={5} selected={type === "5"}>
            EMI
          </option>
        </select>
      </div>
    </div>
  );
};

const PaymentHisStatus = ({ handle, status }) => {
  return (
    <div className={styles.PaymentHisType}>
      <div className={styles.formLabel}>
        <label htmlFor="">Status</label>
        <select name="status" id="status" onChange={handle}>
          <option value="">All</option>
          <option value={1} selected={status === "1"}>
            Pending
          </option>
          <option value={2} selected={status === "2"}>
            Token
          </option>
          <option value={3} selected={status === "3"}>
            Completed
          </option>
        </select>
      </div>
    </div>
  );
};
const PaymentHisUsers = () => {
  return (
    <div className={styles.PaymentHisUsers}>
      <div className={styles.formLabel}>
        <label htmlFor="">Users</label>
        <select name="" id="">
          <option value="">All</option>
          <option value="">select 1</option>
          <option value="">select 1</option>
          <option value="">select 1</option>
        </select>
      </div>
    </div>
  );
};

const ApplyBtn = ({
  submit,
  setFilterData,
  setToggle,
  data,
  paymentType,
  paymentStatus,
}) => {
  let excelData = [];

  data?.map((el) => {
    excelData.push({
      "Date & Time": new Date(el?.created_date).toLocaleDateString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
      Invoice: el?.uid,
      Type: paymentType(el?.payment_type),
      Status: paymentStatus(el?.payment_status),
      Amount: el?.total,
    });
  });

  const handleFilter = () => {
    setToggle(false);
    setFilterData({
      startDate: "",
      endDate: "",
      type: "",
      status: "",
    });
  };
  return (
    <div className={styles.applyBtn}>
      <div>
        <button type="submit" onClick={submit}>
          Apply
        </button>
      </div>
      <div>
        <button className={styles.cancBtn} type="btn" onClick={handleFilter}>
          Cancel
        </button>
      </div>
      <ExcelButton
        data={excelData}
        filename="payment-history.xlsx"
        sheetname="Sheet 1"
      />
      {/* <div>
        <h5>
          <span>
            <i class="bi bi-box-arrow-in-down"></i>
          </span>{" "}
          Export
        </h5>
      </div> */}
    </div>
  );
};

const PaymentHisTable = ({
  history,
  search,
  filterHis,
  toggle,
  filterData,
  paymentType,
  paymentStatus,
}) => {
  return (
    <div className={styles.paymentHisTable}>
      <table>
        <thead>
          <th>DATE & TIME</th>
          <th>INVOICE NO.</th>
          <th>Product Id</th>
          <th>STATUS</th>
          <th>AMOUNT</th>
        </thead>
        {search || toggle
          ? filterHis.map((history) => {
              return (
                <>
                  <tbody>
                    <td>
                      {new Date(history?.created_date).toLocaleDateString(
                        "default",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                      <p>
                        {new Date(history?.created_date).toLocaleTimeString(
                          "default",
                          { hour: "numeric", minute: "numeric" }
                        )}
                      </p>
                    </td>
                    <td>{history?.uid}</td>
                    <td>{history?.product}</td>
                    <td className={styles.paid}>
                      <span>{paymentStatus(history?.payment_status)}</span>
                    </td>
                    <td>
                      <span>&#8377; </span>
                      {history?.total}
                    </td>
                  </tbody>
                </>
              );
            })
          : history?.reverse()?.map((history) => {
              return (
                <>
                  <tbody>
                    <td>
                      {new Date(history?.created_date).toLocaleDateString(
                        "default",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                      <p>
                        {new Date(history?.created_date).toLocaleTimeString(
                          "default",
                          { hour: "numeric", minute: "numeric" }
                        )}
                      </p>
                    </td>
                    <td>{history?.uid}</td>
                    <td>{history?.product}</td>
                    <td className={styles.paid}>
                      <span>{paymentStatus(history?.payment_status)}</span>
                    </td>
                    <td>
                      <span>&#8377; </span>
                      {history?.total}
                    </td>
                  </tbody>
                </>
              );
            })}
      </table>
    </div>
  );
};
