// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_main_containter__8i7dc {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 98%;
  /* height: 100px; */
  /* border: 1px solid red; */
}
.loader_loader__ycsXv {
  margin-top: 2rem;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 5px solid #019c89;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: loader_rotation__2rcxd 1s linear infinite;
}
@keyframes loader_rotation__2rcxd {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/loader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gCAAgC;EAChC,kBAAkB;EAClB,sBAAsB;EACtB,oDAAsC;AACxC;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".main_containter {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  max-width: 98%;\r\n  /* height: 100px; */\r\n  /* border: 1px solid red; */\r\n}\r\n.loader {\r\n  margin-top: 2rem;\r\n  display: inline-block;\r\n  width: 40px;\r\n  height: 40px;\r\n  border: 5px solid #019c89;\r\n  border-bottom-color: transparent;\r\n  border-radius: 50%;\r\n  box-sizing: border-box;\r\n  animation: rotation 1s linear infinite;\r\n}\r\n@keyframes rotation {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_containter": `loader_main_containter__8i7dc`,
	"loader": `loader_loader__ycsXv`,
	"rotation": `loader_rotation__2rcxd`
};
export default ___CSS_LOADER_EXPORT___;
