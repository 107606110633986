import React, { useCallback, useEffect, useRef, useState } from "react";
import { hero } from "../assets/images/index";
import { Search } from "./Navigation";
import styles from "../assets/css/hero.module.css";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { plus_symbol, minus, m_search, downIcon } from "../assets/images/index";
import { FaMinus } from "react-icons/fa";
import {
  fetchCategories,
  fetchSpecialityName,
} from "../app/Slices/ProdAddSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../utils/Loader";
import { Loader as Loader2 } from "./Loader";
import { postData, postData1 } from "../services";
import useClickOutside from "../customHooks/useClickOutside";
import UseDebounce from "../utils/UseDebounce";
import { ToastContainer, toast } from "react-toastify";
import { useDebounce } from "@uidotdev/usehooks";

export const Hero = (props) => {
  const userId = localStorage.getItem("uid");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const heroStyle = {
    backgroundImage: `url(${hero})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const handlePostValid = (event) => {
    // if (userId) {
    //   setLoader(true);
    //   (async () => {
    //     const res = await postData1("users/get_user_detail/", {}, true, false);
    //     if (res?.status_code === 200) {
    //       if (Object.keys(res?.data?.profile).length === 0) {
    //         toast.warning("Please complete your profile first!",{toastId: 'success1'});
    //       } else {
    //         navigate("/post/");
    //       }
    //     } else {
    //       toast.error("Something went wrong, please try after some time !");
    //     }
    //     setLoader(false);

    //   })();
    // } else {
    //   navigate("/user/login/");
    // }
    navigate("/post/");
  };
  return (
    <>
      <div id="heroBlur">
        {loader && <Loader2 />}
        <div style={heroStyle} className={styles.heroContainer}>
          <Catogories />
          <div className={styles.heroContent}>
            <p className={styles.heroHead}>
              Empower Your Health Journey Monitor Blood Pressure Like a Pro!
            </p>
            <p className={styles.heroDesc}>
              Precision measurements, advanced technology. Take control of your
              health with accurate blood pressure monitoring for informed
              decisions and optimal well-being
            </p>
            <NavLink to="/search/" className={styles.buyBtn}>
              Buy Product
            </NavLink>
            <div onClick={handlePostValid} className={styles.postAdvt}>
              Post Your Advertisement
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Catogories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const filterCategoriesName = categories?.filter((el) => {
    return el.name !== "adminTest";
  });
  const [filterEquip, setFilterEquip] = useState("");
  const loader = useSelector((state) => state.addProd.prodAddData.isCatLoading);

  const handleChange = (e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    if (regex.test(e.target.value)) {
      setFilterEquip(e.target.value);
    } else {
      setFilterEquip("");
    }
  };

  const handleKeyPress = (e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (filterEquip?.length > 0) {
      dispatch(fetchCategories(filterEquip));
    } else {
      dispatch(fetchCategories());
    }
  }, [filterEquip]);

  return (
    <div className={styles.catogories_container}>
      <div className={styles.upper_part}>
        {/* <span>FILTER</span> */}

        <Search
          placeholder="Search by category.."
          width={294}
          height={30}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          categorySearch={filterEquip}
          buttonWidth={48}
          buttonHeight={22}
          buttonFontSize={14}
          // isheader={true}
        />
        <p>EQUIPMENT</p>
      </div>
      <div className={styles.lower_part}>
        {loader && <Loader />}
        {filterCategoriesName?.length > 0
          ? filterCategoriesName
              ?.slice()
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((topLevelEquipment) => {
                return (
                  <CatItem
                    key={topLevelEquipment?.id}
                    pic={plus_symbol}
                    equipment={topLevelEquipment}
                  />
                );
              })
          : !loader && (
              <div
                style={{
                  color: "#4E4D4D",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                EQUIPMENT NOT FOUND
              </div>
            )}
      </div>
    </div>
  );
};

const CatItem = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleNodeClick = () => {
    if (props?.equipment?.children?.length > 0) {
      setIsExpanded(!isExpanded);
    }
  };
  const handleProdNameClick = (name) => {
    if (props?.equipment?.children?.length > 0) {
      setIsExpanded(!isExpanded);
    } else {
      const encodedEquipName = encodeURIComponent(name);
      navigate(`/search/search-items/${encodedEquipName}/`, {
        state: { cat: name },
      });
    }
  };

  return (
    <div className={styles.cat_item}>
      <div>
        <div className={styles.cat_inner}>
          {props?.equipment?.children?.length > 0 ? (
            <img
              src={props?.pic}
              alt="..."
              onClick={handleNodeClick}
              className={styles.in_img}
            />
          ) : (
            <FaMinus />
          )}
          <span onClick={() => handleProdNameClick(props?.equipment?.name)}>
            {props?.equipment?.name}
          </span>
        </div>
        {isExpanded &&
          props?.equipment?.children?.map((child) => {
            return (
              <CatItem
                key={child?.id}
                pic={child?.children?.length > 0 ? props?.pic : <FaMinus />}
                equipment={child}
              />
            );
          })}
      </div>
    </div>
  );
};

export const MobileHero = () => {
  return (
    <div className={styles.MobileHero}>
      {/* <p style={{ textAlign: "center", marginTop:"40px" }}>WHAT ARE YOU LOOKING FOR?</p> */}
      <div className={styles.MobileHeroSearch}>
        <MobileSearch />
      </div>
    </div>
  );
};
export const MobileSearch = (props) => {
  const location = useLocation();
  const { toggle, setToggle, click } = props;
  const [togg, setTogg] = useState(toggle);
  const navigate = useNavigate();
  const [searchEqip, setSearchEquip] = useState("");
  const [loading, setLoading] = useState(false);
  const [getCategories, setCategories] = useState([]);
  const [filterCategories, setFilterCategoires] = useState([]);
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const handleEquipCategories = async () => {
    const formData = new FormData();
    formData.append("q", searchEqip);
    if (location?.pathname?.split("/")[1] === "speciality-search") {
      const res = await postData("product/speciality/lists/", formData);
      if (res.status) {
        setCategories(res.data);
      }
    } else {
      setCategories(categories);
    }
  };

  useDebounce(
    () => {
      setFilterCategoires(
        getCategories?.filter((d) => {
          return d?.name?.toLowerCase()?.includes(searchEqip?.toLowerCase());
        })
      );
    },
    [getCategories, searchEqip],
    2000
  );

  useEffect(() => {
    handleEquipCategories();
  }, [searchEqip]);

  const data = [
    "Ultrasound Machines",
    "CT Scanners",
    "IPL Machines",
    "MRI machines",
    "X-ray machines",
    "Alexandrite Lasers",
    "Optical coherence tomography",
    "Shock wave therapy machines",
    "Dialysis Machine",
  ];

  const ref = useRef(null);

  const clickOutSide = () => {
    setTogg(false);
    setSearchEquip("");
  };
  useClickOutside(ref, clickOutSide);

  const handleSearchItems = (item) => {
    const encodedEquipName = encodeURIComponent(item);
    navigate(`/search/search-items/${encodedEquipName}/`, {
      state: { cat: item },
    });
    setTogg(false);
    setSearchEquip("");
  };

  const handleScrollSpeed = (e) => {
    const scrollSpeed = 2;
    ref.current.scrollTop += e.deltay * scrollSpeed;
  };

  const handleCategoriesChange = (e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    if (regex.test(e.target.value)) {
      setSearchEquip(e.target.value);
    } else {
      setSearchEquip("");
    }
    setTogg(true);
  };

  return (
    <>
        {location.pathname === "/" && <SearchType placeholder="Find medical instrument.."/>}
    
      {/* <form
        style={{ position: "relative" }}
        ref={ref}
        className={togg ? styles.mobileSearch1 : styles.mobileSearch}
      >
        <div>
          <input
            ref={click}
            type="text"
            style={{
              border: !togg ? "2px solid #019C89" : "",
              width: togg ? "80%" : "",
            }}
            className={styles.inputField}
            value={searchEqip}
            onClick={() => {
              setTogg(true);
              // setToggle(true);
            }}
            onChange={(e) => handleCategoriesChange(e)}
            placeholder="Find medical equipments.."
          />
        </div>
        {togg ? (
          <button disabled={true} className={styles.searchBtn}>
            Search
          </button>
        ) : (
          <img src={m_search} alt="..." />
          // <div>
          //   <Category/>
          // </div>
        )}
        {togg && loading ? (
          <p className={styles.loadingView} />
        ) : togg ? (
          <p className={styles.loadingView1} />
        ) : (
          ""
        )}
        <div
          style={{ padding: "10px" }}
          ref={ref}
          onWheel={handleScrollSpeed}
          className={togg && searchEqip ? styles.searchCont1 : ""}
        >
          {searchEqip ? (
            filterCategories?.map((el) => {
              return (
                <>
                  <div
                    onClick={() => handleSearchItems(el?.name)}
                    className={el?.name ? styles.searchItem1 : ""}
                  >
                    <p className={styles.searchText}>
                      {`${searchEqip} in ${el?.name}`}
                    </p>
                  </div>
                </>
              );
            })
          ) : (
            <div className={styles.grid_container}>
              {togg
                ? data?.map((item, index) => (
                    <div
                      key={index}
                      className={styles.grid_item}
                      onClick={() => handleSearchItems(item)}
                    >
                      {item}
                    </div>
                  ))
                : ""}
            </div>
          )}
        </div>
      </form> */}
    </>
  );
};


export const SearchType = (props) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState(props?.categorySearch ?? "");
  const [categoriesList, setCategoriesList] = useState([]);
  const debouncedCategories = useDebounce(categories, 500);

  const handleClickOutSide = useCallback(() => {
    setCategories("");
  }, []);

  useClickOutside(ref, handleClickOutSide);

  const handleChange = useCallback((e) => {
    let regex = /^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/;
    
    if (regex.test(e.target.value)) {
      setCategories(e.target.value);
    } else {
      setCategories("");
    }
  }, []);

  const handleSubmit = useCallback((item) => {
    setCategories("");
    navigate(`/search/search-items/${encodeURIComponent(item)}/`, {
      state: { cat: item },
    });
  }, []);

  const handleSubmitCategory = useCallback(
    (event) => {
      event.preventDefault();
      
      if( categories === "") {
        return
      }
      
      navigate(`/search/search-items/${encodeURIComponent(categories)}/`, {
        state: { cat: categories },
      });
    },
    [categories]
  );

  const fetchCategoriesList = useCallback(async (categories) => {
    const formData = new FormData();
    formData.append("q", categories);

    try {
      const res = await postData("product/category/menulist/", formData);

      if (res?.data) {
        setCategoriesList(
          res.data.filter((d) => {
            return (
              d?.name !== "Other" &&
              d?.name?.toLowerCase().includes(categories?.toLowerCase())
            );
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    setCategories(props?.categorySearch ?? "");
  }, [props?.categorySearch]);

  useEffect(() => {
    if (debouncedCategories) {
      fetchCategoriesList(debouncedCategories);
    }
  }, [debouncedCategories]);

  return (
    <div ref={ref}>
      <form
        // className={styles.search}
        // style={{ width: props.width, height: props.height }}
        style={{height: "45px",}}
      >
        <input
          type="text"
          style={{
            height: "40px",
            width: "70%",
            fontSize: props.fontSize,
          }}
          value={categories}
          placeholder={props.placeholder}
          onChange={props?.handleChange ?? handleChange}
          onKeyDown={props?.handleKeyPress}
        />
        <input
          type="submit"
          value={props.buttonValue || "Search"}
          // onClick={(e) => e.preventDefault()}
          onClick={handleSubmitCategory}
          style={{
            height: "40px",
            paddingLeft: "2px",
            paddingRight: "2px",
            cursor: "pointer",
            // width: props.buttonWidth,
            // height: props.buttonHeight,
            // fontSize: props.buttonFontSize,
          }}
        />
      </form>

      {/* {categories && categoriesList?.length > 0 && (
        <div
          className={
            categoriesList?.length > 1
              ? styles.mainHeaderSearch
              : styles.mainHeaderSearch1
          }
          style={{
            width: props.width,
            height: props.height,
            display: props.showSearch == true ? "none" : "",
          }}
        >
          {categoriesList?.map((category) => {
            return (
              <p
                key={category?.id}
                onClick={() => handleSubmit(category?.name)}
                className={styles.subDropDOwn}
              >
                {category?.name}
              </p>
            );
          })}
        </div>
      )} */}
    </div>
  );
};


export const MobileCatogories = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  )
    ?.slice()
    ?.sort((a, b) => a.id > b.id);

  const filterCategoriesName = categories?.filter((el) => {
    return el.name !== "adminTest";
  });

  const speciality = useSelector(
    (state) => state.addProd.prodAddData.specialtiey_name
  )
    ?.slice()
    ?.sort((a, b) => a.id > b.id);

  const filterSPecialityName = speciality?.filter((el) => {
    return el.name !== "adminTest1" && el.name !== "admintTest2";
  });

  useEffect(() => {
    if (location?.pathname.split("/")[1] === "search") {
      dispatch(fetchCategories(""));
    } else if (location?.pathname.split("/")[1] === "speciality-search") {
      dispatch(fetchSpecialityName());
    }
  }, [location]);

  return (
    <div className={styles.mobileCatContainer}>
      {location?.pathname.split("/")[1] === "search"
        ? filterCategoriesName
            ?.slice()
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((values, index) => {
              return <CatgoriesDropDown key={index} data={values} />;
            })
        : filterSPecialityName
            ?.slice()
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((values, index) => {
              return <CatgoriesDropDown key={index} data={values} />;
            })}
    </div>
  );
};
const CatgoriesDropDown = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const subcat = props?.data?.children;
  const navigate = useNavigate();
  const handleList = () => {
    // navigate(
    //   `/search/search-items/${props?.data?.name.split("/").join("%")}/`,
    //   { state: { cat: props?.data?.name } }
    // );
  };
  return (
    <>
      {props.data.name ? (
        <div
          className={styles.catDrop}
          onMouseOver={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div onClick={handleList} className={styles.catTitle}>
            <span>{props?.data?.name}</span>
            {location?.pathname.split("/")[1] === "search" && (
              <img src={downIcon} alt="..." />
            )}
          </div>
          {location?.pathname.split("/")[1] === "search" && isOpen ? (
            <div className={styles.subCatogories}>
              {subcat?.map((value, index) => {
                return (
                  <NavLink
                    to={`/search/search-items/${encodeURIComponent(
                      value?.name
                    )}/`}
                    state={{ cat: value?.name }}
                    key={index}
                  >
                    {value?.name}
                  </NavLink>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <span>loading</span>
      )}
    </>
  );
};





const Category = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div className={styles.CategoryBtnDrop}>
      <select id="dropdown" value={selectedValue} onChange={handleChange}>
        <option value="">Category</option>
        <option value="option1">category 1</option>
        <option value="option2">category 2</option>
        <option value="option3">category 3</option>
      </select>
    </div>
  );
};

export default Category;

// non components Funtion
