import React, { useEffect, useState } from "react";
import { postData1 } from "../../../services";
import { orderListImg } from "../../../assets/images";
import orderStyle from "../../../assets/css/user/buyer_seller/order.module.css";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { checkBox } from "../../../assets/images";

const OrderList = () => {
  const buyerId = localStorage.getItem("uid");
  const [order, setorder] = useState([]);
  const [loader, setLoader] = useState(true);

  const handleOrderLists = async () => {
    const data = {
      buyer: buyerId,
    };
    const res = await postData1("product/order/lists/", data, true);
    if (res?.status) {
      setorder(res?.data?.reverse());
      setLoader(false);
    }
  };

  useEffect(() => {
    handleOrderLists();
  }, []);
  return (
    <section className={orderStyle.myOrder}>
      <div className={orderStyle.orderCard}>
        <h1>Order List</h1>

        <div className={orderStyle.orderTable}>
          <div className={orderStyle.tableHeading}>
            <li>
              <p>Product</p>
            </li>
            <li>
              <p>Order ID</p>
            </li>
            <li>
              <p>Order Date</p>
            </li>
            <li>
              <p>Avail Services</p>
            </li>
            <li>
              <p>Price</p>
            </li>
            <li>
              <p>Total</p>
            </li>
            <li>
              <p>Details</p>
            </li>
          </div>
          <div className={orderStyle.noDataF}>
            <div>
              {order?.length > 0
                ? order?.map((orderData, index) => {
                    return <OrderListCard key={index} orderData={orderData} />;
                  })
                : " No data found"}
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </section>
  );
};

const OrderListCard = ({ orderData }) => {
  const navigate = useNavigate();
  const orderDetails = () => {
    navigate(`/profile/order-detail/${orderData.id}`, {
      state: {
        productDetails: orderData,
      },
    });
  };
  return (
    <section className={orderStyle.orderList}>
      <div className={orderStyle.orderListImg}>
        <div className={orderStyle.product}>
          <img
            src={
              orderData?.product_detail?.product_images
                ? orderData?.product_detail?.product_images[0]?.product_images
                : orderListImg
            }
            // alt="orderImg"
          />
          <span>
            {orderData?.product_detail?.post_type === 1
              ? "PRE-OWNED"
              : orderData?.product_detail?.post_type === 2
              ? "NEW"
              : orderData?.product_detail?.post_type === 3
              ? "SPARE & ACCESSORIES"
              : "SERVICES"}
          </span>
        </div>
      </div>
      <div className={orderStyle.orderListImg}>
        <div>
          <h2>{orderData?.id}</h2>
        </div>
      </div>
      <div className={orderStyle.orderListImg}>
        <div>
          <h2>{new Date(orderData?.created_date).toLocaleDateString()}</h2>
        </div>
      </div>
      <div className={orderStyle.orderListImg}>
        <div className={orderStyle.availServices}>
          <h2>
            Shippping Eqipment
            <span>
              {orderData?.shipping_equipment ? (
                <img style={{ width: "15px", height: "15px" }} src={checkBox} />
              ) : (
                <img alt="" />
              )}
            </span>
          </h2>
          <h2>
            Handling/Installation
            <span>
              {orderData?.handling_instalation ? (
                <img style={{ width: "15px", height: "15px" }} src={checkBox} />
              ) : (
                <img alt="" />
              )}
            </span>
          </h2>
          <h2>
            AMC/CMC Service
            <span>
              {orderData?.amc_services ? (
                <img style={{ width: "15px", height: "15px" }} src={checkBox} />
              ) : (
                <img alt="" />
              )}
            </span>
          </h2>
        </div>
      </div>
      <div className={orderStyle.orderListImg}>
        <h2>
          <span>&#8377;</span>
          {Number(orderData?.asking_price).toFixed(2)}
        </h2>
      </div>
      <div className={orderStyle.orderListImg}>
        <h2>
          {" "}
          <span>&#8377;</span>
          {Number(orderData?.total).toFixed(2)}{" "}
        </h2>
      </div>
      <div className={orderStyle.orderListImg}>
        <h2
          onClick={orderDetails}
          style={{ color: "#019C89" }}
          className={orderStyle.viewData}
        >
          View
        </h2>
      </div>
    </section>
  );
};
export default OrderList;
