import React, { useState } from "react";
import styles from "../../assets/css/forms.module.css";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";

import { postData1 } from "../../../services";
import { ToastContainer, toast } from "react-toastify";

export const DistMFform = () => {
  const [formDisabled, setFormDisabled] = useState(true);
  const [perm, updatePerm] = useState(true);
  const location = useLocation();
  const userData = location.state;
  const [userStatus, setUserStatus] = useState(userData?.status);

  const formik = useFormik({
    initialValues: {
      resPerson: userData?.responsible_person,
      brands: userData?.product_deal,
      business: userData?.firm_name,
      website: userData?.website_name,
      phone: userData?.phone_no,
      email: userData?.email,
      location: userData?.office_location,
      eqipCat: userData?.equipname_list,
    },

    onSubmit: function (values) {
      // handleSubmit(values);
    },
  });

  const handlePermission = async () => {
    let body = { uid: userData?.uid, status: userData?.status ? 0 : 1 };
    const res = await postData1("admin/update_dist/", body, true, true);
    if (res?.status_code === 200) {
      toast.success("Permission updation successful");
      updatePerm(true);
      setUserStatus(body.status);
    } else {
      toast.warning("Something Went Wrong, Try Again !");
    }
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      <form className={styles.updateForm} disabled={true}>
        <div className={styles.formFieds}>
          <div>
            <p>Responsible Person</p>
            <input
              disabled={formDisabled}
              type="text"
              name="resPerson"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.resPerson}
            />
          </div>
          <div>
            <p>Brands or Product </p>
            <input
              disabled={formDisabled}
              type="text"
              name="brands"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brands}
            />
          </div>
          <div>
            <p>Business/Firm Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="business"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.business}
            />
          </div>
          <div>
            <p>Website Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="website"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.website}
            />
          </div>
          <div>
            <p>Phone</p>
            <input
              disabled={formDisabled}
              type="number"
              name="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
          </div>
          <div>
            <p>Email</p>
            <input
              disabled={formDisabled}
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div>
            <p>Location</p>
            <input
              disabled={formDisabled}
              type="text"
              name="location"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
            />
          </div>
          <div>
            <p>Equipment Category</p>
            <input
              disabled={formDisabled}
              type="text"
              name="eqipCat"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.eqipCat}
            />
          </div>
        </div>
        {/* <input disabled={formDisabled} className={styles.submitTab} type="submit" value="Save & Update"/> */}
        {perm && (
          <p onClick={handlePermission} className={styles.submitTab}>
            {userStatus === 1 ? "Reject" : "Approve"}
          </p>
        )}
      </form>
    </section>
  );
};
