import { Navigate, Outlet } from "react-router-dom";

const DistributorAuth = () => {
  let userType =
    JSON.parse(localStorage.getItem("userDetails"))?.type === 1 ||
    JSON.parse(localStorage.getItem("userDetails"))?.type === 2;
  return userType ? (
    <>
      <div className="container-fluid dashboardMain vh-100 bg-light">
        <div className="row mainInner h-100">
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/user/login/" />
  );
};

export default DistributorAuth;
