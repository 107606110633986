// current date time
export const getDateTime = () => {
  let currDate = new Date();
  return {
    year: currDate.getFullYear(),
    day: currDate.getDay(),
    month: currDate.getMonth(),
    hours: currDate.getHours(),
    minute: currDate.getMinutes(),
    second: currDate.getSeconds(),
  };
};

export const isProdAddInCart = (prod_id, add_items) => {
  let index = -1;
  add_items?.forEach((prod, ind) => {
    if (prod.id === prod_id) {
      index = ind;
    }
  });
  return index;
};

// Event will occur when while scrolling its intersect with specified element
export const scrollInterSectEvent = (element_id, callback) => {
  function handleIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target);
      }
    });
  }
  const observer = new IntersectionObserver(handleIntersection, {
    threshold: 1, // Trigger the callback when 50% of the target is in the viewport
  });
  const targetElement = document.getElementById(element_id);
  observer.observe(targetElement);
};

export const unableDisableForm = (formRef, isDisabled) => {
  const formElements = formRef.current.elements;

  for (let i = 0; i < formElements.length; i++) {
    formElements[i].disabled = isDisabled;
    formElements[i].style.backgroundColor = isDisabled ? "#d8dbe0" : "#FFFFFF";
  }
};

export const nullToEmptyString = (field) => {
  return field === "null" ? "" : field;
};

export const mergeAddress = (address) => {
  if (!address) {
    return "";
  }
  return `${address?.address},${address?.state},${address?.landmark},${address?.zip_code}.`.toUpperCase();
};
