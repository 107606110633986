import { createSlice } from "@reduxjs/toolkit";
import { postData } from "../../services";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    UserData: {},
    UserLists: [],
    cartItems: [],
    add_item_length: 0,
    profile_image: null,
    currBuyStatus: 0,
    meeting_details: {},
    shipping_eq: true,
    handling_in: true,
    amc_cmc_service: true,
    createRequirementModal: false,
  },
  reducers: {
    getUserData: (state, action) => {
      state.UserData = action.payload;
    },
    getUserLists: (state, action) => {
      state.UserLists = action.payload;
    },
    getAddCartLists: (state, action) => {
      state.cartItems = action.payload;
    },
    clearAddItems: (state) => {
      state.cartItems = [];
    },
    getProfileImage: (state, action) => {
      state.profile_image = action.payload;
    },
    setCurrBuyStatus: (state, action) => {
      state.currBuyStatus = action.payload.curr;
    },
    setAddCartLength: (state, action) => {
      state.add_item_length = action.payload;
    },
    setSheduleMeetingStatus: (state, action) => {
      state.meeting_details = action.payload;
    },
    clearMeetingData: (state) => {
      state.meeting_details = null;
    },
    handleShipping: (state) => {
      state.shipping_eq = !state.shipping_eq;
    },
    handleHandling: (state) => {
      state.handling_in = !state.handling_in;
    },
    handleAmcCms: (state) => {
      state.amc_cmc_service = !state.amc_cmc_service;
    },
    handleOnloadService: (state) => {
      state.shipping_eq = true;
      state.handling_in = true;
      state.amc_cmc_service = true;
    },
    handleCreateRequirement: (state, action) => {
      state.createRequirementModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserData,
  getProfileImage,
  clearAddItems,
  setCurrBuyStatus,
  setAddCartLength,
  getUserLists,
  getAddCartLists,
  setSheduleMeetingStatus,
  clearMeetingData,
  handleShipping,
  handleHandling,
  handleAmcCms,
  handleOnloadService,
  handleCreateRequirement,
} = UserSlice.actions;

export const fetchUserDetails = (uid) => async (dispatch) => {
  const formData = new FormData();
  formData?.append("uid", uid);
  const res = await postData("users/get_user_detail/", formData, true);
  if (res?.status) {
    dispatch(getUserLists(res?.data?.profile));
    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        number: res?.data?.mobile,
        fName: res?.data?.profile?.first_name,
        lName: res?.data?.profile?.last_name,
        email: res?.data?.email,
        id: res?.data?.id,
        uid: res?.data?.uid,
        type: res?.data?.usertype,
        profileImg: res?.data?.profile?.profile_image,
      })
    );
  }
};
export const fetchAddToCart = (token) => async (dispatch) => {
  const formData = new FormData();
  formData.append("buyer", token);
  const res = await postData("product/order/cartlist/", formData, true);
  if (res?.status) {
    dispatch(getAddCartLists(res?.data));
  }
};

export default UserSlice.reducer;
