import React, { useEffect, useState } from "react";
import styles from "../assets/css/cart.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddToCart, setAddCartLength } from "../app/Slices/UserData";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { postData, postData1 } from "../services";
import { pngwing } from "../assets/images/index";

export const Cart = () => {
  const dispatch = useDispatch();
  const isLogin = localStorage.getItem("token");
  const add_items = JSON.parse(localStorage.getItem("add_to_cart"));
  const addCart = useSelector((state) => state.profileData.cartItems);
  const [addItems, setAddItems] = useState([]);

  useEffect(() => {
    dispatch(fetchAddToCart(isLogin));
  }, [isLogin]);

  return (
    <>
      <ToastContainer />
      <section
        className={styles.cartContainer}
        // style={add_items?.length || addCart?.length < 1 ? {display:"flex",alignItems:"center"}:{}}
      >
        <div className={styles.cartItems}>
          <h3>My Cart</h3>
          <div>
            {add_items?.length || addCart?.length > 0 ? (
              <div className={styles.cartHead}>
                <span>Item</span>
                <span>Total Price</span>
                <span>Actions</span>
              </div>
            ) : (
              <div className={styles.removeCart}>
                <span style={{ alignItems: "center" }}>
                  Your cart is empty.
                </span>
              </div>
            )}

            {addItems?.length > 0
              ? addItems?.map((prodData, index) => {
                  return (
                    <CartProdCard
                      key={index}
                      prodData={prodData}
                      setAddItems={setAddItems}
                    />
                  );
                })
              : addCart?.length
              ? addCart?.map((prodData, index) => {
                  return (
                    <CartProdCard
                      key={index}
                      prodData={prodData}
                      setAddItems={setAddItems}
                    />
                  );
                })
              : add_items?.map((prodData, index) => {
                  return (
                    <CartProdCard
                      key={index}
                      prodData={prodData}
                      setAddItems={setAddItems}
                    />
                  );
                })}
          </div>
        </div>
      </section>
    </>
  );
};

const CartProdCard = ({ prodData, setAddItems }) => {
  const isLogin = localStorage.getItem("token");
  const [isdisabled, setIsDisabled] = useState(false);
  const add_items = JSON.parse(localStorage.getItem("add_to_cart"));
  const localCart = add_items ? prodData : prodData?.product_detail;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheckOut = () => {
    navigate(
      `/products/${encodeURIComponent(localCart?.equip_name)}/?id=${
        localCart?.id
      }/`,
      {
        state: { prodDetails: localCart },
      }
    );
    window.scrollTo(0, 0);
  };
  const handleRemove = async () => {
    if (isLogin) {
      setIsDisabled(true); // Disable button to prevent multiple clicks
  
      const data = {
        cart_id: prodData?.uid,
      };
  
      try {
        const res = await postData1("product/order/cartremove/", data, true);
        
        if (res && res.status) { // Check if res is defined and has status
          toast.success("Delete Successfully!", {
            autoClose: 1000,
          });
          setIsDisabled(false); // Re-enable the button after success
          dispatch(fetchAddToCart(isLogin));
        } else {
          toast.error(res?.msg || "Error removing from cart", {
            autoClose: 1000,
          });
          setIsDisabled(false); // Re-enable the button after error
        }
      } catch (error) {
        toast.error("Failed to remove from cart. Please try again.", {
          autoClose: 1000,
        });
        setIsDisabled(false); // Re-enable button if error occurs
      }
  
    } else {
      // If the user is not logged in, handle removal from localStorage
      const itemToRemoveId = prodData?.id;
      const updatedItems = add_items?.filter((el) => el?.id !== itemToRemoveId);
      localStorage.setItem("add_to_cart", JSON.stringify(updatedItems));
      setAddItems(updatedItems);
      dispatch(setAddCartLength(updatedItems));
      toast.success(`${prodData?.equip_name} is Removed from the cart`, {
        autoClose: 1800,
      });
    }
  };
  
  return (
    <>
      <ToastContainer />
      <div className={styles.prodCartCont}>
        <div className={styles.prodDetails}>
          <img
            src={
              localCart?.product_images?.length > 0
                ? localCart?.product_images[0]?.product_images
                : pngwing
            }
            alt="..."
          />
          <div className={styles.cunitPrice}>
            <span>{localCart?.equip_name} </span>
            <span>{prodData?.model}</span>
            <span style={{ marginTop: "5px", color: "#858585" }}>
              Including GST
            </span>
          </div>
        </div>

        <div className={styles.cunitPrice} style={{ width: "72px" }}>
          ₹{parseFloat(prodData?.asking_price).toFixed(2)}
        </div>

        <div className={styles.prodAction}>
         {isdisabled === false &&
         <button  className={styles.removeProd} onClick={handleRemove}>
         <span>
           <i class="bi bi-x"></i>
         </span>
         <span>REMOVE</span>
       </button>
} 
          <div className={styles.checkout} onClick={handleCheckOut}>
            <span>CHECK OUT</span>
            <span>
              <i class="bi bi-arrow-right"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
