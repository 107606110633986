import { createSlice } from "@reduxjs/toolkit";

export const ManufactureTabSlice = createSlice({
  name: "manufacturerProdAdd",
  initialState: {
    prodAdd: {
      equip_selected_postType: null,
      equip_images: [],
      equip_videos: [],
      equip_name: null,
      other_name: false,
      newEquip_name: null,
      equip_category: [],
      equip_speciality: [],
      location: {
        lang: null,
        lat: null,
      },
      equip_location: {
        number: null,
        email: null,
        address: null,
        address1: null,
        city: null,
        State: null,
        zip_code: "",
      },
      equip_manufacturer_year: null,
      equip_Specifications: {
        brand: null,
        model: null,
        under_warranty: null,
        equip_amc_cmc: null,
        other_desc: null,
        condition: null,
        price: null,
        negotiable: null,
        prod_desc: null,
      },
    },
  },
  reducers: {
    setEquipSelectedProdTpye: (state, action) => {
      state.prodAdd.equip_selected_postType = action.payload;
    },
    setEquipName: (state, action) => {
      state.prodAdd.equip_name = action.payload;
    },
    selectOtherName: (state) => {
      state.prodAdd.other_name = !state.prodAdd.other_name;
    },
    setNewEquipName: (state, action) => {
      state.prodAdd.newEquip_name = action.payload;
    },

    setEquip_Location: (state, action) => {
      state.prodAdd.equip_location = action.payload;
    },

    setLatLong: (state, action) => {
      const { name, value } = action.payload;
      state.prodAdd.location[name] = value;
    },

    setFullAddAddress: (state, action) => {
      const { address, city, address1, zip_code, State, number, email } =
        action.payload;
      state.prodAdd.equip_location = {
        ...state.prodAdd.equip_location,
        number: number,
        email: email,
        address: address,
        city: city,
        address1: address1,
        zip_code: zip_code ?? "",
        State: State,
      };
    },
    addEquipImages: (state, action) => {
      state.prodAdd.equip_images.push(action.payload);
    },
    removeEqipImages: (state, action) => {
      state.prodAdd.equip_images = state.prodAdd.equip_images.filter(
        (image) => image.imageId !== action.payload
      );
    },
    setEquipSpecality: (state, action) => {
      const specialityName = action.payload;
      const index = state.prodAddData.specility?.indexOf(specialityName);
      if (index === -1) {
        state.prodAddData.specility?.push(specialityName);
      } else {
        state.prodAddData.specility?.splice(index, 1);
      }
    },
    setfilterSpec: (state, action) => {
      const speciality = action.payload;
      let fill = state.prodAddData.specility?.filter((el) => {
        return el !== speciality;
      });
      state.prodAddData.specility = fill;
    },
    setEquipSpecification: (state, action) => {
      const { name, value } = action.payload;
      state.prodAdd.equip_Specifications[name] = value;
    },
    setManufactureYear: (state, action) => {
      state.prodAdd.equip_manufacturer_year = action.payload;
    },
    addEquipVideos: (state, action) => {
      state.prodAdd.equip_videos.push(action.payload);
    },
    removeEqipVideos: (state, action) => {
      state.prodAdd.equip_videos = state.prodAdd.equip_videos.filter(
        (video) => video.videoId !== action.payload
      );
    },
    clearDisProdAdd: (state) => {
      state.prodAdd = {
        equip_selected_postType: null,
        equip_images: [],
        equip_videos: [],
        equip_name: null,
        other_name: false,
        newEquip_name: null,
        equip_category: [],
        equip_speciality: [],
        location: {
          lang: null,
          lat: null,
        },
        equip_location: {
          number: null,
          email: null,
          address: null,
          address1: null,
          city: null,
          State: null,
          zip_code: null,
        },
        equip_manufacturer_year: null,
        equip_Specifications: {
          brand: null,
          model: null,
          under_warranty: null,
          equip_amc_cmc: null,
          other_desc: null,
          condition: null,
          price: null,
          negotiable: null,
          prod_desc: null,
        },
      };
    },
  },
});

export const {
  setEquipSelectedProdTpye,
  setEquipName,
  addEquipImages,
  removeEqipImages,
  addEquipVideos,
  removeEqipVideos,
  setEquipSpecification,
  setEquipCondition,
  setManufactureYear,
  selectOtherName,
  setNewEquipName,
  setEquipSpecality,
  setfilterSpec,
  setEquip_Location,
  setLatLong,
  setFullAddAddress,
  clearDisProdAdd,
} = ManufactureTabSlice.actions;

export default ManufactureTabSlice.reducer;
