import React, { useEffect, useState } from "react";
import styles from "../../Admin/assets/css/ViewPage.module.css";
import img1 from "../../Admin/assets/img/chart.jpg";
import { useLocation } from "react-router-dom";
import { UsersAddress } from "../../utils/UsersAddress";

export const ViewPayment = () => {
  const location = useLocation();
  const item = location?.state?.item;
  const [usersAddress, setUsersAddress] = useState({
    buyer: "",
    seller: "",
  });

  UsersAddress({
    buyer: item?.buyer,
    seller: item?.product_detail?.user,
    setUsersAddress,
  });

  return (
    <>
      <section className={styles.viewPayment}>
        <h1>PAYMENT MANAGEMENT</h1>

        <div className={styles.userDetails}>
          <div className={styles.data}>
            <div className={styles.name}>
              <h4>Id:</h4>
              <p>{item?.buyer}</p>
            </div>
            <div className={styles.name}>
              <h4>BuyerName:</h4>
              <p>{`${item?.buyer_firstname} ${item?.buyer_lastname}`}</p>
            </div>
            <div className={styles.name}>
              <h4>Address:</h4>
              <p>{usersAddress?.buyer?.address}</p>
            </div>
          </div>
          <div className={styles.data}>
            <div className={styles.name}>
              <h4>Id:</h4>
              <p>{item?.product_detail?.user}</p>
            </div>
            <div className={styles.name}>
              <h4>Seller Name:</h4>
              <p>{`${item?.product_detail?.seller_firstname} ${item?.product_detail?.seller_lastname}`}</p>
            </div>
            <div className={styles.name}>
              <h4>Address:</h4>
              <p>{usersAddress?.seller?.address}</p>
            </div>
          </div>
        </div>
        {/* product details */}

        <div className={styles.productDetails}>
          <h1>Product Details</h1>
          <div className={styles.pdata}>
            <div>
              <img
                src={
                  item?.product_detail?.product_images?.length > 0
                    ? item?.product_detail?.product_images[0]?.product_images
                    : img1
                }
                alt=""
              />
            </div>
            <div className={styles.det}>
              <div>
                <h2>Product Id</h2>
                <p>{item?.product}</p>
              </div>
              <div>
                <h2>Date</h2>
                <p>{item?.product_detail?.date}</p>
              </div>
              <div>
                <h2>Equipm Name</h2>
                <p>{item?.product_detail?.equip_name}</p>
              </div>
              <div>
                <h2>Equip Condition</h2>
                <p>
                  {item?.product_detail?.equip_condition === 1
                    ? "Good"
                    : item?.product_detail?.equip_condition === 2
                    ? "Excellent"
                    : "As Good as New"}
                </p>
              </div>
              <div>
                <h2>Year</h2>
                <p>{item?.product_detail?.year}</p>
              </div>
              <div>
                <h2>Brand</h2>
                <p>{item?.product_detail?.brand}</p>
              </div>
              <div>
                <h2>Model</h2>
                <p>{item?.product_detail?.model}</p>
              </div>
              <div>
                <h2>Warranty</h2>
                <p>{item?.product_detail?.warranty === 1 ? "YES" : "NO"}</p>
              </div>
              <div>
                <h2>shipping From</h2>
                <p>{item?.product_detail?.address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.paymentDetails}>
          <h1>Payment Details</h1>
          <table>
            <tr>
              <th>Payment Id</th>
              <th>Initate Date</th>
              <th>Payment Type</th>
              <th>Intallation Price</th>
              <th>Payment Status</th>
              <th>Total Amount</th>
            </tr>
            <tr>
              <td>{item?.id}</td>
              <td>{item?.product_detail?.date}</td>
              <td>online</td>
              <td>
                <span>&#8377;</span>
                {+item?.product_detail?.installation_price}
              </td>
              <td>
                <span
                  style={{
                    backgroundColor: "#019c81",
                    color: "white",
                    border: "1px solid green",
                    borderRadius: "5px",
                    padding: "4px 8px 4px 8px",
                  }}
                >
                  {item?.payment_status === 1
                    ? "Pending"
                    : item?.payment_status === 2
                    ? "Partial"
                    : "Completed"}
                </span>
              </td>

              <td>
                <span>&#8377;</span>
                {+item?.total}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </section>
    </>
  );
};
