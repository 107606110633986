import React from "react";
import styles from "../assets/css/pagination.module.css";
const Pagination = (props) => {
  const handleNextPage = () => {
    if (props?.pageNo < props?.totalPage) {
      props.setPageNO(props?.pageNo + 1);
    }
  };
  const handlePrePage = () => {
    if (props?.pageNo > 1) {
      props.setPageNO(props?.pageNo - 1);
    }
  };
  return (
    <div className={styles.header}>
      <button onClick={handlePrePage} className={styles.btn}>
        Previous
      </button>
      <h3
        className={styles.text}
      >{`${props?.pageNo}-${props.totalPage} of ${props?.totalItem}`}</h3>
      <button onClick={handleNextPage} className={styles.btn}>
        Next
      </button>
    </div>
  );
};

export default Pagination;
