import { useLocation } from "react-router-dom";
import styles from "../../assets/css/aboutus/aboutDetail.module.css";
import DOMPurify from "dompurify";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const AboutDetail = () => {
  const location = useLocation();
  const blogData = location?.state?.item;
  const title = location?.state?.title;
  let imgURL = `${IMGURL}/mmq_apps/static/upload/content/${blogData?.image}`;

  const sanitizedDescription = DOMPurify.sanitize(blogData?.description);

  return (
    <div className={styles.aboutmore}>
      <div className={styles.aboutMoreMain}>
        <h1>{title ? title : "Blog"} Details</h1>
        <div>
          <img src={imgURL} alt="" />
          <div className={styles.aboutMoreContent}>
            <div className={styles.aboutMoreImg}>
              <img src={imgURL} alt="" />
            </div>
            <div>
              <p>
                <span>
                  <i className="bi bi-calendar-day"></i>
                </span>{" "}
                {new Date(blogData?.created_date).toDateString()}
              </p>
            </div>
          </div>
          <h2 style={{ textTransform: "capitalize" }}>{blogData?.name}</h2>
          <p
            style={{ textTransform: "capitalize" }}
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </div>
      </div>
    </div>
  );
};
