import React, { useEffect, useState } from "react";

import styles from "../../assets/css/content.module.css";
import commoncss from "../../assets/css/common.module.css";
import flowchart from "../../../assets/images/mFlowChart.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { postData1 } from "../../../services";
import { Pagination } from "../../Common";
import { ToastContainer, toast } from "react-toastify";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const BlogsArticles = (props) => {
  const apiData = props?.data;
  const location = useLocation();
  const [contentlist, setContentlist] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);
  const [Delete, setDelete] = useState(1);

  const updateDelete = () => {
    setDelete(Delete + 1);
  };

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };
  useEffect(() => {
    (async () => {
      let data = {
        is_deleted: 0,
        content_type: apiData?.type,
        _page: currPage,
        _limit: 6,
      };
      const res = await postData1("master/list_content/", data, true, true);
      if (res?.status_code === 200) {
        setContentlist(res?.data?.data);
        setPaging({
          total: res?.data?.total,
          total_pages: res?.data?.total_pages,
        });
      }
    })();
  }, [currPage, Delete, location?.pathname]);

  return (
    <section className={styles.contContainer}>
      <h1>{apiData?.title}</h1>
      <div className={styles.actions}>
        <NavLink to={apiData?.addURL}>
          <span className={commoncss.applyBtn}>Add</span>
        </NavLink>
      </div>
      <div className={styles.contentlist}>
        {contentlist?.map((data, ind) => {
          return (
            <ContentCard
              addURL={apiData?.addURL}
              updateDelete={updateDelete}
              data={data}
              key={ind}
            />
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={6}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />
    </section>
  );
};

const ContentCard = ({ updateDelete, data, addURL }) => {
  let imgURL = `${IMGURL}/mmq_apps/static/upload/content/${data?.image}`;
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(addURL, { state: data });
  };

  const deleteBlog = async (event) => {
    const res = await postData1(
      "master/add_content/",
      { is_deleted: 1, uid: data?.uid },
      true,
      true
    );
    if (res?.status_code === 200) {
      toast.success("Content remove successfully !");
      updateDelete();
    } else {
      toast.error("Something went wrong try again !");
    }
  };

  return (
    <div className={styles.contCard}>
      <ToastContainer />
      <img src={imgURL} />
      <h4 style={{ fontWeight: "400px" }}>{data?.name}</h4>
      <div className={styles.cardAction}>
        <i onClick={handleNavigate} className="bi bi-pencil-square"></i>
        <i onClick={deleteBlog} className="bi bi-trash3"></i>
      </div>
    </div>
  );
};
