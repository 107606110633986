import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import commonCss from "../assets/css/common.module.css";
import { BlockToggle } from "./UsersList";
import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { nextArow, swipetestleft } from "../../assets/images";
import { postData1 } from "../../services";
import { BackgroundBlur } from "../../utils/Popups";
import { ToastContainer, toast } from "react-toastify";
import { Pagination } from "../Common";

export const SystemPrice = () => {
  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>SYSTEM PRICE MANAGEMENT</h1>
      </div>

      <div className={styles.dataItems + " " + commonCss.catItems}>
        <div
          className={styles.itemHead}
          style={{ justifyContent: "space-around" }}
        >
          <span>Platform Fees (%) </span>
          <span>
            <input placeholder="Enter in percentage" />
          </span>
          <span className={commonCss.applyBtn}>Apply</span>
        </div>
        <div
          className={styles.itemHead}
          style={{ justifyContent: "space-around" }}
        >
          <span>Inspection & Verification Cost (%)</span>
          <span>
            <input placeholder="Enter in percentage" />
          </span>
          <span className={commonCss.applyBtn}>Apply</span>
        </div>
        <div
          className={styles.itemHead}
          style={{ justifyContent: "space-around" }}
        >
          <span>GST (%)</span>
          <span>
            <input placeholder="Enter in percentage" />
          </span>
          <span className={commonCss.applyBtn}>Apply</span>
        </div>
      </div>
    </section>
  );
};
