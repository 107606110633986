import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import commonCss from "../assets/css/common.module.css";
import { BlockToggle } from "./UsersList";
import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { nextArow, swipetestleft } from "../../assets/images";
import { postData1 } from "../../services";
import { BackgroundBlur } from "../../utils/Popups";
import { ToastContainer, toast } from "react-toastify";
import { Pagination } from "../Common";

export const Speciality = () => {
  const [showForm, setForm] = useState(false);
  const [SpeclistData, setSpecListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleAdd = () => {
    setForm(!showForm);
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1(
        "product/speciality/lists/",
        config,
        true,
        true
      );
      setSpecListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage, showForm]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>SPECIALITY MANAGEMENT</h1>
      </div>
      <div className={styles.listHead}>
        <span className={commonCss.addBtn} onClick={handleAdd}>
          ADD
        </span>
      </div>
      <div className={styles.dataItems + " " + commonCss.catItems}>
        <div
          className={styles.itemHead}
          style={{ justifyContent: "space-around" }}
        >
          <span>ID</span>
          <span>NAME</span>
          {/* <span>DETAIL</span> */}
        </div>

        {SpeclistData.map((data, index) => {
          return (
            <div
              className={styles.itemData}
              key={index}
              style={{ justifyContent: "space-around" }}
            >
              <span>{data?.id}</span>
              <span>{data?.name}</span>
              {/* <span><NavLink>View</NavLink></span> */}
            </div>
          );
        })}
        <Pagination
          total={paging?.total}
          perPage={8}
          currPage={currPage}
          prevPage={prevPage}
          tPage={paging?.total_pages}
          updatePage={updatePage}
        />

        {loader && <Loader />}
      </div>

      {showForm && <AddSpeciality handleAdd={handleAdd} />}
    </section>
  );
};

const AddSpeciality = ({ handleAdd }) => {
  const [userSpec, setUserSpec] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setUserSpec(value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (userSpec.length < 2) {
      setError("Enter Correct Speciality");
      return;
    }
    const res = await postData1(
      "product/speciality/add/",
      { name: userSpec },
      true,
      true
    );
    if (res.status_code === 200) {
      handleAdd();
    } else {
      toast.error("Something went wrong,try again !");
    }
  };

  return (
    <React.Fragment>
      <form className={commonCss.popContiner} onSubmit={submitForm}>
        <h2>
          ADD SPECIALITY
          <i
            onClick={handleAdd}
            className="bi bi-x-lg"
            style={{ cursor: "pointer" }}
          ></i>
        </h2>
        <p>SPECIALITY NAME</p>
        <input
          type="text"
          placeholder="write speciality"
          value={userSpec}
          onChange={handleChange}
        />
        {error && <span style={{ color: "red" }}>{error}</span>}
        <input type="submit" value="Add" />
      </form>
      <BackgroundBlur />
      <ToastContainer />
    </React.Fragment>
  );
};
