import React from "react";
import Styles from "../assets/css/loader.module.css";
const Loader = () => {
  return (
    <div className={Styles.main_containter}>
      <span className={Styles.loader}></span>
    </div>
  );
};

export default Loader;
