// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import logo from "../assets/images/ImageUpload.png";

// const generatePDF = (invoiceData) => {
//   console.log(invoiceData,'invoiceData')
//   const pdf = new jsPDF();

//   pdf.setFontSize(20);
//   pdf.text("Tax Invoice", pdf.internal.pageSize.getWidth() / 2, 15, {
//     align: "center",
//   });
//   const maxWidth = 400; 
//   const billToLines =  pdf.splitTextToSize(`Ship-from Address: ${invoiceData.sellerAddress}`, maxWidth);;
//   const lineHeight = 5; 
  
//   pdf.setFontSize(10);
//   pdf.text(`Sold By: ${invoiceData.sellerName}`, 10, 25);
//   // pdf.text(`Ship-from Address: ${invoiceData.sellerAddress}`, 10, 30);
//   billToLines.forEach((line, index) => {
//       pdf.text(line, 10, 30 + index * lineHeight);
//   });
//   // pdf.text(`GSTIN – Seller GST No. ${invoiceData.sellerGSTNo}`, 10, 35);
//   // pdf.text(`Seller Certificate N0- ${invoiceData.sellerCertificate}`, 10, 40);

//   const invoiceNumberText = `Invoice Number - ${invoiceData.invoice_number}`;
//   const borderPadding = 3;
//   const fixedBoxWidth = 100;

//   const xPosition = pdf.internal.pageSize.getWidth() / 1.05 - fixedBoxWidth;

//   pdf.rect(xPosition, 42, fixedBoxWidth, 10, "S");

//   const textXPosition = xPosition + borderPadding;
//   const textYPosition = 48;
//   pdf.text(invoiceNumberText, textXPosition, textYPosition, {
//     align: "left",
//   });

//   const lineY = 55; 
//   const lineMargin = 1;
//   pdf.setLineWidth(0.1);
//   pdf.line(
//     lineMargin,
//     lineY,
//     pdf.internal.pageSize.getWidth() - lineMargin,
//     lineY
//   );
  
//   pdf.text(`Ship To: ${invoiceData.buyerName}`, 105, 60);
//   pdf.text(`Bill To: ${invoiceData.buyerAddress}`, 105, 70);
//   pdf.text(`Order ID: ${invoiceData.orderId}`, 10, 60);
//   pdf.text(`Order Date: ${invoiceData.orderDate}`, 10, 67);
//   pdf.text(`Invoice Date: ${invoiceData.orderDate}`, 10, 74);
//   // pdf.text(`PAN : ${invoiceData.sellerCertificate}`, 10, 81);
//   // pdf.text(`CIN : ${invoiceData.sellerCertificate}`, 10, 88);
//   pdf.text(`Total items : ${invoiceData.product_qty}`, 10, 85);

//   const lineY1 = 90; 
//   const lineMargin1 = 1;
//   pdf.setLineWidth(0.1);
//   pdf.line(
//     lineMargin1,
//     lineY1,
//     pdf.internal.pageSize.getWidth() - lineMargin1,
//     lineY1
//   );

//   pdf.text(`Product`, 10, 97);
//   pdf.text(`Discription`, 50, 97);
//   pdf.text(`Qty`, 80, 97);
//   pdf.text(`Gross Amount`, 95, 97);
//   pdf.text(`Discounts/Coupons`, 125, 97);
//   pdf.text(`Taxable Value`, 160, 97);
//   pdf.text(`Total`, 190, 97);

//   pdf.text(`IGST: ${18.0}%`, 30, 120);
//   pdf.text(`Shipping And Handling Charges: ${invoiceData?.Shipping_and_handling_charge}`, 30, 125);

//   const lineY2 = 112;
//   const lineMargin2 = 1;
//   pdf.setLineWidth(0.1);
//   pdf.setLineHeightFactor(2)
//   pdf.line(
//     lineMargin2,
//     lineY2,
//     pdf.internal.pageSize.getWidth() - lineMargin2,
//     lineY2
//   );
//   const maxWidthOne = 30; 
//   const billToLinesOne =  pdf.splitTextToSize(`${invoiceData.product}`, maxWidthOne);;
//   const lineHeightOne = 5; 
//   billToLinesOne.forEach((line, index) => {
//     pdf.text(line, 10, 105 + index * lineHeightOne);
// });
//   // pdf.text(`${invoiceData.product}`, 10, 105);
//   pdf.text(`${invoiceData.product_details}`, 50, 105);
//   pdf.text(`${invoiceData.product_qty}`, 80, 105);
//   pdf.text(`${invoiceData.Gross_amt}`, 95, 105);
//   pdf.text(`${invoiceData.Discounts}`, 125, 105);
//   pdf.text(`${invoiceData.Taxable_amt}`, 160, 105);
//   pdf.text(`${invoiceData.Total}`, 190, 105);

//   const lineY3 = 130;
//   const lineMargin3 = 1;
//   const reductionPercentage = 0.1;

//   const reductionAmount =
//     pdf.internal.pageSize.getWidth() * reductionPercentage;

//   pdf.setLineWidth(0.1);
//   pdf.line(
//     lineMargin3 + reductionAmount,
//     lineY3,
//     pdf.internal.pageSize.getWidth() - lineMargin3,
//     lineY3
//   );

//   pdf.text(`Total: ${invoiceData.Total}`, 45, 136);

//   const lineY4 = 140;
//   const lineMargin4 = 1;
//   pdf.setLineWidth(0.1);
//   pdf.line(
//     lineMargin4,
//     lineY4,
//     pdf.internal.pageSize.getWidth() - lineMargin4,
//     lineY4
//   );

//   // pdf.text(`Grand Total`, 150, 165);


// pdf.textWithLink('Return Policy', 10, 150, { url: 'https://mediquip.in/refundpolicy' });

// pdf.setDrawColor(255, 255, 255); // Set the border color to white (invisible)
// pdf.rect(10, 150, pdf.getStringUnitWidth('REFUND POLICY') * 3.5, 0.2, 'F'); // Adjust width as needed


//   // Save the PDF
//   pdf.save("invoice.pdf");
// };

// export default generatePDF;



import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/images/ImageUpload.png";

const generatePDF = (invoiceData) => {
  
  const pdf = new jsPDF();

  pdf.setFontSize(20);
  pdf.text("Tax Invoice", pdf.internal.pageSize.getWidth() / 2, 15, {
    align: "center",
  });
  const maxWidth = 400; 
  const billToLines =  pdf.splitTextToSize(`Ship-from Address: ${invoiceData.sellerAddress}`, maxWidth);;
  const lineHeight = 5; 
  
  pdf.setFontSize(10);
  pdf.text(`Sold By: ${invoiceData.sellerName}`, 10, 25);
  // pdf.text(`Ship-from Address: ${invoiceData.sellerAddress}`, 10, 30);
  billToLines.forEach((line, index) => {
      pdf.text(line, 10, 30 + index * lineHeight);
  });
  // pdf.text(`GSTIN – Seller GST No. ${invoiceData.sellerGSTNo}`, 10, 35);
  // pdf.text(`Seller Certificate N0- ${invoiceData.sellerCertificate}`, 10, 40);

  const invoiceNumberText = `Invoice Number - ${invoiceData.invoice_number}`;
  const borderPadding = 3;
  const fixedBoxWidth = 100;

  const xPosition = pdf.internal.pageSize.getWidth() / 1.05 - fixedBoxWidth;

  pdf.rect(xPosition, 42, fixedBoxWidth, 10, "S");

  const textXPosition = xPosition + borderPadding;
  const textYPosition = 48;
  pdf.text(invoiceNumberText, textXPosition, textYPosition, {
    align: "left",
  });

  const lineY = 55; 
  const lineMargin = 1;
  pdf.setLineWidth(0.1);
  pdf.line(
    lineMargin,
    lineY,
    pdf.internal.pageSize.getWidth() - lineMargin,
    lineY
  );
  
  pdf.text(`Ship To: ${invoiceData.buyerName}`, 105, 60);
  pdf.text(`Bill To: ${invoiceData.buyerAddress}`, 105, 70);
  pdf.text(`Order ID: ${invoiceData.orderId}`, 10, 60);
  pdf.text(`Order Date: ${invoiceData.orderDate}`, 10, 67);
  pdf.text(`Invoice Date: ${invoiceData.orderDate}`, 10, 74);
  // pdf.text(`PAN : ${invoiceData.sellerCertificate}`, 10, 81);
  // pdf.text(`CIN : ${invoiceData.sellerCertificate}`, 10, 88);
  pdf.text(`Total items : ${invoiceData.product_qty}`, 10, 85);

  const lineY1 = 90; 
  const lineMargin1 = 1;
  pdf.setLineWidth(0.1);
  pdf.line(
    lineMargin1,
    lineY1,
    pdf.internal.pageSize.getWidth() - lineMargin1,
    lineY1
  );

  pdf.text(`Description`, 10, 97);
  pdf.text(`Platform Fee`, 50, 97);
  pdf.text(`Amount`, 90, 97);
  pdf.text(`GST`, 130, 97);
  pdf.text(`Total`, 170, 97);

  pdf.text(`Shipping Charges : `, 10, 118);
  pdf.text(` N/A`, 50, 118);
  pdf.text(` ${invoiceData?.shipping_equipment_amount}`, 90, 118);
  pdf.text(` ${18}%`, 130, 118);
  pdf.text(` ${invoiceData?.total_shipping_equipment_amount}`, 170, 118);

  pdf.text(`Installation Charges : `, 10, 123);
  pdf.text(` N/A`, 50, 123);
  pdf.text(` ${invoiceData?.handling_instalation_amount}`, 90, 123);
  pdf.text(` ${18}%`, 130, 123);
  pdf.text(` ${invoiceData?.total_handling_instalation_amount}`, 170, 123);
  
  pdf.text(`CMC/AMC Charges :`, 10, 128);
  pdf.text(` N/A`, 50, 128);
  pdf.text(` ${invoiceData?.amc_services_amount}`, 90, 128);
  pdf.text(` ${18}%`, 130, 128);
  pdf.text(` ${invoiceData?.total_amc_services_amount}`, 170, 128);
  
  

  const lineY2 = 112;
  const lineMargin2 = 1;
  pdf.setLineWidth(0.1);
  pdf.setLineHeightFactor(2)
  pdf.line(
    lineMargin2,
    lineY2,
    pdf.internal.pageSize.getWidth() - lineMargin2,
    lineY2
  );
  
  const maxWidthOne = 30; 
  const billToLinesOne =  pdf.splitTextToSize(`${invoiceData.product}`, maxWidthOne);
  const lineHeightOne = 5; 
//   billToLinesOne.forEach((line, index) => {
//     pdf.text(line, 10, 105 + index * lineHeightOne);
// });
billToLinesOne.forEach((product, index) => {
  const productText = `${product}`; // Include product ID in the text
  const productYPosition = 105 + index * lineHeightOne;

  pdf.textWithLink(productText, 10, productYPosition, {
      url: `javascript:alert('Product ID: ${invoiceData?.productId}')`, 
  });
  

});
  pdf.text(`${5}%`, 50, 105);
  pdf.text(`${invoiceData.Gross_amt}`, 90, 105);
  pdf.text(`${18}%`, 130, 105);
  pdf.text(`${invoiceData.Total}`, 170, 105);

  const lineY3 = 131;
  const lineMargin3 = 1;
  const reductionPercentage = 0.1;

  // const reductionAmount =
  //   pdf.internal.pageSize.getWidth() * reductionPercentage;

  pdf.setLineWidth(0.1);
  pdf.line(
    lineMargin3,
    lineY3,
    pdf.internal.pageSize.getWidth() - lineMargin3,
    lineY3
  );

  pdf.text(`Grand Total :`, 10, 137);
  pdf.text(`${invoiceData?.Shipping_and_handling_charge}`, 170, 137)

  const lineY4 = 141;
  const lineMargin4 = 1;
  pdf.setLineWidth(0.1);
  pdf.line(
    lineMargin4,
    lineY4,
    pdf.internal.pageSize.getWidth() - lineMargin4,
    lineY4
  );

  // pdf.text(`Grand Total`, 150, 165);

  pdf.setTextColor('blue');
  // Draw the text without a link
// const text = '*Return Policy';
// const textX = 10;
// const textY = 148;
// pdf.text(text, textX, textY);


  pdf.textWithLink('* Return Policy', 12, 148, { url: 'https://mediquip.in/refundpolicy'  });
// pdf.textWithLink('Return Policy', 10, 150, { url: 'https://mediquip.in/refundpolicy', textColor: 'blue', });

pdf.setDrawColor(255, 255, 255); // Set the border color to white (invisible)
pdf.rect(10, 150, pdf.getStringUnitWidth('REFUND POLICY') * 3.5, 0.2, 'F'); // Adjust width as needed


  // Save the PDF
  pdf.save("invoice.pdf");
};

export default generatePDF;
