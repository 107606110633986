import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SearchComponent = () => {
  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );
  console.log(categories)
  const[search, setSearch] = useState('');
  const[searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    console.log(e.target.value)
    setSearch(e.target.value);
  };

  useEffect(() => {
    const searchList = [];
    categories.forEach((category) => {
      category.children.forEach((child) => {
        searchList.push({id: child.id, name: child.name});
      });
    });
    console.log("data")
    console.log(searchList)
    setSearchData(searchList);
  }, [search]);



  const handleSearchItems = (item) => {
    const encodedEquipName = encodeURIComponent(item); 
    navigate(`/search/search-items/${encodedEquipName}/`, {
        state: { cat: item},
    });
    setSearch('');
  }

  const searchByText = (item) => {
    if ( item === '') {
      return
    }
    const encodedEquipName = encodeURIComponent(item); 
    navigate(`/search/search-items/${encodedEquipName}/`, {
        state: { cat: item},
    });
    setSearch('');
  }

    return (
        <div style={{position: 'fixed', top: 0, left: 0, zIndex: 1000, width: '100%'}} className="relative h-screen bg-gray-200 lg:hidden">
            <div className='relative mx-1 mt-1'>
              <span className="absolute inset-y-0 left-0 flex items-center" onClick={() => navigate('/')}>
                  <FaArrowLeft className="text-gray-400 h-4 w-4 text-green-500" onClick={() => navigate('/')}/>
              </span>
              <input
                  onChange={(e) => handleSearch(e)}
                  type="text"
                  value={search}
                  placeholder="Find Medical Instruments..."
                  className="w-full h-12 pl-6 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <FaSearch className="text-green-500" onClick={() => searchByText(search)}/>
              </span>
            </div>
            <ul className="absolute top-14 w-full bg-white shadow-lg rounded-lg h-full overflow-y-auto mx-1">
              {searchData.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).map((item) => (
                <li key={item.id} className="px-4 py-2 hover:bg-gray-100">
                  <a onClick={() => handleSearchItems(item.name)} className="block w-full">{item.name}</a>
                </li>
              ))}
            </ul>
            
        </div>
    );
  };
  
  export default SearchComponent;
