import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slices/AuthSlice";
import prodAddSlice from "./Slices/ProdAddSlice";
import UserData from "./Slices/UserData";
import MFSlice from "./Slices/ManufacturerSlice";
import ProdListSlice from "./Slices/ProdListSlice";
import thunk from "redux-thunk";
import ManufactureTabSlice from "./Slices/ManufactureTabSlice";
export default configureStore({
  reducer: {
    auth: authSlice,
    addProd: prodAddSlice,
    profileData: UserData,
    mfSlice: MFSlice,
    plist: ProdListSlice,
    manuProdAdd: ManufactureTabSlice,
  },
  middleware: [thunk],
});
