import React, { useEffect, useState } from "react";
import styles from "../../assets/css/manufacture/manufacture.module.css";
import {
  setSelectedMF,
  removeSelectedMF,
  setSelectedCat,
  removeSelectCat,
} from "../../app/Slices/ManufacturerSlice";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { fetchCategories } from "../../app/Slices/ProdAddSlice";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "../../Admin/Common";
import {
  searchDrop,
  relatedImg,
  filterMF,
  sortMf,
} from "../../assets/images/index";
import { postData1 } from "../../services";
import ProdAddress from "../../utils/ProdAddress";

export const Manufacturer = (props) => {
  const [pcheckList, setPCheckList] = useState([]);
  const [prodCount, setProdCount] = useState("");

  const selectedMF = useSelector((state) => state.mfSlice.selectedMF);
  const selectedCat = useSelector((state) => state.mfSlice.selectedCat);
  let isOptionSelectd =
    selectedMF.length > 0 || selectedCat.length > 0 ? true : false;

  if (!props?.mf) {
    isOptionSelectd = true;
  }

  const [ismobile, setMobile] = useState(false);
  const handleMobileSearch = () => {
    setMobile(!ismobile);
  };

  let title = "Medical Equipment Manufacturers";
  if (props?.new) {
    title = "New Medical Equipment";
  } else if (props?.used) {
    title = "Pre Owned Medical Equipment";
  } else if (props?.trend) {
    title = "Trending Medical Equipment";
  }
  return (
    <section className={styles.manuFactureCont}>
      <div className={styles.manuSubCont} >
        <div  onClick={handleMobileSearch} class={`absolute bg-black opacity-80 inset-0 ${ismobile === false ? "hidden" : "z-[998]"} h-screen`}></div>
        <div className={ismobile ? "bg-gray-100 z-[999] absolute left-0 top-0" : styles.manureSearch}>
          {ismobile && (
            <div className={styles.mobileBack} >
              <i class="bi bi-arrow-left-short"></i>
              <spna>FILTER</spna>
            </div>
          )}

          <div style={{ padding: "1rem 0rem" }} className={styles.manuPath}>
            <NavLink to="/">
              <i
                className="bi bi-house-door"
                style={{ fontSize: "18px", color: "black" }}
              ></i>
            </NavLink>
            <i
              style={{ fontSize: "12px", color: "black" }}
              className="bi bi-chevron-right"
            ></i>
            <NavLink>
              {props?.mf ? "Manufacturers and Distributors" : title}
            </NavLink>
          </div>
          <ManuSearch
            pcheckList={pcheckList}
            setPCheckList={setPCheckList}
            data={[]}
            searchFor={2}
            title="EQUIPMENT CATEGORY"
          />
        </div>
        {/* Right Pannel */}
        <div style={{ padding: "1rem 0rem" }} className={styles.manuContent}>
          <div className={styles.mobileContTop}>
            <div className={styles.manuPath}>
              <NavLink to="/">
                <i
                  className="bi bi-house-door"
                  style={{ fontSize: "18px", color: "black" }}
                ></i>
              </NavLink>
              <i
                style={{ fontSize: "12px", color: "black" }}
                className="bi bi-chevron-right"
              ></i>
              <NavLink>Manufacture & Distribution</NavLink>
            </div>
            <div className={styles.filters}>
              <img
                src={filterMF}
                onClick={handleMobileSearch}
                width="120px"
                alt="filter"
              />
              {/* <img src={sortMf} width="120px" alt="sortby" /> */}
            </div>
          </div>

          <h1>{title}</h1>
          {/* <p style={{ fontSize: "15px", marginTop: "8px" }}>
            Found {prodCount} {title}
          </p> */}
          <div className={styles.filterEquipmentData}>
            {isOptionSelectd ? (
              <div className={styles.mfContent}>
                <MFProdCard
                  searchItem={pcheckList}
                  setProdCount={setProdCount}
                />
              </div>
            ) : (
              <div className={styles.manuBrand}>
                <MFProdCard
                  searchItem={pcheckList}
                  setProdCount={setProdCount}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const ManuSearch = (props) => {
  const Equip_categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const filterCategoriesName = Equip_categories?.filter((el) => {
    return el.name !== "adminTest";
  });

  const dispatch = useDispatch();

  const [filter, setFilter] = useState("");

  const handleFilter = (e) => {
    setFilter(e.currentTarget.value);
  };
  useEffect(() => {
    if (props?.searchFor === 2) {
      dispatch(fetchCategories(filter));
    }
  }, [filter, dispatch, props?.searchFor]);

  return (
    <React.Fragment>
      <div className="">
      <div className={styles.msearchCont}>
        <h4>{props.title}</h4>
        <div className={styles.msearch}>
          <input
            type="text"
            onChange={handleFilter}
            value={filter}
            placeholder="Search"
          />
          <span>
            <i className="bi bi-search"></i>
          </span>
        </div>
        {filterCategoriesName?.length === 0 ? (
          <p style={{ fontSize: "14px", textAlign: "center" }}>
            No category found !
          </p>
        ) : (
          <div
            className={
              props.searchFor === 1
                ? styles.searchDropCont1
                : styles.searchDropCont
            }
          >
            {props.searchFor === 1
              ? props?.data
                  ?.slice()
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((data, index) => {
                    return (
                      <SearchDropDown1
                        filter={setFilter}
                        searchFor={props.searchFor}
                        data={data}
                        key={index}
                      />
                    );
                  })
              : filterCategoriesName
                  ?.slice()
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((data, index) => {
                    return (
                      <SearchDropDown
                        filter={setFilter}
                        pcheckList={props?.pcheckList}
                        setPCheckList={props?.setPCheckList}
                        searchFor={props.searchFor}
                        data={data}
                        key={index}
                      />
                    );
                  })}
          </div>
        )}
      </div>
      </div>
      {props?.ads ? "" : <div className={styles.maniLine}></div>}
    </React.Fragment>
  );
};

const SearchDropDown1 = (props) => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  // const update = useUpdateselection();
  const handleDrop = (e) => {
    let iconStyle = e.currentTarget.style;
    isDropOpen ? (iconStyle.rotate = "0deg") : (iconStyle.rotate = "  0deg");
    setIsDropOpen(!isDropOpen);
  };
  const handleChange = (e) => {
    let isChecked = e.currentTarget.checked;
    // update(isChecked, props.searchFor, props.data);
    // let iconStyle = e.currentTarget.style;
    // isDropOpen ? (iconStyle.rotate = "0deg") : (iconStyle.rotate = " 0deg");
    // setIsDropOpen(!isDropOpen);
  };

  return (
    <div>
      <div className={styles.searchTitle}>
        <div className={styles.subSearchT}>
          {props?.data?.children?.length > 0 && (
            <img src={searchDrop} alt="search drop" onClick={handleDrop} />
          )}

          <input
            type="checkbox"
            value={props.data?.name}
            onChange={handleChange}
          />
          <span>{props?.data?.name}</span>
        </div>
        <span>({props?.data?.children?.length})</span>
      </div>
      {isDropOpen && <div style={{ marginLeft: "20px" }}></div>}
    </div>
  );
};
// const DropCard1 = (props) => {
//   const handleLists = (e) => {
//     let isChecked = e.currentTarget.checked;
//   };

//   return (
//     <div className={styles.searchTitle}>
//       <div className={styles.subSearchT}>
//         <input
//           type="checkbox"
//           value={props?.childData?.name}
//           onClick={handleLists}
//         />
//         <span>{props?.childData?.name}</span>
//       </div>
//       <span>({props?.childData?.children?.length})</span>
//     </div>
//   );
// };

const SearchDropDown = (props) => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const update = useUpdateselection();
  const handleDrop = (e) => {
    let iconStyle = e.currentTarget.style;
    isDropOpen ? (iconStyle.rotate = "0deg") : (iconStyle.rotate = "  90deg");
    setIsDropOpen(!isDropOpen);
  };
  const handleChange = (e) => {
    let isChecked = e.currentTarget.checked;
    if (isChecked) {
      let fill = props?.data?.children?.map((el) => el?.name);
      props.setPCheckList([
        ...props?.pcheckList,
        e.currentTarget.value,
        ...fill,
      ]);
    } else {
      props?.setPCheckList([]);
    }
    update(isChecked, props.searchFor, props.data);
    let iconStyle = e.currentTarget.style;
    isDropOpen ? (iconStyle.rotate = "0deg") : (iconStyle.rotate = "  0deg");
    setIsDropOpen(!isDropOpen);
  };

  return (
    <div>
      <div className={styles.searchTitle}>
        <div className={styles.subSearchT}>
          {props?.data?.children?.length > 0 && (
            <img style={{ width: "8px", height: "8px" }} src={searchDrop} alt="search drop" onClick={handleDrop} />
          )}
          <input
            type="checkbox"
            value={props.data?.name}
            onChange={handleChange}
          />
          <span>{props?.data?.name}</span>
        </div>
      </div>
      {isDropOpen && (
        <div style={{ marginLeft: "20px" }}>
          {props.data?.children
            ?.slice()
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((data, index) => {
              return (
                <DropCard
                  pcheckList={props.pcheckList}
                  setPCheckList={props?.setPCheckList}
                  childData={data}
                  key={index}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

const DropCard = (props) => {
  const handleLists = (e) => {
    let isChecked = e.currentTarget.checked;
    if (isChecked) {
      props.setPCheckList([...props.pcheckList, e.target.value]);
    } else {
      let fill = props?.pcheckList?.filter((el) => {
        return el !== e.currentTarget.value;
      });
      props.setPCheckList(fill);
    }
  };

  return (
    <div className={styles.searchTitle}>
      <div className={styles.subSearchT}>
        <input
          type="checkbox"
          value={props?.childData?.name}
          onClick={handleLists}
          checked={props?.pcheckList?.includes(props?.childData?.name)}
        />
        <span>{props?.childData?.name}</span>
      </div>
    </div>
  );
};

export const MFProdCard = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);
  const item = props?.searchItem;
  const [plists, setPlist] = useState([]);
  const [preload, setPreload] = useState(true);
  useEffect(() => {
    setCurrPage(1);
  }, [item, location]);

  const handleSearchItem = async () => {
    setPreload(true);
    let data;
    if (location.pathname.split("/")[1] === "preowned-equip") {
      if (item?.length > 0) {
        data = { equip_name: item, post_type: 1 };
      } else {
        data = { post_type: 1 };
      }
    } else if (
      location.pathname.split("/")[1] === "new-equip" ||
      location.pathname.split("/")[1] === "trending-equip"
    ) {
      if (item?.length > 0) {
        data = { equip_name: item, post_type: 2 };
      } else {
        data = { post_type: 2 };
      }
    } else if (location.pathname.split("/")[1] === "manufacturers") {
      data = { q: item };
    } else {
      data = { q: item };
    }
    data.product_status = 1;
    data._limit = 10;
    data._page = currPage;

    const res = await postData1("product/plists/", data);

    setPreload(false);
    if (res?.status) {
      if (location.pathname.split("/")[1] === "manufacturers") {
        let filter = res?.data?.data?.filter((el) => {
          return el?.seller_usertype === "2" || el?.seller_usertype === "1";
        });
        setPlist(filter);
      } else {
        setPlist(res?.data?.data);
      }
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });

      if (
        location.pathname.split("/")[1] === "preowned-equip" ||
        location.pathname.split("/")[1] === "new-equip" ||
        location.pathname.split("/")[1] === "manufacturers"
      ) {
        props?.setProdCount(res?.data?.length);
      }
    }
  };

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleProdClick = (list) => {
    navigate(
      `/products/${encodeURIComponent(list?.equip_name)}/?id=${list?.id}/`,
      {
        state: { prodDetails: list },
      }
    );
  };
  useEffect(() => {
    handleSearchItem();
  }, [item, location, currPage]);

  return (
    <>
      <div className={styles.prodListing}>
        {plists?.length > 0 ? (
          plists?.map((list, index) => {
            return (
              <div
                key={index}
                className={styles.mfProdCard}
                onClick={(e) => handleProdClick(list)}
              >
                <img
                  src={
                    list?.product_images[0]?.product_images
                      ? list?.product_images[0]?.product_images
                      : relatedImg
                  }
                  className={styles.prodImg}
                  alt="..."
                />
                <div className={styles.mfProdData}>
                  <div className={styles.mfTitile}>
                    <h4>{list?.equip_name}</h4>
                    <span>
                      {list?.post_type === 1
                        ? "PRE OWNED"
                        : list?.post_type === 2
                        ? "NEW"
                        : list?.post_type === 3
                        ? "SPARE & ACCESSORIES"
                        : "SERVICES"}
                    </span>
                    <p className={styles.mfBrand}>
                      {!list?.post_type ? list?.brand : ""}
                    </p>
                  </div>
                  {!list?.post_type && (
                    <div className={styles.mfType}>
                      <span>Video laryngoscopes</span>
                      <span>Lithotripters</span>
                      <span>Video ureteroscopes</span>
                    </div>
                  )}
                  {list?.post_type && (
                    <>
                      <p>{list?.uid.slice(0, 8)}</p>
                      <h3>
                        Seller :{" "}
                        {`${
                          list?.seller_firstname !== null
                            ? list?.seller_firstname
                            : ""
                        } ${
                          list?.seller_lastname !== null
                            ? list?.seller_lastname
                            : ""
                        }`}
                      </h3>
                    </>
                  )}
                  <p>City: {<ProdAddress address={list?.address} />}</p>
                  <p>
                    {list?.description === "null"
                      ? list?.other_details?.length > 100
                        ? `${list?.other_details?.slice(0, 100)}`
                        : list?.other_details
                      : list?.description?.length > 100
                      ? `${list?.description?.slice(0, 100)}`
                      : list?.description}
                  </p>
                  <div className={styles.priceCont}>
                    <div className={styles.pricing}>
                      <h2>₹ {Number(list?.asking_price).toFixed(2)}</h2>
                      <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                        (GST tax included)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : preload ? (
          <div>Loading...</div>
        ) : (
          <div className={styles.mfEmptyy}>
            <div style={{ textAlign: "center", width: "50vw" }}>
              No Data Found
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: "right", marginTop: "15px", width: "100%" }}>
        <Pagination
          total={paging?.total}
          perPage={10}
          currPage={currPage}
          prevPage={prevPage}
          tPage={paging?.total_pages}
          updatePage={updatePage}
        />
      </div>
    </>
  );
};

export const FilterProductData = () => {
  return (
    <>
      <div className={styles.filterText}>
        <h3>FILTER</h3>
      </div>
      <div className={styles.searchContainer}>
        <div className={styles.filterDate}>
          <div className={styles.filterDateContent}>
            <label htmlFor="">PRICE</label>
            <input type="number" name="price" id="price" placeholder="" />
            <input type="number" name="price" id="price" placeholder="" />
          </div>
          <div className={styles.filterDateContent}>
            <label htmlFor="">YEAR</label>
            <input type="" name="date" id="price" />
            <input type="" name="date" id="price" />
          </div>
        </div>

        <Appac />
      </div>
    </>
  );
};

// filter Data
function Appac() {
  const [activeButton, setActiveButton] = useState(null);

  const handleToggle = (buttonId) => {
    if (activeButton === buttonId) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonId);
    }
  };

  return (
    <div className={styles.accordion}>
      <button
      //  onClick={() => handleToggle(1)}
      >
        CONDITION OF THE EQUIPMENT
        <span>
          <i class="bi bi-arrow-right-short"></i>
        </span>
      </button>
      {activeButton === 1 ? (
        <div className={styles.filterform}>
          <div className={styles.filterfom1}>
            <input type="checkbox" id="viewall" name="viewall" value="all" />
            <label for="vehicle1">All</label>
          </div>
          <div className={styles.filterfom1}>
            <input type="checkbox" id="viewall" name="viewall" value="all" />
            <label for="vehicle1">Used</label>
          </div>
        </div>
      ) : null}

      <button
      // onClick={() => handleToggle(2)}
      >
        BRAND
        <span>
          <i class="bi bi-arrow-right-short"></i>
        </span>
      </button>
      {/* {activeButton === 2 ? ( */}
      <div className={styles.filterform}>
        <div className={styles.brand}>
          <input type="text" placeholder="BRAND" />
          <label for="brand">
            {" "}
            <i class="bi bi-search"></i>{" "}
          </label>
        </div>
      </div>
      {/* ) : null} */}

      <button
      //  onClick={() => handleToggle(3)}
      >
        MODEL
        <span>
          <i class="bi bi-arrow-right-short"></i>
        </span>
      </button>
      {/* {activeButton === 3 ? ( */}
      <div className={styles.filterform}>
        <div className={styles.brand}>
          <input type="text" placeholder="MODEL" />
          <label for="model">
            {" "}
            <i class="bi bi-search"></i>{" "}
          </label>
        </div>
      </div>
      {/* ) : null} */}

      <button
      //  onClick={() => handleToggle(4)}
      >
        SHIPING FROM
        <span>
          <i class="bi bi-arrow-right-short"></i>
        </span>
      </button>
      {/* {activeButton === 4 ? ( */}
      <div className={styles.filterform}>
        <div className={styles.brand}>
          <input type="text" placeholder="CITY" />
          <label for="model">
            {" "}
            <i class="bi bi-search"></i>{" "}
          </label>
        </div>
        <div className={styles.shipingFrom}>
          <ul className={styles.dropoodown}>
            <li>
              <input type="checkbox" />
              Delhi
            </li>
            <li>
              <input type="checkbox" />
              Mumbai
            </li>
          </ul>
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
}

/* Custom Hooks */

function useUpdateselection() {
  const dispatch = useDispatch();
  const updateSelection = (isChecked, searchFor, data) => {
    if (isChecked) {
      if (searchFor === 1) {
        dispatch(setSelectedMF(data));
      } else if (searchFor === 2) {
        dispatch(setSelectedCat(data));
      }
    } else {
      if (searchFor === 1) {
        dispatch(removeSelectedMF(data));
      } else if (searchFor === 2) {
        dispatch(removeSelectCat(data));
      }
    }
  };
  return updateSelection;
}
