import React from "react";
import generatePDF from "./PdfGenerator";
import { orderStatusImag1 } from "../assets/images";

const Invoice = ({ invoiceData }) => {
  const handleDownloadPDF = () => {
    generatePDF(invoiceData);
  };

  return (
    <div>
      <button onClick={handleDownloadPDF}>
        {" "}
        <span>
          <img src={orderStatusImag1} alt="invoice-download" />
        </span>{" "}
        Download Invocie
      </button>
    </div>
  );
};

export default Invoice;
