import React from 'react'

export const RequestForServiceContact = () => {
  const [address, setAddress] = React.useState([]);
  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const getAddress = async () => {
    try {
      const response = await fetch('https://mmq.mediquip.in/api/services/address/get/all', {
        method: 'GET',
        headers: {
          accept: 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAddress(data);
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  }

  React.useEffect(() => {
    getAddress();
  }, []);

  const handleView = (item) => {
    setSelectedItem(item);
    setShowPopup(true);
  }

  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
          <th scope="col" className="px-6 py-3">
              Request For
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Contact
            </th>
            <th scope="col" className="px-6 py-3">
              Address
            </th>
            <th scope="col" className="px-6 py-3">
              City
            </th>
            <th scope="col" className="px-6 py-3">
              State
            </th>
            <th scope="col" className="px-6 py-3">
              Pincode
            </th>
            <th scope="col" className="px-6 py-3">
              Message
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {address.map(item => (
            <tr key={item.id} className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
              <td className="px-6 py-2">
                {item.to.otherData?.data?.providerName}
              </td>
              <td className="px-6 py-2">
                {item.from.name}
              </td>
              <td className="px-6 py-2">
                {item.from.email}
              </td>
              <td className="px-6 text-[#219d8e] cursor-pointer py-2">
                {item.from.contact}
              </td>
              <td className="px-6 py-2">
                {item.from.address}
              </td>
              <td className="px-6 py-2">
                {item.from.city}
              </td>
              <td className="px-6 py-2">
                {item.from.state}
              </td>
              <td className="px-6 py-2">
                {item.from.pincode}
              </td>
              <td className="px-6 py-2">
                {item.from.message}
              </td>
              <td className="px-6 py-2">
                <button type="button" onClick={() => handleView(item)} className="text-white bg-[#219d8e] hover:bg-bg-[#219d8e] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-bg-[#219d8e] dark:hover:bg-bg-[#219d8e] focus:outline-none dark:focus:ring-blue-800">
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {console.log(selectedItem)}
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50">
          <div className="flex items-center justify-center h-full">
            <div className="bg-white p-10 rounded-lg">
              <h2 className="text-2xl font-bold mb-4">Service Details</h2>
              <p>Service Name: {selectedItem?.to?.otherData?.data?.providerName}</p>
              <p>Service Type: {selectedItem?.to?.serviceType}</p>
              <p>Service Address: {selectedItem?.to?.otherData?.data?.address}</p>
              <p>Service Description: {selectedItem?.to?.description}</p>
              <button type="button" onClick={() => setShowPopup(false)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

