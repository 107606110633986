import React, { useEffect, useState } from "react";
import Style from "../../../assets/css/user/buyer_seller/orderDetail.module.css";
import { orderStatusImag2 } from "../../../assets/images/index";
import progress1 from "../../../assets/images/progress1.png";
import progress2 from "../../../assets/images/progress2.png";
import progress3 from "../../../assets/images/progress3.png";
import progress4 from "../../../assets/images/progress4.png";
import { useLocation } from "react-router-dom";
import { postData } from "../../../services";
import Invoice from "../../../Pdfgenerator/Invoice";
const API_KEY = process.env.REACT_APP_ADDRESS_KEY;

export const OrderDetail = () => {
  const location = useLocation();
  let item = location?.state?.productDetails;
  const [orderDetails, setOrderDetails] = useState(item);

  return (
    <section className={Style.orderStatus}>
      <div className={Style.orderStatusCard}>
        <h1>Order Status </h1>
        <div className={Style.orderDetails}>
          <BookingDetails item={orderDetails} />
          {/*  */}
        </div>
      </div>
    </section>
  );
};

const BookingDetails = ({ item }) => {
  const buyerId = localStorage.getItem("userDetails");
  const [address, setAddress] = useState("");
  const [buyerAddress, setBuyerAddress] = useState("");

  const handleProdAddress = async () => {
    const formData = new FormData();
    formData.append("uid", item?.product_detail?.address);
    const res = await postData("users/address_list/", formData, true);
    if (res?.status) {
      setAddress(res?.data[0]);
    }
  };

  const handleBuyerAddress = async () => {
    const formData = new FormData();
    formData.append("uid", item?.delivery_address);
    const res = await postData("users/address_list/", formData, true);
    if (res?.status) {
      setBuyerAddress(res?.data[0]);
    }
  };

  useEffect(() => {
    handleProdAddress();
    handleBuyerAddress();
  }, [item]);

  const order_date = new Date(item?.created_date).toLocaleDateString();

  const invoiceData = {
    sellerName: `${item?.product_detail?.seller_firstname} ${item?.product_detail?.seller_lastname}`,
    sellerAddress: address?.address,
    buyerName: `${JSON.parse(buyerId)?.fName} ${JSON.parse(buyerId)?.lName}`,
    buyerAddress: `${buyerAddress?.city}, ${buyerAddress?.state}`,
    invoice_number: item?.uid,
    orderId: item?.order_id,
    orderDate: order_date,
    product: item?.product_detail?.equip_name,
    product_details:
      item?.product_detail?.description === "null"
        ? item?.product_detail?.other_details
        : item?.product_detail?.description,
    product_qty: 1,
    Gross_amt: parseFloat(item?.asking_price).toFixed(2),
    Discounts: item?.discount,
    Taxable_amt: item?.tax,
    Total: parseFloat(item?.total).toFixed(2),
    IGST: "",
    productId: item?.product,
    amc_services_amount: parseFloat(item?.amc_services_amount)?.toFixed(2),
    handling_instalation_amount: parseFloat(
      item?.handling_instalation_amount
    )?.toFixed(2),
    shipping_equipment_amount: parseFloat(
      item?.shipping_equipment_amount
    )?.toFixed(2),
    total_amc_services_amount: (
      (+item?.amc_services_amount * 18) / 100 +
      +item?.amc_services_amount
    ).toFixed(2),
    total_handling_instalation_amount: (
      (+item?.handling_instalation_amount * 18) / 100 +
      +item?.handling_instalation_amount
    ).toFixed(2),
    total_shipping_equipment_amount: (
      (item?.shipping_equipment_amount * 18) / 100 +
      +item?.shipping_equipment_amount
    ).toFixed(2),
    Shipping_and_handling_charge: `${(
      (+item?.amc_services_amount * 18) / 100 +
      +item?.amc_services_amount +
      ((+item?.handling_instalation_amount * 18) / 100 +
        +item?.handling_instalation_amount) +
      ((+item?.shipping_equipment_amount * 18) / 100 +
        +item?.shipping_equipment_amount) +
      +item?.total
    )?.toFixed(2)}`,
    sub_total: "",
    grand_total: "",
    // ... other invoice details ...
  };
  return (
    <section className={Style.bookingDetails}>
      <h2>Booking Details</h2>

      <div className={Style.container}>
        <div className={Style.deliveryAddress}>
          <h3>Delivery Address</h3>
          <h2>{buyerAddress?.address}</h2>
        </div>
        <div className={Style.bookinTime}>
          <h3>Booking Date & Time</h3>
          <h2>
            {item?.date}{" "}
            <span>{new Date(item?.created_date).toLocaleTimeString()}</span>
          </h2>
        </div>
        <div className={Style.eqipmentLocation}>
          <h3>Equipment Location</h3>
          <h2>{address?.city}</h2>
        </div>
        <div className={Style.bookingInvoice}>
          <Invoice invoiceData={invoiceData} />
        </div>
      </div>

      <div className={Style.productDetials}>
        <ProductDetails item={item} />
        <LiveStatus orderStatus={item?.order_status} />
      </div>
    </section>
  );
};

const ProductDetails = ({ item }) => {
  const [readMore, setReadMore] = useState(false);
  return (
    <div>
      <div className={Style.productHead}>
        <h3>Product Details</h3>
        <h3>
          <button>
            {" "}
            {/* <strong>
              <img src={orderStatusImag3} alt="" />
            </strong>{" "}
            Need Help */}
            CANCEL
          </button>
        </h3>
      </div>
      <div className={Style.content}>
        <div>
          <img
            src={
              item?.product_detail?.product_images
                ? item?.product_detail?.product_images[0]?.product_images
                : orderStatusImag2
            }
            alt=""
          />
        </div>
        <div>
          <span>
            {item?.product_detail?.post_type === 1
              ? "PRE-OWNED"
              : item?.product_detail?.post_type === 2
              ? "NEW"
              : item?.product_detail?.post_type === 3
              ? "SPARE & ACCESSORIES"
              : "SERVICES"}
          </span>
          <h2>{item?.product_detail?.equip_name}</h2>
          <h3>({item?.uid})</h3>
          <p>
            {item?.product_detail?.other_details?.length > 200 && !readMore
              ? item?.product_detail?.other_details.slice(150)
              : readMore
              ? item?.product_detail?.other_details
              : item?.product_detail?.other_details}

            {item?.product_detail?.other_details?.length > 200 && (
              <button
                onClick={() => setReadMore(!readMore)}
                style={{ marginLeft: "5px" }}
              >
                Read more
              </button>
            )}
          </p>
          <h2>
            <strong>&#8377; </strong>
            {Number(item?.asking_price)}
          </h2>
          <h2>
            {"Including Platform Fee "}
            <strong>&#8377; </strong>
            {`${Number(item?.total)}`}
          </h2>
        </div>
      </div>
    </div>
  );
};
const LiveStatus = ({ orderStatus }) => {
  let progress = 0;
  if (orderStatus === 5) {
    progress = 27;
  } else if (orderStatus === 6) {
    progress = 33.3;
  } else if (orderStatus === 7) {
    progress = 66.3;
  } else if (orderStatus === 8) {
    progress = 99.3;
  }

  return (
    <div className={Style.liveStatus}>
      <h2>Live Status</h2>
      <div className={Style.progressBody}>
        <div className={Style.progressBar} style={{ width: `${progress}%` }}>
          <img src={progress1} style={{ left: "0%" }} alt="prog1" />
          <img src={progress2} style={{ left: "33.3%" }} alt="prog1" />
          <img src={progress3} style={{ left: "66.3%" }} alt="prog1" />
          <img src={progress4} style={{ left: "100%" }} alt="prog1" />
        </div>
      </div>
      <div className={Style.progressName}>
        <div style={{ left: "0%" }}>Order Confirmed</div>
        <div style={{ left: "33.3%" }}>Shipped</div>
        <div style={{ left: "66.3%" }}>Delivered</div>
        <div style={{ left: "100%" }}>Installed</div>
      </div>
    </div>
  );
};
