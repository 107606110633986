import React, { useEffect } from "react";
import styles from "../../assets/css/manufacture/payments.module.css";
import distListImg from "../../assets/images/manufacture/distributor5.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderList } from "../../app/Slices/ProdListSlice";

export const DistPayments = () => {
  const dispatch = useDispatch();
  const buyerId = localStorage.getItem("uid");
  const paymentList = useSelector(
    (state) => state.plist.prodListData.order_list
  );
  useEffect(() => {
    dispatch(fetchOrderList(buyerId));
  }, []);
  return (
    <section className={styles.paymentOrder}>
      <div className="">
        <h1>Payment Overview</h1>
        <div>
          <DistPaymentList list={paymentList} />
        </div>
      </div>
    </section>
  );
};

const DistPaymentList = ({ list }) => {
  return (
    <div className={styles.distPaymentList}>
      <div className={styles.list2}>
        <table>
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Details</th>
              <th>Token Amount(&#8377;)</th>
              <th>Final Amount(&#8377;)</th>
              <th>Services</th>
              <th>Total Amount(&#8377;)</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((el) => {
              return (
                <tr>
                  <td>
                    <h3>
                      {el?.payment_status === 1
                        ? "Pending"
                        : el?.payment_status === 2
                        ? "Partial"
                        : "Completed"}
                    </h3>
                  </td>
                  <td>
                    <p>
                      Order Id : <span>#{el?.uid}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>&#8377;</span>
                      {Number(el?.sub_total).toFixed(2)}
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>&#8377;</span>
                      {Number(el?.total).toFixed(2)}
                    </p>
                  </td>
                  <td>
                    <div className={styles.orderServices}>
                      <p>Shiping Equipment</p>
                      <h2>(&#8377;) 12233.00</h2>
                    </div>
                    <div className={styles.orderServices}>
                      <p>Handling & installation</p>
                      <h2> - </h2>
                    </div>
                    <div className={styles.orderServices}>
                      <p>AMC/CMC Service</p>
                      <h2>-</h2>
                    </div>
                  </td>
                  <td>
                    <p>
                      <span>&#8377;</span> {Number(el?.total).toFixed(2)}
                    </p>
                  </td>
                </tr>
              );
            })}
            {/* <tr>
              <td>
                <h3>ORDER</h3>
              </td>
              <td>
                <p>
                  Order Id : <span>#204c348</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <div className={styles.orderServices}>
                  <p>Shiping Equipment</p>
                  <h2>(&#8377;) 12233.00</h2>
                </div>
                <div className={styles.orderServices}>
                  <p>Handling & installation</p>
                  <h2> - </h2>
                </div>
                <div className={styles.orderServices}>
                  <p>AMC/CMC Service</p>
                  <h2>-</h2>
                </div>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>12200.00
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <h3>ORDER</h3>
              </td>
              <td>
                <p>
                  Order Id : <span>#204c348</span>
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <div className={styles.orderServices}>
                  <p>Shiping Equipment</p>
                  <h2>(&#8377;) 12233.00</h2>
                </div>
                <div className={styles.orderServices}>
                  <p>Handling & installation</p>
                  <h2> - </h2>
                </div>
                <div className={styles.orderServices}>
                  <p>AMC/CMC Service</p>
                  <h2>-</h2>
                </div>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>12200.00
                </p>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const DistDownloadInvoice = () => {
  return (
    <React.Fragment>
      <div className={styles.distInvoice}>
        <h1>Download Invoice</h1>
      </div>
      <div className={styles.distPaymentList}>
        <div className={styles.list2}>
          <table>
            <thead>
              <tr>
                <th>Equipement Details</th>
                <th>LIsting Date</th>
                <th>Final Price</th>
                <th>Status</th>
                <th>Category</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={styles.distPaymentListContent}>
                    <div>
                      <img src={distListImg} alt="lists" />
                    </div>
                    <div>
                      <p>
                        Order Id : <span>#204c348</span>
                      </p>
                      <h3>Dunlop Wheel</h3>
                    </div>
                  </div>
                </td>
                <td>
                  <p>12-09-2023</p>
                </td>
                <td>
                  <p>
                    <span>&#8377;</span>
                    120000:00
                  </p>
                </td>

                <td>
                  <h5>Generated</h5>
                </td>
                <td>
                  <p>Cardiology</p>
                </td>
                <td>
                  <h6> Download</h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};
