import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BackgroundBlur, GetStarted } from "../../utils/Popups";

const fetchSellSpecilityList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/speciality/lists/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status_code === 200) {
      return data.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};

const getMenuList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/category/menulist/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (data.status_code === 200) {
      console.log(data);
      return data.data;
    }
  } catch (err) {
    console.log("err", err);
  }
};
const getUserDetail = async (uid, token) => {
  const response = await fetch(
    "https://mmq.mediquip.in/api/v1/users/get_user_detail/",
    {
      method: "POST",
      headers: {
        accept: "application/json",
        "accept-language": "en-IN,en-US;q=0.9,en-GB;q=0.8,en;q=0.7,hi;q=0.6",
        authorization: `Token ${token}`,
        "content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryFLkVzp4G03o1rAcK",
      },
      body: `------WebKitFormBoundaryFLkVzp4G03o1rAcK\r\nContent-Disposition: form-data; name="uid"\r\n\r\n${uid}\r\n------WebKitFormBoundaryFLkVzp4G03o1rAcK--\r\n`,
    }
  );
  const data = await response.json();
  if (data.status_code === 200) {
    return data.data;
  }
};

function SellServices() {
  const token = localStorage.getItem("token");
  const buyerId = localStorage.getItem("uid");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [showloader, setShowLoader] = useState(false);
  const { serviceType } = state || {};
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [speciality, setSpeciality] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [equipmentList, setEquipMentList] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [userData, setUserData] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [showList, setShowList] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  // const [isBlur, setBlur] = useState(false);
  // const [getStart, setGetStart] = useState(false);
  const userLocation = userData?.profile["location"];
  const obj ={
    serviceType,
    providerName: serviceType,
    serviceDescription: "",
    images: [],
    equipmentName: "",
    categoryName: "",
    specialtyName: "",
  }
  const [data, setData] = useState(obj);
  const obj1 ={
    phone: "",
    email: "",
    address: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
  }
  const [formData, setFormData] = useState(obj1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowLoading(true);
        const response = await fetchSellSpecilityList();
        setSpeciality(response);
        const menu = await getMenuList({ parent: 41 });
        setCategoryList(menu);
        const User = await getUserDetail(buyerId, token);
        setUserData(User);
        console.log(userData);

        // Flatten category list into equipment list
        let filtered = [];
        menu.forEach((parent) => {
          if (parent.children.length > 0) {
            parent.children.forEach((child) => {
              filtered.push(child);
            });
          }
        });
        setEquipMentList(filtered);
        setShowLoading(false);
      } catch (error) {
        console.error("Error fetching speciality or menu data:", error);
      }
    };
    fetchData();
  }, []);

  // Function to handle equipment search
  const handleEquipmentSearch = (e) => {
    const searchTerm = e.target.value;
    setData({ ...data, equipmentName: searchTerm });

    if (searchTerm) {
      const filtered = equipmentList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEquipment(filtered);
    } else {
      setFilteredEquipment(equipmentList);
    }
  };

  // Function to handle the selection from the dropdown
  const handleEquipmentSelect = (item) => {
    if (item === "Other") {
      setShowOtherInput(true);
      setData({ ...data, equipmentName: "" }); // Clear equipment name when selecting "Other"
      console.log(data);
    } else {
      setData({ ...data, equipmentName: item.name });
      setShowOtherInput(false);
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setData({
      ...data,
      images: [...data.images, ...e.target.files],
    });
  };

  const handleCustomEquipmentInput = (e) => {
    setData({ ...data, equipmentName: e.target.value });
  };

  const handleCategorySelect = (categoryId) => {
    // If the category is already selected, remove it, otherwise add it
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };
  const handleSpecialitySelect = (specialityId) => {
    if (selectedSpecialities.includes(specialityId)) {
      setSelectedSpecialities(
        selectedSpecialities.filter((id) => id !== specialityId)
      );
    } else {
      setSelectedSpecialities([...selectedSpecialities, specialityId]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/user/login");
      return;
    }
    setShowLoader(true);
    const selectedCategoryNames = categoryList
      .filter((category) => selectedCategories.includes(category.id))
      .map((category) => category.name)
      .join(", ");

    const selectedSpecialityNames = speciality
      .filter((spec) => selectedSpecialities.includes(spec.id))
      .map((spec) => spec.name)
      .join(", ");
    console.log(selectedSpecialityNames);
    console.log(selectedCategoryNames);
    setData({
      ...data,
      categoryName: selectedCategoryNames,
      specialtyName: selectedSpecialityNames,
    });
    console.log(data);
    // API call to store data
    const obj = {
      uid: buyerId,
      serviceType: data.serviceType,
      specialityOrProviderName:
        userData.profile.first_name + " " + userData.profile.last_name,
      description: data.serviceDescription,
      location2: data.userLocation,
      otherData: { type: "serviceProvider", isActive: false, data, userData, address: formData },
    };
  
    console.log(obj);
    try {
      const response = await axios.post(
        "https://mmq.mediquip.in/api/services/add",
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      console.log("service data", response);
      if (response.status === 201) {
        toast.success(`Your ${data.serviceType} Service submitted successfully !`);
        setData(obj);
          setFormData(obj1);
          setShowLoader(false);
          navigate('/profile/myservices')
      } else {
        toast.error("Something went wrong");
      }
     
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className="container max-w-6xl mx-auto p-4">
      <ToastContainer />
      {/* {getStart ? (
        <GetStarted setGetStart={setGetStart} setBlur={setBlur} />
      ) : (
        ""
      )}
      {isBlur ? <BackgroundBlur /> : ""} */}
      {showLoading ? (
        <body class="bg-gray-100 flex items-center justify-center h-screen">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
              <h2 class="text-xl font-semibold mb-4">Processing...</h2>

              <div class="flex justify-center">
                <div class="w-12 h-12 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              </div>
            </div>
          </div>
        </body>
      ) : (
        <form
          className="bg-white shadow-md rounded p-8"
          onSubmit={handleSubmit}
        >
          <h2 className="text-2xl text-center font-bold mb-4">
            Add Your Services for {data.serviceType}
          </h2>

          <div className="flex flex-wrap -mx-3 mt-10 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="equipmentName"
              >
                Equipment Name
              </label>

              {/* Search Input */}
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="equipmentName"
                type="text"
                value={data.equipmentName}
                onChange={handleEquipmentSearch}
                onFocus={() => setShowList(true)}
                onBlur={() => setTimeout(() => setShowList(false), 700)}
                placeholder="Search or type to add custom equipment"
              />

              {/* Dropdown for Matching Equipment */}
              {showList && filteredEquipment.length > 0 && (
                <ul className="bg-white border border-gray-300 mt-1 max-h-48 overflow-y-auto">
                  {filteredEquipment.map((item) => (
                    <li
                      key={item.id}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                      onClick={() => handleEquipmentSelect(item)}
                    >
                      {item.name}
                    </li>
                  ))}
                  <li
                    className="cursor-pointer p-2 hover:bg-gray-100 text-blue-500"
                    onClick={() => handleEquipmentSelect("Other")}
                  >
                    Other
                  </li>
                </ul>
              )}

              {/* Input for Custom Equipment Name */}
              {showOtherInput && (
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mt-2 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  placeholder="Enter custom equipment name"
                  value={data.equipmentName}
                  onChange={handleCustomEquipmentInput}
                />
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <div className="w-full md:w-full  mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="categoryList"
                >
                  Categories
                </label>

                {/* Dropdown Toggle */}
                <div className="relative">
                  <button
                    type="button"
                    className="bg-gray-200 border border-gray-300 text-gray-700 py-2 px-4 rounded w-full text-left"
                    onClick={() => setLoading(!loading)} // Toggle the dropdown
                  >
                    {selectedCategories.length > 0
                      ? `Selected Categories: ${selectedCategories.length}`
                      : "Select Categories"}
                    <span className="float-right">▼</span>
                  </button>

                  {/* Dropdown List with Checkboxes */}
                  {loading && (
                    <ul className="absolute z-10 bg-white border border-gray-300 w-full max-h-48 overflow-y-auto mt-1">
                      {categoryList.map((category) => (
                        <li
                          key={category.id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                        >
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={selectedCategories.includes(category.id)}
                              onChange={() => handleCategorySelect(category.id)}
                              className="mr-2"
                            />
                            {category.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              {/* Optional Display of Selected Categories */}
              {selectedCategories.length > 0 && (
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Selected Categories:
                  </label>
                  <div className="p-2 bg-gray-100 border border-gray-300 rounded">
                    {selectedCategories.length > 0 ? (
                      <ul>
                        {categoryList
                          .filter((category) =>
                            selectedCategories.includes(category.id)
                          )
                          .map((category) => (
                            <li key={category.id}>{category.name}</li>
                          ))}
                      </ul>
                    ) : (
                      <p>No categories selected</p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/2  mb-6 md:mb-0">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="specialityList"
                >
                  Specialities
                </label>

                {/* Dropdown Toggle */}
                <div className="relative">
                  <button
                    type="button"
                    className="bg-gray-200 border border-gray-300 text-gray-700 py-2 px-4 rounded w-full text-left"
                    onClick={() => setLoading1(!loading1)} // Toggle the dropdown
                  >
                    {selectedSpecialities.length > 0
                      ? `Selected Specialities: ${selectedSpecialities.length}`
                      : "Select Specialities"}
                    <span className="float-right">▼</span>
                  </button>

                  {/* Dropdown List with Checkboxes */}
                  {loading1 && (
                    <ul className="absolute z-10 bg-white border border-gray-300 w-full max-h-48 overflow-y-auto mt-1">
                      {speciality.map((spec) => (
                        <li
                          key={spec.id}
                          className="p-2 hover:bg-gray-100 cursor-pointer"
                        >
                          <label className="flex items-center">
                            <input
                              type="checkbox"
                              checked={selectedSpecialities.includes(spec.id)}
                              onChange={() => handleSpecialitySelect(spec.id)}
                              className="mr-2"
                            />
                            {spec.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              {/* Optional Display of Selected Specialities */}
              {selectedSpecialities.length > 0 && (
                <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Selected Specialities:
                  </label>
                  <div className="p-2 bg-gray-100 border border-gray-300 rounded">
                    {selectedSpecialities.length > 0 ? (
                      <ul>
                        {speciality
                          .filter((spec) =>
                            selectedSpecialities.includes(spec.id)
                          )
                          .map((spec) => (
                            <li key={spec.id}>{spec.name}</li>
                          ))}
                      </ul>
                    ) : (
                      <p>No specialities selected</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                htmlFor="serviceDescription"
              >
                Service description
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                id="serviceDescription"
                value={data.serviceDescription}
                onChange={handleChange}
              />
            </div>
            {serviceType === "Consumable" && (
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="images"
                >
                  Images IN Consusamable
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  id="images"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                />
              </div>
            )}
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="phone" className="text-sm font-medium mb-1">
                Phone Number:
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Number"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="email" className="text-sm font-medium mb-1">
                Email Id:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Email"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="address" className="text-sm font-medium mb-1">
                Address:
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Balbir Nagar, Shahdara Tehsil, Shahdara, Delhi, 110093, India"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="address1" className="text-sm font-medium mb-1">
                Address1:
              </label>
              <input
                type="text"
                id="address1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Address1"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="city" className="text-sm font-medium mb-1">
                City:
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Shahdara"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="state" className="text-sm font-medium mb-1">
                State/Region:
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="Delhi"
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2 mt-6 px-3">
              <label htmlFor="zip" className="text-sm font-medium mb-1">
                Zip/Postal Code:
              </label>
              <input
                type="text"
                id="zip"
                name="zip"
                value={formData.zip}
                onChange={handleChange}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                placeholder="110093"
              />
            </div>
          </div>

          <div className="flex items-center w-full justify-between">
            <button
              className="bg-blue-500 w-full xl:w-auto hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      )}
      {showloader && (
        <body class="bg-gray-100 flex items-center justify-center h-screen">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
            <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
              <h2 class="text-xl font-semibold mb-4">Processing...</h2>

              <div class="flex justify-center">
                <div class="w-12 h-12 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
              </div>
            </div>
          </div>
        </body>
      )}
    </div>
  );
}

export default SellServices;
