import React, { useState, useEffect } from "react";
import styles from "../../assets/css/forms.module.css";
import commonCss from "../../assets/css/common.module.css";

import defaultProfile from "../../../assets/images/testimage1.png";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailSchema,
  fnameSchema,
  gstinSchema,
  lnameSchema,
  mnumberSchema,
  nationalitySchema,
  pancardSchema,
} from "../../../utils/validation";
import { postData1 } from "../../../services";
import { ToastContainer, toast } from "react-toastify";
import prevIcon from "../../../assets/images/previous_arrow.png";
import nextIcon from "../../../assets/images/next_arrow.png";
import { orderStatus } from "../../MappedData";

export const OrderForm = () => {
  const [isDisable, setisDisable] = useState(true);
  const [perm, updatePerm] = useState(true);
  const location = useLocation();
  const userData = location.state;
  const [imgs, setImgs] = useState(userData?.product_detail?.product_images);
  const [imgPointer, setImgPointer] = useState(0);
  const [videoPointer, setVideoPointer] = useState(0);
  const [videos, setVideos] = useState(userData?.product_detail?.product_video);
  const [currStatus, setStatus] = useState(userData?.order_status);
  const [isOpen, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      orderid: userData?.id,
      prodId: userData?.product_detail?.id,
      delivryAdd: userData?.product_detail?.address,
      postType: userData?.product_detail?.post_type,
      equipName: userData?.product_detail?.equip_name,
      buyerFname: userData?.buyer_firstname,
      buyerLname: userData?.buyer_lastname,
      sellerFname: userData?.seller_firstname,
      sellerLname: userData?.seller_lastname,
      discount: userData?.discount,
      remain: userData?.remain_amount,
      subTotal: userData?.sub_total,
      tax: userData?.tax,
      total: userData?.total,
      orderStatus: userData?.order_status,
      payStatus: userData?.payment_status,
      payType: userData?.payment_type,
    },

    // onSubmit: function (values) {
    //     handleSubmit(values);
    // },
  });

  const handleOrderStatus = (e, data) => {
    let value = e.currentTarget.innerHTML;
    setStatus(data);
    setOpen(false);
  };

  const moveImg = (e, isVideo) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      isVideo
        ? setVideoPointer(videoPointer === 0 ? 0 : videoPointer - 1)
        : setImgPointer(imgPointer === 0 ? 0 : imgPointer - 1);
    } else {
      isVideo
        ? setVideoPointer(
            videoPointer === videos?.length - 1
              ? videos?.length - 1
              : videoPointer + 1
          )
        : setImgPointer(
            imgPointer === imgs?.length - 1 ? imgs?.length - 1 : imgPointer + 1
          );
    }
  };

  const updateOrderStatus = async () => {
    const res = await postData1(
      "admin/update_order_status/",
      { uid: userData?.uid, order_status: currStatus },
      true,
      true
    );
    if (res?.status_code === 200) {
      toast.success("Status update successfully !");
    } else {
      toast.error("Something went wrong, try again !");
    }
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      {/* <h2>Detail Info <i onClick={()=>setisDisable(!isDisable)} style={{cursor:"pointer"}} className="bi bi-pencil-square"></i> </h2> */}
      <form
        className={styles.updateForm}
        disabled={true}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formFieds}>
          <div>
            <p>Order Id</p>
            <input
              disabled={true}
              type="text"
              name="orderid"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.orderid}
            />
          </div>
          <div>
            <p>Product Id</p>
            <input
              disabled={true}
              type="text"
              name="prodId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.prodId}
            />
          </div>
          <div>
            <p>Product</p>
            <input
              disabled={true}
              type="text"
              name="equipName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.equipName}
            />
          </div>
          <div>
            <p>Product Type</p>
            <input
              disabled={true}
              type="text"
              name="postType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.postType === 1
                  ? "PREOWNED"
                  : formik.values.postType === 2
                  ? "NEW"
                  : formik.values.postType === 3
                  ? "SPARE & ACCESSORIES"
                  : "SERVICES"
              }
            />
          </div>
          <div>
            <p>Buyer First Name</p>
            <input
              disabled={true}
              type="text"
              name="buyerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.buyerFname}
            />
          </div>
          <div>
            <p>Buyer Last Name</p>
            <input
              disabled={true}
              type="text"
              name="buyerLname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.buyerLname}
            />
          </div>
          <div>
            <p>Seller First Name</p>
            <input
              disabled={true}
              type="text"
              name="sellerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerFname}
            />
          </div>
          <div>
            <p>Seller Last Name</p>
            <input
              disabled={true}
              type="text"
              name="sellerLname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerLname}
            />
          </div>
          <div>
            <p>Sub Total</p>
            <input
              disabled={true}
              type="text"
              name="subTotal"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subTotal}
            />
          </div>
          <div>
            <p>Discount</p>
            <input
              disabled={true}
              type="text"
              name="discount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.discount}
            />
          </div>
          <div>
            <p>Tax</p>
            <input
              disabled={true}
              type="text"
              name="tax"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tax}
            />
          </div>

          <div>
            <p>Total</p>
            <input
              disabled={true}
              type="text"
              name="total"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.total}
            />
          </div>
          <div>
            <p>Remains</p>
            <input
              disabled={true}
              type="text"
              name="remain"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remain}
            />
          </div>
          <div>
            <p>Order Status</p>
            <input
              disabled={isDisable}
              type="text"
              name="orderStatus"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={orderStatus[formik.values.orderStatus]}
            />
          </div>
          <div>
            <p>Payment Status</p>
            <input
              disabled={isDisable}
              type="text"
              name="payStatus"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.payStatus === 1
                  ? "Pending"
                  : formik.values.payStatus === 2
                  ? "Partial"
                  : "Completed"
              }
            />
          </div>

          <div>
            <p>Payment Type</p>
            <input
              disabled={isDisable}
              type="text"
              name="payType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.payType === 1 ? "Active" : "InActive"}
            />
          </div>

          <div>
            <p>Delivery Address</p>
            <textarea
              disabled={isDisable}
              type="text"
              name="delivryAdd"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.delivryAdd}
            ></textarea>
          </div>

          {/* images && videos */}
          <div>
            <p>Imges</p>
            <div
              style={{
                backgroundImage: `url(${imgs[imgPointer]?.product_images})`,
              }}
              className={styles.imgeCont}
            ></div>
            <div className={styles.vidMover}>
              <img
                src={prevIcon}
                onClick={(e) => moveImg(e, 0)}
                alt="Prev"
                name="prev"
              />
              <p style={{ textAlign: "center" }}>
                {imgs?.length === 0 ? 0 : imgPointer + 1}/{imgs?.length}
              </p>
              <img
                src={nextIcon}
                onClick={(e) => moveImg(e, 0)}
                alt="Next"
                name="next"
              />
            </div>
          </div>

          <div>
            <p>videos</p>
            <video className={styles.imgeCont} controls>
              <source src={videos[videoPointer]} type="video/mp4" />
            </video>
            <div className={styles.vidMover}>
              <img
                src={prevIcon}
                onClick={(e) => moveImg(e, 1)}
                alt="Prev"
                name="prev"
              />
              <p style={{ textAlign: "center" }}>
                {videos?.length === 0 ? 0 : videoPointer + 1}/{videos?.length}
              </p>
              <img
                src={nextIcon}
                onClick={(e) => moveImg(e, 1)}
                alt="Next"
                name="next"
              />
            </div>
          </div>
        </div>
      </form>
      <div className={styles.ProdActions}>
        <div className={styles.pAct}>Actions</div>

        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Order Status</span>
          <div>
            <p onClick={() => setOpen(!isOpen)} className={styles.selOpt}>
              {orderStatus[currStatus]}
              <i className="bi bi-chevron-down"></i>
            </p>
            {isOpen && (
              <div className={styles.dropOptions}>
                {Object.values(orderStatus).map((value, index) => {
                  return (
                    <p
                      onClick={(e) => handleOrderStatus(e, index + 1)}
                      key={index}
                    >
                      {value}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <span className={commonCss.applyBtn} onClick={updateOrderStatus}>
            Apply
          </span>
        </div>
      </div>
    </section>
  );
};
