import React, { useRef, useState } from "react";
import styles from "../Admin/assets/css/adminLayout.module.css";
import * as adminAsset from "../Admin/assets/img/index";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useClickOutside from "../customHooks/useClickOutside";

export const AdminLayout = () => {
  const navData = [
    {
      head: "Dashboard",
      path: "/admin/dashboard/",
      icon: "bi bi-speedometer2",
      children: [],
    },
    {
      head: "Manage Users",
      path: "/",
      icon: "bi bi-people",
      children: [
        { name: "Users", path: "/admin/manageusers/" },
        { name: "Manufacturers", path: "/admin/managemanufacturer/" },
        { name: "Distributors", path: "/admin/managedistributor/" },
      ],
    },
    {
      head: "Manage Orders",
      path: "/",
      icon: "bi bi-cart",
      children: [
        {name: "Orders", path: "/admin/manageorder/"},
        {name: "Requested Orders", path: "/admin/requestedorders/"}
      ],
    },
    {
      head: "Manage Content",
      path: "/",
      icon: "bi bi-newspaper",
      children: [
        { name: "Blogs", path: "/admin/blogs/" },
        { name: "Articles", path: "/admin/articles/" },
        { name: "Tech Updates", path: "/admin/techupdates/" },
        { name: "Expert Tips", path: "/admin/experttips/" },
      ],
    },
    {
      head: "Manage Payment",
      path: "/",
      icon: "bi bi-credit-card",
      children: [
        { name: "Received", path: "/admin/managepayments/" },
        { name: "System Price", path: "/admin/managesystemprice/" },
      ],
    },
    {
      head: "Manage Requests",
      path: "/",
      icon: "bi bi-door-closed",
      children: [
        { name: "Arrange Demo", path: "/admin/demorequests/" },
        { name: "Requirements", path: "/admin/managerequest/" },
        { name: "Contacts", path: "/admin/contactlist/" },
        { name: "Request For Service Contact", path: "/admin/requestforservice/" },
        
      ],
    },
    {
      head: "Manage Ads",
      path: "/",
      icon: "bi bi-bag-plus",
      children: [
        { name: "New", path: "/admin/manageneweqip/" },
        { name: "Preowned", path: "/admin/manageusedeqip/" },
        { name: "SpareSpare & Accessories", path: "/admin/sparepare/" },
        { name: "Services", path: "/admin/Services/" },
      ],
    },
    {
      head: "Scheduled Meetings",
      path: "/admin/managemeetings/",
      icon: "bi bi-calendar-event",
      children: [],
    },
    {
      head: "Requested Services",
      path: "/admin/requestServices/",
      icon: "bi bi-tools",
      children: [],
    },
    {
      head: "Inspection",
      path: "/admin/manageinspection/",
      icon: "bi bi-textarea",
      children: [],
    },
    {
      head: "Categories",
      path: "/admin/managecategories/",
      icon: "bi bi-aspect-ratio",
      children: [],
    },
    {
      head: "Specialties",
      path: "/admin/managespeciality/",
      icon: "bi bi-distribute-vertical",
      children: [],
    },
    {
      head: "Manage Admin",
      path: "/",
      icon: "bi bi-person-check",
      children: [],
    },
  ];
  const [adminDrop, setAdminDrop] = useState(false);
  const [openSideBar, setSideBar] = useState(true);
  const dropRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth <= 800) {
      setSideBar(!openSideBar);
    }
  }, []);

  const currentPath = window.location.href.split('/').slice(3).join('/');
  console.log("firstPath", currentPath);
  return (
    <section className={styles.adminLayoutCont}>
      {openSideBar && (
        <div className={styles.adminSideNav}>
          <div className={styles.sidetop}>
            <NavLink to="/admin/dashboard/">
              <img
                src={adminAsset.logo}
                className={styles.logo}
                alt="EquipMedy"
              />
            </NavLink>
            <i
              onClick={() => setSideBar(!openSideBar)}
              className="bi bi-x-lg"
            ></i>
          </div>
          <div className={styles.dropOtions}>
            {navData.map((data, index) => {
              return <DropDown data={data} index={index} key={index} />;
            })}
          </div>
        </div>
      )}

      <div
        className={styles.adminData}
        style={{ width: openSideBar ? "calc(100% - 260px)" : "100%" }}
      >
        <header className={styles.dashhead}>
          <div>
            <img
              onClick={() => setSideBar(!openSideBar)}
              className={openSideBar ? styles.fullSize : styles.halfsize}
              src={openSideBar ? adminAsset.left : adminAsset.humberger}
              alt="Full"
            />
            <h2>Dashboard</h2>
          </div>
          <div>
            <NavLink to="notification/">
              <span className={styles.notify}>
                <span>2</span>
                <i className="bi bi-bell"></i>
              </span>
            </NavLink>

            <div className={styles.adminDrop} ref={dropRef}>
              <div
                className={styles.adminDHead}
                onClick={() => setAdminDrop(!adminDrop)}
              >
                <i className="bi bi-person-circle"></i>

                <span className={styles.admname}>Super Admin</span>
                <span>
                  <i className="bi bi-chevron-down"></i>
                </span>
              </div>
              {adminDrop && <AdminProfile />}
            </div>
          </div>
        </header>
        <div className={styles.datalist}>
        {/* {currentPath =='admin/Services/'  ? */}
        <div className={styles.backbtn}>
            <NavLink to="/admin/dashboard/" onClick={()=>window.history.back(-1)}>Back</NavLink>
          </div>
          {/* :null}   */}
          <div className={styles.listContiner} >
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

const AdminProfile = () => {
  const navigate = useNavigate();

  const AdminLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("adminAuth");
    navigate("/admin/login/");
  };

  return (
    <div className={styles.admdroplist}>
      <NavLink>Profile</NavLink>
      <NavLink>Change Password</NavLink>
      <NavLink onClick={AdminLogout}>Logout</NavLink>
    </div>
  );
};

const DropDown = ({ data, index }) => {
  const [isOpen, setOpen] = useState(false);
  const handleLink = (e, path) => {
    setOpen(!isOpen);
    if (path === "/") {
      e.preventDefault();
    }
  };
  return (
    <div className={styles.dropDown}>
      <NavLink
        className={styles.dropHead}
        to={data?.path}
        onClick={(e) => handleLink(e, data?.path)}
        style={activateLink}
      >
        <div>
          <i className={data.icon}></i>
          <span>{data.head}</span>
        </div>
        {Boolean(data.children.length) &&
          (isOpen ? (
            <i style={{ fontSize: "14px" }} className="bi bi-chevron-up"></i>
          ) : (
            <i style={{ fontSize: "14px" }} className="bi bi-chevron-down"></i>
          ))}
      </NavLink>

      {isOpen && (
        <div className={styles.subOption}>
          {data.children.map((child, index) => {
            return (
              <NavLink
                key={index}
                style={activateLink}
                to={child.path}
                className={styles.dropHead}
              >
                <span>{child.name}</span>
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

const activateLink = ({ isActive }) => {
  return {
    backgroundColor: isActive ? "#0e8b7f" : "",
  };
};
