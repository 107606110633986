import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/css/aboutus/about.module.css";
import { scrollInterSectEvent } from "../../utils/purefun";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Reasons7, NewsArticle, LetsConnect } from "./Aboutus2";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./styles.css";
import { CompanyTitle } from "../../assets/data/data";

import {
  herobg1,
  herobg2,
  herobg3,
  herobg4,
  herobg5,
  abi1,
  abi2,
  abi3,
  abi4,
  abi5,
  abi6,
  abi7,
  brand_1,
  brand_2,
  brand_3,
  brand_4,
  brand_5,
  brand_6,
  brand_7,
  brand_8,
  arewe1,
  arewe2,
  arewe3,
  eclipse1,
  eclipse1Act,
  eclipse2Act,
  eclipse3Act,
  eclipse2,
  eclipse3,
  mmqCircle,
  mission_icon,
  vision_icon,
  value_icon,
  sellBanner,
  aboutSellBanner,
  //process imges
  findE,
  schedule,
  inspection,
  closeDeal,
  handling,
  amc,
  shipped,
} from "../../assets/images/index";
import { NavLink } from "react-router-dom";

export const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.aboutCont}>
      <AboutHero />
      <div className={styles.aboutCat}>
        <span>
          WELCOME TO EquipMedy<sup>&reg;</sup>{" "}
        </span>
      </div>
      {/* <AboutCat /> */}
      {/* <AboutBrand/> */}
      <WhoWeAre />
      <Mission />
      <SellOnMedieQuip />
      <MMQWorkProcess />
      <Reasons7 />
      <NewsArticle data={{ title: "Blogs", type: 3, id: "contx" }} />
      <LetsConnect />
    </div>
  );
};

export const AboutHero = () => {
  const images = [herobg1, herobg2, herobg3, herobg4, herobg5];

  return (
    <div className={styles.aboutHero}>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images.map((value, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={value} alt="sliding" />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={styles.heroText}>
        <h3>
          ENHANCED HEALTH <br />
          THROUGH ADVANCED GEAR
        </h3>
        <p>
          Discover high-quality medical devices and equipment at EquipMedy
          <sup style={{ color: "white" }}>&reg;</sup>, dedicated to <br />{" "}
          enhancing healthcare outcomes and patient well-being.
        </p>
        <NavLink to="/">
          <span>KNOW MORE</span>
        </NavLink>
      </div>
    </div>
  );
};

const AboutCat = () => {
  let imgs = [abi1, abi2, abi3, abi4, abi5, abi6, abi7];
  return (
    <div className={styles.aboutCat}>
      <span>
        WELCOME TO EquipMedy <sup>&reg;</sup>
      </span>
      <h4 style={{ textAlign: "center" }}>
        Explore Medical Equipment's By Category
      </h4>
      <div className={styles.aboutcards}>
        {imgs.map((value, indx) => {
          return <AboutCatCard key={indx} data={value} />;
        })}
      </div>
    </div>
  );
};

const AboutCatCard = (props) => {
  return (
    <div className={styles.aboutCard}>
      <img src={props.data} height="30px" alt="..." />
      <h4>Angioplasty</h4>
      <p>
        Our team of highl professionals uses the latest heal echnologies health
        quickly and easily.
      </p>
      <span>
        <span>Go to Equipment</span>
        <i className="bi bi-chevron-double-right"></i>{" "}
      </span>
    </div>
  );
};

const AboutBrand = () => {
  const brands = [
    brand_1,
    brand_2,
    brand_3,
    brand_4,
    brand_5,
    brand_6,
    brand_7,
    brand_8,
  ];
  return (
    <div className={styles.aboutBrand}>
      <h2>Our Brand Partners</h2>

      <div className={styles.abrands}>
        <marquee>
          {brands.map((value, index) => {
            return <img src={value} key={index} />;
          })}
        </marquee>
      </div>
    </div>
  );
};
// ------
export const WhoWeAre = () => {
  const [imgs, setImg] = useState(0);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setImg((imgs) => (imgs === 0 ? 1 : imgs === 1 ? 2 : 0));
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.whrCont}>
      <div className={styles.whData}>
        <h3>
          WHO WE ARE?
          <div className={styles.whHeadLine}></div>
        </h3>
        <div style={{ color: "#757575" }}>
          <h4 style={{ fontWeight: "600", fontSize: "14px" }}>
            The Journey of EquipMedy<sup>&reg;</sup>: Enhancing Healthcare
            Access:
          </h4>
          <p style={{ fontSize: "14px", lineHeight: "22px" }}>
            <b>EquipMedy.com</b> is an Indian online platform where doctors,
            hospitals, manufacturers, distributors, and suppliers can easily
            Sell and Buy pre-owned or new medical equipment, consumables, and
            related services.
          </p>
          {/* <br /> */}
          <p style={{ fontSize: "14px", lineHeight: "22px" }}>
            In a nation pulsating with life, accessibility of latest medical
            technologies and equipment to healthcare professionals remains a
            complex challenge. At EquipMedy<sup>&reg;</sup>, we've embarked on a
            mission to transform this narrative, ensuring that every healthcare
            professional, regardless of their geographic location, has the power
            to provide the best medical treatment to their patients.
          </p>
          {/* <br /> */}
          <h4 style={{ marginTop: "1rem" }}>Our Genesis:</h4>
          <p style={{ fontSize: "14px", lineHeight: "22px" }}>
            We strongly believe that every doctor in our country possesses
            immense capabilities to provide the best medical treatment, given
            access to the latest medical equipment and devices. Currently, this
            access is limited to a few healthcare professionals which make
            patients to struggle for proper healthcare facilities.
          </p>

          {/* <br /> */}
          {readMore && (
            <span>
              <p style={{ fontSize: "14px", lineHeight: "22px" }}>
                The medical device industry is relentlessly working to offer
                state-of-the-art medical technologies, but the high costs
                associated with reaching a wider geographic area in India have
                been a significant hurdle in technology penetration.
              </p>
              <h4 style={{ marginTop: "1rem" }}>
                EquipMedy<sup>&reg;</sup> Stands For The Larger Purpose:
              </h4>
              <p style={{ fontSize: "14px", lineHeight: "22px" }}>
                <b> EquipMedy.com</b> offers a platform for the buying and
                selling of both pre-owned and new medical equipment. Sellers can
                post their advertisements and connect with potential buyers.
                EquipMedy
                <sup>&reg;</sup> acts as an intermediary throughout the entire
                process, overseeing verification, inspection, mediating
                discussions, negotiations, as well as managing shipping and
                installation.
              </p>
              {/* <br /> */}
              <p style={{ fontSize: "14px", lineHeight: "22px" }}>
                Our platform caters to Doctors, Healthcare Professionals,
                Hospitals, Manufacturers, Distributors, and Suppliers, fostering
                an ecosystem where both new and used medical equipment find
                their way into the hands of those who need them. We are more
                than just an ecommerce platform; we are the architects of a more
                inclusive and effective healthcare system for every citizen of
                India.
              </p>
            </span>
          )}
        </div>

        <span onClick={() => setReadMore(!readMore)}>
          <span>{readMore ? "LESS" : "READ MORE"}</span>
          <i className="bi bi-chevron-double-right"></i>{" "}
        </span>
      </div>
      <div className={`${styles.whslider} ${imgs !== 0 ? styles.hide : ""}`}>
        <img className={styles.whimg1} src={arewe1} alt="..." />
        <img className={styles.whimg2} src={arewe2} alt="..." />
        <img className={styles.whimg3} src={arewe3} alt="..." />
      </div>
      <div className={`${styles.whslider} ${imgs !== 1 ? styles.hide : ""}`}>
        <img className={styles.whimg1} src={arewe2} alt="..." />
        <img className={styles.whimg2} src={arewe3} alt="..." />
        <img className={styles.whimg3} src={arewe1} alt="..." />
      </div>
      <div className={`${styles.whslider} ${imgs !== 2 ? styles.hide : ""}`}>
        <img className={styles.whimg1} src={arewe3} alt="..." />
        <img className={styles.whimg2} src={arewe1} alt="..." />
        <img className={styles.whimg3} src={arewe2} alt="..." />
      </div>
    </div>
  );
};

const Mission = () => {
  const defaultImgs = { e1: eclipse3, e2: eclipse1, e3: eclipse2 };
  const activeImgs = { e1: eclipse3Act, e2: eclipse1Act, e3: eclipse2Act };

  const [imgs, setImgs] = useState(defaultImgs);

  const handleMouseOver = (event) => {
    const name = event.target.name;
    const card = document.getElementById(name);
    card.style.boxShadow = "rgba(0, 0, 0, 0.1) 0px 4px 12px";
    setImgs({ ...defaultImgs, [name]: activeImgs[name] });
  };
  const handleMouseLeave = (event) => {
    const name = event.target.name;
    const card = document.getElementById(name);
    card.style.boxShadow =
      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
    setImgs({ ...defaultImgs });
  };

  return (
    <div className={styles.aboutMission}>
      <MissionCard
        img={mission_icon}
        title='<span">EquipMedy<sup>&reg;</sup> on a Mission</span>'
        content="Enhancing access to the latest medical equipment and technologies for every healthcare professional."
        id="1"
      />
      <MissionCircle
        imgs={imgs}
        handleMouseOver={handleMouseOver}
        handleMouseLeave={handleMouseLeave}
      />
      <MissionCard
        img={vision_icon}
        title="Vision"
        content="Our vision is to revolutionize the way medical equipment is sourced, sold, and utilized. We aim to empower healthcare providers, clinics, and hospitals to access high-quality equipment while reducing costs, minimizing waste, and promoting a sustainable healthcare ecosystem."
        id="2"
      />
      <MissionCard
        img={value_icon}
        title="Values"
        content=" Revolutionize medical equipment access, reduce costs, minimize waste, and promote sustainability. Empower healthcare providers, clinics, and hospitals for enhanced patient care and a sustainable healthcare ecosystem."
        id="3"
      />
    </div>
  );
};
const MissionCircle = (props) => {
  return (
    <div className={styles.mmqCircle}>
      <img
        src={props.imgs.e1}
        className={styles.mimg4}
        onMouseLeave={props.handleMouseLeave}
        onMouseOver={props.handleMouseOver}
        name="e1"
        alt="..."
      />
      <img src={mmqCircle} className={styles.mimg3} alt="..." />
      <img
        src={props.imgs.e2}
        className={styles.mimg1}
        onMouseLeave={props.handleMouseLeave}
        onMouseOver={props.handleMouseOver}
        name="e2"
        alt="..."
      />
      <img
        src={props.imgs.e3}
        className={styles.mimg2}
        onMouseLeave={props.handleMouseLeave}
        onMouseOver={props.handleMouseOver}
        name="e3"
        alt="..."
      />
    </div>
  );
};

const MissionCard = (props) => {
  return (
    <div className={styles.missionCard}>
      <h2>
        {/* {props.title} */}
        <span dangerouslySetInnerHTML={{ __html: props.title }} />

        <div
          className={styles.whHeadLine}
          style={{ width: "18%", margin: "5px 0px 0px auto" }}
        ></div>
      </h2>
      <div className={styles.missionCont} id={`e${props.id}`}>
        <div>
          <img src={props.img} alt="..." width="37px" />
          {/* <b>{props.title}</b> */}
          <span dangerouslySetInnerHTML={{ __html: props.title }} />
        </div>
        <p>{props.content}</p>
      </div>
    </div>
  );
};

const SellOnMedieQuip = () => {
  const stylebg = {
    backgroundImage: `url(${sellBanner})`,
  };
  return (
    <div style={stylebg} className={styles.abSellBanner}>
      <div className={styles.sellBannerRight}>
        <h1>Sell On EquipMedy</h1>
        <h3>Expand Your business with EquipMedy</h3>
        <p>
          List Your Products on MyMediequip and connect with our network of
          150K+ hospital, clinics and <br /> individual proactioners
        </p>
      </div>

      <div className={styles.sellBannerLeft}>
        <img src={aboutSellBanner} alt="aboutSellBanner" />
      </div>
    </div>
  );
};

const MMQWorkProcess = () => {
  var prevInterSect = null;
  const handleInterSection = (e) => {
    // reset styles
    // let prevInterSect=document.getElementById("mmqp"+String(parseInt(e.id[4])-1))

    if (prevInterSect) {
      prevInterSect.style.borderRight = "3px solid #F3F3F3";
      prevInterSect.style.borderRadius = "0px";
      prevInterSect.style.borderImage = "none";
    }

    // activate styles
    e.style.borderRight = "3px solid";
    e.style.borderRadius = "8px";
    e.style.borderImage =
      "linear-gradient(to bottom, #dae5e4, #5bcdc0a6, #0EDAC1) 1";
    prevInterSect = e;
  };
  useEffect(() => {
    let elementsId = [
      "mmqp1",
      "mmqp2",
      "mmqp3",
      "mmqp4",
      "mmqp5",
      "mmqp6",
      "mmqp7",
    ];
    elementsId.forEach((elementId) => {
      // Giving Intersection Event while scrolling
      scrollInterSectEvent(elementId, handleInterSection);
    });
  }, []);

  return (
    <div className={styles.abMmqWorkCont}>
      <AboutHead title={`How EquipMedy.com Work? `} />
      <div className={styles.abmProcess}>
        <div className={styles.admCol1}>
          <div
            style={{ marginBottom: "70px" }}
            className={styles.imgCont}
            id="mmqp1"
          >
            <img src={findE} alt="..." />
          </div>
          <ProcessCard
            id="mmqp2"
            title={"Schedule Meeting With Seller"}
            seriel={2}
            desc={`IIdentify equipment according to your requirements, connect with the seller via video call to obtain more details, and negotiate based on the equipment condition. The EquipMedy executive is here to facilitate you at each step of your buying process.`}
          />
          <div
            style={{ marginTop: "150px" }}
            id="mmqp3"
            className={styles.imgCont}
          >
            <img src={inspection} alt="..." />
          </div>
          <ProcessCard
            id="mmqp4"
            title={"Close The Deal"}
            seriel={4}
            desc={`Negotiate with ease, guided by the insights from the inspection report. Finalize the deal with the assurance that EquipMedy <sup>&reg;</sup> is by your side, offering its services at a nominal platform fee.`}
          />
          <div
            style={{ marginTop: "70px" }}
            id="mmqp5"
            className={styles.imgCont}
          >
            <img src={shipped} alt="..." />
          </div>
          <ProcessCard
            id="mmqp6"
            title={"Handling & Installation"}
            seriel={6}
            desc={`Elevate your experience with EquipMedy <sup>&reg;</sup> add-on services. Let us handle the logistics, ensuring your equipment is seamlessly installed at your clinic or hospital.`}
          />
          <div id="mmqp7" className={styles.imgCont}>
            <img src={amc} alt="..." />
          </div>
        </div>

        <div className={styles.admCol3}>
          <ProcessCard
            title={"Find Equipment"}
            seriel={1}
            desc={
              "Search from the listed Pre-owned and New equipment. Ads are directly posted by Doctors  / Hospitals, distributors / Manufacturers."
            }
          />

          <div style={{ marginTop: "30px" }} className={styles.imgCont}>
            <img src={schedule} alt="..." />
          </div>
          <ProcessCard
            title={"Get Inspection Report"}
            seriel={3}
            desc={
              "For pre-owned equipment, the actual shipping cost is to be borne by the buyer. However, the shipping cost is inclusive in the case of new equipment sold directly by distributors, manufacturers, or the company."
            }
          />
          <div style={{ marginTop: "110px" }} className={styles.imgCont}>
            <img src={closeDeal} alt="..." />
          </div>
          <ProcessCard
            title={"Get It Shipped"}
            seriel={5}
            desc={
              "For pre-owned equipment, the actual shipping cost is to be borne by the buyers. However, the shipping cost is inclusive in the case of new equipment sold directly by distributors/ manufacturers/ or the company."
            }
          />
          <div style={{ marginTop: "80px" }} className={styles.imgCont}>
            <img src={handling} alt="..." />
          </div>
          <ProcessCard
            title={"AMC/CMC,Services"}
            seriel={7}
            desc={`Beyond the purchase, EquipMedy<sup>&reg;</sup> remains your practice partner. Explore our Annual Maintenance and Comprehensive Maintenance Services, ensuring your equipment functions optimally, always.`}
          />
        </div>
      </div>
    </div>
  );
};

const ProcessCard = (props) => {
  return (
    <div className={styles.abProcessCard} id={props?.id}>
      <h3>0{props.seriel}</h3>
      <b>{props.title}</b>
      {/* <p>{props.desc}</p> */}
      <p dangerouslySetInnerHTML={{ __html: props.desc }} />
    </div>
  );
};

export const AboutHead = (props) => {
  return (
    <h1
      style={{
        fontSize: "28px",
        padding: "10px 0px 10px 0px",
        textAlign: "center",
        color: "rgb(107, 107, 107)",
      }}
    >
      {props.title}
      {/* <div
        className={styles.whHeadLine}
        style={{ width: "80%", margin: "5px auto 0px 0px" }}
      ></div> */}
    </h1>
  );
};
