import React, { useEffect, useState } from "react";
import styles from "../../assets/css/manufacture/distOrder.module.css";
import distListImg from "../../assets/images/manufacture/distributor5.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderList } from "../../app/Slices/ProdListSlice";
import { RenewsDate } from "../../utils/RenewDate";
import { clearLatLng } from "../../app/Slices/ProdAddSlice";

export const DistOrders = () => {
  const dispatch = useDispatch();
  const buyerId = localStorage.getItem("uid");
  const orderList = useSelector((state) => state.plist.prodListData.order_list);
  const [originalOrderList, setOriginalOrderList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    dispatch(fetchOrderList(buyerId));
  }, [buyerId, dispatch]);

  useEffect(() => {
    setOriginalOrderList(orderList);
    setFilteredOrders(orderList);
  }, [orderList]);

  const handleFilter = (e, status) => {
    if (e.target.checked !== false) {
      const filtered = originalOrderList.filter(
        (order) => order.order_status === status
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orderList);
    }
  };
  const handleSearch = (query) => {
    const filtered = originalOrderList.filter(
      (order) =>
        order.product_detail.equip_name
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        order.product_detail.description
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        order.uid.toLowerCase().includes(query.toLowerCase()) ||
        order.total.toString().toLowerCase().includes(query.toLowerCase())
    );
    setFilteredOrders(filtered);
  };
  return (
    <section className={styles.distOrder}>
      <div>
        <h1>Active Orders</h1>
      </div>
      <div className={styles.distOrderContent}>
        <ActiveOrderFilter handleFilter={handleFilter} />
        <ActiveOrderSearch handleSearch={handleSearch} />
      </div>
      <div>
        <DistOrderList list={filteredOrders} />
      </div>
    </section>
  );
};

const ActiveOrderFilter = ({ handleFilter }) => {
  return (
    <div className={styles.orderData}>
      <div className={styles.orderHead}>
        <h3>Filter</h3>
      </div>
      <div className={styles.activeOrderFilter}>
        <div className={styles.checkboxx}>
          <p>On the way</p>
          <input
            type="checkbox"
            id="vehicle1"
            name="way"
            value="Bike"
            onClick={(e) => handleFilter(e, 2)}
          />
        </div>
        <div className={styles.checkboxx}>
          <p>Delivered</p>
          <input
            type="checkbox"
            id="vehicle1"
            name="way"
            value="Bike"
            onClick={(e) => handleFilter(e, 3)}
          />
        </div>
        <div className={styles.checkboxx}>
          <p>Cancelled</p>
          <input
            type="checkbox"
            id="vehicle1"
            name="way"
            value="Bike"
            onClick={(e) => handleFilter(e, 4)}
          />
        </div>
        <div className={styles.checkboxx}>
          <p>Return</p>
          <input
            type="checkbox"
            id="vehicle1"
            name="way"
            value="Bike"
            onClick={(e) => handleFilter(e, 5)}
          />
        </div>
      </div>
    </div>
  );
};

const ActiveOrderSearch = ({ handleSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    handleSearch(e.target.value);
  };
  return (
    <div className={styles.activeOrderSearchh}>
      <input
        type="search"
        placeholder="search your order here"
        value={searchQuery}
        onChange={handleChange}
      />
      <button type="submit">
        <span></span>Search Order
      </button>
    </div>
  );
};

const DistOrderList = ({ list }) => {
  return (
    <div className={styles.distOrderList}>
      <div className={styles.list2}>
        <table>
          <thead>
            <tr>
              <th className={styles.orderActDes}>Equipement Details</th>
              <th>Details</th>
              <th>Price</th>
              <th>Deleverd</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((el) => {
              return (
                <tr>
                  <td className={styles.distOrderListContent}>
                    <div>
                      <img
                        src={
                          el?.product_detail?.product_images
                            ? el?.product_detail?.product_images[0]
                                ?.product_images
                            : distListImg
                        }
                        alt="lists"
                      />
                    </div>
                    <div className={styles.orderActDes}>
                      <h3>
                        {el?.product_detail?.equip_name
                          ? el?.product_detail?.equip_name
                          : "Dunlop Wheel"}
                      </h3>
                      <p>{el?.product_detail?.description}</p>
                    </div>
                  </td>
                  <td>
                    <p>
                      <span>Order ID:</span>#{el?.uid}
                    </p>
                  </td>
                  <td>
                    <p>
                      <span>&#8377;</span>
                      {Number(el?.total)?.toFixed(2)}
                    </p>
                  </td>
                  <td>
                    <h6>Delivered {RenewsDate(el?.product_detail?.date)}</h6>
                  </td>
                </tr>
              );
            })}

            {/* <tr>
              <td className={styles.distOrderListContent}>
                <div>
                  <img src={distListImg} alt="lists" />
                </div>
                <div className={styles.orderActDes}>
                  <h3>Dunlop Wheel</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Exercitationem, eos Magni optio eveniet dolorem reiciendis,
                    magnam fuga cum quasi sit ipsa. Autem, ex quaerat.
                  </p>
                </div>
              </td>
              <td>
                <p>
                  <span>Order ID:</span>
                  #874847584
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <h6>Delivered 21st jan</h6>
              </td>
            </tr>
            <tr>
              <td className={styles.distOrderListContent}>
                <div>
                  <img src={distListImg} alt="lists" />
                </div>
                <div className={styles.orderActDes}>
                  <h3>Dunlop Wheel</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Exercitationem, eos Magni optio eveniet dolorem reiciendis,
                    magnam fuga cum quasi sit ipsa. Autem, ex quaerat.
                  </p>
                </div>
              </td>
              <td>
                <p>
                  <span>Order ID:</span>
                  #874847584
                </p>
              </td>
              <td>
                <p>
                  <span>&#8377;</span>
                  120000:00
                </p>
              </td>
              <td>
                <h6>Delivered 21st jan</h6>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};
