import React from "react";
import styles from "../assets/css/productdetails.module.css";
import { relatedImg } from "../assets/images/index";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { postData } from "../services";
import ProdAddress from "../utils/ProdAddress";
import { Pagination } from "../Admin/Common";
import { CreateRequirement } from "../features/CreateReqirement";
import { handleCreateRequirement } from "../app/Slices/UserData";
import { useDispatch, useSelector } from "react-redux";

const AllFilterProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state?.cat;
  const dispatch = useDispatch();
  const { createRequirementModal } = useSelector((state) => state.profileData);
  const [plists, setPlist] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);
  const [preload, setPreload] = useState(true);

  useEffect(() => {
    setCurrPage(1);
  }, [item]);

  const handleSearchItem = async () => {
    setPreload(true);
    const formData = new FormData();
    formData.append("product_status", 1);
    formData.append("_limit", 6);
    formData.append("_page", currPage);
    const res = await postData("product/plists/", formData);
    if (res?.status) {
      let fill = res?.data?.data?.filter((el) => {
        const { equip_name } = el;
        return equip_name.toLowerCase().includes(item.toLowerCase());
      });
      setPlist(fill);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setPreload(false);
    }
  };

  useEffect(() => {
    handleSearchItem();
  }, [item, currPage]);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  const handleProdClick = (list) => {
    navigate(
      `/products/${encodeURIComponent(list?.equip_name)}/?id=${list?.id}/`,
      {
        state: { prodDetails: list },
      }
    );
  };

  return (
    <div className={styles.main_container}>
      {/* <div className={styles.prodFilterse}>
        <FilterProductData/>
       </div> */}
      <div className={styles.prodlisting}>
        <div className={styles.prodListingInner}>
          {plists?.length > 0 ? (
            plists?.map((list, index) => {
              return (
                <div
                  key={index}
                  className={styles.mfProdCard}
                  onClick={(e) => handleProdClick(list)}
                >
                  <img
                    src={
                      list?.product_images[0]?.product_images
                        ? list?.product_images[0]?.product_images
                        : relatedImg
                    }
                    className={styles.prodImg}
                    alt="..."
                  />
                  <div className={styles.mfProdData}>
                    <div className={styles.mfTitile}>
                      <h4>{list?.equip_name}</h4>
                      <span>
                        {list?.post_type === 1
                          ? "PRE OWNED"
                          : list?.post_type === 2
                          ? "NEW"
                          : list?.post_type === 3
                          ? "SPARE & ACCESSORIES"
                          : "SERVICES"}
                      </span>
                      <p className={styles.mfBrand}>
                        {!list?.post_type ? list?.brand : ""}
                      </p>
                    </div>
                    {!list?.post_type && (
                      <div className={styles.mfType}>
                        <span>Video laryngoscopes</span>
                        <span>Lithotripters</span>
                        <span>Video ureteroscopes</span>
                      </div>
                    )}
                    {list?.post_type && (
                      <>
                        <p>{list?.uid.slice(0, 8)}</p>
                        <h3>
                          Seller :{" "}
                          {`${
                            list?.seller_firstname !== null
                              ? list?.seller_firstname
                              : ""
                          } ${
                            list?.seller_lastname !== null
                              ? list?.seller_lastname
                              : ""
                          }`}
                        </h3>
                      </>
                    )}
                    <p>City: {<ProdAddress address={list?.address} />}</p>
                    <p>
                      {list?.description === "null"
                        ? list?.other_details?.length > 100
                          ? `${list?.other_details?.slice(0, 100)}`
                          : list?.other_details
                        : list?.description?.length > 100
                        ? `${list?.description?.slice(0, 100)}`
                        : list?.description}
                    </p>
                    <div className={styles.priceCont}>
                      <div className={styles.pricing}>
                        <h2>₹ {Number(list?.asking_price).toFixed(2)}</h2>
                        <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                          (GST tax included)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : preload ? (
            <div>Loading...</div>
          ) : (
            <div className={styles.mfEmpty}>
              <div>No Data Found!</div>
              <div>
                <NavLink
                  className={styles.CreatBtn}
                  onClick={() => dispatch(handleCreateRequirement(true))}
                  style={{ color: "#FFFFFF" }}
                  to="/"
                >
                  Create Your Requirement
                </NavLink>
              </div>
              {createRequirementModal && <CreateRequirement />}
            </div>
          )}
        </div>
        {plists?.length > 0 && (
          <div style={{ textAlign: "right", width: "100%" }}>
            <Pagination
              total={paging?.total}
              perPage={6}
              currPage={currPage}
              prevPage={prevPage}
              tPage={paging?.total_pages}
              updatePage={updatePage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AllFilterProduct;
