import React from "react";
import { BrowserRouter } from "react-router-dom";
import { UserRoute } from "./Routing/UserRoute";
import { AdminRoute } from "./Routing/AdminRoute";
import { ToastContainer } from "react-toastify";

export const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <UserRoute />
      <AdminRoute />
    </BrowserRouter>
  );
};
