import { createSlice } from "@reduxjs/toolkit";
import { postData, postData1 } from "../../services";

export const prodAddSlice = createSlice({
  name: "prodAdd",
  initialState: {
    prodAddData: {
      isCatLoading: true,
      other_name: false,
      selectedPostType: null,
      selectServiceType: null,
      addAddress: {
        number: null,
        email: null,
        address: null,
        landmark: null,
        city: null,
        State: null,
        zip_code: null,
      },
      prodImgs: [],
      prodVideos: [],
      Equip_name: null,
      newEquip_name: null,
      Equip_name_get: null,
      Equip_categories: [],
      categories: [],
      specility: [],
      // Equip_spacality:null,
      Equip_location: null,
      Compatible_Models: null,
      Prod_price: null,
      specialtiey: null,
      location: {
        lang: null,
        lat: null,
      },
      prodCondition: {
        condition: null,
        price: null,
        negotiable: null,
        prod_desc: null,
      },
      purchase_year: null,
      specifications: {
        brand: null,
        model: null,
        waranty: null,
        amc_cme: null,
        other_details: null,
      },
    },
  },
  reducers: {
    setCatLoading: (state, action) => {
      state.prodAddData.isCatLoading = action.payload;
    },
    selectOtherName: (state) => {
      state.prodAddData.other_name = !state.prodAddData.other_name;
    },
    setServiceType: (state, action) => {
      state.prodAddData.selectServiceType = action.payload;
    },
    setType: (state, action) => {
      state.prodAddData.selectedPostType = action.payload.selected;
    },
    addImg: (state, action) => {
      state.prodAddData.prodImgs.push(action.payload);
    },
    setFullAddAddress: (state, action) => {
      const { address, city, landmark, zip_code, State, number, email } =
        action.payload;
      state.prodAddData.addAddress = {
        ...state.prodAddData.addAddress,
        number: number,
        email: email,
        address: address,
        city: city,
        landmark: landmark,
        zip_code: zip_code,
        State: State,
      };
    },
    removeImg: (state, action) => {
      state.prodAddData.prodImgs = state.prodAddData.prodImgs.filter(
        (image) => image.imageId !== action.payload
      );
    },
    addVideos: (state, action) => {
      state.prodAddData.prodVideos.push(action.payload);
    },
    removeVideo: (state, action) => {
      state.prodAddData.prodVideos = state.prodAddData.prodVideos.filter(
        (video) => video.imageId !== action.payload
      );
    },
    setEquipmentName: (state, action) => {
      state.prodAddData.Equip_name = action.payload;
    },
    setNewEquipName: (state, action) => {
      state.prodAddData.newEquip_name = action.payload;
    },
    setCategories: (state, action) => {
      const category = action.payload;
      const index = state.prodAddData.categories?.indexOf(category);
      if (index === -1) {
        // Category was not selected, add it
        state.prodAddData.categories?.push(category);
      } else {
        state.prodAddData.categories?.splice(index, 1);
      }
    },
    filterCategories: (state, action) => {
      const category = action.payload;
      let fill = state.prodAddData.categories?.filter((el) => {
        if (el?.id !== category?.id) {
          return el;
        }
      });
      state.prodAddData.categories = fill;
    },
    setSpecality: (state, action) => {
      const specialityName = action.payload;
      const index = state.prodAddData.specility?.indexOf(specialityName);
      if (index === -1) {
        state.prodAddData.specility?.push(specialityName);
      } else {
        state.prodAddData.specility?.splice(index, 1);
      }
    },
    filterSpec: (state, action) => {
      const speciality = action.payload;
      let fill = state.prodAddData.specility?.filter((el) => {
        return el !== speciality;
      });
      state.prodAddData.specility = fill;
    },
    setLatLong: (state, action) => {
      const { name, value } = action.payload;
      state.prodAddData.location[name] = value;
    },
    setEquip_Location: (state, action) => {
      state.prodAddData.Equip_location = action.payload;
    },

    fetchEuipCategories: (state, action) => {
      state.prodAddData.Equip_categories = action.payload;
    },

    fetchSpecialName: (state, action) => {
      state.prodAddData.specialtiey_name = action.payload;
    },
    setManufacturingYear: (state, action) => {
      state.prodAddData.purchase_year = action.payload;
    },

    setEquipCondition: (state, action) => {
      const { name, value } = action.payload;
      state.prodAddData.prodCondition[name] = value;
    },
    setEquipSpecification: (state, action) => {
      const { name, value } = action.payload;
      state.prodAddData.specifications[name] = value;
    },
    setProdPrice: (state, action) => {
      state.prodAddData.Prod_price = action.payload;
    },
    setCompatibleModels: (state, action) => {
      state.prodAddData.Compatible_Models = action.payload;
    },
    clearLatLng: (state) => {
      state.prodAddData.location = {
        lat: null,
        lang: null,
      };
    },
    clearProdAddData: (state) => {
      state.prodAddData = {
        selectedPostType: null,
        selectServiceType: null,
        other_name: false,
        prodImgs: [],
        prodVideos: [],
        Equip_name: null,
        newEquip_name: null,
        addAddress: {
          address: null,
          landmark: null,
          city: null,
          State: null,
          zip_code: null,
        },
        Equip_categories: [],
        specialtiey_name: [],
        categories: [],
        specility: [],
        // Equip_spacality:null,
        Equip_location: null,
        Compatible_Models: null,
        Prod_price: null,
        specialtiey: null,
        location: {
          lang: null,
          lat: null,
        },
        prodCondition: {
          condition: null,
          price: null,
          negotiable: null,
          prod_desc: null,
        },
        purchase_year: null,
        specifications: {
          brand: null,
          model: null,
          waranty: null,
          amc_cme: null,
          other_details: null,
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCatLoading,
  setType,
  setServiceType,
  clearLatLng,
  addImg,
  removeImg,
  addVideos,
  removeVideo,
  setEquipmentName,
  setNewEquipName,
  setManufacturingYear,
  setEquipSpecification,
  setCompatibleModels,
  setProdPrice,
  clearProdAddData,
  setEquipCondition,
  setEquip_Location,
  fetchEuipCategories,
  setCategories,
  fetchSpecialName,
  setSpecality,
  setLatLong,
  filterSpec,
  filterCategories,
  selectOtherName,
  setFullAddAddress,
  selectCurrentLocation,
} = prodAddSlice.actions;

// Asynchronous thunk action
export const fetchCategories = (Equip_name, id) => async (dispatch) => {
  dispatch(setCatLoading(true));
  const formData = new FormData();
  if (Equip_name) {
    formData.append("q", Equip_name);
  } else if (id) {
    formData.append("id", id);
  } else {
    formData.append("parent", 41);
  }
  try {
    const res = await postData("product/category/menulist/", formData);
    dispatch(setCatLoading(false));
    dispatch(fetchEuipCategories(res?.data));
  } catch (error) {}
};

export const fetchSpecialityName = (spec_name) => async (dispatch) => {
  const formData = new FormData();
  if (spec_name) {
    formData.append("q", spec_name);
  }
  try {
    const res = await postData("product/speciality/lists/", "");
    dispatch(fetchSpecialName(res?.data));
  } catch (error) {
    console.log(error, "error");
  }
};

export default prodAddSlice.reducer;
