import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/css/manufacture/listing.module.css";
import listingIcon from "../../assets/images/manufacture/listingIcon.png";
import distListImg from "../../assets/images/manufacture/distributor5.png";
import useClickOutside from "../../customHooks/useClickOutside";
import Select from "react-select";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProdAddData,
  fetchCategories,
  fetchSpecialityName,
  filterCategories,
  filterSpec,
  setCategories,
  setSpecality,
} from "../../app/Slices/ProdAddSlice";
import {
  ImageUpload,
  postDropdown,
  location as locationImage,
} from "../../assets/images";
import AddressModel from "../../utils/AddressModel";
import {
  addEquipImages,
  addEquipVideos,
  clearDisProdAdd,
  removeEqipImages,
  removeEqipVideos,
  selectOtherName,
  setEquipName,
  setEquipSelectedProdTpye,
  setEquipSpecality,
  setEquipSpecification,
  setFullAddAddress,
  setLatLong,
  setManufactureYear,
  setNewEquipName,
  setfilterSpec,
} from "../../app/Slices/ManufactureTabSlice";
import { fetchUserPList } from "../../app/Slices/ProdListSlice";
import Pagination from "../../utils/Pagination";
import axios from "axios";
import { postData, postData1 } from "../../services";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../../components/Loader";
import { FaPlusCircle } from "react-icons/fa";
const API_KEY = process.env.REACT_APP_ADDRESS_KEY;

export const DistListing = () => {
  const plist = useSelector((state) => state.plist.prodListData.user_plist);
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  const [pageNo, setPageNO] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPList(userId?.uid, pageNo, 6));
  }, [pageNo]);

  const FilterData = [
    "Category",
    "Brand",
    "Listing Price",
    "Stock",
    "Listing Quality",
  ];
  return (
    <section className={styles.listCont}>
      <h1>Listing Management</h1>
      <div className={styles.listTags}>
        <div className={styles.activeList}>
          <div>
            <img src={listingIcon} alt="lists" />
            <span>Active Listings</span>
            <h1>{plist?.data?.length}</h1>
          </div>
        </div>
        <NavLink to="/distributor/addlist/" className={styles.addNewList}>
          Add New Listing
        </NavLink>
      </div>
      <div className={styles.filterList}>
        {FilterData?.map((value, index) => {
          return <FilterDropDown data={value} />;
        })}
        <div
          className={styles.filterDropCont}
          style={{ cursor: "pointer", color: "#276DE5" }}
        >
          Apply
        </div>
      </div>
      <div>
        <DistEqpList list={plist} />
        {plist?.data?.length > 0 && (
          <Pagination
            setPageNO={setPageNO}
            pageNo={pageNo}
            totalPage={plist?.total_pages}
            totalItem={plist?.total}
          />
        )}
      </div>
    </section>
  );
};

const FilterDropDown = ({ data }) => {
  const [showSub, setSub] = useState(false);
  const [curSelected, setCurrSelction] = useState(data);
  const ref = useRef(null);

  const updateCurrSelection = (e) => {
    let currVal = e.currentTarget.innerHTML;
    setCurrSelction(currVal);
    setSub(false);
  };
  const handleClick = () => {
    setSub(!showSub);
  };
  useClickOutside(ref, () => setSub(false));
  return (
    <div className={styles.filterDropCont} ref={ref}>
      <div className={styles.dropHead} onClick={handleClick}>
        <span>{curSelected}</span>
        <span>
          <i className="bi bi-chevron-down"></i>
        </span>
      </div>
      {showSub && (
        <div className={styles.subOptions}>
          <span onClick={updateCurrSelection}>New</span>
          <span onClick={updateCurrSelection}>Preowned</span>
          <span onClick={updateCurrSelection}>Services</span>
          <span onClick={updateCurrSelection}>Spare</span>
        </div>
      )}
    </div>
  );
};

const DistEqpList = ({ list }) => {
  return (
    <div className={styles.distEqpList}>
      <div className={styles.list2}>
        <table>
          <thead>
            <tr>
              <th>Equipement Details</th>
              <th>Product Type</th>
              <th>Date</th>
              <th>Brand</th>
              <th>Model</th>
              <th>Listing Price</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody className={styles.tbody_border}>
            {list?.data?.length > 0 &&
              list?.data?.map((el, ind) => {
                return (
                  <tr key={ind}>
                    <td className={styles.distEqpListContent}>
                      <div>
                        <img
                          src={
                            el?.product_images
                              ? el?.product_images[0]?.product_images
                              : distListImg
                          }
                          alt="lists"
                        />
                      </div>
                      <div>
                        {/* <p>
                        Order Id : <span>#204c348</span>
                      </p> */}
                        <h3>
                          {el?.equip_name ? el?.equip_name : "Dunlop Wheel"}
                        </h3>
                      </div>
                    </td>
                    <td>
                      <h5>
                        {el?.post_type === 1
                          ? "PRE-OWNED"
                          : el?.post_type === 2
                          ? "NEW"
                          : el?.post_type === 3
                          ? "SPARE & ACCESSORIES"
                          : "SERVICES"}
                      </h5>
                    </td>

                    <td>
                      <p>{el?.date}</p>
                    </td>
                    <td>
                      <p>{el?.brand ? el?.brand : "brand"}</p>
                    </td>
                    <td>
                      <p>{el?.model ? el?.model : "model"}</p>
                    </td>
                    <td>
                      <p>
                        <span>&#8377;</span>
                        {Number(el?.asking_price).toFixed(2)}
                      </p>
                    </td>
                    {/* <td>
                      <h6>Edit Listing</h6>
                    </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AddNewListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const [searchName, setSearchName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const manuData = useSelector((state) => state.manuProdAdd.prodAdd);
  const allData = useSelector((state) => state.addProd.prodAddData);

  const {
    equip_name,
    equip_Specifications,
    equip_manufacturer_year,
    location,
    other_name,
    newEquip_name,
    equip_location,
  } = useSelector((state) => state.manuProdAdd.prodAdd);

  const categories = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  const specialityName = useSelector(
    (state) => state.addProd.prodAddData.specialtiey_name
  );

  const handlePostAdv = () => {
    if (!manuData?.equip_selected_postType) {
      toast.info("Please select AD type", {
        autoClose: 2000,
        closeButton: false,
      });
    } else if (userDetails?.type === 2 || userDetails?.type === 1) {
      if (manuData?.equip_selected_postType === "PRE OWNED") {
        toast.info(
          "Attention: Only new products can be added. Manufacturers and distributors are not permitted to add pre-owned products",
          { autoClose: 2000, closeButton: false }
        );
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleEquipmentList = () => {
    if (
      (manuData?.equip_name || manuData?.newEquip_name) &&
      allData?.categories?.length > 0 &&
      allData?.specility?.length > 0
    ) {
      return true;
    } else {
      toast.info("All Fields Are Mandatory !", {
        autoClose: 2000,
        closeButton: false,
      });
    }
  };

  const handleEquipNameChange = (event) => {
    const newName = event.target.value;
    if (newName.trim() !== "") {
      setSearchName(newName);
    } else {
      setSearchName("");
    }
    if (equip_name?.length > searchName?.length) {
      dispatch(setEquipName());
    }
  };
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

  const handleEquipSpecChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(setEquipSpecification({ ...equip_Specifications, name, value }));
    } else {
      dispatch(setEquipSpecification({ name, value: "" }));
    }
  };

  const handleYearChoose = (year) => {
    dispatch(setManufactureYear(year));
    setIsValid(false);
  };

  const handleDateList = () => {
    let date = new Date();
    let currentDate = date.getFullYear();
    let lastDate = date.setFullYear(date.getFullYear() - 15);
    let lastUpdateDate = new Date(lastDate).getFullYear();
    let list = [];
    for (let i = lastUpdateDate; i <= currentDate; i++) {
      list.push(i);
    }
    setYearList(list);
  };

  useEffect(() => {
    handleDateList();
  }, []);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSpecialityName());
  }, [dispatch]);

  useEffect(() => {
    handleFilterCate();
  }, [searchName]);

  const handleOtherCheck = () => {
    dispatch(selectOtherName(true));
    if (!other_name) {
      dispatch(setNewEquipName(null));
    }
  };

  const handleNewEquipName = (event) => {
    const newName = event.target.value;
    if (newName.trim() !== "") {
      dispatch(setNewEquipName(newName));
    } else {
      dispatch(setNewEquipName(""));
    }
  };

  const handleFilterCate = () => {
    let ar = [];
    categories?.map((el) => {
      el?.children?.filter((el) => {
        if (el?.name?.toLowerCase().includes(searchName?.toLowerCase())) {
          ar?.push(el);
        }
      });
    });
    setFilterCategories(ar);
  };
  const handleCatSearch = (name) => {
    dispatch(setEquipName(name));
    setSearchName("");
  };

  const dropCat = {
    title: "Category",
    mandt: "*",
    placeholder: "Select the equipment Categories",
    dataList: categories,
  };

  const filterSpecialityName = specialityName?.filter((el) => {
    return el.name !== "adminTest1" && el.name !== "admintTest2";
  });
  const dropSpec = {
    title: "Speciality",
    mandt: "*",
    placeholder: "Select the medical specialty",
    dataList: filterSpecialityName,
  };

  const handleLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch(setLatLong({ name: "lat", value: latitude }));
          dispatch(setLatLong({ name: "lang", value: longitude }));
        },
        (error) => {
          console.log(error, "error getting location");
        }
      );
    } else {
      console.log("Gelocation is not available");
    }
  };

  useEffect(() => {
    const API_URL = `https://nominatim.openstreetmap.org/reverse?lat=${location?.lat}&lon=${location?.lang}&format=json&apiKey=${API_KEY}`;
    axios
      .get(API_URL)
      .then((response) => {
        dispatch(
          setFullAddAddress({
            number: userDetails?.number !== null ? userDetails?.number : "",
            email: userDetails?.email !== null ? userDetails?.email : "",
            address: response?.data?.display_name,
            address1: response?.data?.address.suburb,
            city: response?.data?.address?.state_district,
            State: response?.data?.address?.state,
            zip_code: response?.data?.address?.postcode,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  }, [location]);

  const handleAddressForm = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(
        setFullAddAddress({
          number: name === "number" ? value : equip_location.number,
          email: name === "email" ? value : equip_location.email,
          address: name === "address" ? value : equip_location.address,
          city: name === "city" ? value : equip_location.city,
          address1: name === "landmark" ? value : equip_location.address1,
          zip_code: name === "zip_code" ? value : equip_location.zip_code,
          State: name === "State" ? value : equip_location.State,
        })
      );
    } else {
      dispatch(
        setFullAddAddress({
          number: name === "number" ? "" : equip_location.number,
          email: name === "email" ? "" : equip_location.email,
          address: name === "address" ? "" : equip_location.address,
          city: name === "city" ? "" : equip_location.city,
          address1: name === "landmark" ? "" : equip_location.address1,
          zip_code: name === "zip_code" ? "" : equip_location.zip_code,
          State: name === "State" ? "" : equip_location.State,
        })
      );
    }
  };

  // Fetch city and state when pin code changes
  useEffect(() => {
    const fetchLocation = async () => {
      if (equip_location.zip_code.length >= 6) {
        try {
          const response = await fetch(
            `https://api.zippopotam.us/in/${equip_location.zip_code}`
          );
          if (response.ok) {
            const data = await response.json();
            const city = data.places[0]["place name"];
            const state = data.places[0]["state abbreviation"];
            const country = data.country;
            dispatch(
              setFullAddAddress({
                ["number"]: equip_location.number,
                ["email"]: equip_location.email,
                ["address"]: equip_location.address,
                ["city"]: city,
                ["address1"]: equip_location.address1,
                ["zip_code"]: equip_location.zip_code,
                ["State"]: state,
              })
            );
          } else {
            toast.error("Enter the valid Pin Code!");
            // Clear city and state if the response is not ok
            dispatch(
              setFullAddAddress({
                ["number"]: equip_location.number,
                ["email"]: equip_location.email,
                ["address"]: equip_location.address,
                ["city"]: "",
                ["address1"]: equip_location.address1,
                ["zip_code"]: equip_location.zip_code,
                ["State"]: "",
              })
            );
          }
        } catch (error) {
          dispatch(
            setFullAddAddress({
              ["number"]: equip_location.number,
              ["email"]: equip_location.email,
              ["address"]: equip_location.address,
              ["city"]: "",
              ["address1"]: equip_location.address1,
              ["zip_code"]: equip_location.zip_code,
              ["State"]: "",
            })
          );
        }
      } else {
        // Clear city and state if the pin code is not valid
        dispatch(
          setFullAddAddress({
            ["number"]: equip_location.number,
            ["email"]: equip_location.email,
            ["address"]: equip_location.address,
            ["city"]: "",
            ["address1"]: equip_location.address1,
            ["zip_code"]: equip_location.zip_code,
            ["State"]: "",
          })
        );
      }
    };
    fetchLocation();
  }, [equip_location.zip_code.length]);

  const handleAddressValidation = () => {
    if (
      manuData?.equip_location?.number === null ||
      manuData?.equip_location?.number === "" ||
      manuData?.equip_location?.email === null ||
      manuData?.equip_location?.email === "" ||
      manuData?.equip_location?.address === null ||
      manuData?.equip_location?.address === "" ||
      manuData?.equip_location?.city === null ||
      manuData?.equip_location?.city === "" ||
      manuData?.equip_location?.State === null ||
      manuData?.equip_location?.State === "" ||
      manuData?.equip_location?.zip_code === null ||
      manuData?.equip_location?.zip_code === ""
    ) {
      toast.error("Enter Equipment Location !", {
        autoClose: 2000,
        closeButton: false,
      });
      return false;
    } else if (
      manuData?.equip_location?.number?.length > 10 ||
      manuData?.equip_location?.number?.length < 10
    ) {
      toast.error("Please Enter 10 Digits Mobile Number !", {
        autoClose: 2000,
        closeButton: false,
      });
      return false;
    } else if (
      manuData?.equip_location?.zip_code?.length > 6 ||
      manuData?.equip_location?.zip_code?.length < 6
    ) {
      toast.error("Please Enter 6 Digits Zip Code !", {
        autoClose: 2000,
        closeButton: false,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!handlePostAdv()) {
      return;
    }
    if (!handleEquipmentList()) {
      return;
    }
    if (!handleAddressValidation()) {
      return;
    }
    handleLocation();
    let addressId;
    if (location?.lat && location?.lang) {
      let data = {
        name: `${userDetails?.fName}${userDetails?.lName}`,
        mobile: manuData?.equip_location?.number,
        alternate_mobile: manuData?.equip_location?.number,
        address: manuData?.equip_location?.address,
        state: manuData?.equip_location?.State,
        city: manuData?.equip_location?.city,
        zip_code: manuData?.equip_location?.zip_code,
        landmark: manuData?.equip_location?.address1,
        latitude: location?.lat,
        longitude: location?.lang,
        ["location"]: "India",
        is_default: 0,
      };
      const res = await postData1("users/add_address/", data, true);
      if (res?.status) {
        addressId = res?.data?.uid;
      } else {
        toast.error("address", { autoClose: 2000, closeButton: false });
      }
    }
    if (addressId) {
      if (
        manuData?.equip_images?.length > 0 &&
        manuData.equip_manufacturer_year &&
        manuData?.equip_Specifications?.brand &&
        manuData?.equip_Specifications?.model &&
        manuData?.equip_Specifications?.under_warranty &&
        manuData?.equip_Specifications?.equip_amc_cmc &&
        manuData?.equip_Specifications?.other_desc &&
        manuData?.equip_Specifications?.condition &&
        manuData?.equip_Specifications?.price &&
        manuData?.equip_Specifications?.negotiable &&
        manuData?.equip_Specifications?.prod_desc &&
        manuData?.equip_Specifications?.prod_desc
      ) {
        let images = manuData?.equip_images?.map((el) => el?.file);
        let videos = manuData?.equip_videos?.map((el) => el?.file);

        const formData = new FormData();
        formData.append(
          "post_type",
          manuData?.equip_selected_postType === "PRE OWNED"
            ? 1
            : manuData?.equip_selected_postType === "NEW"
            ? 2
            : ""
        );
        images.forEach((image) => {
          formData.append("images", image);
        });
        videos.forEach((video) => {
          formData.append("videos", video);
        });
        formData.append(
          "equip_name",
          manuData?.other_name ? manuData?.newEquip_name : manuData?.equip_name
        );
        formData.append("address", addressId);
        formData.append(
          "category_list",
          allData?.categories?.map((el) => el?.id)
        );
        formData.append(
          "speciality_name",
          allData?.specility?.map((el) => (el ? el : "other"))
        );
        formData.append(
          "equip_condition",
          manuData?.equip_Specifications?.condition
            ? manuData?.equip_Specifications?.condition
            : ""
        );
        formData.append(
          "negotiable_type",
          manuData?.equip_Specifications?.negotiable
            ? manuData?.equip_Specifications?.negotiable
            : ""
        );
        formData.append(
          "asking_price",
          manuData?.equip_Specifications?.price
            ? `${
                Number(manuData?.equip_Specifications?.price) +
                Number(manuData?.equip_Specifications?.price * 5) / 100
              }`
            : ""
        );
        formData.append(
          "description",
          manuData?.equip_Specifications?.prod_desc
        );
        formData.append(
          "year",
          manuData?.equip_manufacturer_year
            ? manuData?.equip_manufacturer_year
            : ""
        );

        formData.append("brand", manuData?.equip_Specifications?.brand);
        formData.append("model", manuData?.equip_Specifications?.model);
        formData.append(
          "warranty",
          manuData?.equip_Specifications?.under_warranty
            ? manuData?.equip_Specifications?.under_warranty
            : ""
        );
        formData.append(
          "existing_amc",
          manuData?.equip_Specifications?.equip_amc_cmc
            ? manuData?.equip_Specifications?.equip_amc_cmc
            : ""
        );
        formData.append(
          "other_details",
          manuData?.equip_Specifications?.other_desc
        );
        formData.append("latitude", manuData?.location?.lat);
        formData.append("longitude", manuData?.location?.lang);
        formData.append("user", userDetails?.uid);

        const res = await postData("product/add/", formData, true);
        if (res?.status) {
          // setLoader(false);
          // setFormDisabled(false);
          toast.success("Product added successfully Review By Admin !", {
            autoClose: 2000,
            closeButton: false,
          });
          // dispatch(filterCategories([]));
          // dispatch(filterSpec([]));
          dispatch(clearDisProdAdd());
          dispatch(clearProdAddData());
          setTimeout(() => {
            navigate("/distributor/listing/");
          }, 2000);
        } else {
          toast.error(res?.msg, { autoClose: 2000, closeButton: false });
        }
      } else {
        if (!manuData?.equip_selected_postType) {
          handlePostAdv();
        } else {
          toast.error("All Fields Are Mandatory !");
        }
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className={styles.distNewInvent}>
        <div onClick={() => window.history.back(-1)} className={styles.backTab}>
          <i class="bi bi-arrow-left-short"></i>
          <span>Back</span>
        </div>

        <div className={styles.NewIntForm}>
          <form className={styles.prodAddForm} onSubmit={handleSubmit}>
            <h4 className={styles.formTitle}>Add New Inventrory</h4>
            <div className={styles.postType}>
              <label htmlFor="equip_name">
                Post Your Adv
                <span
                  style={{ color: "red", fontWeight: "700", border: "none" }}
                >
                  *
                </span>
              </label>
              <div
                style={{
                  backgroundColor:
                    manuData?.equip_selected_postType == "PRE OWNED"
                      ? "#abe4dd"
                      : "",
                  borderRadius: "5px",
                }}
                onClick={() => dispatch(setEquipSelectedProdTpye("PRE OWNED"))}
              >
                Pre owned
              </div>
              <div
                style={{
                  backgroundColor:
                    manuData?.equip_selected_postType == "NEW" ? "#abe4dd" : "",
                  borderRadius: "5px",
                }}
                onClick={() => dispatch(setEquipSelectedProdTpye("NEW"))}
              >
                New
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="equip_name">
                  Equipment Name <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  value={equip_name ? equip_name : searchName}
                  id="equip_name"
                  name="equip_name"
                  autoComplete="off"
                  placeholder="Equip Name"
                  onChange={handleEquipNameChange}
                  disabled={other_name === true}
                />
                {searchName && (
                  <div
                    className={
                      filterCategories.length > 5
                        ? styles.equipNameDrop
                        : filterCategories.length < 1
                        ? ""
                        : styles.equipNameDrop1
                    }
                  >
                    {filterCategories.length
                      ? filterCategories?.map((el) => {
                          return (
                            <>
                              <p
                                onClick={() => {
                                  handleCatSearch(el?.name);
                                }}
                                className={styles.equipnameDropDown}
                              >
                                {el?.name}
                              </p>
                            </>
                          );
                        })
                      : !other_name && (
                          <div style={{ marginTop: "20px" }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                justifyContent: "fl",
                              }}
                            >
                              <label>Other</label>
                              <input
                                type="checkbox"
                                name="other_name"
                                id="other_name"
                                onClick={handleOtherCheck}
                                checked={other_name}
                              />
                            </span>
                          </div>
                        )}
                  </div>
                )}
                {other_name && (
                  <div style={{ marginTop: "20px" }}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "fl",
                      }}
                    >
                      <label>Other</label>
                      <input
                        type="checkbox"
                        name="other_name"
                        id="other_name"
                        onClick={handleOtherCheck}
                        checked={other_name}
                      />
                    </span>
                    <div className="specifyed">
                      <label>Please Specify Other</label>
                      <input
                        type="text"
                        id="newEquip_name"
                        placeholder="Enter New Equipment"
                        name="newEquip_name"
                        onChange={handleNewEquipName}
                        value={newEquip_name}
                        autoComplete="off"
                        disabled={other_name === false}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.formLable}>
                {/* <label htmlFor="">Category</label> */}
                <AdvtCategoriesDorpDown data={dropCat} />
              </div>
            </div>
            {/* <div className={styles.formdiv}>
            <AddressModel />
          </div> */}
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                {/* <label htmlFor="equip_name">Speciality</label>
              <input type="text" placeholder="Select the equipment Specality" /> */}
                <AdvtSpecialityDorpDown data={dropSpec} />
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.equipLocation}>
                <label htmlFor="equip_name">Equipment Location</label>
                <div for="lname" className={styles.currentLocation}>
                  <img
                    onClick={handleLocation}
                    className={styles.locationPng}
                    src={locationImage}
                    alt="..."
                  />
                  &nbsp;&nbsp;
                  <span onClick={handleLocation}>Use Current Location</span>
                </div>
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="price">
                  Zip/Postal Code <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="number"
                  placeholder="zip code"
                  name="zip_code"
                  id="zip_code"
                  value={equip_location?.zip_code}
                  onChange={handleAddressForm}
                />
              </div>
              <div className={styles.formLable}>
                <label htmlFor="equip_manufacturer_year">
                  Manufacturing/ Purchase Year{" "}
                  <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  readOnly
                  placeholder="Year"
                  name="equip_manufacturer_year"
                  id="equip_manufacturer_year"
                  value={equip_manufacturer_year}
                  onClick={() => setIsValid(true)}
                  autoComplete="off"
                />
                {isValid && (
                  <div ref={ref} className={styles.yearListSh}>
                    {yearList
                      ?.sort((a, b) => b - a)
                      ?.map((el) => {
                        return (
                          <p
                            className={styles.yearListDs}
                            onClick={() => handleYearChoose(el)}
                          >
                            {el}
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="city">
                  City <span className={styles.mandt}>*</span>
                </label>
                <input
                  disabled
                  type="text"
                  name="city"
                  id="city"
                  value={equip_location?.city}
                  onChange={handleAddressForm}
                />
              </div>
              <div className={styles.formLable}>
                <label htmlFor="price">
                  State/Region <span className={styles.mandt}>*</span>
                </label>
                <input
                  disabled
                  type="text"
                  name="State"
                  id="State"
                  value={equip_location?.State}
                  onChange={handleAddressForm}
                />
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="address">
                  Address <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={equip_location?.address}
                  onChange={handleAddressForm}
                />
              </div>
              <div className={styles.formLable}>
                <label htmlFor="landmark">Address1</label>
                <input
                  placeholder="Address1"
                  type="text"
                  name="landmark"
                  id="landmark"
                  value={equip_location?.landmark}
                  onChange={handleAddressForm}
                />
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="number">
                  {" "}
                  Phone Number <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Number"
                  name="number"
                  id="number"
                  value={equip_location?.number}
                  onChange={handleAddressForm}
                />
              </div>
              <div className={styles.formLable}>
                <label htmlFor="email">
                  Email <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  id="email"
                  value={equip_location?.email}
                  onChange={handleAddressForm}
                />
              </div>
            </div>
            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label htmlFor="brand">
                  Brand/Company <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Brand/company name"
                  name="brand"
                  value={equip_Specifications.brand}
                  id="brand"
                  onChange={handleEquipSpecChange}
                />
              </div>
              <div className={styles.formLable}>
                <label htmlFor="model">
                  Model <span className={styles.mandt}>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Equip model"
                  value={equip_Specifications.model}
                  name="model"
                  id="model"
                  onChange={handleEquipSpecChange}
                />
              </div>
            </div>
            <div>
              <label htmlFor="price">Equipment Price </label>
              <div className={styles.totalPrice}>
                <div className={styles.currSymbol}>
                  <label htmlFor="price" className={styles.askprice}>
                    Asking Price <span className={styles.mandt}>*</span>
                  </label>
                  <i className="bi bi-currency-rupee"></i>
                  <input
                    className={styles.priceinput}
                    type="number"
                    name="price"
                    step="0.01"
                    id={styles.rupee}
                    value={
                      equip_Specifications?.price !== null
                        ? equip_Specifications?.price
                        : ""
                    }
                    placeholder="price"
                    onKeyDown={blockInvalidChar}
                    // value={specifications?.price}
                    onChange={handleEquipSpecChange}
                  />
                  <p
                    style={{
                      marginTop: "5px",
                      marginBottom: "10px",
                      fontSize: "12px",
                    }}
                  >
                    <b>Note:</b> Platform fee including GST*
                  </p>
                </div>
                <div className={styles.currSymbol}>
                  <h3 className={styles.askprice}>
                    Platform Fee*{" "}
                    <span className={styles.priceSpan}> (in INR*)</span>
                  </h3>
                  <i className="bi bi-currency-rupee"></i>
                  <input
                    className={styles.priceinput}
                    type="number"
                    name="price"
                    step="0.01"
                    disabled="true"
                    onKeyDown={blockInvalidChar}
                    id={styles.rupee}
                    value={
                      equip_Specifications?.price !== null
                        ? (Number(equip_Specifications?.price) * 5) / 100
                        : ""
                    }
                    onChange={handleEquipSpecChange}
                  />
                </div>
                <div className={styles.currSymbol}>
                  <h3 className={styles.askprice}>
                    Total Price*{" "}
                    <span className={styles.priceSpan}> (in INR*)</span>
                  </h3>
                  <i className="bi bi-currency-rupee"></i>
                  <input
                    className={styles.priceinput}
                    type="number"
                    name="price"
                    step="0.01"
                    disabled="true"
                    onKeyDown={blockInvalidChar}
                    id={styles.rupee}
                    value={
                      equip_Specifications?.price !== null
                        ? Number(equip_Specifications?.price) +
                          Number(equip_Specifications?.price * 5) / 100
                        : ""
                    }
                    onChange={handleEquipSpecChange}
                  />
                </div>
              </div>
            </div>

            {/* <div>
              <div className={styles.formLable}>
                <label htmlFor="price">Product Price</label>
                <input
                  type="number"
                  placeholder="Equip price"
                  name="price"
                  id="price"
                  onKeyDown={blockInvalidChar}
                  value={specifications?.price}
                  onChange={handleEquipSpecChange}
                />
              </div>
            </div> */}
            <div className={styles.productSpesf}>
              <h1>Product Specification</h1>
              <div>
                <h2>
                  Equipment Condition <span className={styles.mandt}>*</span>
                </h2>
                <div className={styles.specifCont}>
                  <div>
                    <input
                      type="radio"
                      name="condition"
                      id="condition"
                      value="1"
                      checked={equip_Specifications?.condition === "1"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="condition">Good</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="condition"
                      id="condition"
                      value="2"
                      checked={equip_Specifications?.condition === "2"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="condition">Excellent</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="condition"
                      id="condition"
                      value="3"
                      checked={equip_Specifications?.condition === "3"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="condition">As Good As New</label>
                  </div>
                </div>
              </div>
              <div>
                <h2>
                  Negotiable <span className={styles.mandt}>*</span>
                </h2>
                <div className={styles.specifCont}>
                  <div>
                    <input
                      type="radio"
                      name="negotiable"
                      id="negotiable"
                      value="1"
                      checked={equip_Specifications?.negotiable === "1"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="negotiable">Negotiable</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="negotiable"
                      id="negotiable"
                      value="2"
                      checked={equip_Specifications?.negotiable === "2"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="negotiable">Slightly Negotiable</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="negotiable"
                      id="negotiable"
                      value="3"
                      checked={equip_Specifications?.negotiable === "3"}
                      onChange={handleEquipSpecChange}
                    />
                    <label for="negotiable">Non-Negotiable</label>
                  </div>
                </div>
              </div>
              <div className={styles.distWarranty}>
                <div>
                  <h2>
                    Under Warranty <span className={styles.mandt}>*</span>
                  </h2>
                  <div className={styles.specifCont}>
                    <div className={styles.warrantyCont}>
                      <div>
                        <input
                          type="radio"
                          value="1"
                          name="under_warranty"
                          id="under_warranty"
                          checked={equip_Specifications?.under_warranty === "1"}
                          onChange={handleEquipSpecChange}
                        />
                        <label for="under_warranty">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          value="0"
                          name="under_warranty"
                          id="under_warranty"
                          checked={equip_Specifications?.under_warranty === "0"}
                          onChange={handleEquipSpecChange}
                        />
                        <label for="under_warranty">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2>
                    Existing AMC/CMC <span className={styles.mandt}>*</span>
                  </h2>
                  <div className={styles.specifCont}>
                    <div className={styles.warrantyCont}>
                      <div>
                        <input
                          type="radio"
                          value="1"
                          name="equip_amc_cmc"
                          id="equip_amc_cmc"
                          checked={equip_Specifications?.equip_amc_cmc === "1"}
                          onChange={handleEquipSpecChange}
                        />
                        <label for="equip_amc_cmc">Yes</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="equip_amc_cmc"
                          id="equip_amc_cmc"
                          value="0"
                          checked={equip_Specifications?.equip_amc_cmc === "0"}
                          onChange={handleEquipSpecChange}
                        />
                        <label for="equip_amc_cmc">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formdiv}>
              <div className={styles.formLable}>
                <label style={{ marginBottom: "0.5rem" }} htmlFor="equip_name">
                  You can upload up to 7 images for the Product
                  <span className={styles.mandt}>*</span>
                </label>
                <MultipleImageUpload dispatch={dispatch} />
              </div>
              <div className={styles.formLable}>
                <label style={{ marginBottom: "0.5rem" }} htmlFor="equip_name">
                  You can upload up to 3 videos for the Product (optional)
                </label>
                <MultipleVideoUpload dispatch={dispatch} />
              </div>
            </div>

            <div className={styles.addInventDesc}>
              <div className={styles.formdiv}>
                <div className={styles.formLable}>
                  <label style={{ marginBottom: "0.5rem" }} htmlFor="prod_desc">
                    Product Description <span className={styles.mandt}>*</span>
                  </label>
                  <textarea
                    name="prod_desc"
                    id="prod_desc"
                    type="text"
                    placeholder="Enter Product Desc"
                    cols="30"
                    value={equip_Specifications?.prod_desc}
                    onChange={handleEquipSpecChange}
                  ></textarea>
                </div>
                <div className={styles.formLable}>
                  <label
                    style={{ marginBottom: "0.5rem" }}
                    htmlFor="other_desc"
                  >
                    Other Details <span className={styles.mandt}>*</span>
                  </label>
                  <textarea
                    name="other_desc"
                    id="other_desc"
                    type="text"
                    placeholder="Other Details"
                    cols="30"
                    value={equip_Specifications?.other_desc}
                    onChange={handleEquipSpecChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <p style={{ marginTop: "3rem" }}>
              <b>Please Note </b>: Once Seller Added the details, it will go for
              the admin approval, after admin approved them only this will be
              visible on home page
            </p>

            <button type="submit" className={styles.bttn}>
              {" "}
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const options = [
  { label: "Option 1", value: "option1" },
  { label: "Option 2", value: "option2" },
  { label: "Option 3", value: "option3" },
];

export const Category = ({ data }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  return (
    <div className={styles.disAddInvent}>
      <Select
        className={styles.selectss}
        options={options}
        value={selectedOptions}
        placeholder="Select the equipment Category"
        onChange={handleChange}
        isMulti
      />
    </div>
  );
};

export const AdvtCategoriesDorpDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPostType = useSelector(
    (state) => state.addProd.prodAddData.selectedPostType
  );
  const categoriesId = useSelector(
    (state) => state.addProd.prodAddData.categories
  );

  const [show, setShow] = useState(false);
  const [selectedCat, setSelectedCat] = useState({});
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilter] = useState([]);

  const handleCategoriesName = (event, val) => {
    let updateVal = { id: val?.id, name: val?.name };
    if (event.target.checked) {
      dispatch(setCategories(updateVal));
    } else {
      dispatch(filterCategories(updateVal));
    }
  };

  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilter(fil);
  };

  const handleCancelBtn = (item) => {
    dispatch(filterCategories(item));
    setFilterName("");
  };
  return (
    <>
      <ToastContainer />
      <div ref={ref}>
        <div className={styles.specTag}>
          <span>
            {props.data.title}
            <span className={styles.mandt}>{props.data.mandt}</span>
          </span>
        </div>
        <div className={styles.selectEquipDiv} onClick={() => setShow(true)}>
          {!show ? (
            <p style={{ padding: "9px" }}>{props.data.placeholder}</p>
          ) : (
            <input
              type="text"
              placeholder="search category"
              className={styles.catInputField}
              onChange={(e) => setFilterName(e.target.value)}
            />
          )}

          <img className={styles.dropDownImage} src={postDropdown} alt="..." />
        </div>

        {show && (
          <div
            className={
              props?.data?.dataList?.length > 4
                ? styles.checkBox
                : styles.checkBox1
            }
          >
            {filterData?.length > 0 ? (
              filterData
                .slice()
                ?.sort((a, b) => a?.name.localeCompare(b?.name))
                ?.map((value) => {
                  return (
                    <div className={styles.checkboxCont} key={value.id}>
                      <input
                        type="checkbox"
                        id="categories"
                        value={value?.id}
                        checked={
                          categoriesId
                            ? categoriesId?.some((el) => el.id === value.id)
                            : false
                        }
                        name="categories"
                        onChange={(e) => handleCategoriesName(e, value)}
                      />
                      <label for="checkbox1">{value?.name}</label>
                    </div>
                  );
                })
            ) : filterData?.length < 1 ? (
              <div className={styles.checkboxCont}>
                <input
                  type="checkbox"
                  id="categories"
                  value={1057}
                  checked={
                    categoriesId
                      ? categoriesId?.some((el) => el.id === 1057)
                      : false
                  }
                  name="categories"
                  onChange={(e) =>
                    handleCategoriesName(e, { id: 1057, name: "other" })
                  }
                />
                <label for="checkbox1">other</label>
              </div>
            ) : (
              props?.data?.dataList
                ?.slice()
                .sort((a, b) => a?.name.localeCompare(b?.name))
                ?.map((value) => {
                  return (
                    <div className={styles.checkboxCont} key={value.id}>
                      <input
                        type="checkbox"
                        id="categories"
                        value={value?.id}
                        checked={
                          categoriesId
                            ? categoriesId?.some((el) => el.id === value.id)
                            : false
                        }
                        name="categories"
                        onChange={(e) => handleCategoriesName(e, value)}
                      />
                      <label for="checkbox1">{value?.name}</label>
                    </div>
                  );
                })
            )}
          </div>
        )}
        <div className={styles.catSelect}>
          {categoriesId?.map((el, index) => {
            return (
              <div className={styles.catSelectClose} key={index}>
                <p>
                  {el?.name}
                  <span onClick={() => handleCancelBtn(el)}>
                    <i class="bi bi-x"></i>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const AdvtSpecialityDorpDown = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const selectedPostType = useSelector(
  //   (state) => state.addProd.prodAddData.selectedPostType
  // );
  const Speciality = useSelector(
    (state) => state.addProd.prodAddData.specility
  );
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilter] = useState([]);

  // useEffect(() => {
  //   if (!selectedPostType) {
  //     navigate("/post/");
  //   }
  // }, [selectedPostType]);
  const handleCategoriesName = (event) => {
    dispatch(setSpecality(event.target.value));
  };
  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilter(fil);
  };

  const handleCancelBtn = (item) => {
    dispatch(filterSpec(item));
    setFilterName("");
  };
  return (
    <>
      <div style={{ marginTop: "-3px" }} ref={ref}>
        <div className={styles.specTag}>
          <p>
            {props.data.title}
            <span className={styles.mandt}>{props.data.mandt}</span>
          </p>
        </div>
        <div className={styles.selectEquipDiv} onClick={() => setShow(true)}>
          {!show ? (
            <p style={{ padding: "9px" }}>{props.data.placeholder}</p>
          ) : (
            <input
              type="text"
              placeholder="search category"
              className={styles.catInputField}
              // style={{ padding: "5px", border: "none" }}
              onChange={(e) => setFilterName(e.target.value)}
            />
          )}
          <img className={styles.dropDownImage} src={postDropdown} alt="..." />
        </div>

        {show && (
          <div
            className={
              props?.data?.dataList?.length > 4
                ? styles.checkBox
                : styles.checkBox1
            }
          >
            {filterData?.length > 0
              ? filterData
                  ?.slice()
                  ?.sort((a, b) => a?.name.localeCompare(b?.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="specility"
                          value={value?.name}
                          checked={Speciality?.includes(value?.name)}
                          name="specility"
                          onChange={(e) => handleCategoriesName(e)}
                        />
                        <label for="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })
              : props?.data?.dataList
                  ?.slice()
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((value) => {
                    return (
                      <div className={styles.checkboxCont} key={value.id}>
                        <input
                          type="checkbox"
                          id="specility"
                          value={value?.name}
                          checked={Speciality?.includes(value?.name)}
                          name="specility"
                          onChange={(e) => handleCategoriesName(e)}
                        />
                        <label for="checkbox1">{value?.name}</label>
                      </div>
                    );
                  })}
          </div>
        )}
        <div className={styles.catSelect}>
          {Speciality?.map((el, index) => {
            return (
              <div key={index}>
                <p className={styles.catSelectClose}>
                  {el}
                  <span onClick={() => handleCancelBtn(el)}>
                    <i class="bi bi-x"></i>
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const MultipleImageUpload = (props) => {
  const ref = useRef();
  const dispatch = props?.dispatch;
  const selectedImages = useSelector(
    (state) => state.manuProdAdd.prodAdd.equip_images
  );

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000;
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  }

  const handleFileChange = (event) => {
    const current = event.target;
    const files = current.files;
    if (files.length > 7)
      for (let i = 0; i < files.length; i++) {
        const imageId = generateUniqueId();
        const imageUrl = URL.createObjectURL(files[i]);
        const file = files[i];
        dispatch(addEquipImages({ imageId, imageUrl, file }));
      }
  };

  const handleImageRemove = (imageId) => {
    dispatch(removeEqipImages(imageId));
  };

  const handleImageClick = () => {
    ref.current.click();
  };

  return (
    <div className={styles.fileAddInvent}>
      {selectedImages?.map((value) => {
        return (
          <div style={{ display: "flex" }}>
            <img src={value?.imageUrl} key={value?.imageId} />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => handleImageRemove(value?.imageId)}
            >
              X
            </p>
            {/* <img src={value?.imageUrl} key={value?.imageId} /> */}
          </div>
        );
      })}
      <label style={{ padding: "20px" }} for="inputimg">
        <input
          type="file"
          accept="image/*"
          name="equip_images"
          id="equip_images"
          ref={ref}
          multiple
          onChange={handleFileChange}
        />
        <span onClick={handleImageClick} style={{ fontSize: "22px" }}>
          <FaPlusCircle />
        </span>
        {/* <img src={ImageUpload} alt="Upload" onClick={handleImageClick} /> */}
      </label>
    </div>
  );
};

export const MultipleVideoUpload = (props) => {
  const ref = useRef();
  const dispatch = props?.dispatch;
  const selectedVideos = useSelector(
    (state) => state.manuProdAdd.prodAdd.equip_videos
  );

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000;
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  }

  const handleFileChange = (event) => {
    const current = event.target;
    const files = current.files;
    if (files.length > 7) {
      toast.error("You can only upload a maximum of 7 images.");
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const videoId = generateUniqueId();
      const videoUrl = URL.createObjectURL(files[i]);
      const file = files[i];
      dispatch(addEquipVideos({ videoId, videoUrl, file }));
    }
  };

  const handleVideoRemove = (videoId) => {
    dispatch(removeEqipVideos(videoId));
  };

  const handleImageClick = () => {
    ref.current.click();
  };

  return (
    <div className={styles.fileAddInvent}>
      {selectedVideos?.map((value) => {
        return (
          <div style={{ display: "flex" }}>
            <video
              style={{
                height: "45px",
                height: "45px",
                border: "1px solid #019C89",
              }}
              key={value?.videoId}
            >
              <source src={value?.videoUrl} type="video/mp4" />
            </video>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleVideoRemove(value?.videoId)}
            >
              X
            </span>
          </div>
        );
      })}
      <label style={{ padding: "20px" }} for="inputimg">
        <input
          type="file"
          accept="video/*"
          name="equip_videos"
          id="equip_videos"
          ref={ref}
          multiple
          onChange={handleFileChange}
        />
        <span onClick={handleImageClick} style={{ fontSize: "22px" }}>
          <FaPlusCircle />
        </span>
        {/* <img src={ImageUpload} alt="Upload" onClick={handleImageClick} /> */}
      </label>
    </div>
  );
};
