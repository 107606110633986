// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_header__5Edol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ffff;
}
.pagination_btn__kWm\\+D {
  border: 1px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px;
  border-radius: 3px;
  color: black;
  background-color: rgb(135, 179, 135);
  margin: 5px;
  cursor: pointer;
}
.pagination_text__zntcZ {
  color: gray;
}
@media screen and (max-width: 1200px) {
  .pagination_header__5Edol{
    margin-bottom: 20px;
  }
  }
  @media screen and (max-width: 650px) {
    .pagination_header__5Edol{
      margin-bottom: 35px;
    }
    }
`, "",{"version":3,"sources":["webpack://./src/assets/css/pagination.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,WAAW;EACX,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;EACpC,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,mBAAmB;EACrB;EACA;EACA;IACE;MACE,mBAAmB;IACrB;IACA","sourcesContent":[".header {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n  color: #ffff;\r\n}\r\n.btn {\r\n  border: 1px;\r\n  width: fit-content;\r\n  padding: 8px;\r\n  border-radius: 3px;\r\n  color: black;\r\n  background-color: rgb(135, 179, 135);\r\n  margin: 5px;\r\n  cursor: pointer;\r\n}\r\n.text {\r\n  color: gray;\r\n}\r\n@media screen and (max-width: 1200px) {\r\n  .header{\r\n    margin-bottom: 20px;\r\n  }\r\n  }\r\n  @media screen and (max-width: 650px) {\r\n    .header{\r\n      margin-bottom: 35px;\r\n    }\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `pagination_header__5Edol`,
	"btn": `pagination_btn__kWm+D`,
	"text": `pagination_text__zntcZ`
};
export default ___CSS_LOADER_EXPORT___;
