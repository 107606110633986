import React from "react";
import styles from "../../Admin/assets/css/admNotificaiton.module.css";

export const AdmNotification = () => {
  return (
    <section className={styles.notifictions}>
      <div className={styles.notifiactionHead}>
        <h1>NOTIFICATIONS</h1>
      </div>
      <div className={styles.notifiactionData}>
        <div className={styles.notificationCard}>
          <div className={styles.notificationCont}>
            <h2>Joinded New User</h2>
            <h3>New Registration:FineBus Benurem et molerem</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus,
              et reiciendis. Ipsa, possimus libero dolorum aut pariatur et
              facere! Cupiditate?
            </p>
            <h4>Allen Due</h4>
          </div>
          <div className={styles.notificationDate}>
            <span></span>
            <p>
              <span>
                <i class="bi bi-clock"></i>
              </span>{" "}
              24 nov 2018 at 9:30AM
            </p>
          </div>
        </div>

        <div className={styles.notificationCard}>
          <div className={styles.notificationCont}>
            <h2>Joinded New User</h2>
            <h3>New Registration:FineBus Benurem et molerem</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus,
              et reiciendis. Ipsa, possimus libero dolorum aut pariatur et
              facere! Cupiditate?
            </p>
            <h4>Allen Due</h4>
          </div>
          <div className={styles.notificationDate}>
            <p>
              <span>
                <i class="bi bi-clock"></i>
              </span>{" "}
              24 nov 2018 at 9:30AM
            </p>
          </div>
        </div>
        <div className={styles.notificationCard}>
          <div className={styles.notificationCont}>
            <h2>Joinded New User</h2>
            <h3>New Registration:FineBus Benurem et molerem</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus,
              et reiciendis. Ipsa, possimus libero dolorum aut pariatur et
              facere! Cupiditate?
            </p>
            <h4>Allen Due</h4>
          </div>
          <div className={styles.notificationDate}>
            <p>
              <span>
                <i class="bi bi-clock"></i>
              </span>{" "}
              24 nov 2018 at 9:30AM
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
