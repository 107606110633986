import React, { useEffect } from "react";
// import { useHistory } from "react-router-dom";
import styles from "../../assets/css/mmqDoc/termsCondition.module.css";
import { CompanyTitle } from "../../assets/data/data";
export const TermsCondition = () => {
  let TermCondata = [
    {
      docSubHeading: "1. User Eligibility",
      docSubHeadingP: [
        "1.1. The User buying or selling the medical equipment through the platform must be a licensed healthcare professional or represent a healthcare institution to use the Platform.",
        "These healthcare professionals may include doctors, healthcare professionals, manufacturers of medical equipment’s and devices, distributors of medical equipment’s and devices, suppliers of medical equipment’s and devices.  ",

        "Provided here that the medical equipment and device is inclusive of both new and old equipment’s and machinery.",
      ],
    },
    {
      docSubHeading: "2. Buying Medical Equipment",
      docSubHeadingP: [
        "2.1. We facilitate the buying and sale of new or second hand medical equipment for doctors, healthcare care professionals, medical device/equipment, manufacturers, distributors and suppliers. The facilitation of second-hand medical equipment is by inspecting second-hand medical equipment. ",
        "Provided that for the facilitation of second hand medical equipment a token amount has to be paid by the buyer to avail the services of the platform to conduct the inspection procedure. ",

        `2.3. ${CompanyTitle} reserves the right to reject the purchase of equipment that does not meet specified standards.`,

        ` Sellers must be the rightful owners of the medical equipment and have the authority to sell it. ${CompanyTitle} reserves the right to accept the publishing/listing of the advertisement for the medical equipment on the website or reject the publishing/listing of the advertisement for the medical equipment on the website.`,
        `Provided this acceptance and rejection is based the criteria that the equipment meets certain standards and criteria set by ${CompanyTitle}.`,
      ],
    },
    {
      docSubHeading: "3.Selling Medical Equipment",
      docSubHeadingP: [
        "3.1. Sellers must be the rightful owners of the medical equipment and have the authority to sell it.",
        " Provided that this ownership has to be proved by valid tax invoices and billing invoices of the machinery. ",
        `3.2. ${CompanyTitle} will inspect the equipment before confirming the purchase, and the seller must cooperate in the inspection process.",
            Provided the services related to inspection are optional services which are pay basis. The buyer has an option to choose or not to choose the optional inspection services provided by ${CompanyTitle}.`,
        "Provided also that if the buyer chooses to avail the inspection services, payment of the inspection fee has to be done in advance to us through the platform. ",
        "3.3. Sellers must provide accurate information about the equipment's condition and specifications.",
        "Provided that any incorrect or misleading information regarding the condition of the machinery may contest refusal /cancellation of purchase / Hold of payment and other legal proceedings. Also, the seller will be solely responsible for the condition of machine and equipment. ",
        "Provided also that before sharing the inspection report with the buyer, the report will be validated with the seller in order to add any specific comment about the condition of machine. ",
      ],
    },
    {
      docSubHeading: "4.Payment and Fees",
      docSubHeadingP: [
        `4.1. The pricing of medical equipment is determined by mutual agreement between the buyer, seller and ${CompanyTitle}.`,

        `Provided that ${CompanyTitle} would just act as mediator to facilitate the negotiations in the process for which a platform fee has to be paid by both the parties. `,

        `4.2. ${CompanyTitle} charges a service fee (platform fee) for facilitating the buying and selling process. This fee will beexclusive of the cost of the equipment and the inspection feeand other add on services availed by the buyer and the seller. `,
        `Provided the payment of charges related to the dismantling, shipping, handling, delivery and installation will be included in the charges if the buyer wants the services of ${CompanyTitle} regarding the above. `,

        "Provided that the buyer is free to use his independent services for receiving the equipment ranging from dismantling, handling, delivery and installation of the equipment and in this case the platform will not be liable for any issues relating to the dismantling, handling, delivery and installation of the equipment.",
      ],
    },

    {
      docSubHeading: "5.Delivery and Installation",
      docSubHeadingP: [
        `5.1. ${CompanyTitle} is responsible for the delivery and installation of purchased medical equipment.`,
        "Provided that the seller is availing the dismantling, handling, delivery and installation of the equipment.",
        "Provided that the seller is availing the dismantling, handling, delivery and installation of the equipment.",
        "5.2. Buyers must provide accurate delivery and installation information",
      ],
    },
    {
      docSubHeading: "6. Warranty and Returns",
      docSubHeadingP: [
        "6.1. New equipment may come with a manufacturer's warranty; details will be provided upon purchasein the contract between seller and buyer along with the tax invoice and the terms and conditions of Manufacturer / Distributor / Supplier will be applicable.",
        `6.2. ${CompanyTitle} is not responsible for warranties on second-hand equipment but will ensure the equipment's condition during the inspection.`,
      ],
    },
    {
      docSubHeading: "7. Privacy Policy",
      docSubHeadingP: [
        `7.1. Your use of the Platform is also governed by our Privacy Policy, available <a style="color:blue" href="https://mediquip.in/privacypolicy" >here.</a>`,
      ],
    },
    {
      docSubHeading: "8. Limitation of Liability",
      docSubHeadingP: [
        `8.1. ${CompanyTitle} is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Platform.`,
      ],
    },
    {
      docSubHeading: "9. Governing Law",
      docSubHeadingP:
        "9.1. These Terms are governed by and construed in accordance with the laws of India.",
    },
    {
      docSubHeading: "10. Contact Information",
      docSubHeadingP: [
        "10.1. For any inquiries regarding these Terms, please contact us at <b>info@equipmedy.com.<b/>",
        "By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms.",
      ],
    },
  ];

  return (
    <>
      <div className={styles.mmqDoc}>
        <div className={styles.docMain}>
          <div className={styles.docHeading}>
            <h1>TERMS AND CONDITIONS - EquipMedy</h1>
            <p>
              These terms and conditions ("Terms") constitute a legal agreement
              between you (referred to as "User" or "you") and {CompanyTitle}
              .com which is registered as <b>AA MEDIE QUIP LLP</b> ("{CompanyTitle},"
              "we," "us,", "our") governing your use of the {CompanyTitle}.com
              website, services, and related applications (collectively, the
              "Platform"). By accessing or using the Platform, you agree to
              comply with and be bound by these Terms. If you do not agree with
              these Terms, please do not use the Platform.
            </p>
          </div>
          <div>
            {TermCondata.map((data, index) => (
              <MmqDoc
                key={index}
                docSubHeading={data.docSubHeading}
                docSubHeadingP={data.docSubHeadingP}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

// export const MmqDoc = (props) => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <div className={styles.docSubHeading}>
//       <div className={styles.tcCont}>
//         <h2>{props.docSubHeading}</h2>
//         {Array.isArray(props.docSubHeadingP) ? (
//           props.docSubHeadingP.map((paragraph, index) => (
//             <p key={index}>{paragraph}</p>
//           ))
//         ) : (
//           <p>{props.docSubHeadingP}</p>
//         )}
//       </div>
//     </div>
//   );
// };

export const MmqDoc = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.docSubHeading}>
      <div className={styles.tcCont}>
        <h2>{props.docSubHeading}</h2>
        {Array.isArray(props.docSubHeadingP) ? (
          props.docSubHeadingP.map((paragraph, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))
        ) : (
          <p dangerouslySetInnerHTML={{ __html: props.docSubHeadingP }} />
        )}
      </div>
    </div>
  );
};


// PrivacyPolicyDatat
export const PrivacyPolicyData = [
  {
    sectionTitle: "1. Who are we?",
    sectionContent: [
      `1. AA MEDIE QUIP LLP is a limited liability incorporation which is the parent company of ${CompanyTitle}.com and the same is registered as AA MEDIE QUIP LLP that is incorporated in India under the Companies Act, 2013. It has its registered office at 
      <b> 
      B-99, 5th Floor, Phase-I Panchsheel Park
      Malviya Nagar, Near Triveni Complex New Delhi - 110017.<b/>`,
    ],
  },
  {
    sectionTitle: "2. What data do we collect about you?",
    sectionContent: [
      "1. Data provided through direct interactions.",
      "2. Registration and other account information.",
      "3. When you register to use our Services, we may collect the following information about you:",
      " - If you register using your mobile number we collect your mobile number.",
      "4. Depending on the choices you make during the log-in to our Services or during the process of engaging our Services, you may opt to give the following additional personal data: ",
      " - Your name; ",
      " - E-mail address; ",
      " - Mobile number; ",
      "5. Your credit card/debit card details in case you choose to purchase our paid services as defined in our Terms of Use using your credit/debit card.",
      "6. Copy of your identity proof (driving license, passport or other card, whichever is convenient.)",
      `7. Validation of your ${CompanyTitle} account.`,
      `8. We validate the accounts of ${CompanyTitle} users by using SMS verification to ensure that each account is associated with a real and unique user. This validation process is initiated once you proceed with posting your first ad listing on our Services.`,
      `9. If you do not agree to validate your account, then your account will remain active and you will be able to use our Services with limited functionality. This limited functionality of an account implies that you cannot publish new ad listings or edit, update, promote, extend, reactivate, deactivate or delete existing ad listings until your account is verified by SMS. You will also not be able to receive or reply to any messages from other users`,
      `10. In case you create several ${CompanyTitle} accounts using the same mobile number and validate all those accounts via SMS verification, all such accounts will have a limited functionality and you will be asked to choose one of them. The account chosen by you will return to full functionality, and the rest of the accounts will remain to have limited functionality.`,
      `11. Once you have validated your ${CompanyTitle} account, it will remain associated with the mobile number used for the SMS verification. If you wish to change the mobile number associated with your ${CompanyTitle} account, you will need to contact our Customer Support team.`,
    ],
  },
  {
    sectionTitle:
      "2.1.2 Data we collect automatically when you use of our Services",
    sectionContent: [
      "1. When you interact with our Services, we automatically collect the following information about you:",
      " - Device Information",
      " - Location information",
      " - Client and Log data",
      " - Clickstream data",
      "2. Cookies and Similar Technologies",
      "3. We use cookies to manage our users’ sessions, to store your preference language selection and deliver relevant advertisements. `Cookies` are small text files transferred from a web server to the hard drive of your device. Cookies may be used to collect the date and time of your visit, your browsing history, your preferences, and your username. You can set your browser to refuse all or some cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Services/Platform may become inaccessible or not function properly. ",
    ],
  },
  {
    sectionTitle: "3. Why do we process your personal information?",
    sectionContent: [
      "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
      "1. Where we need to perform the contract we are about to enter into or have entered into with you, or where you have expressly requested us to contact you.",
      "2. Where it is necessary for our legitimate interests to improve our Services and to provide you a safe and secure Platform.",
      "3. Where we need to comply with a legal or regulatory obligation.",
      "4. We have set out below a description of the reasons for which we use your personal data, [and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are, where appropriate].",
    ],
  },
  {
    sectionTitle:
      "3.1 For providing access and delivering Services through our Platform",
    sectionContent: [
      "1. If you log in using your mobile number we use your first name and last name, mobile number to identify you as a user and provide access to our Platform.",
      "2. We may share your data with select partners in the course of our business, who have agreed to respect and protect your privacy in the same manner we do. These select partners may use your information (being your contact information, details of the medical equipment to be sold or bought) to deliver services tailored to your preferences and interests. We only collaborate with other legally compliant entities that use your data solely for the purpose of providing you with relevant offers and services. We recommend you evaluate their Privacy Policy for further information how your data would be used by such entities. You retain the option to opt out of receiving such tailored services by contacting our customer support team/Grievance Officer. By continuing to use our services, you consent to the sharing of your data with our select partners for the purpose of receiving such personalized communications. We continuously strive to ensure that your personal information is handled responsibly and in line with your expectations.",
      "3. We process the above information for adequate performance of our contract with you.",
    ],
  },
  {
    sectionTitle:
      "3.2 For improving your experience on the Platform and developing new functionalities of the Platform",
    sectionContent: [
      "1. We use clickstream data to:",
      " - Offer you tailored content, such as giving you more relevant search results when using our Services.",
      " - To determine how much time you spend on our Platform and in what manner you navigate through our Platform in order to understand your interests and to improve our Services based on this data.",
      " - To monitor and report the effectiveness of the campaign delivery to our business partners and internal business analysis.",
      "2. To show and recommend ad listings on our Services that may be of interest to you, we make use of algorithms that use information related to your browsing behaviour, items you bought, clickstream data, your user ID. We use this form of automated decision-making on the basis of our legitimate interest in improving our Services and provide a better user experience by offering you more relevant ad listings.",
      " - We process the above information for adequate performance of our contract with you and on the basis of our legitimate interest to improve your experience of our Services.",
    ],
  },
  {
    sectionTitle:
      "3.3 To take your feedback, promote and offer you Services that may be of your interest ",
    sectionContent: [
      "1. We use your mobile number, log data and unique device identifiers to administer and protect our Platform (including troubleshooting, data analysis, testing, fraud prevention, system maintenance, support, reporting and hosting of data).",
    ],
  },
  {
    sectionTitle:
      "3.4 To take your feedback, promote and offer you Services that may be of your interest",
    sectionContent: [
      "1. We may contact you through your registered mobile number or email id in order to take feedback from you about our Services.",
      "2. We use your email address and mobile number (by SMS) to make suggestions and recommendations to you about our Services that may be of interest to you.",
      "3. We use clickstream data to monitor and report the effectiveness of the campaign delivery to our business partners and internal business analysis.",
      "We process the above information based on our legitimate interest in undertaking marketing activities to offer you Services that may be of your interest. Specifically, you may receive certain marketing communications from us:",
      " - By any preferred means of communication if you have requested such information from us.",
      " - By email or phone, regarding similar products and services, if you already use our Services or acquired some of our products.",
      " - By phone or email, if you have provided your feedback for our Services through our Platform, social media, or any other means.",
    ],
  },
  {
    sectionTitle:
      "4. How will we inform you about changes in our privacy statement?",
    sectionContent: [
      "1. For any inquiries regarding this Privacy Policy, please contact us at <b>info@equipmedy.com.<b/>",
      "By using the Platform, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy:",
      "1. We may amend and update this privacy statement from time to time. We will notify you of material changes to this privacy statement as appropriate under the circumstances and as required by applicable laws, e.g. by placing a prominent notice within our Services or by sending you a message via our Services or by sending you an email. If you do not agree with the way we are processing your personal data and the choices we are providing to you, you ",
      "2. may close your account at any time by going into your account settings and select delete account.",
    ],
  },
  {
    sectionTitle: "5. Communication",
    sectionContent: [
      "We will communicate with you by SMS or email if provided in connection with our Services/Platform to confirm your registration, to inform you in case your ad listing has become live/expired and other transactional messages in relation to our Services. As it is imperative for us to provide you such transactional messages you may not be able to opt -out of such messages.",
    ],
  },
  {
    sectionTitle: "6. Who do we share your data with?",
    sectionContent: [
      "1. We may have to share your personal data with the parties set out below for the purposes set out in section 3 above.",
      "2. Select Partners: We may share your personal information which includes name, phone number and email address with select partners in the course of our business, who have agreed to respect and protect your privacy in the same manner we do. These partners may utilize your information to deliver services tailored to your preferences and interests. You may contact our Grievance Officer if you do not wish to receive such services.",
      "3. Third Party Service Providers: We use third party service providers to help us deliver certain aspect of our Services for example, cloud storage facilities.",
      "4. We conduct checks on our third-party service providers and require them to respect the security of your personal data and to treat it in accordance with the law. We do not allow them to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.",
      "5. Advertising and analytics providers: In order to improve our Services, we will sometimes share your non-identifiable information with analytics providers that help us analyze how people are using our Platform/Service. We, share your information with them in non-identifiable form for monitoring and reporting the effectiveness of the campaign delivery to our business partners and for internal business analysis. For further details about our advertisers and analytics providers, please refer to our <Policy for Cookies and similar Technologies>.",
      "6. Law enforcement authorities, regulators and others: We may disclose your personal data to law enforcement authorities, regulators, governmental or public bodies and other relevant third parties comply with any legal or regulatory requirements.",
      "7. We may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy statement.",
      `8. Publicly available information: When you post an item for sale using our Services, you may choose to make certain personal information visible to other ${CompanyTitle} users. This may include your first name, last name, your email address, your location and your contact number. Please note, any information you provide to other users can always be shared by them with others so please exercise discretion in this respect.`,
    ],
  },
  {
    sectionTitle: "7. Where do we store your data and for how long?",
    sectionContent: [
      "1. The data we collect about you will be stored and processed in secure servers in order to provide the best possible user experience. For example – for fast website or mobile application build up.",
      "2. We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.",
      "3. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.",
    ],
  },
  {
    sectionTitle: "8. Your rights - data deletion requests",
    sectionContent: [
      "1. To exercise your privacy rights and choices, if you do not want us to process your data, then you can use the Data Deletion Requests feature to issue a request for its removal. ",
      `2. There may be situations where we cannot grant your request — for example, if you ask us to delete your transaction data and ${CompanyTitle} is legally obligated to keep a record of that transaction to comply with law.`,
    ],
  },
  {
    sectionTitle:
      "9. Technical and organizational measures and processing security",
    sectionContent: [
      `1. All the information we receive about you are stored on secure servers and we have implemented technical and organizational measures that are suitable and necessary to protect your personal data. ${CompanyTitle} continually evaluates the security of its network and adequacy of its internal information security program, which is designed to (a) help secure your data against accidental or unlawful loss, access or disclosure, (b) identify reasonably foreseeable risks to the security of the ${CompanyTitle} network, and (c) minimize security risks, including through risk assessment and regular testing. In addition, we ensure that all payment data are encrypted using SSL technology.`,
      "2. Please note, despite the measures we have implemented to protect your data, the transfer of data through the Internet or other open networks is never completely secure and there is a risk that your personal data may be accessed by unauthorized third parties.",
    ],
  },
  {
    sectionTitle: "10. Links to third-party websites",
    sectionContent: [
      "1. Our Platform may contain links to third party websites or apps. If you click on one of these links, please note that each one will have its own privacy policy. We do not control these websites/apps and are not responsible for those policies. When you leave our Platform, we encourage you to read the privacy notice of every website you visit.",
    ],
  },
  {
    sectionTitle: "*All your personal details are safe with us.",
    sectionContent: [
      `I have read, and accept the ${CompanyTitle} Terms of Use, and Privacy Policy. I consent to be contacted by ${CompanyTitle}.`,
    ],
  },
];
export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={styles.mmqDoc}>
        <div className={styles.docMain}>
          <div className={styles.docHeading}>
            <h1>Privacy Policy - EquipMedy</h1>
            <h3>Privacy Statement:</h3>
            <p>
              We care about your privacy and are committed to protecting your
              personal data. This privacy statement will inform you how we
              handle your personal data, your privacy rights and how the law
              protects you. Please read this privacy statement carefully before
              using our Services.
            </p>{" "}
            <br />
          </div>
          <div>
            {PrivacyPolicyData.map((data, index) => (
              <MmqDoc
                key={index}
                docSubHeading={data.sectionTitle}
                docSubHeadingP={data.sectionContent}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

// Refund policy
export const RefundPolicyData = [
  {
    sectionTitle: "1. Inspection Fee",
    sectionContent:
      "1. The inspection fee paid by the buyer is non-refundable.",
  },
  {
    sectionTitle: "2. Purchase Price Hold",
    sectionContent: [
      `1. The amount paid by the buyer for the purchase of medical equipment will be held by ${CompanyTitle} for a period of one week from the date of payment.",
      "2. The held amount will be released to the seller once the machinery is successfully installed at the address provided by the buyer.`,
    ],
  },
  {
    sectionTitle: "3. Non-Refundable Fees",
    sectionContent: [
      `1. Any amount paid by the buyer for dismantling, handling, delivery, and installation services provided by ${CompanyTitle} will be non-refundable.`,
      `2. If the buyer chooses to use the services mentioned in section 3.1, the respective fees are considered final and will not be refunded.`,
    ],
  },
  {
    sectionTitle: "4. Third-Party Involvement",
    sectionContent: [
      `1. In cases where a third party is involved in the dismantling, handling, delivery, or installation process, ${CompanyTitle} holds no liability for the safe delivery of the machinery.`,
      "2. In such cases, no platform fees or inspection fees will be refunded to the buyer.",
    ],
  },
  {
    sectionTitle: "5. Contact Information",
    sectionContent: [
      "1. For any inquiries regarding this Refund Policy, please contact us at <b>info@equipmedy.com.<b/>",
      "By using the Platform, you acknowledge that you have read, understood, and agree to the terms of this Refund Policy.",
    ],
  },
];

export const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.mmqDoc}>
        <div className={styles.docMain}>
          <div className={styles.docHeading}>
            <h1>Refund Policy - EquipMedy</h1>
            <p>
              This Refund Policy applies to the services provided by
              {CompanyTitle}.com ("{CompanyTitle}," "we," "us," or "our") in
              relation to the buying and selling of medical equipment on our
              platform. By using the Platform, you agree to the terms of this
              Refund Policy.
            </p>
          </div>
          <div>
            {RefundPolicyData.map((data, index) => (
              <MmqDoc
                key={index}
                docSubHeading={data.sectionTitle}
                docSubHeadingP={data.sectionContent}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
