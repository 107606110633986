import React, { useState } from "react";
import styles from "../../Admin/assets/css/ViewPage.module.css";
import { useLocation } from "react-router-dom";
import { UsersAddress } from "../../utils/UsersAddress";

export const DemoView = () => {
  const location = useLocation();
  const item = location?.state?.item;
  const [usersAddress, setUsersAddress] = useState({
    buyer: "",
    seller: "",
  });

  UsersAddress({
    buyer: item?.buyer,
    seller: item?.seller,
    setUsersAddress,
  });

  return (
    <section className={styles.viewPayment}>
      <h1>DEMO REQUEST MANAGEMENT</h1>

      <div className={styles.userDetails}>
        <div className={styles.data}>
          <h4>id</h4>
          <p>{item?.buyer}</p>
          <div className={styles.name}>
            <h4>BuyerName:</h4>
            <p>{`${item?.buyer_firstname} ${item?.buyer_lastname}`}</p>
          </div>
          <div className={styles.name}>
            <h4>Address:</h4>
            <p>{usersAddress?.buyer?.address}</p>
          </div>
        </div>
        <div className={styles.data}>
          <h4>id</h4>
          <p>{item?.seller}</p>
          <div className={styles.name}>
            <h4>Seller Name:</h4>
            <p>{`${item?.seller_firstname} ${item?.seller_lastname}`}</p>
          </div>
          <div className={styles.name}>
            <h4>Address:</h4>
            <p>{usersAddress?.seller?.address}</p>
          </div>
        </div>
      </div>
      {/* product details */}

      <div className={styles.productDetails}>
        <h1>Product Details</h1>
        <div className={styles.pdata}>
          <div className={styles.det}>
            <div className={styles.demodata}>
              <h2>Requiest Id</h2>
              <p>{item?.id}</p>
            </div>

            <div className={styles.demodata}>
              <h2>Date</h2>
              <p>{item?.date}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Equipm Name</h2>
              <p>{item?.equip_name}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Titel</h2>
              <p>{item?.title}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Product</h2>
              <p>{item?.product}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
