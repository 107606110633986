import React, { useEffect, useState } from "react";
// import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "../assets/css/utils.module.css";

import { scheduleBtn } from "../assets/images/index";
import { useDispatch } from "react-redux";
import {
  setCurrBuyStatus,
  setSheduleMeetingStatus,
} from "../app/Slices/UserData";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";

// export const Calander=(props)=>{
//     const dispatch =  useDispatch()
//     const [date,setDate]=useState(new Date());
//     const startTime  = date.toLocaleTimeString('en-US', { hour12: false })
//     const selectedDateTime = new Date(`2000-01-01 ${startTime}`);

//     // Add or subtract 1 hour (3600 seconds)
//     selectedDateTime.setHours(selectedDateTime.getHours() + 1);
//     const resultTime = selectedDateTime.toLocaleTimeString('en-US', { hour12: false });

//     const [time,setTime]=useState({start:startTime,end:resultTime});
//     const remindDateTime = new Date(`2000-01-01 ${time.start}`);
//     const startDateTime = new Date(`2000-01-01 ${time.start}`);

//     remindDateTime.setMinutes(remindDateTime.getMinutes() - 10);
//     const timeDiffSec = (startDateTime -remindDateTime)
//     const remider_time =  Math.floor(timeDiffSec % 3600000 / 60000)

//     const handleTime=(e)=>{
//         let inputObj=e.currentTarget;
//         setTime({...time,[inputObj.name]:inputObj.value});
//     }
//     const minDate=new Date();
//     const maxDate=new Date();
//     maxDate.setDate(maxDate.getDate()+6);
//     minDate.setDate(minDate.getDate()-1);

//     const isDateSelectable=(date)=>{
//         return date>=minDate && date<=maxDate;
//     }
//     const handleChange=(ldate)=>{
//         if(isDateSelectable(ldate)){
//             setDate(ldate);
//         }
//     }

//     const formattedDate = () => {
//         const year = date.getFullYear();
//         const month = (date.getMonth() + 1).toString().padStart(2, '0');
//         const day = date.getDate().toString().padStart(2, '0');
//         formatedDate = `${year}-${month}-${day}`;
//         return formatedDate;
//     };

//     useEffect(()=>{
//         formattedDate()
//     },[date])

//     const handleMeetSuccess = (e) =>{
//         e.preventDefault()
//         let data = {
//             date: formatedDate,
//             start_time: time.start,
//             end_time: time.end,
//             duration: formatted,
//             remind_me: remindDateTime.toLocaleTimeString('en-US', { hour12: false }),
//         }
//         dispatch(setSheduleMeetingStatus(data))
//         props.setSuccess(!props.success)
//         props.setMetScheduled(true)
//         dispatch(setCurrBuyStatus({curr:1}));
//     }

//     const getTimeDiff = (startT, endT) => {
//         const startTime = new Date(`2000-01-01 ${startT}`);
//         const endTime = new Date(`2000-01-01 ${endT}`);
//         const timeDiffInSeconds = (endTime - startTime) / 1000; // Convert milliseconds to seconds
//         const hours = Math.floor(timeDiffInSeconds / 3600);
//         const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
//         const seconds = Math.floor(timeDiffInSeconds % 60);
//         formatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
//         return { hours, minutes, seconds, formatted };
//       };

//     return(
//         <div className={styles.calanderCont}>
//             <Calendar
//                 onChange={handleChange}
//                 selectRange={false}
//                 className={styles.calanderCusttom}
//                 value={date}
//                 tileDisabled={({ date }) => !isDateSelectable(date)}
//             />
//             <div className={styles.calSummary}>
//                 <div className={styles.calSumTop}>
//                     <h1>{date.getDate()}</h1>
//                     <span>{getWeakName(date.getDay())}</span>
//                 </div>
//                 <div className={styles.pickSlot}>
//                     <p>Pick a Time slot</p>
//                     <div>
//                         <span>Start Time</span>
//                         <input type='time' name='start' onChange={handleTime} value={time.start}/>
//                     </div>
//                     <div>
//                         <span>End Time</span>
//                         <input
//                         type='time'
//                         name='end'
//                         onChange={handleTime}
//                         value={time.end}
//                         />
//                     </div>
//                     <div>
//                         <span>Duration</span>
//                         <input type='text' readOnly value={getTimeDiff(time.start,time.end).formatted}/>
//                     </div>

//                 </div>
//                 <div className={styles.remindMe}>
//                     <span>Remind Me</span>
//                     <input type='text' readOnly value={`${remider_time} Minutes Before`}/>
//                 </div>
//                 <div className={styles.calSubmit}>
//                     <img alt="..." style={{cursor:"pointer",marginTop:"30px"}} src={scheduleBtn} height="55px" onClick={handleMeetSuccess}/>
//                 </div>
//             </div>

//         </div>
//     );
// };

// const getWeakName=(weakno)=>{
//     let weaks=["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
//     return weaks[weakno];
// }

export const Calander = (props) => {
  let item = props?.item;
  const [termCond, setTermCond] = useState(true);

  const dispatch = useDispatch();
  const initialStartingTime = new Date().toLocaleDateString("default", {
    hour: "numeric",
    minute: "numeric",
  });
  const todayDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    handle12HourFormate();
  }, [initialStartingTime]);

  const handle12HourFormate = (time) => {
    if (!time) return "";
    const [hours, minutes] = time?.split(":");
    const isPM = parseInt(hours, 10) >= 12;

    let formattedHours = parseInt(hours, 10) % 12 || 12;
    formattedHours =
      formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    return `${formattedHours}:${minutes} ${isPM ? "PM" : "AM"}`;
  };

  const initialValues = {
    title: `Meeting schedule with ${item?.seller_firstname} for product id ${item?.id}.`,
    date: todayDate,
    startTime: handle12HourFormate(initialStartingTime?.split(" ")[1]),
    hr: "",
    min: "30",
  };
  const [form, setForm] = useState(initialValues);
  const [endTime, setEndTime] = useState("");
  const remindDateTime = new Date(`2000-01-01 ${form.startTime}`);

  remindDateTime.setMinutes(remindDateTime.getMinutes() - 10);
  let hour = [0];
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      setForm({ ...form, [name]: value });
    } else {
      setForm({ [name]: "" });
    }
  };

  // for (let i = 0; i <= 24; i++) {
  //   hour.push(i);
  // }

  const handleEndTime = () => {
    let startTimeObject = new Date(
      `2000-01-01 ${handle12HourFormate(form.startTime)}`
    );

    let endTimeObject = new Date(
      startTimeObject.getTime() +
        form.hr * 60 * 60 * 1000 +
        form.min * 60 * 1000
    );
    let duration = endTimeObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    setEndTime(duration);
  };

  useEffect(() => {
    handleEndTime();
  }, [form]);

  const handleMeetSuccess = (e) => {
    e.preventDefault();
    if (!termCond) {
      return;
    }
    if (form?.title === "") {
      toast.error("Please Enter valid input");
    } else if (form.hr === "" && form.min === "") {
      toast.error("Please Enter valid input");
    } else {
      let data = {
        title: form.title,
        date: form.date,
        start_time: `${handle12HourFormate(form?.startTime)?.split(" ")[0]}:00`,
        end_time: `${handle12HourFormate(endTime)?.split(" ")[0]}:00`,
        duration: `${form.hr ? form.hr : "00"}:${
          form.min ? form.min : "00"
        }:00`,
        remind_me: remindDateTime
          .toLocaleTimeString("en-US", { hour12: true })
          .split(" ")[0],
      };
      dispatch(setSheduleMeetingStatus(data));
      props.setSuccess(true);
      props.setScheduleMeeting(false);
      props.setRescheduled("");
      dispatch(setCurrBuyStatus({ curr: 1 }));
    }
  };

  return (
    <div className={styles.Calanderconst2}>
      <div>
        <div className={styles.title}>
          <label htmlFor="title">Title</label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Topic for Discussion"
            value={form?.title}
            onChange={handleChange}
          />
        </div>
        <div className={styles.CalendarDate}>
          <div className={styles.timeDate}>
            <div>
              <input
                type="date"
                name="date"
                id="date"
                value={form?.date}
                onChange={(e) => handleChange(e)}
                min={todayDate}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "87%",
                borderRadius: "4px",
              }}
            >
              <input
                type="time"
                name="startTime"
                id="startTime"
                value={handle12HourFormate(form?.startTime)?.split(" ")[0]}
                onChange={handleChange}
              />
              {/* {handle12HourFormate(form?.startTime)?.split(" ")[1]} */}
            </div>
          </div>

          {/* <span>to</span>
          <input type="time" placeholder="time" /> */}
          <div className={styles.meetingDuration}>
            {/* <span>Duration</span>
            <span>
              <select
                style={{ width: "150px", padding: "8px" }}
                name="hr"
                onChange={handleChange}
              >
                {hour.map((hr) => {
                  return (
                    <option className="selectOption" value={hr}>
                      {hr}
                    </option>
                  );
                })}
              </select>
            </span>
            <span>hr</span> */}
            <span>
              <select
                style={{ width: "150px", padding: "8px" }}
                name="min"
                value={form?.min}
                onChange={handleChange}
              >
                {[0, 15, 30, 45].map((min) => {
                  return (
                    <option className="selectOption" value={min}>
                      {min}
                    </option>
                  );
                })}
              </select>
            </span>
            <span>min</span>
          </div>
        </div>

        <div className={styles.CalanderDays}>
          {/* <div>
            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
            <span for="vehicle1">All Day </span>
          </div> */}
          {/* <div className={styles.globalLocation}>
            <span>
              <i className="bi bi-globe"></i>
            </span>
            <div className={styles.dropdown}>
              <p className={styles.dropbtn}>
                India
                <i className="bi bi-caret-down"></i>
              </p>
              <div className={styles.dropdownContent}>
                <a href="#">Link 1</a>
                <a href="#">Link 2</a>
                <a href="#">Link 3</a>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className={styles.repeatCle}>
          <b>Repeat</b>
          <div class={styles.dropdown}>
            <h6 class={styles.dropbtn}>
              Never
              <i class="bi bi-caret-down"></i>
            </h6>
            <div class={styles.dropdownContent}>
              <a href="#">Link 1</a>
              <a href="#">Link 2</a>
              <a href="#">Link 3</a>
            </div>
          </div>
        </div> */}

        {/* <div className={styles.attendees}>
          <label htmlFor="">Attendees(Optional)</label>
          <input type="text" placeholder="Email or name" />
        </div> */}

        <div className={styles.toogleT}>
          <div>
            <label class={styles.switch}>
              <input
                type="checkbox"
                checked={termCond}
                onChange={() => setTermCond(!termCond)}
              />
              <span class={styles.slider}>
                <span className={styles.round}></span>
              </span>
            </label>
          </div>
          <div>
            <p>
              I agree to all the Terms and condition given from myMediqueQuip.in{" "}
              <NavLink to="/termscondition">Terms & Condition</NavLink>.
            </p>
          </div>
        </div>
        <div className={styles.calSubmit}>
          <img
            alt="..."
            style={{
              cursor: "pointer",
              marginTop: "30px",
              filter: termCond ? "opacity(1)" : "opacity(0.5)",
            }}
            src={scheduleBtn}
            height="55px"
            onClick={handleMeetSuccess}
          />
        </div>
      </div>
    </div>
  );
};
