import { Route, Routes } from "react-router-dom";
import { URLProtected } from "../Admin/Common";
import { AdminLogin } from "../Admin/pages/AdminLogin";
import { AdminLayout } from "../layouts/AdminLayout";
import {
  UserList,
  ManufacturerList,
  DistributorList,
} from "../Admin/pages/UsersList";
import { OrderList } from "../Admin/pages/OrderList";
import { RequestList } from "../Admin/pages/ManageRequests";
import { ViewManageRequest } from "../Admin/pages/ViewManageRequest";
import { NewEquipList, UsedEquipList } from "../Admin/pages/Eqipment";
import { DashboardKpi } from "../Admin/pages/DahsboardKpi";
import { ScheduledMeetings } from "../Admin/pages/SheduledMeet";
import { Inspection } from "../Admin/pages/Inspection";
import { Categories } from "../Admin/pages/Categoreis";
import { Speciality } from "../Admin/pages/Speciality";
import { UserFrom } from "../Admin/pages/Forms/UserForm";
import { DistMFform } from "../Admin/pages/Forms/DistMFform";
import { EquipForm } from "../Admin/pages/Forms/EqipForm";
import { ScheduleMeetForm } from "../Admin/pages/Forms/ScheduleMeetForm";
import { InspectMeetForm } from "../Admin/pages/Forms/InspForm";
import { OrderForm } from "../Admin/pages/Forms/OrderForm";
import { AdmNotification } from "../Admin/pages/AdmNotification";
import { PaymentList } from "../Admin/pages/managePayments";
import { ViewPayment } from "../Admin/pages/ViewPayment";
import { SystemPrice } from "../Admin/pages/SysPrice";
import { BlogsArticles } from "../Admin/pages/content/BlogsArticle";
import React from "react";
import { AddContentForm } from "../Admin/pages/Forms/AddContentForm";
import { ContactList } from "../Admin/pages/ContactList";
import { RequestForm } from "../Admin/pages/Forms/RequestsForm";
import { ArrangeDemoList } from "../Admin/pages/ArrangeDemo";
import { DemoView } from "../Admin/pages/DemoView";



export const AdminRoute = () => {
  return (
    <Routes>
      <Route exact path="/admin/login/" element={<AdminLogin />} />

      <Route
        exact
        path="/admin/"
        element={<URLProtected component={<AdminLayout />} />}
      >
        <Route exact path="dashboard/" element={<DashboardKpi />} />

        <Route exact path="manageusers/" element={<UserList />} />
        <Route
          exact
          path="managemanufacturer/"
          element={<ManufacturerList />}
        />
        <Route exact path="managedistributor/" element={<DistributorList />} />
        <Route exact path="manageorder/" element={<OrderList />} />
        <Route exact path="managerequest/" element={<RequestList />} />
        <Route exact path="managerequest/view-manage-request/" element={<ViewManageRequest />} />


        <Route exact path="contactlist/" element={<ContactList />} />
        <Route exact path="demorequests/" element={<ArrangeDemoList />} />
        <Route exact path="demorequests/demoview/" element={<DemoView/>} />
        <Route exact path="managepayments/" element={<PaymentList />} />
        <Route exact path="managepayments/viewpayment/" element={<ViewPayment/>} /> 
        <Route exact path="managesystemprice/" element={<SystemPrice />} />
        <Route exact path="manageneweqip/" element={<NewEquipList />} />
        <Route exact path="manageusedeqip/" element={<UsedEquipList />} />
        <Route exact path="managemeetings/" element={<ScheduledMeetings />} />
        <Route exact path="manageinspection/" element={<Inspection />} />
        <Route exact path="managecategories/" element={<Categories />} />
        <Route exact path="managespeciality/" element={<Speciality />} />
        <Route exact path="notification/" element={<AdmNotification />} />

        {/* content management */}

        <Route
          exact
          path="blogs/"
          element={
            <BlogsArticles
              data={{ title: "Blogs", type: 3, addURL: "/admin/blogs/add/" }}
            />
          }
        />
        <Route
          exact
          path="blogs/add/"
          element={
            <AddContentForm
              data={{ title: "Blogs", type: 3, listURL: "/admin/blogs/" }}
            />
          }
        />

        <Route
          exact
          path="articles/"
          element={
            <BlogsArticles
              data={{
                title: "Articles",
                type: 5,
                addURL: "/admin/articles/add/",
              }}
            />
          }
        />
        <Route
          exact
          path="articles/add/"
          element={
            <AddContentForm
              data={{ title: "Articles", type: 5, listURL: "/admin/articles/" }}
            />
          }
        />

        <Route
          exact
          path="techupdates/"
          element={
            <BlogsArticles
              data={{
                title: "Tech Updates",
                type: 6,
                addURL: "/admin/techupdates/add/",
              }}
            />
          }
        />
        <Route
          exact
          path="techupdates/add/"
          element={
            <AddContentForm
              data={{
                title: "Tech Updates",
                type: 6,
                listURL: "/admin/techupdates/",
              }}
            />
          }
        />

        <Route
          exact
          path="experttips/"
          element={
            <BlogsArticles
              data={{
                title: "Expert Tips",
                type: 7,
                addURL: "/admin/experttips/add/",
              }}
            />
          }
        />
        <Route
          exact
          path="experttips/add/"
          element={
            <AddContentForm
              data={{
                title: "Expert Tips",
                type: 7,
                listURL: "/admin/experttips/",
              }}
            />
          }
        />

        {/* view users */}
        <Route exact path="manageusers/viewuser/" element={<UserFrom />} />
        <Route
          exact
          path="managemanufacturer/viewuser/"
          element={<DistMFform />}
        />
        <Route exact path="manageneweqip/viewuser/" element={<EquipForm />} />
        <Route
          exact
          path="managemeetings/view/"
          element={<ScheduleMeetForm />}
        />
        <Route
          exact
          path="manageinspection/view/"
          element={<InspectMeetForm />}
        />
        <Route exact path="manageorder/view/" element={<OrderForm />} />
        <Route exact path="contactlist/view/" element={<RequestForm />} />
      </Route>
    </Routes>
  );
};
