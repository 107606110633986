import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import { BlockToggle } from "./UsersList";
import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink, useNavigate } from "react-router-dom";
import common from "../assets/css/common.module.css";
import { Loader } from "../../components/Loader";
import { Pagination } from "../Common";
import { postData1 } from "../../services";

export const Inspection = () => {
  const navigate = useNavigate();
  const [inspectList, setInspectList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1("admin/inspection_list/", config, true, true);
      setInspectList(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);
  const handleView = (e, data) => {
    navigate("/admin/manageinspection/view/", { state: data });
  };
  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>INSPECTION MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>INSPECT ID</span>
          <span>PRODUCT</span>
          <span>BUYER</span>
          <span>SELLER</span>
          <span>STATUS</span>
          <span>DETAIL</span>
        </div>

        {inspectList?.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{data?.product}</span>
              <span>{data?.buyer_firstname}</span>
              <span>{data?.seller_firstname}</span>
              {/* <span>Mr Daniel</span> */}
              <span>
                <span className={common.customStyle}>
                  {data?.inspection_status === 1
                    ? "Pending"
                    : data?.inspection_status === 2
                    ? "Canceled"
                    : data?.inspection_status === 3
                    ? "Completed"
                    : ""}
                </span>
              </span>

              <span
                onClick={(e) => handleView(e, data)}
                style={{ cursor: "pointer" }}
              >
                View
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
