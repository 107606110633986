import { useLocation } from "react-router-dom";
import styles from "../../assets/css/aboutus/aboutDetail.module.css";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import axios from "axios";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const AboutDetail = () => {
  const { pathname } = useLocation();
  const [blogDetail, setBlogDetail] = useState(null);

  const sanitizedDescription = DOMPurify.sanitize(blogDetail?.description);

  useEffect(() => {
    // Ensure all images inside the injected HTML are responsive
    const images = document.querySelectorAll("p span");

    // Apply custom styles to images within the content
    images.forEach((img) => {
      img.style.maxWidth = "100%"; // Ensure images don't exceed the container's width
      img.style.height = "auto"; // Maintain aspect ratio
    });
  }, [sanitizedDescription]);

  const fetArticle = async () => {
    const pathArr = pathname.split("/");
    const uid = pathArr[pathArr.length - 2];
    const res = await axios.post(
      `https://mmq.mediquip.in/api/v1/master/list_content/`,
      JSON.stringify({ uid: uid }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (res?.status === 200) {
      setBlogDetail(res?.data.data[0]);
    }
  };

  useEffect(() => {
    fetArticle();
  }, [blogDetail]);

  const location = useLocation();
  const title = location?.state?.title;

  return (
    <div>
      {blogDetail ? (
        <div className={styles.aboutmore}>
          <div className={styles.aboutMoreMain}>
            <h1>{title ? title : "Blog"} Details</h1>
            <div>
              <img
                src={`${IMGURL}/mmq_apps/static/upload/content/${blogDetail?.image}`}
                alt=""
              />
              <div className={styles.aboutMoreContent}>
                <div className={styles.aboutMoreImg}>
                  <img
                    src={`${IMGURL}/mmq_apps/static/upload/content/${blogDetail?.image}`}
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    <span>
                      <i className="bi bi-calendar-day"></i>
                    </span>{" "}
                    {new Date(blogDetail?.created_date).toDateString()}
                  </p>
                </div>
              </div>
              <h2 style={{ textTransform: "capitalize" }}>
                {blogDetail?.name}
              </h2>
              <p
                style={{ textTransform: "capitalize" }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blogDetail?.description),
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}></div>
        </div>
      )}
    </div>
  );
};

// import { useParams } from "react-router-dom";
// import { useEffect, useState } from "react";
// import DOMPurify from "dompurify";
// import styles from "../../assets/css/aboutus/aboutDetail.module.css";
// import { getData } from "../services"; // Assume this fetches data from the API
// const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

// export const AboutDetail = () => {
//   const { id } = useParams(); // Get the id from the URL
//   const [blogData, setBlogData] = useState(null);

//   useEffect(() => {
//     // Fetch the blog data based on the ID
//     (async () => {
//       const res = await getData(`master/get_content/${id}`, {}, false, false);
//       if (res?.status_code === 200) {
//         setBlogData(res?.data); // Set the fetched data
//       }
//     })();
//   }, [id]);

//   if (!blogData) {
//     return <div>Loading...</div>; // Handle loading state
//   }

//   const imgURL = `${IMGURL}/mmq_apps/static/upload/content/${blogData?.image}`;
//   const sanitizedDescription = DOMPurify.sanitize(blogData?.description);

//   return (
//     <div className={styles.aboutmore}>
//       <div className={styles.aboutMoreMain}>
//         <h1>{blogData?.name || "Blog"} Details</h1>
//         <div>
//           <img src={imgURL} alt="" />
//           <div className={styles.aboutMoreContent}>
//             <div className={styles.aboutMoreImg}>
//               <img src={imgURL} alt="" />
//             </div>
//             <div>
//               <p>
//                 <span>
//                   <i className="bi bi-calendar-day"></i>
//                 </span>{" "}
//                 {new Date(blogData?.created_date).toDateString()}
//               </p>
//             </div>
//           </div>
//           <h2 style={{ textTransform: "capitalize" }}>{blogData?.name}</h2>
//           <p
//             style={{ textTransform: "capitalize" }}
//             dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

