import downIcon from "./down_icon.png";
import checkBox from './checkBox.png';
import searchIcon from './search.png';
import profileIcon from './profile.png';
import hero from './hero.png';
// import hero_1 from './hero1.1.jpg';
import pngwing from './pngwing.com.png';
import favouriate from './favourite.png';
import cart from './cart.png';
import newBanner from './newBanner.png';
import next_arrow from './next_arrow.png';
import prev_arrow from './previous_arrow.png';
import advt1 from './oximeter.png';
import advt2 from './sugar_machine.png';
import expert from './expert.png';
import suffix from './suffix.png';
import line2 from './Line2.png';
import line from './Line.png';
import swipetestleft from './swipeleftTest.png';
import nextArow from './nextArrow.png';
import testimage2 from './testimage1.png';
import video_Advt from './video_Advt.png';
import video2img from './video2img.png';
import spotify from './spotify.png';
import office from './office.png';
import adobe from './adobe.png';
import blender from './blender.png';
import twitter from './twitter.png';
import fb from './facebook.png';
import instagram from './instagram.png';
import linkdin from './linkedin.png';
import plus_symbol from './plus_circle.png';
import minus from './minus.png';
import loginBg from './loginbg.png';
import howWeWorks from './how_we_works.png';
import linkdinLogin from './linkedinLogin.png';
import fbllogin from './facebookLogin.png';
import googleLogin from './googlLogin.png';
import actimg from './atc.png';
import m_search from './m_search.png';
import dashAdvt from './dashad.png';
import pdShare from './pd_share.png';
import star from './star.png';
import location from './location.png';
import homeIcon from './home.png';
import rightMove from './righMove.png';
import relatedImg from './relatedCardImg.png';
import unfilStar from './unfillStar.png';
import mailIcon from './mail.png';
import phoneIcon from './phone-call.png';
import arrLeft from './arrowleft.png';
import Jaipur from './jaipur.png';
import postDropdown from './post_dropdown.png';
import ImageUpload from './ImageUpload.png';
import videoIcon from './videoPlay.webp';
import filledStar from './filledStar.png';
import mFlowChart from './mFlowChart.png';
import contBtn from './contBtn.png';
import atcBtn from './actBtn.png';
import whatsBtn from './whatsAppBtn.png';
import whatsApp2 from './whatsapp2.png';
import dummyMap from './mapDumy.png';
import twitter2 from './twitter3.png';
import email2 from './mail2.png';
import currency from "./indian-currency.jpg"
import meetingImg from './meeting.png';
import scheduleBtn from './schedulBtnImg.png';
import inspectionBtn from './inspection_btn.png';
import buyBtn from './buy_btn.png';
import meetSuccess from './meetSuccess.png';
import inspectImg from './inspectImg.png';
import meetIssued from './meetIssued.png';
import currLocat from './currenLocat.png';
import activeLoc from './activeLocation.png';
import decrease from './decrease.png';
import incrase from './increase.png';
import paytam from './paytm.png';
import upib from './upiB.png';
import findE from './process/findEquip.png';
import schedule from './process/schedule.png';
import inspection from './process/inspection.png';
import closeDeal from './process/closeDeal.png';
import shipped from './process/shipping.png';
import handling from './process/install.png';
import amc from './process/amc.png';
import manuSearch from './menuSeach.png';
import searchDrop from './searDrop.png';
import brand1 from './manufacture/div.v-image__image.png';
import philips from './manufacture/philips.png';
import bussWithUs from './bussWithus.png';
import manIcon from './manIcon.png';
import filterMF from './filter.png';
import sortMf from './sortBy.png';
import serviceImg from './serviceImg.png';
import serviceMail from './Servic_mail.png';
import contactbg from './contactbg.png';
import cancel from "./cancelBtn.png";
import profileImg from "./profile-img.png"

// aboutus
import herobg1 from './aboutus/herobg1.png';
import herobg2 from './aboutus/herobg2.png';
import herobg3 from './aboutus/herobg3.png';
import herobg4 from './aboutus/herobg4.png';
import herobg5 from './aboutus/herobg5.png';

// aboutus equipment icons
import abi1 from './aboutus/abi1.png';
import abi2 from './aboutus/abi2.png';
import abi3 from './aboutus/abi3.png';
import abi4 from './aboutus/abi4.png';
import abi5 from './aboutus/abi5.png';
import abi6 from './aboutus/abi6.png';
import abi7 from './aboutus/abi7.png';

// aboutus brands
import brand_1 from './aboutus/brand1.png';
import brand_2 from './aboutus/brand2.png';
import brand_3 from './aboutus/brand3.png';
import brand_4 from './aboutus/brand4.png';
import brand_5 from './aboutus/brand5.png';
import brand_6 from './aboutus/brand6.png';
import brand_7 from './aboutus/brand7.png';
import brand_8 from './aboutus/brand8.png';

//who we are
import arewe1 from './aboutus/arewe1.png';
import arewe2 from './aboutus/arewe2.png';
import arewe3 from './aboutus/arewe3.png';

//about mission
import eclipse1 from './aboutus/Ellipse1.png';
import eclipse1Act from './aboutus/elipse1act.png';
import eclipse2 from './aboutus/Ellipse2.png';
import eclipse2Act from './aboutus/Ellipse2Act.png';
import eclipse3Act from './aboutus/Ellipse3Act.png';
import eclipse3 from './aboutus/Ellipse3.png';
import mmqCircle from './aboutus/MEDIEQUIPE_circle.png';
import mission_icon from './aboutus/vision_icon.png';
import vision_icon from './aboutus/visionicon.png';
import value_icon from './aboutus/valuesicon.png';
import sellBanner from './aboutus/selllAbout.png';

import WhyChooseText from './aboutus/Why to choose MyMedieQuip.png';
import reason1 from './aboutus/reason1.png';
import reason2 from './aboutus/reason2.png';
import reason3 from './aboutus/reason3.png';
import reason4 from './aboutus/reason4.png';
import reason5 from './aboutus/reason5.png';
import reason6 from './aboutus/reason6.png';
import reason7 from './aboutus/reason7.png';
import backBanner from './aboutus/backBanner.png';
import aboutSellBanner from "./aboutus/MyMediequip .png"

import connectProf from './aboutus/connectProfile.png';

//user Dashboard 
import asset1 from './Vector.png';
import asset2 from './Group0.png';
import asset3 from './Group1.png';
import asset4 from './Group2.png';
import asset5 from './Group4.png';
import asset6 from './Group5.png';
import asset7 from './Group6.png';
import asset8 from './Logout.png';
import asset9 from './testimage1.png';
import editpen from './edit-2.png';
import orderListImg from './orderList1.png';
import orderStatusImag1 from './download.png';
import orderStatusImag2 from './orderList1.png';
import orderStatusImag3 from './helpicon.png';
import notification from "./notification.png";

export {
    // user Dashboard
    cancel,
    orderStatusImag1,
    orderStatusImag2,
    orderStatusImag3,
    orderListImg,
    asset1,
    asset2,
    asset3,
    asset4,
    asset5,
    asset6,
    asset7,
    asset8,
    asset9,
    editpen,
    profileImg,

    connectProf,
    WhyChooseText,
    reason1,
    reason2,
    reason3,
    reason4,
    reason5,
    reason6,
    reason7,
    // about mission
    eclipse1,
    eclipse1Act,
    eclipse2Act,
    eclipse3Act,
    eclipse2,
    eclipse3,
    mmqCircle,
    mission_icon,
    vision_icon,
    value_icon,
    sellBanner,

    // aboutusaboutus
    herobg1,
    herobg2,
    herobg3,
    herobg4,
    herobg5,

    // about us icon
    abi1,
    abi2,
    abi3,
    abi4,
    abi5,
    abi6,
    abi7,

    // about us brand
    brand_1,
    brand_2,
    brand_3,
    brand_4,
    brand_5,
    brand_6,
    brand_7,
    brand_8,
    aboutSellBanner,
    // who we are
    arewe1,
    arewe2,
    arewe3,

    contactbg,
    serviceMail,
    serviceImg,
    filterMF,
    sortMf,
    manIcon,
    bussWithUs,
    brand1,
    philips,
    searchDrop,
    manuSearch,
    currency,
    findE,
    schedule,
    inspection,
    closeDeal,
    handling,
    amc,
    shipped,
    paytam,
    upib,
    decrease,
    incrase,
    buyBtn,
    currLocat,
    activeLoc,
    meetIssued,
    inspectImg,
    meetSuccess,
    inspectionBtn,
    scheduleBtn,
    meetingImg,
    twitter2,
    email2,
    whatsApp2,
    dummyMap,
    contBtn,
    atcBtn,
    whatsBtn,
    filledStar,
    mFlowChart,
    videoIcon,
    postDropdown,
    ImageUpload,
    arrLeft,
    Jaipur,
    mailIcon,
    phoneIcon,
    unfilStar,
    relatedImg,
    rightMove,
    homeIcon,
    location,
    star,
    pdShare,
    dashAdvt,
    m_search,
    actimg,
    linkdinLogin,
    fbllogin,
    googleLogin,
    loginBg,
    howWeWorks,
    twitter,
    fb,
    instagram,
    linkdin,
    spotify,
    office,
    adobe,
    blender,
    downIcon,
    checkBox,
    searchIcon,
    profileIcon,
    hero,
    // hero_1,
    pngwing,
    favouriate,
    cart,
    newBanner,
    next_arrow,
    prev_arrow,
    advt1,
    advt2,
    expert,
    suffix,
    line2,
    testimage2,
    line,
    swipetestleft,
    nextArow,
    video_Advt,
    video2img,
    plus_symbol,
    minus,
    notification
};