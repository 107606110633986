import React, { useEffect, useRef, useState } from "react";
import styles from "../../../assets/css/user/buyer_seller/profile.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { Logout } from "../../../components/Navigation";
import {
  asset1,
  asset2,
  asset3,
  asset4,
  asset5,
  asset6,
  asset7,
  asset8,
  testimage2,
  editpen,
  profileIcon,
  homeIcon,
  rightMove,
  downIcon,
} from "../../../assets/images";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailSchema,
  fnameSchema,
  gstinSchema,
  lnameSchema,
  mnumberSchema,
  nationalitySchema,
  pancardSchema,
} from "../../../utils/validation";
import { postData, postData1 } from "../../../services";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getProfileImage } from "../../../app/Slices/UserData";
import { Loader } from "../../../components/Loader";
import { mergeAddress } from "../../../utils/purefun";
import { DelieveryAddress } from "../../Buy/Checkout";

const imagePreviewUrl = process.env.REACT_APP_IMAGE_PREVIEW;

export const MyProfile = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [blob, setblob] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [loader, setLoader] = useState(true);
  const [defaultAddress, setdefaultAddress] = useState("");
  const [addressTab, setAddressTab] = useState(false);

  const formik = useFormik({
    initialValues: {
      fname: "",
      pnumber: "",
      nationality: "",
      gstin: "",
      Lname: "",
      email: "",
      Pancard: "",
      describe: "",
    },
    validationSchema: yup.object({
      fname: fnameSchema,
      // Lname: lnameSchema,
      pnumber: mnumberSchema,
      // email: emailSchema,
      // gstin: gstinSchema,
      // Pancard: pancardSchema,
      // nationality: nationalitySchema,
    }),
    onSubmit: function (values) {
      handleSubmitForm(values);
    },
  });

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const parseDetails = JSON.parse(userDetails);
    }
  }, []);

  const handleUserDetails = async () => {
    const res = await postData("users/get_user_detail/", "", true);
    if (res?.status) {
      setPreviewImage(res?.data?.profile?.image);
      dispatch(getProfileImage(res?.data?.profile?.image));
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          number: res?.data?.mobile,
          fName: res?.data?.profile?.first_name,
          lName: res?.data?.profile?.last_name,
          email: res?.data?.email,
          uid: res?.data?.uid,
          id: res?.data?.id,
          type: res?.data?.usertype,
          profileImg: res?.data?.profile?.profile_image,
        })
      );
      formik.setValues({
        fname: res?.data?.profile?.first_name,
        pnumber: res?.data?.mobile,
        nationality: res?.data?.profile?.location,
        gstin: res?.data?.profile?.gstin,
        Lname:
          res?.data?.profile?.last_name !== "undefined"
            ? res?.data?.profile?.last_name
            : "",
        email: res?.data?.email,
        Pancard: res?.data?.profile?.pan_no,
        // describe :res?.data?.profile?.describe
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    handleUserDetails();
    let user = localStorage.getItem("uid");
    // Getting user default address
    (async () => {
      const res = await postData1(
        "users/address_list/",
        { user: user, is_default: 1 },
        true
      );
      if (res?.status_code === 200) {
        setdefaultAddress(res?.data[0]);
      }
    })();
  }, [!!!addressTab]);

  const handleSubmitForm = async (val) => {
    const formData = new FormData();
    formData.append("first_name", val?.fname);
    formData.append("mobile", val?.pnumber);
    if (val?.Lname) {
      formData.append("last_name", val?.Lname);
    }
    if (val?.email) {
      formData.append("email", val?.email);
    }
    if (val?.nationality) {
      formData.append("location", val?.nationality);
    }
 console.log("ssssssss", selectedFile)
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    const res = await postData("users/add_profile/", formData, true);
    if (res?.status) {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          fName: res?.data?.first_name,
          lName: res?.data?.last_name,
          profileImg: res?.data?.profile_image,
        })
      );
      setSelectedFile(null);
      toast.success("Profile updated successfully !");
      handleUserDetails();
      setLoader(false);
    } else {
      toast.error(res?.msg);
    }
    setEditMode(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log("ssssssss", selectedFile)
    const imageUrl = URL?.createObjectURL(file);
    setblob(imageUrl);
  };

  const resetForm = () => {
    setEditMode(true);
    handleUserDetails();
    formik.resetForm({
      values: formik.initialValues,
    });
  };

  const handleLinkClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <ToastContainer />
      <section className={styles.profileRight}>
        {addressTab ? (
          <DelieveryAddress
            lat={defaultAddress?.latitude}
            lng={defaultAddress?.longitude}
            details={{ inspection_price: false }}
            profile={true}
            setAddressTab={setAddressTab}
          />
        ) : (
          <div className={styles.row}>
            <div className={styles.cardTop}>
              <h2>PERSONAL INFORMATION</h2>
              <button
                onClick={() => setEditMode(!editMode)}
                type="button"
                className={styles.btn2}
              >
                <span>Edit Profile</span>
                {/* <img src={editpen} alt="edit" /> */}
                <span>
                  <i class="bi bi-pencil-square"></i>
                </span>
              </button>
            </div>

            <form
              action="upload_endpoint"
              method="POST"
              encType="multipart/form-data"
              onSubmit={formik.handleSubmit}
            >
              <div className={styles.column1}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${
                      blob
                        ? blob
                        : previewImage
                        ? `${imagePreviewUrl}${previewImage}`
                        : profileIcon
                    })`,
                  }}
                ></div>
                <div className={styles.cardTop}>
                  <div className={styles.profileImg}>
                    {/* <img
                  src={editpen}
                  style={{ height: "fit-content" }}
                  alt="edit"
                /> */}
                    <a
                      className={styles.imgEdit}
                      style={{ cursor: "pointer", margin: "0px" }}
                      onClick={handleLinkClick}
                    >
                      Edit Profile image <i class="bi bi-pencil-square"></i>
                    </a>
                  </div>
                  <a
                    className={styles.imgEdit2}
                    style={{ cursor: "pointer", margin: "0px" }}
                    onClick={handleLinkClick}
                  >
                    <spa>
                      <i class="bi bi-pencil-square"></i>
                    </spa>{" "}
                  </a>
                  <button
                    onClick={() => setEditMode(!editMode)}
                    type="button"
                    className={styles.btn3}
                  >
                    <span>Update Profile</span>
                    <i class="bi bi-pencil-square"></i>
                    {/* <img src={editpen} alt="edit" /> */}
                  </button>
                </div>
                <input
                  disabled={editMode}
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {/* <h4 className={styles.discribe}>WHATS BEST DECRIBES YOU</h4>
              <div className={styles.radios}>
                <div>
                  <input
                    className={styles.rd}
                    disabled={editMode}
                    type="radio"
                    value="buyer"
                    checked={formik.values.describe === "buyer"}
                    name="describe"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className={styles.rdt}>BUYER</label>
                </div>
                <div>
                  <input
                  disabled={editMode}
                    className={styles.rd}
                    type="radio"
                    value="seller"
                    checked={formik.values.describe === "seller"}
                    name="describe"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label className={styles.rdt}>SELLER</label>
                </div>
              </div> */}
              </div>
              <div className={styles.column2}>
                <div className={styles.colDiv}>
                  <div className={styles.col21}>
                    <label className={styles.name}>
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled={editMode}
                      className={styles.nameField}
                      type="text"
                      name="fname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fname}
                    />
                    {formik.errors.fname && formik.touched.fname && (
                      <div style={{ color: "red" }}>{formik.errors.fname}</div>
                    )}

                    {/* number -- */}

                    <label className={styles.nameC}>Last Name</label>
                    <input
                      disabled={editMode}
                      className={styles.nameField}
                      type="text"
                      name="Lname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Lname}
                    />
                    {/* {formik.errors.Lname && formik.touched.Lname && (
                    <div style={{ color: "red" }}>{formik.errors.Lname}</div>
                  )} */}

                    <label className={styles.name}>City</label>
                    <input
                      disabled={editMode}
                      className={styles.nameField}
                      type="text"
                      name="nationality"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.nationality}
                    />
                    {/* {formik.errors.nationality && formik.touched.nationality && (
                    <div style={{ color: "red" }}>Please enter your city</div>
                  )} */}
                    {/* <label className={styles.name}>GSTIN Number</label>
                <input disabled={editMode} className={styles.nameField} type="text" name="gstin" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.gstin}  /> */}
                    {/* {formik.errors.gstin && formik.touched.gstin && (<div style={{color : 'red'}}>{formik.errors.gstin}</div>)} */}
                    {/* <div className={styles.btnContainer}>
                  <button   type="submit" disabled={editMode} className={styles.btn}>
                    UPDATE INFO
                  </button>
                  <button type="button" disabled={editMode} className={styles.btn2}>
                    DISCARD CHANGE
                  </button>
                </div> */}
                  </div>

                  {/* Last n */}
                  <div className={styles.col22}>
                    <label className={styles.name}>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled={editMode}
                      className={styles.nameField}
                      type="number"
                      name="pnumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pnumber}
                    />
                    {formik.errors.pnumber && formik.touched.pnumber && (
                      <div style={{ color: "red" }}>
                        {formik.errors.pnumber}
                      </div>
                    )}
                    <label className={styles.nameC}>Email</label>
                    <input
                      disabled={editMode}
                      className={styles.nameField}
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {/* {formik.errors.email && formik.touched.email && (
                    <div style={{ color: "red" }}>{formik.errors.email}</div>
                  )} */}
                    {/* <label className={styles.nameC}>Pan Card Number <span style={{color : "red"}}></span></label>
                <input disabled={editMode} className={styles.nameField} type="text" name="Pancard" onChange={formik.handleChange}  onBlur={formik.handleBlur} value={formik.values.pancard} />
                {formik.errors.Pancard && formik.touched.Pancard && (<div style={{color : 'red'}}>{formik.errors.Pancard}</div>)} */}

                    <label className={styles.nameC}>
                      Address{" "}
                      <i
                        style={{ cursor: "pointer" }}
                        onClick={() => setAddressTab(!addressTab)}
                        className="bi bi-pencil-square"
                      ></i>
                    </label>
                    <input
                      className={styles.nameField}
                      disabled={true}
                      value={mergeAddress(defaultAddress)}
                    />
                  </div>
                </div>

                <div className={styles.btnContainer}>
                  {!editMode && (
                    <p onClick={resetForm} className={styles.discardBtn}>
                      Discard Changes
                    </p>
                  )}
                  <button
                    type="submit"
                    disabled={editMode}
                    className={styles.btn}
                  >
                    SAVE CHANGES
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {loader && <Loader />}
      </section>
    </>
  );
};

export const DashboardMenu = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const profileLinks = [
    // {title:"Profile",img:asset1,path:"/profile/"},
    { title: "Distributor dashborad", img: homeIcon, path: "/distributor/dashboard/" },
    { title: "My ADS", img: asset2, path: "/profile/ads/" },
    { title: "Notification", img: asset3, path: "/profile/notifications/" },
    { title: "Payment History", img: asset4, path: "/profile/paymen-history" },
    { title: "My Orders", img: asset5, path: "/profile/order-list/" },
    {
      title: "Scheduled Meetings",
      img: asset6,
      path: "/profile/shedule-meeting-list/",
    },
  ];

  const profileLinks1 = [
    // {title:"Profile",img:asset1,path:"/profile/"},
    { title: "My ADS", img: asset2, path: "/profile/ads/" },
    { title: "Notification", img: asset3, path: "/profile/notifications/" },
    { title: "Payment History", img: asset4, path: "/profile/paymen-history" },
    { title: "My Orders", img: asset5, path: "/profile/order-list/" },
    {
      title: "Scheduled Meetings",
      img: asset6,
      path: "/profile/shedule-meeting-list/",
    },
  ];
  const [allLinks, setAllLinks] = useState(userDetails?.type === 1 || userDetails?.type === 2 ? profileLinks : profileLinks1);
  const [showMenu, setMenu] = useState(false);
  const [selectedLink, setLink] = useState({
    title: "Personal Information",
    img: asset1,
    path: "/profile/",
  });

  const toggleMenu = () => {
    if (window.innerWidth > 649) {
      return;
    }
    setMenu(!showMenu);
  };
  const linkClick = (e, index) => {
    if (window.innerWidth > 649) {
      return;
    }
    let curr = selectedLink;
    setLink(allLinks[index]);
    allLinks[index] = curr;
    setAllLinks([...allLinks]);
    setMenu(false);
  };
  useEffect(() => {
    if (window.innerWidth > 649) {
      setMenu(true);
    }
  }, []);

  return (
    <aside className={styles.profileSidebar}>
      <h1 style={{ textAlign: "start", padding: "0px 55px" }}>MY PROFILE</h1>
      <div className={styles.dashPath}>
        <NavLink to="/">
          <img src={homeIcon} height="20px" alt="home" />
        </NavLink>
        <img src={rightMove} alt="home" />
        <span>My Profile</span>
      </div>

      <div className={styles.profileNav}>
        <ul>
          <li onClick={toggleMenu} id={styles.selectedMenu}>
            <NavLink
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={styles.navLink}
              to={selectedLink.path}
            >
              {" "}
              <span>
                <img src={selectedLink.img} alt="..." />
              </span>
              {selectedLink.title}
              <img className={styles.downIcon} src={downIcon} alt="..." />
            </NavLink>
          </li>
          {showMenu &&
            allLinks?.map((value, index) => {
              return (
                <li onClick={(e) => linkClick(e, index)} key={index}>
                  <NavLink
                    style={activateLink}
                    className={styles.navLink}
                    to={value.path}
                  >
                    {" "}
                    <span>
                      <img src={value.img} alt="..." />
                    </span>
                    {value.title}
                  </NavLink>
                </li>
              );
            })}

          <li>
            <span className={styles.plogout}>
              <img src={asset8} alt="" />
              <Logout />
            </span>
          </li>
        </ul>
      </div>
    </aside>
  );
};

// non components functions
const activateLink = ({ isActive }) => {
  return {
    // backgroundColor:isActive?"#4D4D4D":"#FFFFFF",
    color: isActive ? "#000000" : "#8A8C88",
  };
};
