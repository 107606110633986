import React, { useEffect, useState } from "react";
import {
  pngwing,
  currency,
  ImageUpload,
  postDropdown,
} from "../../../assets/images/index";
import styles from "../../../assets/css/user/buyer_seller/ads.module.css";
import { postData1 } from "../../../services/index";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import Pagination from "../../../utils/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPList } from "../../../app/Slices/ProdListSlice";
import { CompanyTitle } from "../../../assets/data/data";

const MyAds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pList = useSelector((state) => state.plist.prodListData.user_plist);
  const userId = JSON.parse(localStorage.getItem("userDetails"))
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNO] = useState(1);

  const handleEditForm = (data) => {
    navigate(`/profile/ads/${encodeURIComponent(data?.equip_name)}/`, {
      state: { item: data },
    });
  };

  useEffect(() => {
    dispatch(fetchUserPList(userId?.uid, pageNo, 6));
    setLoader(false);
  }, [pageNo]);

  return (
    <div className={styles.main_content}>
      {pList?.data?.length > 0 ? (
        pList?.data?.map((item) => {
          return <MyAdsCard item={item} handleEditForm={handleEditForm} />;
        })
      ) : (
        <div className={styles.mfEmpty}>No Data Found</div>
      )}
      {loader && <Loader />}
      {pList?.data?.length > 0 && (
        <Pagination
          setPageNO={setPageNO}
          pageNo={pageNo}
          totalPage={pList?.total_pages}
          totalItem={pList?.total}
        />
      )}
    </div>
  );
};
export default MyAds;

const MyAdsCard = ({ item, handleEditForm }) => {
  const [is3dot, set3dot] = useState(false);
  const handle3dot = () => {
    set3dot(!is3dot);
  };
  const handleProductDeactivate = async () => {
    let body = {
      product_id: item?.id,
      post_type: item?.post_type,
      product_status: 4,
    };
    const res = await postData1("product/add/", body, true, false);
    if (res?.status_code === 200) {
      toast.success(
        `roduct deactivated successful, to live the product reach out to ${CompanyTitle} support ! `
      );
    } else {
      toast.error("Something went wrong, pls try after some time !");
    }
  };

  return (
    <div className={styles.sub_content} onMouseLeave={() => set3dot(false)}>
      <ToastContainer />
      <div className={styles.sub_content1}>
        <img
          src={
            item?.product_images[0]?.product_images
              ? item?.product_images[0]?.product_images
              : pngwing
          }
          alt=""
          className={styles.content_img}
        />
        <div className={styles.sub_content_text}>
          <span className={styles.content_text}>
            <h3>{item?.equip_name}</h3>
            <span className={styles.new_text}>
              {item?.post_type == 1
                ? "PRE-OWNED"
                : item?.post_type == 2
                ? "NEW"
                : item?.post_type == 3
                ? "SPARE & ACCESSORIES"
                : "SERVICES"}
            </span>
          </span>
          <p style={{ marginBottom: "10px" }}>
            {`${item?.seller_firstname}${" "}${item?.seller_lastname}`}
          </p>
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
          <p>
            {}
            {item?.description === "null"
              ? item?.other_details?.length > 260
                ? `${item?.other_details?.slice(0, 260)}`
                : item?.other_details
              : item?.description?.length > 260
              ? `${item?.description?.slice(0, 260)}`
              : item?.description}
          </p>
        </div>
      </div>
      <div className={styles.sub_content_status}>
        <div className=" flex flex-row xl:flex-col items-center justify-between">
          <p style={{ display: "flex", alignItems: "center" }}>
            <img src={currency} style={{ width: "24px", height: "24px" }} />{" "}
            {Number(item?.asking_price)?.toFixed(2)}
          </p>
          <button className={styles.sub_status}>
            {item?.product_status === 1
              ? "Live"
              : item?.product_status === 2
              ? "Lock"
              : item?.product_status === 3
              ? "Selled"
              : item?.product_status === 4
              ? "Pending"
              : "No"}
          </button>
        </div>
      </div>
      <div className={styles.select_type}>
        {is3dot && (
          <span>
            {/* <p onClick={() => handleEditForm(item?.uid)}>Edit</p> */}
            <p onClick={() => handleEditForm(item)}>
              {item?.product_status === 4 ? "Edit" : "View"}
            </p>
            {/* <p>Delete</p> */}
            {item?.product_status === 1 ? (
              <p onClick={handleProductDeactivate}>Deactivate</p>
            ) : (
              ""
            )}
          </span>
        )}
        <i className="bi bi-three-dots-vertical" onClick={handle3dot}></i>
      </div>
    </div>
  );
};
