import React, { useEffect, useState } from "react";
import { postData } from "../services";
import axios from "axios";

const API_KEY = process.env.REACT_APP_ADDRESS_KEY;

const ProdAddress = (props) => {
  const [address, setAddress] = useState({});
  console.log(props?.address);
  const [error, setError] = useState(null);
  const [geolocation, setGeoLocation] = useState({
    lat: undefined,
    long: undefined,
  });

  const fetchAddress = async (lat, long) => {
    try {
      const API_URL = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json&apiKey=${API_KEY}`;
      const response = await axios.get(API_URL);
      setAddress(response?.data);
    } catch (error) {
      setError(error?.message);
    }
  };

  useEffect(() => {
    if (geolocation?.lat !== undefined && geolocation?.long !== undefined) {
      fetchAddress(geolocation?.lat, geolocation?.long);
    }
  }, [geolocation?.lat, geolocation?.long]);

  const handleProdAddress = async (address) => {
    const formData = new FormData();
    formData.append("uid", address);
    try {
      const res = await postData("users/address_list/", formData, true);
      if (res?.status) {
        setAddress(res?.data[0]);
      }else{
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            setGeoLocation({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
          });
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    if (props?.address) {
      handleProdAddress(props?.address);
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          setGeoLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        });
      }
    }
  }, [props?.address]);

  if (error) {
    return <>{`Error: ${error}`}</>;
  }

  return (
    <>
      {address && (
        <>{`${
          address?.city ? address?.city : address.address?.state_district
        }, ${address?.state ? address?.state : address.address?.state}`}</>
      )}
    </>
  );
};

export default ProdAddress;
