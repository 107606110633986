import React, { useState } from "react";
import styles from "../../assets/css/forms.module.css";
import defaultProfile from "../../../assets/images/testimage1.png";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { postData1 } from "../../../services";
import {
  emailSchema,
  fnameSchema,
  gstinSchema,
  lnameSchema,
  mnumberSchema,
  nationalitySchema,
  pancardSchema,
} from "../../../utils/validation";

export const UserFrom = () => {
  const [formDisabled, setFormDisabled] = useState(true);
  const location = useLocation();
  const userData = location.state;
  const [userStatus, setUserStatus] = useState(userData?.status);
  const [perm, updatePerm] = useState(true);

  const formik = useFormik({
    initialValues: {
      fname: userData?.profile?.first_name,
      pnumber: userData?.mobile,
      nationality: userData?.profile?.location,
      gstin: userData?.profile?.gstin,
      lname: userData?.profile?.last_name,
      email: userData?.email,
      pancard: userData?.profile?.pan_no,
    },
    // validationSchema: yup.object().shape({
    //     fname  : fnameSchema,
    //     lname : lnameSchema,
    //     pnumber : mnumberSchema,
    //     email : emailSchema,
    //     gstin : gstinSchema,
    //     pancard : pancardSchema,
    //     nationality : nationalitySchema
    // }),
    onSubmit: function (values) {
      // handleSubmit(values);
    },
  });

  const handlePermission = async () => {
    let body = { uid: userData?.uid, status: userData?.status ? 0 : 1 };
    const res = await postData1("admin/update_user_status/", body, true, true);
    if (res?.status_code === 200) {
      updatePerm(true);
      setUserStatus(body.status);
      toast.success("Permission updation successful");
    } else {
      toast.warning("Something Went Wrong, Try Again !");
    }
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      {/* <h2>Detail Info <i onClick={()=>setFormDisabled(!formDisabled)} className="bi bi-pencil-square"></i> </h2> */}
      <form className={styles.updateForm} disabled={true}>
        <div className={styles.formProfile}>
          <label
            for="image"
            style={{ backgroundImage: `url(${defaultProfile})` }}
          />
          <input
            disabled={formDisabled}
            type="file"
            name="image"
            id="image"
            accept="image/*"
          />
        </div>
        <div className={styles.formFieds}>
          <div>
            <p>First Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="fname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fname}
            />
          </div>
          <div>
            <p>Last Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="lname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lname}
            />
          </div>
          <div>
            <p>Phone No</p>
            <input
              disabled={formDisabled}
              type="number"
              name="pnumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pnumber}
            />
          </div>
          <div>
            <p>Email</p>
            <input
              disabled={formDisabled}
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div>
            <p>City</p>
            <input
              disabled={formDisabled}
              type="text"
              name="nationality"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nationality}
            />
          </div>
          <div>
            <p>Pan</p>
            <input
              disabled={formDisabled}
              type="text"
              name="pancard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pancard}
            />
          </div>
          <div>
            <p>GSTIN</p>
            <input
              disabled={formDisabled}
              type="text"
              name="gstin"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gstin}
            />
          </div>
        </div>
        {/* <input disabled={formDisabled} className={styles.submitTab} type="submit" value="Save & Update"/> */}

        {perm && (
          <p onClick={handlePermission} className={styles.submitTab}>
            {userStatus === 1 ? "Block" : "Unblock"}
          </p>
        )}
      </form>
    </section>
  );
};
