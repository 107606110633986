import React, { useEffect, useState } from "react";
import Styles from "../assets/css/utils.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setFullAddAddress } from "../app/Slices/ProdAddSlice";
const AddressModel = () => {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const addressList = useSelector(
    (state) => state.addProd.prodAddData.addAddress
  );
  const handleAddressForm = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      dispatch(
        setFullAddAddress({
          number: name === "number" ? value : addressList.number,
          email: name === "email" ? value : addressList.email,
          address: name === "address" ? value : addressList.address,
          city: name === "city" ? value : addressList.city,
          landmark: name === "landmark" ? value : addressList.landmark,
          zip_code: name === "zip_code" ? value : addressList.zip_code,
          State: name === "State" ? value : addressList.State,
        })
      );
    } else {
      dispatch(
        setFullAddAddress({
          number: name === "number" ? "" : addressList.number,
          email: name === "email" ? "" : addressList.email,
          address: name === "address" ? "" : addressList.address,
          city: name === "city" ? "" : addressList.city,
          landmark: name === "landmark" ? "" : addressList.landmark,
          zip_code: name === "zip_code" ? "" : addressList.zip_code,
          State: name === "State" ? "" : addressList.State,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      setFullAddAddress({
        number: userDetails?.number !== null ? userDetails?.number : "",
        email: userDetails !== null ? userDetails.email : "",
      })
    );
  }, []);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <form>
      <div className={Styles.addressForm}>
        <label htmlFor="number">Phone Number:</label>
        <input
          type="text"
          value={addressList?.number || ""}
          placeholder="Number"
          id="number"
          name="number"
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="number">Email Id:</label>
        <input
          type="text"
          value={addressList?.email || ""}
          placeholder="Email"
          id="email"
          name="email"
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          value={addressList?.address}
          placeholder="address"
          id="address"
          name="address"
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="landmark">Address1:</label>
        <input
          type="text"
          placeholder="Address1"
          id="landmark"
          name="landmark"
          value={
            addressList?.landmark === undefined ? "" : addressList?.landmark
          }
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="city">City:</label>
        <input
          type="text"
          value={addressList?.city}
          placeholder="city"
          id="city"
          name="city"
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="state">State/Region:</label>
        <input
          type="text"
          value={addressList?.State}
          placeholder="state"
          id="State"
          name="State"
          onChange={handleAddressForm}
        />
      </div>
      <div className={Styles.addressForm}>
        <label htmlFor="zip_code">Zip/Postal Code :</label>
        <input
          type="number"
          onKeyDown={blockInvalidChar}
          value={addressList?.zip_code}
          placeholder="zip code"
          id="zip_code"
          name="zip_code"
          onChange={handleAddressForm}
        />
      </div>
    </form>
  );
};

export default AddressModel;
