import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import downloadIcon from "./assets/img/downloadIcon.png";
import * as XLSX from "xlsx";
import styles from "../Admin/assets/css/UserList.module.css";
import { prev_arrow, next_arrow } from "../assets/images";
import commoncss from "../Admin/assets/css/common.module.css";
import { BackgroundBlur } from "../utils/Popups";

export const URLProtected = ({ component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    let adminAuth = localStorage.getItem("adminAuth");
    if (!adminAuth) {
      navigate("/admin/login/");
    }
  }, []);

  return component;
};

export const DownloadToExcel = ({ data, filename }) => {
  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <img
      src={downloadIcon}
      onClick={handleDownload}
      height="28px"
      style={{ cursor: "pointer" }}
      alt="Download"
    />
  );
};

export const Pagination = ({
  total,
  perPage,
  currPage,
  tPage,
  updatePage,
  prevPage,
}) => {
  return (
    <div className={styles.pagination}>
      <p>Total {total}</p>
      <p>Per Page {perPage}</p>
      <p>
        {currPage} of {tPage}
      </p>
      <div className={styles.pageNum}>
        <img src={prev_arrow} onClick={updatePage} name="prev" alt="Prev" />
        {new Array(5).fill(0).map((value, index) => {
          return (
            prevPage + index <= tPage && (
              <span
                onClick={updatePage}
                key={index}
                className={
                  prevPage + index === currPage ? styles.selectedPage : ""
                }
              >
                {prevPage + index}
              </span>
            )
          );
        })}
        <img src={next_arrow} onClick={updatePage} name="next" alt="Next" />
      </div>
    </div>
  );
};

export const AlertPopup = ({ userBlock }) => {
  return (
    <React.Fragment>
      <div className={commoncss.alertBox}>
        <h1>Do you realy want to block the user?</h1>
        <div>
          <span
            onClick={userBlock}
            style={{ backgroundColor: "#019C89", color: "white" }}
          >
            Yes
          </span>
          <span onClick={userBlock}>No</span>
        </div>
      </div>
      <BackgroundBlur />
    </React.Fragment>
  );
};
