import React, { useEffect } from "react";
import { NavLink, useNavigate, Outlet, useLocation } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLocation,
  changeLoginStatus,
  clearLocation,
} from "../../app/Slices/AuthSlice";
import styles from "../../assets/css/loginregister.module.css";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import "react-phone-input-2/lib/style.css";
import { toast } from "../../utils/Toaster";
import { loginBg, howWeWorks, cancel } from "../../assets/images/index";
import { whatsApp2 } from "../../assets/images/index";
import { postData, postData1 } from "../../services";
import { fetchUserDetails, getUserData } from "../../app/Slices/UserData";
import {
  clearProdAddData,
  filterCategories,
  filterSpec,
} from "../../app/Slices/ProdAddSlice";
import { ToastContainer } from "react-toastify";

export const LoginRegister = () => {
  const Style1 = { backgroundImage: `url(${loginBg})` };
  return (
    <div style={Style1} className={styles.userContainer}>
      <div className={styles.lrContent}>
        <h3>How EquipMedy Works?</h3>
        <img src={howWeWorks} alt="..." />
        <p>
          If Inspection Engineer Does Not Find Equipment in Acceptable
          Condition.
          <br /> Amount paid for blocking will be refunded
        </p>
      </div>
      <FormContainer />
    </div>
  );
};

export const FormContainer = () => {
  return (
    <div className={styles.FormContainer}>
      <div style={{ background: "white", width: "300px" }}>
        <div className={styles.formHead}>
          <NavLink to="/user/login/" style={ActivateLink}>
            SIGN IN
          </NavLink>
          {/* <NavLink to='/user/registeration/' style={ActivateLink}>SIGN UP</NavLink> */}
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export const Signup = () => {
  const navigate = useNavigate();
  const handleSignupSubmition = (event) => {
    event.preventDefault();
    navigate("/user/verifyotp/");
  };

  return (
    <form className={styles.signupForm} onSubmit={handleSignupSubmition}>
      <div>
        <p style={{ marginBottom: "8px" }}>MOBILE NO.</p>
        <PhoneInput
          country={"in"}
          value="91"
          inputStyle={{ width: "100%", backgroundColor: "#FAFFFE" }}
        />
      </div>
      <div className={styles.registerType}>
        <p for="buyerSeller">WHATS BEST DECRIBES YOU </p>
        <label className={styles.registerRadio}>
          <span>
            <input id="buyerSeller" name="buyerSeller" type="radio" />
            BUYER{" "}
          </span>
          <span>
            <input id="buyerSeller" name="buyerSeller" type="radio" />
            SELLER{" "}
          </span>
        </label>
      </div>
      <input type="text" placeholder="GSTIN Number" />
      <input type="submit" value="GET OTP" />
      <label style={{ textAlign: "center" }}>
        Already have an account ? <NavLink to="#">Sign In</NavLink>
      </label>
    </form>
  );
};

export const Login = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userDetails")) || {};
  const navigateTo = location?.state?.navigateTo;
  const item = location?.state?.prodDetails;
  const [isPhone, setIsphone] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const initialValues = {
    userfName: "",
    userlName: "",
    whatsappCheck: true,
  };
  const [userName, setUserName] = useState(initialValues);
  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [mobile, setMobile] = useState("");
  const navigate = useNavigate();
  const [formDisabled, setFormDisabled] = useState(false);

  const handleUserName = (event) => {
    const { name, value, type } = event.target;
    if (value.trim() !== "") {
      setUserName({
        ...userName,
        [name]: type === "checkbox" ? !userName?.whatsappCheck : value,
      });
    } else {
      setUserName({ [name]: "" });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    dispatch(clearLocation());
  }, [dispatch]);

  const validatePhone = () => {
    if (!mobile) {
      setPhoneError("Phone number is required");
      return false;
    } else if (mobile.length < 10) {
      setPhoneError("Phone number must be 10 digits");
      return false;
    }
    // Add more validation logic if needed
    setPhoneError("");
    return true;
  };
  const validateFName = () => {
    if (userName.userfName === "") {
      setFNameError("Please Enter First Name !");
      return false;
    }
    setFNameError("");
    return true;
  };

  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const parseDetails = JSON.parse(userDetails);
    }
  }, []);

  const handleLoginSubmition = async (event) => {
    event.preventDefault();
    setFormDisabled(true);
    if (validatePhone() && validateFName()) {
      const formData = new FormData();
      formData.append("mobile", mobile?.slice(2, 12));
      formData.append("confirm", userName?.whatsappCheck ? "yes" : "no");
      const res = await postData("users/generateotp/", formData);
      if (res?.status) {
        toast.success("Please enter OTP to verify mobile!", {
          autoClose: 2000,
          closeButton: false,
        });
        // handleSubmitForm()
        localStorage.getItem(
          "userDetails",
          JSON.stringify({
            number: mobile?.slice(2, 12),
            fName: userName?.userfName,
            lName: userName?.userlName,
          })
        );
        dispatch(changeLocation(true));
        setTimeout(() => {
          if (props.setOtpForm) {
            props.setOtpForm(true);
            props.setotp(res?.data?.otp);
            props.setNumber(mobile);
            props.setFirstName(userName?.userfName);
            props.setLastName(userName?.userlName);
            return;
          } else {
            navigate("/user/verifyotp/", {
              state: {
                otp: res?.data?.otp,
                number: mobile?.slice(2, 12),
                navigateTo: navigateTo,
                item: item,
                fname: userName?.userfName,
                lname: userName?.userlName,
              },
            });
          }
        }, 2000);
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <form className={styles.signupForm} onSubmit={handleLoginSubmition}>
        {isPhone && (
          <div>
            <p style={{ marginBottom: "8px" }}>
              Phone Number <span style={{ color: "red" }}>*</span>
            </p>
            <PhoneInput
              country={"in"}
              value={mobile}
              countryCodeEditable={false}
              disableDropdown
              inputStyle={{ width: "100%", backgroundColor: "#FAFFFE" }}
              onChange={(phone) => {
                setMobile(phone);
                setFormDisabled(false);
              }}
              onBlur={validatePhone}
            />
            {phoneError && <div style={{ color: "red" }}>{phoneError}</div>}
          </div>
        )}
        <div
          style={{
            width: "100%",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ color: "black", marginBottom: "10px" }}>
            First Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            style={{ width: "95%" }}
            type="text"
            name="userfName"
            onChange={(e) => handleUserName(e)}
            onBlur={validateFName}
            value={userName?.userfName}
          />
        </div>
        {fnameError && <div style={{ color: "red" }}>{fnameError}</div>}
        <div
          style={{
            width: "100%",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label style={{ color: "black", marginBottom: "10px" }}>
            Last Name
          </label>
          <input
            style={{ width: "95%" }}
            type="text"
            name="userlName"
            onChange={(e) => handleUserName(e)}
            value={userName?.userlName}
          />
        </div>
        {lnameError && <div style={{ color: "red" }}>{lnameError}</div>}
        <div className={styles.whatsAppDesign}>
          <input
            type="checkbox"
            name="whatsappCheck"
            style={{ cursor: "pointer" }}
            onChange={(e) => handleUserName(e)}
            checked={userName?.whatsappCheck}
            value={userName?.whatsappCheck}
          />
          <label style={{ color: "black" }}>Get updates on Whatsapp</label>
          <img style={{ height: "22px" }} src={whatsApp2} alt="noimg" />
        </div>
        <input
          type="submit"
          disabled={formDisabled}
          value={isPhone ? "GET OTP" : "Sign In"}
        />
      </form>
    </>
  );
};

export const OtpVervicatonForm = ({
  getOtp,
  number,
  setotp,
  setGetStart,
  setBlur,
  firstName,
  lastName,
}) => {
  const prodData = useSelector((state) => state.addProd.prodAddData);
  const reLoadPage = useSelector((state) => state.auth.onReload);
  const location = useLocation();
  let preOtp = location?.state?.otp;
  let preNumber = location?.state?.number;
  let fname = location?.state?.fname;
  let lname = location?.state?.lname;

  const [otp, setOtp] = useState("");
  const [resendotp, setresendotp] = useState("");
  const [num, setnum] = useState("");
  const [nav, setnav] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpTime, setOtpTime] = useState({ minute: 4, sec: 59 });
  const navigateTo = location?.state?.navigateTo
    ? location?.state?.navigateTo
    : nav
    ? nav
    : "/";
  const item = location?.state?.prodDetails;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styleInpute = {
    width: "30px",
    height: "30px",
    margin: "auto",
    backgroundColor: "#FAFFFE",
    border: "1px solid #DDE6E4",
    borderRadius: "2px",
    outline: "1px solid #019C89",
  };

  useEffect(() => {
    if (!reLoadPage) {
      navigate("/user/login/");
    }
  }, [reLoadPage, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTime.minute === 0 && otpTime.sec === 0) {
        clearInterval(interval);
      } else {
        if (otpTime.sec === 0) {
          setOtpTime((prevTime) => ({ minute: prevTime.minute - 1, sec: 59 }));
        } else {
          setOtpTime((prevTime) => ({ ...prevTime, sec: prevTime.sec - 1 }));
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [otpTime]);

  useEffect(() => {
    handleOtp();
  }, [otp]);

  const handleResend = async (num, otp, navigate) => {
    setnum(num);
    setnav(navigate);
    const formData = new FormData();
    formData.append("mobile", num);
    const res = await postData("users/generateotp/", formData);
    if (res.status) {
      setOtpTime({ minute: 0, sec: 5 });
      if (getOtp) {
        setotp(res?.data?.otp);
      }
      if (otp) {
        setresendotp(res?.data?.otp);
      }
    }
  };

  const handleProdAdd = async (locationId, userId) => {
    let images = prodData?.prodImgs?.map((el) => el?.file);
    let videos = prodData?.prodVideos?.map((el) => el?.file);

    const formData = new FormData();
    formData.append(
      "post_type",
      prodData.selectedPostType === "PRE OWNED"
        ? 1
        : prodData.selectedPostType === "NEW"
        ? 2
        : prodData.selectedPostType === "SPARE & ACCESSORIES"
        ? 3
        : prodData.selectedPostType === "SERVICES"
        ? 4
        : ""
    );
    images.forEach((image) => {
      formData.append("images", image);
    });
    videos.forEach((video) => {
      formData.append("videos", video);
    });
    formData.append(
      "equip_name",
      prodData?.other_name ? prodData?.newEquip_name : prodData?.Equip_name
    );
    formData.append("address", locationId);
    formData.append(
      "category_list",
      prodData?.categories?.map((el) => el?.id)
    );
    formData.append(
      "speciality_name",
      prodData?.specility?.map((el) => el)
    );
    formData.append(
      "equip_condition",
      prodData?.prodCondition?.condition
        ? prodData?.prodCondition?.condition
        : ""
    );
    formData.append(
      "negotiable_type",
      prodData?.prodCondition?.negotiable
        ? prodData?.prodCondition?.negotiable
        : ""
    );
    formData.append(
      "asking_price",
      prodData?.prodCondition?.price
        ? `${
            Number(prodData.prodCondition?.price) +
            Number(prodData.prodCondition?.price * 5) / 100
          }`
        : ""
    );
    formData.append("description", prodData?.prodCondition?.prod_desc);
    formData.append(
      "year",
      prodData?.purchase_year ? prodData?.purchase_year : ""
    );

    formData.append("brand", prodData?.specifications?.brand);
    formData.append("model", prodData?.specifications?.model);
    formData.append(
      "warranty",
      prodData?.specifications?.waranty ? prodData?.specifications?.waranty : ""
    );
    formData.append(
      "existing_amc",
      prodData?.specifications?.amc_cme ? prodData?.specifications?.amc_cme : ""
    );
    formData.append("other_details", prodData?.specifications?.other_details);
    formData.append("latitude", prodData?.location?.lat);
    formData.append("longitude", prodData?.location?.lang);
    formData.append("user", userId);
    formData.append("Compatible_Models", prodData?.Compatible_Models);
    formData.append(
      "Prod_price",
      `${
        Number(prodData?.Prod_price) + (Number(prodData?.Prod_price) * 5) / 100
      }`
    );
    const res = await postData("product/add/", formData, true);
    if (res?.status) {
      toast.success("Product added successfully Review By Admin !", {
        autoClose: 2000,
        closeButton: false,
      });
      dispatch(filterCategories([]));
      dispatch(filterSpec([]));
      dispatch(clearProdAddData());
      setTimeout(() => {
        navigate("/profile/ads/");
      }, 2000);
    }
  };

  const handleProdAddAddress = async (userId) => {
    let data = {
      name: `${firstName ? firstName : fname} ${
        lastName ? lastName : lname ? lname : ""
      }`,
      mobile: preNumber || number?.slice(2.12) || num,
      alternate_mobile: preNumber || number?.slice(2.12) || num,
      address: prodData.addAddress.address,
      state: prodData.addAddress.State,
      city: prodData.addAddress.city,
      zip_code: prodData.addAddress.zip_code,
      landmark: prodData.addAddress.landmark,
      latitude: prodData.location?.lat,
      longitude: prodData.location?.lang,
      location: "India",
      is_default: 0,
    };
    const res = await postData1("users/add_address/", data, true);
    if (res?.status) {
      setTimeout(() => {
        handleProdAdd(res?.data?.uid, userId);
      }, 1000);
    } else {
      toast.error("addresss", { autoClose: 2000, closeButton: false });
    }
  };

  const handleUserForm = async (userId) => {
    const formData = new FormData();
    formData.append("first_name", firstName ? firstName : fname);
    formData.append("last_name", lastName ? lastName : lname);
    const res = await postData("users/add_profile/", formData, true);
    if (res?.status) {
      handleProdAddAddress(userId);
    }
  };

  const handleOtp = async () => {
    if (otp.length === 6 && /^\d+$/.test(otp)) {
      const formData = new FormData();
      formData.append("mobile", preNumber || number?.slice(2.12) || num);
      formData.append("otp", otp || getOtp);
      const res = await postData("users/verifyotp/", formData);
      if (res?.status) {
        setOtpError(false);
        toast.success("Verified OTP successfully !", {
          autoClose: 2000,
          closeButton: false,
        });
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("uid", res?.data?.uid);
        localStorage.setItem("utype", res?.data?.usertype);
        dispatch(fetchUserDetails(res?.data?.uid));
        localStorage.removeItem("add_to_cart");
        if (prodData?.selectedPostType !== null) {
          handleUserForm(res?.data?.uid);
        } else {
          setTimeout(() => {
            dispatch(getUserData(res?.data));
            dispatch(changeLoginStatus(true));
            if (
              navigateTo ===
              `/products/${encodeURIComponent(item?.equip_name)}/?id=${
                item?.id
              }/`
            ) {
              setGetStart(false);
              setBlur(false);
            } else {
              navigate(navigateTo, { state: { prodDetails: item } });
            }
          }, 2000);
        }
      } else {
        setOtpError(true);
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <div
        style={{
          position: "relative",
          top: "-50px",
          right: "20px",
          // // left: "0px",
          // alignItems:"end",
          // cursor: "pointer",
          display: "flex",
          justifyContent: "flex-end  ",
        }}
      >
        <div>
          <img
            src={cancel}
            onClick={() => navigate("/user/login/")}
            style={{ width: "20px", height: "20px" }}
            alt="no image"
          />
        </div>
      </div>

      <div className={styles.otpVerifyCont}>
        <div>
          <h4 style={{ color: "black" }}>Verification Code</h4>
          <p>Enter the 6 digit OTP send to your phone number</p>
        </div>
        {otpError && <p style={{ color: "red" }}>Invalid OTP</p>}
        <div className={styles.otp_digits}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            numInputs={6}
            inputStyle={styleInpute}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }} />}
          />
        </div>
        <p>
          Code Expire in{" "}
          <span>
            {" "}
            0{otpTime.minute}:
            {otpTime.sec % 10 === otpTime.sec ? "0" + otpTime.sec : otpTime.sec}
          </span>{" "}
        </p>
        {otpTime.minute === 0 && otpTime.sec === 0 ? (
          <NavLink
            onClick={() =>
              handleResend(preNumber || number, preOtp, navigateTo)
            }
          >
            Resend
          </NavLink>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
// non components function

const ActivateLink = ({ isActive }) => {
  return {
    borderBottom: isActive ? "2px solid black" : "none",
    color: isActive ? "black" : "#A3A3A3",
  };
};
