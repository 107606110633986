import React, { useEffect, useState } from "react";
import styles from "../assets/css/adminLogin.module.css";
import loginBg from "../assets/img/LoginBG.jpg";
import { useFormik } from "formik";
import * as yup from "yup";
import { emailSchema, passwordSchema } from "../../utils/validation";
import { NavLink, useNavigate } from "react-router-dom";
import { postData1 } from "../../services";

export const AdminLogin = () => {
  const [serverError, setServerError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object().shape({
      email: emailSchema,
      password: passwordSchema,
    }),
    onSubmit: function (values) {
      handleLogin(values);
    },
  });

  const handleLogin = async (values) => {
    const res = await postData1("admin/loginuser/", values, false);

    if (res?.status_code === 200) {
      localStorage.setItem("adminAuth", res?.data?.token);
      navigate("/admin/dashboard/");
    } else {
      setServerError("Invalid Credentials, Try Again !");
    }
  };
  useEffect(() => {
    let adminAuth = localStorage.getItem("adminAuth");
    if (adminAuth) {
      navigate("/admin/dashboard/");
    }
  }, []);

  return (
    <section className={styles.adminLoginCont}>
      <div className={styles.loginCont}>
        <div
          className={styles.loginbg}
          style={{ backgroundImage: `url(${loginBg})` }}
        ></div>
        <form className={styles.loginFrom} onSubmit={formik.handleSubmit}>
          <h1>Login</h1>
          <p>Sign In to your account</p>
          <div className={styles.finput}>
            <span>
              <i className="bi bi-person"></i>
            </span>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              placeholder="Email"
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.finput}>
            <span>
              <i className="bi bi-lock"></i>
            </span>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              placeholder="Password"
              onChange={formik.handleChange}
            />
          </div>

          <div className={styles.fopt}>
            <div>
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
            <NavLink to="/">Forgot Password</NavLink>
          </div>
          <input className={styles.loginBtn} type="submit" value="Login" />
          <div className={styles.loginError}>
            <p>{formik.errors.email}</p>
            <p>{formik.errors.password}</p>
            {serverError && <p>{serverError}</p>}
          </div>
        </form>
      </div>
    </section>
  );
};
