import { useCallback, useEffect } from "react";

export default function UseDebounce(effect, dependencies, delay) {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeOut = setTimeout(callback, delay);
    return () => clearTimeout(timeOut);
  }, [callback, delay]);
}
