import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import common from "../assets/css/common.module.css";
import { Loader } from "../../components/Loader";
import { nextArow, swipetestleft } from "../../assets/images";
import { BlockToggle } from "./UsersList";
import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink, useNavigate } from "react-router-dom";
import { postData1 } from "../../services";
import { Pagination } from "../Common";

export const PaymentList = () => {
  const navigate = useNavigate();
  const [orderlistData, setOrderListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleView=(e,data)=>{
  //     navigate("/admin/manageorder/view/",{state:data});
  // }

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1("admin/payment_list/", config, true, true);
      setOrderListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>PAYMENT MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>PAYMENT ID</span>
          <span>PRODUCT</span>
          <span>BUYER</span>
          <span>SELLER</span>
          <span>INTIATE DATE</span>
          <span>AMOUNT</span>
          <span>STATUS</span>
          <span>DETAIL</span>
        </div>

        {orderlistData.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{data?.product_detail?.equip_name}</span>
              <span>{data?.buyer_firstname}</span>
              <span>{data?.product_detail?.seller_firstname}</span>
              <span>{new Date(data?.created_date).toLocaleDateString()}</span>
              <span>{data?.total}</span>
              <span>
                <span className={common.customStyle}>
                  {data?.payment_status === 1
                    ? "Pending"
                    : data?.payment_status === 2
                    ? "Partial"
                    : data?.payment_status === 3
                    ? "Completed"
                    : "None"}
                </span>
              </span>
              <span style={{ cursor: "pointer" }}>
                {" "}
                <NavLink to="viewpayment/" state={{ item: data }}>
                  {" "}
                  View{" "}
                </NavLink>{" "}
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
