
import logo from '../img/whiteLogo.png';
import left from '../img/left.png';
import humberger from '../img/humberger.png';
import defaultProfile from '../img/defaultProfile.png';
import togleA from '../img/togleA.png';
import togleB from '../img/toggleB.png';
import view from  '../img/view.png';

import rupee from "../img/products.png";
import question from "../img/questionMark.png";
import rupees from '../img/rupees.png';
import user from "../img/total-buyer.svg";
import user2 from "../img/users.png";
import chart from "../img/chart.jpg";
import shipping from '../img/shiping.png';
import distributors from '../img/distributors.png';
import inspections from '../img/inspections.png';
import orders from '../img/orders.png';
import speciality from '../img/speciality.png';

export {
    speciality,
    orders,
    inspections,
    rupee,
    rupees,
    question,
    shipping,
    distributors,
    user,
    user2,
    chart,
    logo,
    left,
    humberger,
    defaultProfile,
    togleA,
    togleB,
    view
}