import React, { useEffect, useState } from "react";
import styles from "../../assets/css/notification.module.css";
import { postData1 } from "../../services";
import { clearLatLng } from "../../app/Slices/ProdAddSlice";

export const Notifactions = () => {
  const userId = localStorage.getItem("userDetails")
  const [notification, setNotification] = useState([])

  useEffect(() => {
    handleNotification()
  }, [])
  const handleNotification = async () => {
    const data = {
      user_id: JSON.parse(userId)?.id,
      sort: "asc"
    }
    const res = await postData1("users/notification/", data, true)
    setNotification(res?.data)
  }
  return (
    <section className={styles.notifictions}>
      <div className={styles.notifiactionHead}>
        <h1>NOTIFICATIONS</h1>
      </div>
      <div className={styles.notifiactionData}>
        {
          notification?.sort((a,b)=>new Date(b.msg_generated_time) - new Date(a.msg_generated_time))?.map((el) => {
            return (
              <div className={styles.notificationCard}>
                <div className={styles.notificationCont}>
                  <h2>{el?.subject}</h2>
                  {/* <h3>New Registration:FineBus Benurem et molerem</h3> */}
                  <p>
                    {el?.text}
                  </p>
                  {/* <h4>Allen Due</h4> */}
                </div>
                <div className={styles.notificationDate}>
                  <span></span>
                  <p>
                    <span>
                      <i className="bi bi-clock"></i>
                    </span>{" "}
                    {`${new Date(el?.msg_generated_time).toLocaleDateString("default", {day : "numeric",month : "short", year : "numeric",})} at ${new Date(el?.msg_generated_time).toLocaleTimeString("default", {hour : "2-digit",minute : "2-digit",hour12 : true})}`}
                    {/* 24 nov 2018 at 9:30AM */}
                  </p>
                </div>
              </div>
            )
          })
        }


        {/* <div className={styles.notificationCard}>
          <div className={styles.notificationCont}>
            <h2>Joinded New User</h2>
            <h3>New Registration:FineBus Benurem et molerem</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus,
              et reiciendis. Ipsa, possimus libero dolorum aut pariatur et
              facere! Cupiditate?
            </p>
            <h4>Allen Due</h4>
          </div>
          <div className={styles.notificationDate}>
            <p>
              <span>
                <i className="bi bi-clock"></i>
              </span>{" "}
              24 nov 2018 at 9:30AM
            </p>
          </div>
        </div>
        <div className={styles.notificationCard}>
          <div className={styles.notificationCont}>
            <h2>Joinded New User</h2>
            <h3>New Registration:FineBus Benurem et molerem</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Natus,
              et reiciendis. Ipsa, possimus libero dolorum aut pariatur et
              facere! Cupiditate?
            </p>
            <h4>Allen Due</h4>
          </div>
          <div className={styles.notificationDate}>
            <p>
              <span>
                <i className="bi bi-clock"></i>
              </span>{" "}
              24 nov 2018 at 9:30AM
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
};
