import React, { useEffect, useState } from "react";
import styles from "../assets/css/UserList.module.css";

import { defaultProfile, togleA, togleB, view } from "../assets/img";
import { NavLink, useNavigate } from "react-router-dom";
import { postData1 } from "../../services";
import { Loader } from "../../components/Loader";
import { prev_arrow, next_arrow } from "../../assets/images";
import { DownloadToExcel, Pagination } from "../Common";
import { AlertPopup } from "../Common";

export const UserList = () => {
  const [userlistData, setUserListData] = useState([]);
  let downloadData = [];
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);
  const navigate = useNavigate();

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  const handleView = (e, data) => {
    navigate("/admin/manageusers/viewuser/", { state: data });
  };

  useEffect(() => {
    (async () => {
      const config = { user_type: 2, _page: currPage, _limit: 8 };
      const res = await postData1("admin/user_list/", config, true, true);
      setUserListData(res?.data?.data);
      downloadData = [];
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>USER MANAGEMENT</h1>
        <div>
          <DownloadToExcel data={downloadData} filename="userlist" />
        </div>
      </div>

      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>Profile</span>
          <span>Name</span>
          <span>Email</span>
          <span>Mobile</span>
          <span>City</span>
          <span>PAN</span>
          <span>GST</span>
          <span>Verified</span>
          <span>Action</span>
        </div>

        {userlistData?.map((data, index) => {
          downloadData.push({
            mobile: data?.mobile,
            email: data?.email,
            first_name: data?.profile?.first_name,
            last_name: data?.profile?.last_name,
            gstin: data?.profile?.gstin,
            pan_no: data?.profile?.pan_no,
            dob: data?.profile?.dob,
            gender: data?.profile?.gender,
            location: data?.profile?.location,
            zip_code: data?.profile?.zip_code,
          });
          return (
            <div className={styles.itemData} key={index}>
              <span>
                <img
                  className={styles.proImg}
                  src={
                    data?.profile?.profile_image
                      ? data?.profile?.profile_image
                      : defaultProfile
                  }
                  alt="No Img"
                />
              </span>
              <span>{data?.profile?.first_name}</span>
              <span>{data?.email}</span>
              <span>{data?.mobile}</span>
              <span>{data?.profile?.location}</span>
              <span>{data?.profile?.pan_no}</span>
              <span>{data?.profile?.gstin}</span>
              <span>{data?.status ? "Yes" : "No"}</span>
              <span className={styles.actiontd}>
                <img
                  onClick={(e) => handleView(e, data)}
                  src={view}
                  title="view"
                  alt="..."
                />
                {/* <BlockToggle/> */}
              </span>
            </div>
          );
        })}
      </div>

      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
export const ManufacturerList = () => {
  const navigate = useNavigate();
  const [manlistData, setManlistData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  const handleClick = (e, data) => {
    navigate("/admin/managemanufacturer/viewuser/", { state: data });
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1(
        "admin/manufacturer_list/",
        config,
        true,
        true
      );
      setManlistData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>MANUFACTURER MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>Deal Type</span>
          <span>Buisness Name</span>
          <span>Person</span>
          <span>Email</span>
          <span>Mobile</span>
          <span>City</span>
          <span>Equipment Cat</span>
          <span>Verified</span>
          <span>Action</span>
        </div>

        {manlistData?.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.product_deal}</span>
              <span>{data?.firm_name}</span>
              <span>{data?.responsible_person}</span>
              <span>{data?.email}</span>
              <span>{data?.phone_no}</span>
              <span>{data?.office_location}</span>
              <span>{data?.equipname_list}</span>
              <span>{data?.status ? "Yes" : "No"}</span>
              <span className={styles.actiontd}>
                <img
                  src={view}
                  title="view"
                  onClick={(e) => handleClick(e, data)}
                  alt="..."
                />
                {/* <BlockToggle/> */}
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />
      {loader && <Loader />}
    </section>
  );
};
export const DistributorList = () => {
  const navigate = useNavigate();
  const [distlistData, setDistlistData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1(
        "admin/distributer_list/",
        config,
        true,
        true
      );
      setDistlistData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  const handleClick = (e, data) => {
    navigate("/admin/managemanufacturer/viewuser/", { state: data });
  };

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>DISTRIBUTOR MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>Deal Type</span>
          <span>Buisness Name</span>
          <span>Person</span>
          <span>Email</span>
          <span>Mobile</span>
          <span>City</span>
          <span>Website</span>
          <span>EQUIPMENT Cat</span>
          <span>Verified</span>
          <span>Action</span>
        </div>

        {distlistData?.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.product_deal}</span>
              <span>{data?.firm_name}</span>
              <span>{data?.responsible_person}</span>
              <span>{data?.email}</span>
              <span>{data?.phone_no}</span>
              <span>{data?.office_location}</span>
              <span>{data?.website_name}</span>
              <span>{data?.equipname_list}</span>
              <span>{data?.status ? "Yes" : "No"}</span>
              <span className={styles.actiontd}>
                <img
                  onClick={(e) => handleClick(e, data)}
                  src={view}
                  title="view"
                  alt="..."
                />
                {/* <BlockToggle/> */}
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />
      {loader && <Loader />}
    </section>
  );
};

export const BlockToggle = () => {
  const [blockTog, setBlockTog] = useState(false);

  const userBlock = () => {
    setBlockTog(!blockTog);
  };

  return (
    <React.Fragment>
      <img
        src={blockTog ? togleA : togleB}
        title={blockTog ? "Unblock" : "Block"}
        onClick={userBlock}
        alt="..."
      />
      {blockTog && <AlertPopup userBlock={userBlock} />}
    </React.Fragment>
  );
};
