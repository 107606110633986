/* eslint-disable react/prop-types */
import { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";
import { useSwipeable } from "react-swipeable";

const SliderComponent = ({images}) => {
  // const [images] = useState([
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/20240412_154523_12042024120428789461.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/Xray_27092024055357347505.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/images_16092024045627981085.jpeg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/20240412_154523_12042024120428789461.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/Xray_27092024055357347505.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/images_16092024045627981085.jpeg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/20240412_154523_12042024120428789461.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/Xray_27092024055357347505.jpg",
  //   },
  //   {
  //     product_images:
  //       "https://mmq.mediquip.in/mmq_apps/static/upload/product/image/images_16092024045627981085.jpeg",
  //   },
  //   // Add more images here
  // ]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(images.length / itemsPerPage);

  // Get current page products
  const currentImages = images.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Change page function
  const handleNext = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (prev === 0 ? totalPages - 1 : prev - 1));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventScrollOnSwipe: true,
    trackMouse: true, // Allows swiping with mouse on desktop as well
  });

  // code for mobile version

  const [currentMobileIndex, setCurrentMobileIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const incrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex(
        (prevMobileIndex) => (prevMobileIndex + 1) % images.length
      );
      setIsSliding(false);
    }, 300);
  };

  const decrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex((prevMobileIndex) =>
        prevMobileIndex - 1 < 0 ? images.length - 1 : prevMobileIndex - 1
      );
      setIsSliding(false);
    }, 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: incrementMobileIndex,
    onSwipedRight: decrementMobileIndex,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const openPopup = (index) => {
    setCurrentMobileIndex(index);
    setIsPopUpOpen(true);
  };

  return (
    <>
      {/* Mobile version */}
      <div
        className="relative w-full flex justify-center items-center md:hidden lg:hidden xl:hidden"
        {...handlers}
      >
        <div className="relative overflow-hidden w-full max-w-lg">
          <div
            className={`flex transition-transform duration-300 ease-in-out ${
              isSliding ? "transform" : ""
            }`}
            style={{ transform: `translateX(-${currentMobileIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <div key={index} className="w-full flex-shrink-0">
                <img
                  onClick={() => openPopup(index)}
                  src={image.product_images}
                  alt={`Slide ${index}`}
                  className="w-full h-64 object-cover rounded-lg shadow-lg"
                />
              </div>
            ))}
          </div>

          {/* Left arrow */}
          <button
            className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
            onClick={decrementMobileIndex}
          >
            <FaAngleLeft />
          </button>

          {/* Right arrow */}
          <button
            className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
            onClick={incrementMobileIndex}
          >
            <FaAngleRight />
          </button>
        </div>
      </div>

      {/* Tablets mode */}
      {/* Desktop view */}
      <div
        className="hidden md:block md:relative md:w-full lg:block lg:relative lg:w-full xl:block xl:relative xl:w-full "
        {...swipeHandlers}
      >
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-4">
          {currentImages.length === 0
            ? ""
            : currentImages?.map((image, index) => (
                <div
                  key={index}
                  className=" bg-white shadow-md shadow-green-200 rounded-lg relative transition duration-500 ease-in-out hover:shadow-lg hover:-translate-y-1 hover:scale-110"
                >
                  <div className="flex flex-col">
                    <img
                      src={image.product_images}
                      alt={image.equip_name}
                      className="h-40 w-full object-cover rounded-md m-2"
                      onClick={() => openPopup(index)}
                    />
                  </div>
                </div>
              ))}
        </div>

        {/* Left and Right Arrows (Visible on larger screens) */}
        {images.length > 6 && (
          <button
            className="hidden lg:hidden xl:flex absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
            onClick={handlePrev}
          >
            <FaAngleLeft size={24} />
          </button>
        )}
        {images.length > 6 && (
          <button
            className="hidden lg:hidden xl:flex absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full"
            onClick={handleNext}
          >
            <FaAngleRight size={24} />
          </button>
        )}

        {/* Dot Navigation for Mobile */}
        <div className="flex justify-center mt-4">
          {Array(totalPages)
            .fill()
            .map((_, i) => (
              <span
                key={i}
                className={`h-4 w-4 rounded-full mx-1 ${
                  currentPage === i ? "bg-blue-600" : "bg-gray-400"
                }`}
              ></span>
            ))}
        </div>
      </div>

      {isPopUpOpen && (
        <PopUpModel
          images={images}
          index={currentMobileIndex}
          close={setIsPopUpOpen}
        />
      )}
    </>
  );
};

const PopUpModel = ({ images, index, close }) => {
  const [currentMobileIndex, setCurrentMobileIndex] = useState(index);
  const [isSliding, setIsSliding] = useState(false);

  const incrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex(
        (prevMobileIndex) => (prevMobileIndex + 1) % images.length
      );
      setIsSliding(false);
    }, 300);
  };

  const decrementMobileIndex = () => {
    setIsSliding(true);
    setTimeout(() => {
      setCurrentMobileIndex((prevMobileIndex) =>
        prevMobileIndex - 1 < 0 ? images.length - 1 : prevMobileIndex - 1
      );
      setIsSliding(false);
    }, 300);
  };

  const handlers = useSwipeable({
    onSwipedLeft: incrementMobileIndex,
    onSwipedRight: decrementMobileIndex,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center z-[999] pt-10 pb-10"
      {...handlers}
    >
      <div className="relative overflow-hidden w-full max-w-lg">
        <div
          className="flex flex-row transition-transform duration-300 ease-in-out"
          style={{ transform: `translateX(-${currentMobileIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div key={index} className="w-full flex-shrink-0 flex justify-center items-center">
              <img
                src={image.product_images}
                alt={`Slide ${index}`}
                className="max-w-full max-h-screen object-contain rounded-lg shadow-lg"
              />
            </div>
          ))}
        </div>

        {/* Left arrow */}
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
          onClick={decrementMobileIndex}
        >
          <FaAngleLeft className="md:text-4xl lg:text-4xl xl:text-4xl" />
        </button>

        {/* Right arrow */}
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2 bg-gray-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
          onClick={incrementMobileIndex}
        >
          <FaAngleRight className="md:text-4xl lg:text-4xl xl:text-4xl" />
        </button>
      </div>
      {/* Close icon */}
      <button
        className="absolute top-2 right-2 md:top-10 md:right-10 lg:top-6 lg:right-6 xl:top-6 xl:right-6 p-2 bg-green-700 rounded-full text-white transition-transform hover:scale-110 focus:scale-110 active:scale-95 shadow-md hover:shadow-lg"
        onClick={() => close(false)}
      >
        <FaTimes className="text-2xl" />
      </button>
    </div>
  );
};

export default SliderComponent
