import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import common from "../assets/css/common.module.css";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { postData1 } from "../../services";
import { Pagination } from "../Common";

export const RequestList = () => {
  const [requestlistData, setRequestListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1(
        "admin/requirementform_list/",
        config,
        true,
        true
      );
      setRequestListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>REQUIREMENTS MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>ID</span>
          <span>EMAIL</span>
          <span>CONTACT</span>
          <span>CITY</span>
          <span>EQUIPMENT</span>
          <span>PRICE RANGE</span>
          <span>CONDITION</span>
          <span>STATUS</span>
          <span>DETAIL</span>
        </div>

        {requestlistData.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{data?.email}</span>
              <span>{data?.contact_no}</span>
              <span>{data?.location}</span>
              <span>{data?.category_list}</span>
              <span>{data?.price_range}</span>
              <span>
                {data?.condition === 1
                  ? "Preowned"
                  : data?.condition === 2
                  ? "NEW"
                  : data?.condition === 3
                  ? "SPARE/ACCESS"
                  : data?.condition === 4
                  ? "SERVICES"
                  : ""}
              </span>
              <span>
                <span className={common.customStyle}>UNRESOLVED</span>
              </span>
              <span>
                <NavLink to="view-manage-request/" state={{ item: data }}>
                  View
                </NavLink>
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
