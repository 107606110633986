import React, { useState, useEffect } from "react";
import styles from "../../assets/css/forms.module.css";
import defaultProfile from "../../../assets/images/testimage1.png";
import { NavLink, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailSchema,
  fnameSchema,
  gstinSchema,
  lnameSchema,
  mnumberSchema,
  nationalitySchema,
  pancardSchema,
} from "../../../utils/validation";
import { postData1 } from "../../../services";
import { view } from "../../assets/img";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const BaseURL = process.env.REACT_APP_BASEURL;
const REACT_APP_IMAGE_PREVIEW = process.env.REACT_APP_IMAGE_PREVIEW;

export const InspectMeetForm = () => {
  const [isDisable, setisDisable] = useState(true);
  const [perm, updatePerm] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const userData = location.state;
  const [currStatus, setStatus] = useState(userData?.inspection_status);
  const [invoice, setInvoice] = useState(userData?.inspection_file);

  const formik = useFormik({
    initialValues: {
      inspId: userData?.id,
      inspAmount: userData?.amount,
      tAmount: userData?.base_amount,
      buyerFName: userData?.buyer_firstname,
      sellerFname: userData?.seller_firstname,
      reqDate: userData?.created_date,
      equipName: userData?.product,
    },

    // onSubmit: function (values) {
    //     handleSubmit(values);
    // },
  });

  useEffect(() => {
    if (currStatus === userData?.inspection_status) {
      return;
    }
    (async () => {
      const res = await postData1(
        "admin/update_inspection/",
        { uid: userData?.uid, inspection_status: currStatus },
        true,
        true
      );
      if (res?.status_code === 200) {
        toast.success("Status update successfully !");
      } else {
        toast.error("Something went wrong, try again !");
      }
    })();
  }, [currStatus]);

  const handleInspectStatus = (e) => {
    let value = e.currentTarget.innerHTML;

    if (value === "Pending") {
      setStatus(1);
    } else if (value === "Canceled") {
      setStatus(2);
    } else if (value === "Completed") {
      setStatus(3);
    }
    setOpen(false);
  };

  const getInoice = async () => {
    const res = await postData1(
      "admin/detail_inspection/",
      { uid: userData?.uid },
      true,
      true
    );
    if (res?.status_code === 200) {
      setInvoice(res?.data?.inspection_file);
    }
  };

  const uplaodInvoice = (event) => {
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("inspection_file", file);
    formData.append("uid", userData?.uid);

    (async () => {
      try {
        const res = await axios.post(
          `${BaseURL}/admin/update_inspection/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("adminAuth")}`,
            },
          }
        );
        if (res?.data?.status_code === 200) {
          toast.success("Invoice upload successfully");
          getInoice();
        } else {
          toast.error("Something went wrong, try again");
        }
      } catch {
        toast.error("Something went wrong, try again !");
      }
    })();
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      {/* <h2>Detail Info <i onClick={()=>setisDisable(!isDisable)} style={{cursor:"pointer"}} className="bi bi-pencil-square"></i> </h2> */}
      <form
        className={styles.updateForm}
        disabled={true}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formFieds}>
          <div>
            <p>Inspection Id</p>
            <input
              disabled={true}
              type="text"
              name="inspId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.inspId}
            />
          </div>
          <div>
            <p>Equipment Name</p>
            <input
              disabled={true}
              type="text"
              name="equipName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.equipName}
            />
          </div>
          <div>
            <p>Inspection Amount</p>
            <input
              disabled={true}
              type="text"
              name="inspAmount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.inspAmount}
            />
          </div>
          <div>
            <p>Total Amount</p>
            <input
              disabled={true}
              type="text"
              name="tAmount"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tAmount}
            />
          </div>
          <div>
            <p>Buyer</p>
            <input
              disabled={true}
              type="text"
              name="buyerFName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.buyerFName}
            />
          </div>
          <div>
            <p>Seller </p>
            <input
              disabled={true}
              type="text"
              name="sellerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerFname}
            />
          </div>
          <div>
            <p>Request Date</p>
            <input
              disabled={true}
              type="text"
              name="reqDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={new Date(formik.values.reqDate).toLocaleDateString()}
            />
          </div>
        </div>

        <div className={styles.actionCont}>
          <div className={styles.uploadImg}>
            <label style={{ cursor: "pointer" }} htmlFor="image">
              {invoice?.length === 0 ? "Upload Report" : "Reupload Report"}
            </label>
            <input
              onChange={uplaodInvoice}
              type="file"
              name="image"
              id="image"
            />

            {invoice?.length === 0 ? (
              ""
            ) : (
              <NavLink
                to={`${REACT_APP_IMAGE_PREVIEW}/mmq_apps/static/upload/inspection/${invoice}`}
                target="_blank"
              >
                <img src={view} height={"20px"} alt="download" />
              </NavLink>
            )}
          </div>

          <div className={styles.statusDrop} style={{ margin: "0px" }}>
            <span style={{ padding: "6px" }}>Update Inspection Status</span>
            <div>
              <p onClick={() => setOpen(!isOpen)} className={styles.selOpt}>
                {currStatus === 1
                  ? "Pending"
                  : currStatus === 2
                  ? "Canceled"
                  : currStatus === 3
                  ? "Completed"
                  : ""}
                <i className="bi bi-chevron-down"></i>
              </p>
              {isOpen && (
                <div>
                  <p onClick={handleInspectStatus}>Pending</p>
                  <p onClick={handleInspectStatus}>Canceled</p>
                  <p onClick={handleInspectStatus}>Completed</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
