import React, { useState } from "react";
import styles from "../../assets/css/forms.module.css";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";

export const RequestForm = () => {
  const [isDisable, setisDisable] = useState(true);
  const location = useLocation();
  const userData = location.state;

  const formik = useFormik({
    initialValues: {
      id: userData?.id,
      date: userData?.created_date,
      name: userData?.name,
      email: userData?.email,
      contact_no: userData?.contact_no,
      location: userData?.location,
      message: userData?.message,
    },

    // onSubmit: function (values) {
    //     handleSubmit(values);
    // },
  });

  return (
    <section className={styles.formCont}>
      <h2>
        Detail Info
        {/* <i onClick={()=>setisDisable(!isDisable)} style={{cursor:"pointer"}} className="bi bi-pencil-square"></i> */}
      </h2>
      <form
        className={styles.updateForm}
        disabled={true}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formFieds}>
          <div>
            <p>Rquest Id</p>
            <input
              disabled={true}
              type="text"
              name="id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.id}
            />
          </div>
          <div>
            <p>Date</p>
            <input
              disabled={true}
              type="text"
              name="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={new Date(formik.values.date).toLocaleDateString()}
            />
          </div>
          <div>
            <p>Name</p>
            <input
              disabled={true}
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
          </div>

          <div>
            <p>Email</p>
            <input
              disabled={true}
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div>
            <p>Phone</p>
            <input
              disabled={true}
              type="text"
              name="contact_no"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.contact_no}
            />
          </div>
          <div>
            <p>Location</p>
            <textarea
              disabled={isDisable}
              type="text"
              name="location"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.location}
            ></textarea>
          </div>
          <div>
            <p>Request Message</p>
            <textarea
              disabled={isDisable}
              type="text"
              name="Message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            ></textarea>
          </div>
        </div>
      </form>
    </section>
  );
};
