import React from "react";
import styles from "../assets/css/page404.module.css";
import { NavLink } from "react-router-dom";
export const Page404 = () => {
  return (
    <>
      <div className={styles.errorPage}>
        <div className={styles.page404}>
          <div className={styles.erro404}>
            <span>
              <i class="bi bi-emoji-frown"></i>
            </span>
            <div className={styles.erro404Content}>
              <h1>404</h1>
              <h3>OOPS! PAGE NOT BE FOUND</h3>
              <p>Sorry but the page you are looking for des not exist.</p>
              <p>hav been removed name changed or is temporarity unavailable</p>
              <NavLink to="/">
                <h5>Back to homepage</h5>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
