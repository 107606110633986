import React from "react";
import { DashboardAdvt } from "../../components/Advt";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "../../assets/css/buy/prod_desc.module.css";
import { RelatedProdCard } from "../../components/Cards";
import { GetStarted, BackgroundBlur } from "../../utils/Popups";
import * as yup from "yup";
import { SocialShare } from "../../utils/Popups";
import { emailSchema, fnameSchema } from "../../utils/validation";
import { useRef, useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Modal } from "antd";
import {
  findE,
  inspection,
  closeDeal,
  handling,
  amc,
  shipped,
  location,
  contBtn,
  atcBtn,
  whatsBtn,
  unfilStar,
  rightMove,
  homeIcon,
  pngwing,
  nextArow,
  swipetestleft,
  pdShare,
  star,
  filledStar,
  testimage2,
  schedule,
} from "../../assets/images/index";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleMeeting } from "./Meeting";
import { setCurrBuyStatus } from "../../app/Slices/UserData";
import { postData, postData1 } from "../../services";
import ReactImageMagnify from "react-image-magnify";
import { CompanyTitle } from "../../assets/data/data";
import ProdAddress from "../../utils/ProdAddress";
import LargePreviewImage from "../../utils/LargePreviewImage";
import { Loader } from "../../components/Loader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useSwipeable } from "react-swipeable";
import SliderComponent from "../../components/products/SliderComponent";
import VideoSlider from "../../components/products/VideoSlider";

export const ProductDescription = () => {
  const [selectedDisplayData, setSelectedDisplayData] = useState();
  return (
    <div className={styles.pd_container}>
      <div className={styles.prod_advtsment}>
        <DashboardAdvt />
        <MMQprocess />
      </div>
      <ProductData setSelectedDisplayData={setSelectedDisplayData} />
      <RelatedProd selectedDisplayData={selectedDisplayData} />
    </div>
  );
};

const ProductData = ({ setSelectedDisplayData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let pathname = location?.search?.split("=");
  let splitEqual = pathname[1]?.split("/");
  const c_seller = location?.state?.c_seller;
  const navigate = useNavigate();
  let isLogin = localStorage.getItem("token");
  let buyerId = localStorage.getItem("uid");
  const [getStart, setGetStart] = useState(false);
  const [isBlur, setBlur] = useState(false);
  const [click, setClick] = useState("details");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedData, setDisplayedData] = useState({});
  const [profile, setProfle] = useState({});
  const [inspection, setInspection] = useState([]);
  const [openSocial, setOpenSocial] = useState(false);
  const [openImageCard, setOpenImageCard] = useState(false);
  const [item, setItem] = useState([]);
  const [toggle, setToggle] = useState(true);
  const phoneNumber = "+918595903939";
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [openMeeting, setMeeting] = useState(c_seller);
  const [buyClick, setBuyClick] = useState(false);

  const contRef = useRef(null);

  const handleDecodeProduct = async () => {
    if (splitEqual && splitEqual?.length > 0) {
      const formData = new FormData();
      formData.append("id", splitEqual[0]);
      const res = await postData(
        "product/filter_list/",
        formData,
        false,
        false
      );
      if (res?.status) {
        setToggle(false);
        setItem(res?.data?.new_products[0]);
        setSelectedDisplayData(res?.data?.new_products[0]);
      }
    }
  };

  useEffect(() => {
    handleDecodeProduct();
  }, [splitEqual[0]]);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const res = await postData1(
        "product/details/",
        { product_id: item?.uid },
        true
      );
      if (res?.status_code === 200) {
        let cstate = 0;
        if (res?.data?.schedule_meeting?.length > 0) {
          cstate = 1;
        }
        if (res?.data?.inspection?.length > 0) {
          cstate = 2;
        }
        dispatch(setCurrBuyStatus({ curr: cstate }));
      }
    })();
  }, []);

  const arrangedDemo = async () => {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    var day = currentDate.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;

    let checkData = {
      product_id: item?.uid,
      buyer_id: buyerId,
    };
    let data = {
      buyer: buyerId,
      seller: item?.user,
      product: item?.uid,
      title: `Request to arrange demo`,
      date: formattedDate,
    };

    const resDemo = await postData1(
      "product/get_arrange_demo/",
      checkData,
      true
    );
    if (resDemo?.status_code === 200) {
      if (resDemo?.data?.length === 0) {
        const res = await postData1("product/add_arrange_demo/", data, true);
        if (res?.status_code === 200) {
          toast.success(
            `Thanks for choosing ${CompanyTitle} services, we are working on your request and will revert shortly on your registered number !`
          );
        } else {
          toast.error("Something went wrong try again !");
        }
      } else {
        toast.info(
          "You have already requested for demo, we will contact you for the same !"
        );
      }
    } else {
      toast.error("Something went wrong try again !");
    }
  };
  const sellarClick = (event, check) => {
    console.log(item?.post_type);
    event.preventDefault();
    if (isLogin) {
      if (item?.post_type === 2 && !check) {
        arrangedDemo();
        return;
      }
      contRef.current.scrollIntoView();
      check ? setBuyClick(true) : setMeeting(true);
    } else {
      setBlur(true);
      setGetStart(!getStart);
      navigate(`${location?.pathname}${location?.search}`, {
        state: {
          navigateTo: `${location?.pathname}${location?.search}`,
          prodDetails: item,
        },
      });
    }
  };

  const handleSocial = (e) => {
    setOpenSocial(!openSocial);
  };

  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpenSocial(false);
      }
    });
  }, []);

  const handleLeft = () => {
    setCurrentIndex(
      (prev) =>
        (prev - 1 + item?.product_images?.length) % item?.product_images?.length
    );
    setPhotoIndex(
      (prev) =>
        (prev - 1 + item?.product_images?.length) % item?.product_images?.length
    );
  };
  const handleRight = () => {
    setCurrentIndex((prev) => prev + (1 % item?.product_images?.length));
    setPhotoIndex((prev) => (prev + 1) % item?.product_images?.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleRight, // Swipe left for next image
    onSwipedRight: handleLeft, // Swipe right for previous image
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Enable swipe support for mouse users (desktop)
  });

  useEffect(() => {
    const updateDisplayedData = () => {
      if (item?.product_images?.length > 0) {
        const displayed =
          item?.product_images[currentIndex % item?.product_images?.length];
        setDisplayedData(displayed);
      }
    };
    updateDisplayedData();
  }, [currentIndex, item]);

  useEffect(() => {
    handleUserProfile();
    handleGetInspection();
  }, []);

  const handleUserProfile = async () => {
    const formData = new FormData();
    formData.append("uid", item?.address);
    const res = await postData("users/get_user_detail/", formData, true);
    if (res?.status) {
      setProfle(res?.data?.profile);
    }
  };

  const handleGetInspection = async () => {
    const data = {
      buyer_id: buyerId,
      product_id: item?.uid,
    };
    const res = await postData1("product/get_inspection/", data, true);
    setInspection(res?.data);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  console.log(windowWidth, "windowWidth");

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the size as needed
    };

    // Initialize the state on mount and add resize listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageChage = (image, index) => {
    setDisplayedData(image);
    setPhotoIndex(index);
  };

  const handleClosePop = () => {
    setOpenImageCard(true);
  };

  const handleSwipe = useSwipeable({
    onSwipedLeft: () => {
      // Move to next image
      setPhotoIndex((photoIndex + 1) % item?.product_images.length);
    },
    onSwipedRight: () => {
      // Move to previous image
      setPhotoIndex(
        (photoIndex + item?.product_images.length - 1) %
          item?.product_images.length
      );
    },
    preventScrollOnSwipe: true, // Prevents mobile scrolling while swiping
    trackMouse: true, // Allows swipe handling with a mouse as well
    delta: 10, // The minimum distance in pixels for a swipe to be detected
  });
  return (
    <React.Fragment>
      {toggle && <Loader />}
      <ToastContainer />
      <div className={styles.prod_path}>
        <NavLink to="/">
          <FaArrowLeft
            style={{ paddingTop: "5px", paddingRight: "5px", fontSize: "15px" }}
          />
        </NavLink>
        <img src={homeIcon} alt="..." />
        <img src={rightMove} alt="..." />
        <NavLink to="/">{item?.equip_name}</NavLink>
      </div>
      <div
        ref={contRef}
        className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col flex-col  xl:items-start xl:justify-start md:item-center md:justify-center  lg:item-start lg:justify-start w-full"
      >
        <div className="  flex items-center justify-center md:items-center md:justify-center lg:items-start lg:justify-start xl:items-center xl:justify-center">
          <div className=" flex xl:relative border rounded-lg border-[#219d8e] flex-col xl:flex-row-reverse md:items-center md:justify-center lg:items-center lg:justify-center items-center justify-center xl:items-start xl:justify-between">
            <div
              className=" ml-0 xl:ml-20  p-2 rounded-lg "
              onClick={() => handleClosePop()}
              {...handlers}
            >
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: "No Image ",
                    isFluidWidth: false,
                    src: displayedData?.product_images
                      ? displayedData?.product_images
                      : pngwing,
                    width: windowWidth >= 768 ? 350 : windowWidth - 70,
                    height: windowWidth >= 768 ? 450 : windowWidth * 0.8,
                  },
                  largeImage: {
                    src: displayedData?.product_images
                      ? displayedData?.product_images
                      : pngwing,
                    width: 1000,
                    height: 1000,
                  },

                  enlargedImageContainerStyle: {
                    // zIndex: "1500",
                    backgroundColor: "#ebedf0",
                    borderRadius: "3px",
                  },
                  enlargedImageContainerDimensions: {
                    width: "200%",
                    height: "150%",
                  },
                }}
              />
            </div>
            <div>
              {openImageCard && (
                // Make sure swipe handler is attached to the correct element
                <div {...handleSwipe}>
                  <Lightbox
                    mainSrc={item?.product_images[photoIndex]?.product_images}
                    nextSrc={
                      item?.product_images[
                        (photoIndex + 1) % item?.product_images.length
                      ]
                    }
                    prevSrc={
                      item?.product_images[
                        (photoIndex + item?.product_images.length - 1) %
                          item?.product_images.length
                      ]
                    }
                    onCloseRequest={() => setOpenImageCard(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + item?.product_images.length - 1) %
                          item?.product_images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex(
                        (photoIndex + 1) % item?.product_images.length
                      )
                    }
                  />
                </div>
              )}
            </div>

            {/* small image */}
            <div
              style={{
                height:
                  window.innerWidth < 768 ||
                  window.innerWidth < 1024 ||
                  window.innerWidth < 500
                    ? 98
                    : "96%",
              }}
              className=" xl:absolute mt-2 mb-2  ml-2 flex-row xl:flex xl:flex-col xl:items-center xl:justify-start xl:justify-between w-[96%] xl:w-auto md:w-[96%] xs:w[96%] sm:w-[96%] lg:w-[96%] lg:ml-0 xl:h-full flex items-center justify-between   xl:rotate-0  border xl:top-0 xl:left-0"
            >
              <div className=" flex items-center justify-center">
                <img
                  className=" w-6 h-6 xl:rotate-90 cursor-pointer rotate-0"
                  src={swipetestleft}
                  onClick={handleLeft}
                  alt="..."
                />
              </div>
              <div className=" flex items-start justify-start lg:flex-row flex-row xl:flex-col">
                {item?.product_images?.length > 0 ? (
                  item?.product_images?.slice(0, 4)?.map((image, index) => {
                    return (
                      <div clasName="flex items-start, justify-start">
                        <img
                          key={image?.id}
                          onClick={() => handleImageChage(image, index)}
                          src={image?.product_images}
                          alt="no image"
                          className={`w-16 h-16 my-2 rounded-lg  p-1 ${
                            photoIndex === index
                              ? "border-2 border-blue-500"
                              : null
                          }`}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    <img className="w-16 h-16" src={pngwing} alt="..." />
                    <img className="w-16 h-16" src={pngwing} alt="..." />
                    <img className="w-16 h-16" src={pngwing} alt="..." />
                    <img className="w-16 h-16" src={pngwing} alt="..." />
                  </>
                )}
              </div>

              <div className=" flex items-center justify-center">
                <img
                  className=" w-6 h-6 xl:rotate-90 cursor-pointer rotate-0"
                  src={nextArow}
                  onClick={handleRight}
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.p_data}>
          <div className={styles.p_head}>
            <div>
              <div className={styles.newProd}>
                <h3 className=" text-xl " style={{ marginBottom: "0px" }}>
                  {item?.equip_name}
                </h3>
                <span className="rounded-full">
                  {item?.post_type === 1
                    ? "PRE OWNED"
                    : item?.post_type === 2
                    ? "NEW"
                    : item?.post_type === 3
                    ? "SPARE & ACCESSORIES"
                    : "SERVICES"}
                </span>
              </div>
              {/* <p>{item?.uid?.slice(0, 8)}</p> */}
            </div>
            <div className={styles.newProd}>
              <img
                src={pdShare}
                ref={ref}
                alt="..."
                onClick={handleSocial}
                style={{ width: "80px", cursor: "pointer" }}
              />
              {openSocial && (
                <div className={styles.socialShare}>
                  <SocialShare item={item} />
                </div>
              )}
            </div>
          </div>
          {/* {openMeeting && ( */}
          <ScheduleMeeting
            openMeeting={openMeeting}
            buyClick={buyClick}
            setBuyClick={setBuyClick}
            setMeeting={setMeeting}
            sellarClick={sellarClick}
            data={item}
            insp={inspection}
            address={item?.address}
          />
          {/* // )} */}
          <ProductMeta info={item} data={profile} />
          <div className=" border px-4">
            <div className={styles.prodDesclaimer}>
              <b style={{ fontSize: "12px" }}>Disclaimer : </b>
              <span style={{ fontSize: "12px" }}>
                Product details are submitted by the seller. {CompanyTitle} will
                carry out inspection and give you inspection report
                before you purchase.
              </span>
            </div>
          </div>

          <div className={styles.prodActLinks}>
            <NavLink
              className={styles.contactSellar}
              onClick={(e) => {
                sellarClick(e, false);
              }}
            >
              <img src={contBtn} height="15px" alt="..." />
              <span>
                {item?.post_type === 2 ? "ARRANGE DEMO" : "CONTACT SELLER"}
              </span>
            </NavLink>
            {isLogin ? (
              <NavLink
                to={`https://wa.me/${encodedPhoneNumber}`}
                target="_blank"
                style={{ backgroundColor: "#2EB943" }}
                className={styles.contactSellar}
              >
                <img src={whatsBtn} height="15px" alt="..." />
                <span>CHAT ON WHATSAPP</span>
              </NavLink>
            ) : (
              <NavLink
                style={{ backgroundColor: "#2EB943" }}
                onClick={sellarClick}
                className={styles.contactSellar}
              >
                <img src={whatsBtn} height="15px" alt="..." />
                <span>CHAT ON WHATSAPP</span>
              </NavLink>
            )}
            <NavLink
              style={{ backgroundColor: "#FFDD75", color: "black" }}
              onClick={(e) => {
                sellarClick(e, true);
              }}
              className={styles.contactSellar}
            >
              <img src={atcBtn} height="15px" alt="..." />
              <span>CLICK TO BUY NOW</span>
            </NavLink>
          </div>
        </div>
      </div>
      {getStart ? (
        <GetStarted setGetStart={setGetStart} setBlur={setBlur} />
      ) : (
        ""
      )}
      {isBlur ? <BackgroundBlur /> : ""}
      <div className={styles.pd_info}>
        <div className=" grid grid-cols-2 border-b-2 border-gray-200 bg-gray-100 gap-0">
          <div
            style={{ marginRight: "20px", padding: "10px", cursor: "pointer" }}
            onClick={() => setClick("details")}
            className={`${
              click == "details" ? styles.isActive : styles.isDeactive
            }`}
          >
            Specifications
          </div>
          {item.product_images?.length > 0 ? (
            <div
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={() => setClick("photo")}
              className={`${
                click == "photo" ? styles.isActive : styles.isDeactive
              }`}
            >
              PHOTOS
            </div>
          ) : null}
          {/* <div
            style={{ marginLeft: "20px" }}
            onClick={() => setClick("review")}
            className={`${
              click == "review" ? styles.isActive : styles.isDeactive
            }`}
          >
            REVIEWS
          </div> */}
        </div>
      </div>
      <div>
        {click === "details" ? (
          <ProductMetaData info={item} />
        ) : click === "photo" ? (
          <div>
            {item.product_video?.length > 0 && (
              <VideoSlider item={item?.product_video} />
            )}
            {item.product_images?.length > 0 && (
              <SliderComponent images={item?.product_images} />
            )}
          </div>
        ) : click === "review" ? (
          <ProductReview />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export const ProductMeta = ({ info, data }) => {
  let negotaiblity = [
    "",
    "Negotiable",
    "Slightly Negotiable",
    "Non-Negotiable",
  ];
  const desc = "This is product description";
  return (
    <React.Fragment>
      <div>
        <div className="-mt-4">
          <h3 className="text-2xl">
            ₹ {Number(info?.asking_price).toFixed(2)}
          </h3>
          <p className=" text-xs font-semibold">Including GST</p>
          {info?.post_type !== 2 && (
            <p className=" font-bold">
              ({negotaiblity[info?.negotiable_type]})
            </p>
          )}
        </div>
        <div style={{ marginTop: "20px" }} className={styles.pd_links}>
          <div className={styles.sellerName}>
            <img
              src={data?.profile_image ? `${data?.profile_image}` : testimage2}
              alt="..."
            />
            {`${info?.seller_firstname} ${info?.seller_lastname}` && (
              <p>
                {info?.seller_firstname}{" "}
                {info?.seller_lastname !== "undefined" &&
                  info?.seller_lastname !== "null" &&
                  info?.seller_lastname}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              width: "40%",
              fontSize: "10px",
            }}
          >
            <span>
              {info?.address && <ProdAddress address={info?.address} />}
            </span>
            <img src={location} alt="..." />
          </div>
        </div>
      </div>

      <div className="border px-4 pb-3">
        <span className=" text-lg font-semibold text-black">
          Product Details
        </span>
        <div
          className="text-gray-800 text-justify text-sm overflow-y-scroll scrollbar-visible lg:overflow-y-scroll xl:overflow-y-scroll"
          style={{
            height: "200px",
            scrollbarWidth: "bold",
            scrollbarColor: "green ",
          }}
          media="(min-width: 1024px) and (max-width: 1280px)"
        >
          <p className="pr-1 lg:pr-4 xl:pr-4 md:pr-2">
            {info?.description == "null" ? (
              ""
            ) : (
              <>
                {info?.description}
                <br />
                <br />
              </>
            )}
            {info?.other_details == "null" ? (
              ""
            ) : (
              <>
                Other Details: {info?.other_details}
                <br />
              </>
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const ProductImgVideo = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexImg, setCurrentIndexImg] = useState(0);
  const [displayVideo, setDisplayVideo] = useState([]);
  const [displayImg, setDisplayImg] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [toggel, setToggle] = useState(false);
  const ref = useRef();
  const [openImageCard, setOpenImageCard] = useState(false);

  const handleScrollRight = () => {
    setCurrentIndex((prev) => (prev % props?.info?.product_video?.length) + 1);
  };

  const handleScrollLeft = () => {
    setCurrentIndex(
      (prev) =>
        (prev - 1 + props?.info?.product_video?.length) %
        props?.info?.product_video?.length
    );
  };

  const handleScrollRightImg = () => {
    setCurrentIndexImg((prev) =>
      prev < props?.info?.product_images?.length - 1 ? prev + 1 : 0
    );
  };

  const handleScrollLeftImg = () => {
    setCurrentIndexImg((prev) =>
      prev > 0 ? prev - 1 : props?.info?.product_images?.length - 1
    );
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleScrollRightImg, // Swipe left to show next image
    onSwipedRight: handleScrollLeftImg, // Swipe right to show previous image
    preventDefaultTouchmoveEvent: true,
    trackTouch: true, // Ensure touch events are tracked for mobile
  });

  const handleShowImg = () => {
    if (props?.info?.product_images?.length > 0) {
      let startIndex = currentIndexImg % props?.info.product_images?.length;
      let display = [
        props?.info?.product_images[startIndex],
        props?.info?.product_images[
          (startIndex + 1) % props?.info?.product_images?.length
        ],
        props?.info?.product_images[
          (startIndex + 2) % props?.info?.product_images?.length
        ],
      ];
      setDisplayImg(display);
    }
  };

  useEffect(() => {
    handleShowImg();
  }, [currentIndexImg, props?.info?.product_images?.length > 0]);

  const handleShowVideo = () => {
    if (props?.info?.product_video?.length > 0) {
      let display = [
        props?.info?.product_video[
          currentIndex % props?.info?.product_video?.length
        ],
      ];
      setDisplayVideo(display);
    }
  };

  useEffect(() => {
    handleShowVideo();
  }, [currentIndex, props?.info?.product_video?.length > 0]);

  const handleImgPreview = (url) => {
    setImgUrl(url);
    setToggle(true);
  };

  const handleOk = () => {
    setToggle(false);
  };

  const handleCancel = () => {
    setToggle(false);
  };

  return (
    <>
      {displayVideo[0] !== undefined ? (
        <div className={styles.prodAsset}>
          <img
            src={swipetestleft}
            alt="..."
            onClick={handleScrollLeft}
            name="prev"
            className={styles.rlatedVideoPrev}
          />
          {displayVideo?.map((video) => {
            return (
              <>
                <video
                  src={video?.product_video}
                  controls
                  className={styles.videoView}
                ></video>
              </>
            );
          })}
          <img
            src={nextArow}
            onClick={handleScrollRight}
            className={styles.rlatedVideoNext}
            name="next"
            alt="..."
          />
        </div>
      ) : (
        ""
      )}
      <div className={styles.prodsImg}>
        <img
          src={swipetestleft}
          alt="..."
          onClick={handleScrollLeftImg}
          name="prev"
          className={styles.imgPrev}
        />
        <div className=" overflow-x-scroll flex flex-row w-full w-full">
          {displayImg?.map((image) => {
            return (
              <img
                key={image?.id}
                ref={ref}
                className={styles.imgPreview}
                src={image?.product_images}
                alt="..."
                onClick={() => handleImgPreview(image)}
              />
            );
          })}
        </div>
        <img
          src={nextArow}
          onClick={handleScrollRightImg}
          className={styles.imgNext}
          name="next"
          alt="..."
        />
      </div>
      {/* {...handlersd} */}
      <div>
        {toggel && (
          <div {...swipeHandlers}>
            <Lightbox
              mainSrc={
                props?.info?.product_images[currentIndexImg]?.product_images
              }
              nextSrc={
                props?.info?.product_images[
                  (currentIndexImg + 1) % props?.info?.product_images.length
                ]?.product_images
              }
              prevSrc={
                props?.info?.product_images[
                  (currentIndexImg + props?.info?.product_images.length - 1) %
                    props?.info?.product_images.length
                ]?.product_images
              }
              onCloseRequest={() => setToggle(false)}
              onMovePrevRequest={() => handleScrollLeftImg()}
              onMoveNextRequest={() => handleScrollRightImg()}
            />
          </div>
        )}
        {/* {toggel && (
        <Lightbox mainSrc={imgUrl?.product_images} onCloseRequest={handleOk} />
      )} */}
      </div>
    </>
  );
};

export const ProductMetaData = ({ info }) => {
  const productdedtails = [
    { pname: "Brand", pvalue: info?.brand },
    { pname: "Model", pvalue: info?.model },
    {
      pname: "Condition",
      pvalue:
        info?.equip_condition == 1
          ? "Good"
          : info?.equip_condition == 2
          ? "Excellent"
          : "As Good as New",
    },
    { pname: "Warranty", pvalue: info?.warranty == 1 ? "YES" : "NO" },
    {
      pname: "Shipping From",
      pvalue: <ProdAddress address={info?.address} />,
    },
    {
      pname: "Category",
      pvalue: info?.equipname_list
        ? info?.equipname_list.map((item) => item).join(", ")
        : "Ultrasound Machine",
    },
    { pname: "Posted", pvalue: info?.date },
    { pname: "Visits", pvalue: info?.visit ? info?.visit : "0" },
  ];
  return (
    <div className={styles.desTable}>
      {/* <h3 className={styles.ptitle}>Product Details</h3> */}
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Details
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Value
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y  divide-gray-200">
                {productdedtails.map((values, index) => (
                  <tr key={index}>
                    <th
                      scope="col"
                      className="px-6 border-l-2 py-2 whitespace-nowrap"
                    >
                      <span className="text-sm font-medium text-gray-900">
                        {values.pname}
                      </span>
                    </th>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <span
                        className="text-sm text-gray-500"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {values.pvalue}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProductReview = () => {
  const reviewData = new Array(4).fill(7);
  return (
    <React.Fragment>
      <div className={styles.prod_reive_continer}>
        <h4>4 RIVIEWS FOR VARIABLE PRODUCT</h4>
        <div className={styles.allReeviewCards}>
          {reviewData.map((values, index) => {
            return <ProductReviewCard key={index} />;
          })}
        </div>
      </div>
      <ReviewForm />
    </React.Fragment>
  );
};

export const ProductReviewCard = () => {
  return (
    <div className={styles.reviewParentCont}>
      <div className={styles.allReviewCont}>
        <div className={styles.userRevImg}>
          <img className={styles.userPic} src={testimage2} alt="" />
        </div>

        <div className={styles.userRevDetails}>
          <div className={styles.nameRating}>
            <div className={styles.dateName}>
              <p className={styles.reviewUserName}>ISHA SHARMA</p>
              <p className={styles.reviewUserDate}>17 JUNE 2023</p>
            </div>
            <div className={styles.userRating}>
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <p className={styles.finalRating}>4.5</p>
            </div>
          </div>
          <div className={styles.reviewDesc}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            ut libero odio. Nam elementum orci ut enim rutrum fringilla. Integer
            pellentesque semper erat id vestibulum. Vestibulum ultrices sapien
            orci, ut auctor ipsum maximus in.
          </div>
        </div>
      </div>
    </div>
  );
};

const RelatedProd = ({ selectedDisplayData }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchRelatedProducts();
  }, []);

  const fetchRelatedProducts = async () => {
    setLoading(true);
    try {
      const res = await postData1("product/filter_list/", {
        product_status: 1,
      });
      console.log("dddddd", res?.data?.featured_products);
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id")?.replace(/\//g, "");
      console.log("id", id);
      const filteredProducts = res?.data?.featured_products?.filter(
        (product) => product?.id !== parseInt(id)
      );
      console.log("filter products", filteredProducts);
      setRelatedProducts(filteredProducts);
      console.log("fffffffffff");
      setLoading(false);
    } catch (error) {
      console.log("Error in fetching related products", error);
      setRelatedProducts([]);
    }
  };

  const handleScrollLeft = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + relatedProducts?.length) % relatedProducts?.length
    );
  };

  const handleScrollRight = () => {
    setCurrentIndex((prev) => (prev + 1) % relatedProducts?.length);
  };

  useEffect(() => {
    const updateDisplayedData = () => {
      const displayed = [
        relatedProducts[currentIndex % relatedProducts?.length],
        relatedProducts[(currentIndex + 1) % relatedProducts?.length],
        relatedProducts[(currentIndex + 2) % relatedProducts?.length],
        relatedProducts[(currentIndex + 3) % relatedProducts?.length],
        relatedProducts[(currentIndex + 4) % relatedProducts?.length],
      ];
      setDisplayedData(displayed);
    };

    updateDisplayedData();
  }, [currentIndex, relatedProducts]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={styles.hzline}>
            <hr className={styles.line1} />
            <h3 className={styles.relprod}>RELATED PRODUCTS</h3>
            <hr className={styles.line2} />
          </div>
          <div
            style={{
              position: "relative",
              marginBottom: "40px",
              margin: window.innerWidth > 768 ? 0 : "20px",
            }}
          >
            <img
              src={swipetestleft}
              alt="..."
              onClick={handleScrollLeft}
              name="prev"
              className={styles.rlatedProdPrev}
            />
            <div className={styles.rowws}>
              {displayedData?.map((value, id) => {
                return <RelatedProdCard data={value} key={id} />;
              })}
            </div>
            <img
              src={nextArow}
              onClick={handleScrollRight}
              className={styles.rlatedProdNext}
              name="next"
              alt="..."
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

const ReviewForm = () => {
  const [ratinsStars, setRatingStar] = useState(new Array(5).fill(false));
  const [ratingErr, setRatingErr] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      review: "",
    },
    validationSchema: yup.object({
      name: fnameSchema,
      email: emailSchema,
      review: fnameSchema,
    }),
    onSubmit: function (values) {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    let rating = (() => {
      let count = 0;
      for (let i = 0; i < 5; i++) {
        if (ratinsStars[i]) {
          count += 1;
        }
      }
      return count;
    })();

    if (rating === 0) {
      setRatingErr(true);
      return;
    }
    setRatingErr(false);

    toast.success("Rating Added successfully", { autoClose: 2000 });
  };

  const handleStars = (e) => {
    let curr = parseInt(e.currentTarget.name);
    if (ratinsStars[curr]) {
      for (let i = curr; i <= 4; i++) {
        ratinsStars[i] = false;
      }
    } else {
      for (let i = 0; i <= curr; i++) {
        ratinsStars[i] = true;
      }
    }
    setRatingStar([...ratinsStars]);
  };
  return (
    <div>
      <div className={styles.reviewFormCont}>
        <h2 className={styles.ratingHeading}>ADD YOUR REVIEW HERE</h2>
        {ratingErr && (
          <p style={{ color: "red", marginBottom: "10px" }}>
            Rating not provided
          </p>
        )}
        <div className={styles.giveRatingCont}>
          <p className={styles.onlyStarCol}>STAR RATING</p>
          <div className={styles.giveRatingImg}>
            {ratinsStars.map((value, index) => {
              return (
                <img
                  onClick={handleStars}
                  key={index}
                  name={index}
                  className={styles.ratingStar}
                  src={value ? filledStar : unfilStar}
                  alt="..."
                />
              );
            })}
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate>
          <div
            className={
              styles.form_group +
              " " +
              styles.forOneLine +
              " " +
              styles.rateFormCol
            }
          >
            <div className={styles.rateFormName}>
              <label for="name">NAME</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter your name"
                required
              />
            </div>
            {formik.errors.name && formik.touched.name && (
              <div style={{ color: "red" }}>{formik.errors.name}</div>
            )}
            <div className={styles.form_group + " " + styles.rateFormName}>
              <label for="email">EMAIL ID</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Enter your email id"
                required
              />
            </div>
            {formik.errors.email && formik.touched.email && (
              <div style={{ color: "red" }}>{formik.errors.email}</div>
            )}
          </div>

          <div className={styles.form_group + " " + styles.rateFormCol}>
            <label for="review">REVIEW</label>
            <textarea
              className={styles.DescPlaceholder}
              id={styles.review}
              name="review"
              rows="4"
              placeholder="Enter your review in this box"
              onChange={formik.handleChange}
              value={formik.values.review}
              required
            ></textarea>
          </div>
          {formik.errors.review && formik.touched.review && (
            <div style={{ color: "red" }}>{formik.errors.review}</div>
          )}
          <input
            className={styles.reviewSubmit}
            type="submit"
            value="Submit Response"
          />
        </form>
      </div>
    </div>
  );
};

const MMQprocess = () => {
  const processData = [
    { name: "Find Equipment", img: findE },
    { name: "Schedule meeting with seller", img: schedule },
    { name: "Get Inspection Report", img: inspection },
    { name: "Close The Deal", img: closeDeal },
    { name: "Get it Shipped", img: shipped },
    { name: "Handling & Installation", img: handling },
    { name: "AMC/ CMC, Services", img: amc },
  ];
  return (
    <div className={styles.processCont}>
      {processData.map((value, index) => {
        return <ProcessCard key={index} curr={index} data={value} />;
      })}
    </div>
  );
};
const ProcessCard = (props) => {
  const currBuyStatus = useSelector((state) => state.profileData.currBuyStatus);
  const cardActiveStyle = {};
  if (currBuyStatus >= props.curr) {
    cardActiveStyle["backgroundColor"] = "aquamarine";
  } else {
    cardActiveStyle["boxShadow"] = "#343434 0px 2px 4px";
  }
  return (
    <div className={styles.processCard}>
      <div className={styles.procImg} style={cardActiveStyle}>
        <img src={props.data.img} alt="..." />
      </div>
      <span className=" text-sm">{props.data.name}</span>
    </div>
  );
};

