import React from "react";
import styles from "../../Admin/assets/css/ViewPage.module.css";
import { useLocation } from "react-router-dom";
export const ViewManageRequest = () => {
  const location = useLocation();
  const item = location?.state?.item;
  const createdDate = new Date(item?.created_date).toLocaleDateString();
  const priceRange = item?.price_range?.split("-");

  return (
    <section className={styles.viewPayment}>
      <h1>REQUIREMENTS MANAGEMENT</h1>

      <div className={styles.userDetails}>
        <div className={styles.data}>
          <div className={styles.name}>
            <h4>Id:</h4>
            <p>{item?.uid}</p>
          </div>
          <div className={styles.name}>
            <h4>BuyerName:</h4>
            <p>{item?.name}</p>
          </div>
          <div className={styles.name}>
            <h4>Address:</h4>
            <p>{item?.location}</p>
          </div>
        </div>
      </div>
      {/* product details */}

      <div className={styles.productDetails}>
        <h1>Details</h1>
        <div className={styles.pdata}>
          <div className={styles.det}>
            <div className={styles.demodata}>
              <h2>Requiest Id</h2>
              <p>{item?.id}</p>
            </div>

            <div className={styles.demodata}>
              <h2>Date</h2>
              <p>{createdDate}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Email</h2>
              <p>{item?.email}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Contact No.</h2>
              <p>{item?.contact_no}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Location</h2>
              <p>{item?.location}</p>
            </div>
            <div className={styles.demodata}>
              <h2>Message</h2>
              <p>{item?.message}</p>
            </div>
            {priceRange !== undefined && (
              <div className={styles.demodata}>
                <h2>Price Range</h2>
                <p>
                  <span>&#8377; {priceRange[0]} - </span>

                  <span>&#8377; {priceRange[1]}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
