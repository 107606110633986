import React from "react";
import * as XLSX from "xlsx";
const ExcelButton = ({ data, filename, sheetname }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetname || "Sheet 1");
    XLSX.writeFile(wb, filename || "exported-data.xlsx");
  };
  return (
    <div onClick={exportToExcel}>
      <h5>
        <span>
          <i class="bi bi-box-arrow-in-down"></i>
        </span>{" "}
        Export
      </h5>
    </div>
  );
};

export default ExcelButton;
