import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/css/manufacture/forms.module.css";
import {
  cancel,
  manIcon,
  meetSuccess,
  postDropdown,
} from "../../assets/images/index";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AdvtCategoriesDorpDown } from "../Sell/PostAdvt";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  emailSchema,
  pnumberSchema,
  stringFyName,
} from "../../utils/validation";
import PhoneInput from "react-phone-input-2";
import { postData1 } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProdAddData,
  fetchCategories,
  fetchSpecialityName,
  filterCategories,
  filterSpec,
  setCategories,
  setSpecality,
} from "../../app/Slices/ProdAddSlice";

export const AskType = (props) => {
  const navigate = useNavigate();
  const [selectedOpt, setSelectOpt] = useState(-1);

  const handleChange = (e) => {
    setSelectOpt(e.currentTarget.name);
  };

  const handleContinue = () => {
    if (selectedOpt === "1") {
      navigate("/distributor-form/", { state: { type: 1 } });
    } else if (selectedOpt === "2") {
      navigate("/manufacturer-form/", { state: { type: 2 } });
    } else {
      toast.info("Please Select Any One Option !", { autoClose: 2000 });
    }
    window.scrollTo(0, 0);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      {/* <div className={styles.askTypeCont}>
        <div className={styles.asktop}>
          <div className={styles.askPos}>
            <img style={{ marginRight: "8px" }} src={manIcon} alt="..." />
            <b>What’s your Position</b>
          </div>
          <div className={styles.crossBtn}>
            <i
              onClick={props.handleClick}
              style={{ fontSize: "30px", cursor: "pointer" }}
              className="bi bi-x"
            ></i>
          </div>
        </div>
        <p>
          Select one category from the following to help us identify which title
          suites you better.
        </p>
        <b>You are*</b>
        <div className={styles.askOptions}>
          <div>
            <input type="radio" onChange={handleChange} name="1" />
            <span>Distributor</span>
          </div>
          <div>
            <input type="radio" onChange={handleChange} name="2" />
            <span>Manufacturer</span>
          </div>
        </div>
        <div onClick={handleContinue} className={styles.continue}>
          CONTINUE
        </div>
      </div> */}
    </React.Fragment>
  );
};

export const DistributorFrom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  const [isSubmitted, setSubmitted] = useState(false);
  const [isPhone, setIsphone] = useState(true);
  const [phNumber, setphNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cate, setCat] = useState(false);
  const [width, setWidth] = useState();
  const categories = useSelector(
    (state) => state.addProd.prodAddData.categories
  );
  const categoriesList = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname?.split("/")[1] === "bussiness-with-us-form") {
      dispatch(clearProdAddData());
      dispatch(filterCategories([]));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      brand: "",
      busi_firm: "",
      website_name: "",
      res_person: "",
      // ph_number: "",
      email_id: "",
      city: "",
    },
    validationSchema: yup.object().shape({
      email_id: emailSchema,
      city: stringFyName,
      brand: stringFyName,
      busi_firm: stringFyName,
      website_name: stringFyName,
      res_person: stringFyName,
    }),
    onSubmit: function (values) {
      handleDistributerForm(values);
    },
  });

  const validatePhone = () => {
    if (phNumber?.length < 0) {
      setPhoneError("Phone number is required");
      return false;
    } else if (phNumber?.length < 10) {
      setPhoneError("Phone number must be 10 digits");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleDistributerForm = async (val) => {
    let categorie = categories?.map((el) => {
      return el?.id;
    });
    if (validatePhone() && categories?.length > 0) {
      const data = {
        user: userId?.uid,
        category_list: categorie?.join(","),
        product_deal: val?.brand,
        firm_name: val?.busi_firm,
        phone_no: phNumber.slice(2, 12),
        email: val?.email_id,
        responsible_person: val?.res_person,
        name: val?.res_person,
        website_name: val?.website_name,
        office_location: val?.city,
        usertype: 1,
      };
      const res = await postData1("product/add_dist/", data, true);
      if (res?.status) {
        toast.success(
          "Thank you for your interest in becoming a distributor! Your request will be reviewed by our team. Kindly await administrative approval"
        );
        dispatch(clearProdAddData());
        dispatch(filterCategories([]));
        formik.resetForm();
        setphNumber("");
      } else {
        toast.error("Email is already exist, please provide other email !");
      }
    } else {
      toast.error("All fields are mandatory !");
    }
  };

  const dropCat = {
    title: "Equipment Category",
    titleImp: "*",
    description: "Select the equipment Categories",
    dataList: categoriesList,
  };
  const handleBackPress = () => {
    dispatch(clearProdAddData());
    dispatch(filterCategories([]));
    navigate("/");
  };
  return (
    <>
      <ToastContainer />
      <div
        className={styles.distriCont}
        style={{ backgroundColor: isSubmitted ? "#e6e6e673" : "#FFFFFF" }}
      >
        {isSubmitted && <FormSubmitPopup setSubmitted={setSubmitted} />}
        <div className={styles.distriTop}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              onClick={handleBackPress}
              className="bi bi-arrow-left-short"
            ></i>
            <span style={{ cursor: "pointer" }} onClick={handleBackPress}>
              Back
            </span>
          </span>
        </div>
        <h3>DISTRIBUTOR LEAD FORM</h3>

        <form className={styles.distForm} onSubmit={formik.handleSubmit}>
          <div className={styles.distRow}>
            <div>
              <b>
                Responsible Person<span className={styles.titleImp}>*</span>
              </b>
              <input
                type="text"
                id="res_person"
                name="res_person"
                placeholder="Responsible person"
                value={formik.values.res_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.res_person && formik.errors.res_person ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.res_person}
                </span>
              ) : null}
            </div>
            <div>
              <b>
                Brands or Product you deal in
                <span className={styles.titleImp}>*</span>
              </b>
              <input
                id="brand"
                name="brand"
                type="text"
                placeholder="write the brands you deal in "
                value={formik.values.brand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.brand && formik.errors.brand ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.brand}
                </span>
              ) : null}
            </div>
          </div>

          <div className={styles.distRow}>
            <div>
              <b>
                Business/Firm Name<span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                type="text"
                id="busi_firm"
                name="busi_firm"
                placeholder="Business/Firm Name"
                value={formik.values.busi_firm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.busi_firm && formik.errors.busi_firm ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.busi_firm}
                </span>
              ) : null}
            </div>
            <div>
              <b>
                Website Name<span className={styles.titleImp}>*</span>
              </b>
              <input
                type="text"
                id="website_name"
                name="website_name"
                placeholder="Website name"
                value={formik.values.website_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.website_name && formik.errors.website_name ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.website_name}
                </span>
              ) : null}
            </div>
          </div>

          <div className={styles.distRow}>
            <div>
              <b
                onClick={() => {
                  setIsphone(true);
                }}
              >
                Phone Number <span className={styles.titleImp}>*</span>
              </b>
              {isPhone && (
                <>
                  <PhoneInput
                    country={"in"}
                    value={phNumber}
                    countryCodeEditable={false}
                    disableDropdown
                    inputStyle={{
                      width: width > 830 ? "353px" : "320",
                      padding: "17px 42px",
                    }}
                    onChange={(ph) => setphNumber(ph)}
                    onBlur={validatePhone}
                  />
                  {phoneError && (
                    <div style={{ color: "red" }}>{phoneError}</div>
                  )}
                </>
              )}
            </div>
            <div>
              <b>
                Email Id<span className={styles.titleImp}>*</span>
              </b>
              <input
                type="email"
                id="email_id"
                name="email_id"
                placeholder="Email"
                value={formik.values.email_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email_id && formik.errors.email_id ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.email_id}
                </span>
              ) : null}
            </div>
          </div>

          <div className={styles.distRow}>
            <div>
              <b>
                Location /City<span className={styles.titleImp}>*</span>
              </b>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Location /City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.city}
                </span>
              ) : null}
            </div>
            <div>
              <EquipCategories
                data={dropCat}
                width={width}
                categories={categories}
                valid={setCat}
                cate={cate}
              />
            </div>
          </div>
          <p className={styles.recheck}>
            Note : Recheck all the details before final submission of the form.
          </p>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button type="submit" className={styles.submitBtn}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const EquipCategories = (props) => {
  const dispatch = useDispatch();
  const [searchCate, setSearchCate] = useState("");
  const [filterCat, setFilterCat] = useState([]);
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  const handleSearchCategories = () => {
    const fill = props.data.dataList?.filter((el) => {
      if (el?.name?.toLowerCase()?.includes(searchCate?.toLowerCase())) {
        return el;
      }
    });
    setFilterCat(fill);
  };

  useEffect(() => {
    handleSearchCategories();
  }, [searchCate]);

  const handleCategoriesList = (event, val) => {
    let updateVal = { id: val?.id, name: val?.name };
    if (event.target.checked) {
      dispatch(setCategories(updateVal));
    } else {
      dispatch(filterCategories(updateVal));
    }
  };

  const handleCancelBtn = (item) => {
    dispatch(filterCategories(item));
    setSearchCate("");
  };

  return (
    <div ref={ref}>
      <div className={styles.specTag}>
        <p>
          {props.data.title}
          <span className={styles.titleImp}>{props.data.titleImp}</span>
        </p>
      </div>
      <div
        className={styles.selectEquipDiv}
        style={{ width: props?.width > 830 ? "353px" : "320" }}
        onClick={() => {
          setShow(true);
          props?.valid(true);
        }}
      >
        {!show ? (
          <>
            <p>{props.data.description}</p>
          </>
        ) : (
          <input
            type="text"
            placeholder="Enter Specality"
            style={{ border: "none", outline: "none" }}
            onChange={(e) => setSearchCate(e.target.value)}
          />
        )}
        <img className={styles.dropDownImage} src={postDropdown} alt="..." />
      </div>

      {show && (
        <div
          className={styles.checkBox}
          style={{ width: props?.width > 830 ? "353px" : "320" }}
        >
          {filterCat?.length > 0
            ? filterCat
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value, index) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value.id}
                        name="category"
                        style={{ height: "16px", width: "16px" }}
                        checked={
                          props?.categories
                            ? props?.categories?.some(
                                (el) => el.id === value.id
                              )
                            : false
                        }
                        onChange={(e) => handleCategoriesList(e, value)}
                      />
                      <label for="checkbox">{value?.name}</label>
                    </div>
                  );
                })
            : props?.data?.dataList
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value, index) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value?.id}
                        name="category"
                        style={{ height: "16px", width: "16px" }}
                        checked={
                          props?.categories
                            ? props?.categories?.some(
                                (el) => el.id === value.id
                              )
                            : false
                        }
                        onChange={(e) => handleCategoriesList(e, value)}
                      />
                      <label for="checkbox">{value?.name}</label>
                    </div>
                  );
                })}
        </div>
      )}
      {props?.categories?.length < 1
        ? props?.cate && (
            <span
              className={styles.enter_categories}
              style={{ color: "red", width: "80%" }}
            >
              Please enter categories !
            </span>
          )
        : null}
      <div className={styles.distFormCat}>
        {props?.categories?.map((el, index) => {
          return (
            <div className={styles.distFormSel}>
              <p className={styles.distformCont}>
                {el?.name}
                <span onClick={() => handleCancelBtn(el)}>
                  <i class="bi bi-x"></i>
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const MANUFACTURERForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  const [isSubmitted, setSubmitted] = useState(false);
  const [phNumber, setphNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cate, setCat] = useState(false);
  const [sp, setSp] = useState(false);
  const [width, setWidth] = useState();
  const Speciality = useSelector(
    (state) => state.addProd.prodAddData.specility
  );
  const categories = useSelector(
    (state) => state.addProd.prodAddData.categories
  );
  const categoriesList = useSelector(
    (state) => state.addProd.prodAddData.Equip_categories
  );
  const specialityList = useSelector(
    (state) => state.addProd.prodAddData.specialtiey_name
  );

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSpecialityName());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname?.split("/")[1] === "manufacturer-form") {
      dispatch(clearProdAddData());
      dispatch(filterCategories([]));
      dispatch(filterSpec([]));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      brand: "",
      busi_firm: "",
      res_person: "",
      ph_number: "",
      email_id: "",
      city: "",
    },
    validationSchema: yup.object().shape({
      email_id: emailSchema,
      city: stringFyName,
      brand: stringFyName,
      busi_firm: stringFyName,
      res_person: stringFyName,
    }),
    onSubmit: function (values) {
      handleManuFactureForm(values);
    },
  });

  const validatePhone = () => {
    if (phNumber?.length < 0) {
      setPhoneError("Phone number is required");
      return false;
    } else if (phNumber?.length < 10) {
      setPhoneError("Phone number must be 10 digits");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleManuFactureForm = async (val) => {
    const categorie = categories?.map((el) => {
      return el?.id;
    });
    if (validatePhone() && categories?.length > 0 && Speciality.length > 0) {
      const data = {
        user: userId?.uid,
        category_list: categorie.join(","),
        speciality_list: Speciality.join(","),
        product_deal: val?.brand,
        firm_name: val?.busi_firm,
        phone_no: phNumber.slice(2, 12),
        email: val?.email_id,
        responsible_person: val?.res_person,
        name: val?.res_person,
        office_location: val?.city,
        usertype: 2,
      };
      const res = await postData1("product/add_dist/", data, true);
      if (res?.status) {
        toast.success(
          "Thank you for your interest in becoming a Manufacturer! Your request will be reviewed by our team. Kindly await administrative approval"
        );
        dispatch(clearProdAddData());
        dispatch(filterCategories([]));
        dispatch(filterSpec([]));
        formik.resetForm();
        setphNumber("");
      } else {
        toast.error("Email is already exist, please provide other email !");
      }
    } else {
      toast.error("All fields are mandatory !");
    }
  };

  const dropCat = {
    title: "Equipment Category",
    titleImp: "*",
    description: "Select the equipment categories",
    dataList: categoriesList,
  };

  const dropSpe = {
    title: "Equipment Specality",
    titleImp: "*",
    description: "Select the equipment Specality",
    dataList: specialityList,
  };
  const handleBackPress = () => {
    dispatch(clearProdAddData());
    dispatch(filterCategories([]));
    dispatch(filterSpec([]));
    navigate("/");
  };
  return (
    <>
      <ToastContainer />
      <div
        className={styles.distriCont}
        style={{ backgroundColor: isSubmitted ? "#e6e6e673" : "#FFFFFF" }}
      >
        {isSubmitted && <FormSubmitPopup setSubmitted={setSubmitted} />}
        <div className={styles.distriTop}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <i
              style={{ cursor: "pointer" }}
              onClick={handleBackPress}
              className="bi bi-arrow-left-short"
            ></i>
            <span style={{ cursor: "pointer" }} onClick={handleBackPress}>
              Back
            </span>
          </span>
        </div>
        <h3>MANUFACTURER LEAD FORM</h3>

        <form className={styles.distForm} onSubmit={formik.handleSubmit}>
          <div className={styles.distRow}>
            <div>
              <b>
                Business/Firm Name<span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                type="text"
                id="busi_firm"
                name="busi_firm"
                placeholder="Business/Firm name"
                value={formik.values.busi_firm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.busi_firm && formik.errors.busi_firm ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.busi_firm}
                </span>
              ) : null}
            </div>
            <div>
              <b>
                Responsible Person<span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                type="text"
                id="res_person"
                name="res_person"
                placeholder="Responsible person"
                value={formik.values.res_person}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.res_person && formik.errors.res_person ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.res_person}
                </span>
              ) : null}
            </div>
          </div>

          <div className={styles.distRow}>
            <div>
              <b>
                Brands or Product you deal in{" "}
                <span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                id="brand"
                name="brand"
                type="text"
                placeholder="Write the brands you deal in "
                value={formik.values.brand}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.brand && formik.errors.brand ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.brand}
                </span>
              ) : null}
            </div>
            <div>
              <b>
                Phone Number<span className={styles.titleImp}>*</span>{" "}
              </b>
              <PhoneInput
                country={"in"}
                countryCodeEditable={false}
                disableDropdown
                value={phNumber}
                inputStyle={{
                  width: width > 830 ? "354px" : "320",
                  padding: "17px 42px",
                }}
                onChange={(ph) => setphNumber(ph)}
                onBlur={validatePhone}
              />
              {phoneError && <div style={{ color: "red" }}>{phoneError}</div>}
            </div>
          </div>

          <div className={styles.distRow}>
            <div>
              <b>
                Email Id <span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                type="email"
                id="email_id"
                name="email_id"
                placeholder="Email"
                value={formik.values.email_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email_id && formik.errors.email_id ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.email_id}
                </span>
              ) : null}
            </div>

            <div>
              <b>
                Office Location<span className={styles.titleImp}>*</span>{" "}
              </b>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="Office location"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <span style={{ color: "red", width: "80%", marginTop: "10px" }}>
                  {formik.errors.city}
                </span>
              ) : null}
            </div>
          </div>

          <div className={styles.distRow}>
            <div style={{ width: "365px", gap: "50px" }}>
              <EquipSpecaltiy
                data={dropSpe}
                width={width}
                Speciality={Speciality}
                valid={setSp}
                sp={sp}
              />
            </div>
            <div className={styles.manuEquipment}>
              <EquipCategories
                data={dropCat}
                width={width}
                categories={categories}
                valid={setCat}
                cate={cate}
              />
            </div>
          </div>
          <p className={styles.recheck}>
            Note : Recheck all the details before final submission of the form.
          </p>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button type="submit" className={styles.submitBtn}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const EquipSpecaltiy = (props) => {
  const dispatch = useDispatch();
  const [searchSpec, setSearchSpec] = useState("");
  const [filterSpecilty, setFilterSpec] = useState([]);
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  const handleSearchCategories = () => {
    const fill = props?.data?.dataList?.filter((el) => {
      if (el?.name?.toLowerCase()?.includes(searchSpec?.toLowerCase())) {
        return el;
      }
    });
    setFilterSpec(fill);
  };

  useEffect(() => {
    handleSearchCategories();
  }, [searchSpec]);

  const handleCategoriesList = (event) => {
    dispatch(setSpecality(event.target.value));
  };

  const handleCancelBtn = (item) => {
    dispatch(filterSpec(item));
    setSearchSpec("");
  };

  return (
    <div ref={ref}>
      <div className={styles.specTag}>
        <p>
          {props.data.title}
          <span className={styles.titleImp}>{props.data.titleImp}</span>
        </p>
      </div>
      <div
        className={styles.selectEquipDiv}
        style={{ width: props?.width > 830 ? "354px" : "320" }}
        onClick={() => {
          setShow(true);
          props?.valid(true);
        }}
      >
        {!show ? (
          <>
            <p>{props?.data?.description}</p>
          </>
        ) : (
          <input
            type="text"
            placeholder="Enter Categories"
            onChange={(e) => setSearchSpec(e.target.value)}
          />
        )}
        <img className={styles.dropDownImage} src={postDropdown} alt="..." />
      </div>

      {show && (
        <div
          className={styles.checkBox}
          style={{ width: props?.width > 830 ? "355px" : "320" }}
        >
          {filterSpecilty?.length > 0
            ? filterSpecilty
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value, index) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="specility"
                        value={value?.name}
                        name="specility"
                        style={{ height: "16px", width: "16px" }}
                        checked={props?.Speciality?.includes(value?.name)}
                        onChange={(e) => handleCategoriesList(e)}
                      />
                      <label for="checkbox">{value?.name}</label>
                    </div>
                  );
                })
            : props?.data?.dataList
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value, index) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="specility"
                        value={value?.name}
                        name="specility"
                        style={{ height: "16px", width: "16px" }}
                        checked={props?.Speciality?.includes(value?.name)}
                        onChange={(e) => handleCategoriesList(e)}
                      />
                      <label for="checkbox">{value?.name}</label>
                    </div>
                  );
                })}
        </div>
      )}
      {props?.Speciality?.length < 1
        ? props?.sp && (
            <span className={styles.enter_speciality}>
              Please enter speciality !
            </span>
          )
        : null}
      <div className={styles.distFormCont}>
        {props?.Speciality?.map((el, index) => {
          return (
            <div
              key={index}
              style={{
                width: "fit-content",
                boxSizing: "border-box",
                margin: "5px",
              }}
            >
              <p className={styles.distCont}>
                {el}

                <span onClick={() => handleCancelBtn(el)}>
                  <i class="bi bi-x"></i>
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const FormSubmitPopup = (props) => {
  return (
    <div className={styles.submitPopupCont}>
      <div className={styles.crossBtn}>
        <i
          onClick={() => props.setSubmitted(false)}
          style={{ fontSize: "30px", cursor: "pointer" }}
          className="bi bi-x"
        ></i>
      </div>
      <img src={meetSuccess} alt="meetSucees" />
      <h4>Form Submitted</h4>
      <p>Thanks for reaching out, we will get in touch soon.</p>
      <span>Continue</span>
    </div>
  );
};

export const BussinessWithUs = () => {
  const userStatus = localStorage.getItem("userDetails");
  let userTypeStatus = JSON.parse(userStatus);
  const [showDistributorForm, setShowDistributorForm] = useState(true);
  const [showManufacturerForm, setShowManufacturerForm] = useState(false);

  const handleDistributorClick = () => {
    setShowDistributorForm(true);
    setShowManufacturerForm(false);
  };

  const handleManufacturerClick = () => {
    setShowDistributorForm(false);
    setShowManufacturerForm(true);
  };

  useEffect(() => {
    if (userTypeStatus?.type == 2) {
      setShowDistributorForm(true);
    } else if (userTypeStatus?.type == 1) {
      setShowManufacturerForm(true);
    }
  }, []);

  return (
    <div className={styles.bussiness}>
      {/* <div className={styles.manuHeading}>
        <p>
          Select one category from the following to help us identify which title
          suites you better.
        </p>
      </div> */}
      <div className={styles.businessBtns}>
        <div className={styles.manuHeading}>
          <p
            style={{
              textAlign: "center",
              textWrap: "wrap",
              padding: "30px 20px 40px 20px",
            }}
          >
            Select one category from the following to help us identify which
            title suites you better.
          </p>
        </div>
        <div className={styles.handling_btns}>
          {/* <button
            onClick={handleDistributorClick}
            className={showDistributorForm ? styles.activeButton : ""}
          >
            Distributor
          </button>
          <button
            onClick={handleManufacturerClick}
            className={showManufacturerForm ? styles.activeButton : ""}
          >
            Manufacturer
          </button> */}
          {userTypeStatus.type == 2 ? (
            <button
              onClick={handleDistributorClick}
              className={showDistributorForm ? styles.activeButton : ""}
            >
              Distributor
            </button>
          ) : userTypeStatus.type == 1 ? (
            <button
              onClick={handleManufacturerClick}
              className={showManufacturerForm ? styles.activeButton : ""}
            >
              Manufacturer
            </button>
          ) : (
            <>
              <button
                onClick={handleDistributorClick}
                className={showDistributorForm ? styles.activeButton : ""}
              >
                Distributor
              </button>
              <button
                onClick={handleManufacturerClick}
                className={showManufacturerForm ? styles.activeButton : ""}
              >
                Manufacturer
              </button>
            </>
          )}
        </div>
      </div>

      {/* {showDistributorForm   && (
        <div>
          <DistributorFrom />
        </div>
      )}
      {showManufacturerForm  && (
        <div>
          <MANUFACTURERForm />
        </div>
      )} */}
      {userTypeStatus.type == 2 ? (
        <div>
          <DistributorFrom />
        </div>
      ) : userTypeStatus.type == 1 ? (
        <div>
          <MANUFACTURERForm />
        </div>
      ) : (
        <div>
          {showDistributorForm && (
            <div>
              <DistributorFrom />
            </div>
          )}
          {showManufacturerForm && (
            <div>
              <MANUFACTURERForm />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
