import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../assets/css/content.module.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { postData1 } from "../services";
import parse from "html-react-parser";

import "./styles.css";
import { advt1, advt2, video_Advt, dashAdvt } from "../assets/images/index";
import { useNavigate } from "react-router-dom";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const Advt = () => {
  const advtStyle1 = { backgroundImage: `url(${advt1})` };
  const advtStyle2 = { backgroundImage: `url(${advt2})` };

  return (
    <div className={styles.advt}>
      <div style={advtStyle1} className={styles.advt_1}></div>
      <div style={advtStyle2} className={styles.advt_1}></div>
    </div>
  );
};

export const Advt2 = (props) => {
  const blogRef = props?.blogRef;
  const advtStyle1 = { backgroundImage: `url(${video_Advt})` };

  return (
    <div className="relative" ref={blogRef}>
      <div className="h-20"></div>
      <div className={styles.advt_blogs} id="blog1">
        <h2 style={{ fontSize: "30px" }}>Blogs</h2>
        <div className={styles.swipeSlide}>
          <div className={styles.blogsContainer}>
            <div style={advtStyle1} className={styles.badvt}></div>
            <div>
              {" "}
              <MySwiper />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DashboardAdvt = (props) => {
  const dashStyle = {
    backgroundImage: `url(${dashAdvt})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "160px",
    // margin: "20px auto 50px auto",
    margin: "20px auto 10px auto",
  };
  return <div style={dashStyle}></div>;
};

const MySwiper = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  console.log("blogs", blogs);

  const readMore = (data) => {
    //navigate("/aboutDetail", { state: { item: data } });
    navigate(`/aboutDetail/${data?.uid}/${data?.name}`, { state: { item: data, title: data?.name } });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    (async () => {
      let data = { _page: 1, _limit: 3, is_deleted: 0, content_type: 3 };
      const res = await postData1("master/list_content/", data, false, false);
      if (res?.status_code === 200) {
        setBlogs(res?.data?.data);
      }
    })();
  }, []);

  const stripHtmlTags = (html) => {
    // Create a temporary DOM element to strip HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.innerText || tempDiv.textContent;
  };

  return (
    <div className={styles.redd}>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        //   className="mySwiper"
      >
        {blogs?.map((data, index) => {
          const descriptionText = stripHtmlTags(data?.description);

          return (
            <SwiperSlide key={index}>
              <div className={styles.articals}>
                {" "}
                <img
                  src={`${IMGURL}/mmq_apps/static/upload/content/${data?.image}`}
                  alt=""
                />
                <span>{new Date(data?.created_date).toDateString()}</span>
                {/* <div className={styles.articalsCont}>
                  <h4>{data?.name?.slice(0, 50)}</h4>
                  <p>{descriptionText.slice(0, 100)}</p>
                  <h5 onClick={() => readMore(data)}>Read More</h5>
                </div> */}
                <div className={styles.articalsCont}>
                  <h4>{data?.name?.slice(0, 50)}</h4>
                  <p>
                    {descriptionText.slice(0, 100)}
                    {descriptionText.length > 100 ? "..." : ""}
                  </p>
                  <h5 onClick={() => readMore(data)}>Read More</h5>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default MySwiper;
