import React, { useState } from "react";

const ServiceForm = ({ onClose, fields, serviceName }) => {

  const [formData, setFormData] = useState(
    fields.reduce((acc, field) => {
      acc[field.label] = "";
      return acc;
    }, {})
  );

  const handleChange = (label, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: value,
    }));
    console.log("first, formData", formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
    // You can handle form submission here (e.g., send data to an API)
    onClose(); // Close the popup after submission
  };
  return (
    <div
      style={{
        position: "fixed",
        inset: "0",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        
        zIndex: "999",
      }}
    >
      <div
        style={{
          backgroundColor: "#ffffff",
          padding: "24px",
          borderRadius: "12px",
          boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
          width: "100%",
          
          maxWidth: "400px",
          margin: "16px",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#6b7280",
            border: "none",
            color: "white",
            
            cursor: "pointer",
            outline: "none",
            backgroundColor: "gray",
            padding: "4px",
            borderRadius: "50%",
          }}
        >
          &#x2715;
        </button>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "24px",
            textAlign: "center",
            color: "#14b8a6",
          }}
        >
          {serviceName} Form
        </h2>
        <form onSubmit={handleSubmit}>
        {fields.map((field, index) => (
          <div key={index} style={{ marginBottom: "16px" }}>
            <label style={{ display: "block", color: "#374151" }}>
              {field.label}
            </label>
            {field.type === "textarea" ? (
              <textarea
              value={formData[field.label]}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                placeholder={field.placeholder}
                style={{
                  width: "100%",
                  marginTop: "4px",
                  padding: "12px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                  outline: "none",
                  transition: "all 0.2s ease",
                }}
                rows="4"
              ></textarea>
            ) : (
              <input
              value={formData[field.label]}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                type={field.type}
                placeholder={field.placeholder}
                style={{
                  width: "100%",
                  marginTop: "4px",
                  padding: "12px",
                  border: "1px solid #d1d5db",
                  borderRadius: "8px",
                  outline: "none",
                  transition: "all 0.2s ease",
                }}
              />
            )}
          </div>
        ))}
       

        <button
          type="submit"
          style={{
            width: "100%",
            padding: "12px",
            backgroundColor: "#14b8a6",
            color: "#ffffff",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            transition: "background-color 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#0d9488")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#14b8a6")}
        >
          Submit
        </button>
        </form>
      </div>
    </div>
  );
};

export default ServiceForm;
