import React, { useEffect } from "react";
import { postData } from "../services";

export const UsersAddress = ({ buyer, seller, setUsersAddress }) => {
  const handleBuyerAddress = async (id, userType) => {
    const formData = new FormData();
    formData.append("user", id);
    const res = await postData("users/address_list/", formData, true, true);
    if (res?.status) {
      setUsersAddress((preAddress) => ({
        ...preAddress,
        [userType]: res.data[0],
      }));
    }
  };
  useEffect(() => {
    if (buyer) {
      handleBuyerAddress(buyer, "buyer");
    }
    if (seller) {
      handleBuyerAddress(seller, "seller");
    }
  }, [buyer, seller]);
  return null;
};
