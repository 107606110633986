const BaseURL = process.env.REACT_APP_BASEURL;

const postData = async (url, formData, isAuthenticated, isAdmin) => {
  const headers = {
    // "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`;
  }
  try {
    const response = await fetch(`${BaseURL}/${url}`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: formData,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("Fetch error:", error.message);
  }
};

const postData1 = async (url, body, isAuthenticated, isAdmin) => {
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };

  if (isAuthenticated) {
    const token = localStorage.getItem(isAdmin ? "adminAuth" : "token");
    headers.Authorization = `Token ${token}`;
  }
  try {
    const response = await fetch(`${BaseURL}/${url}`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("Fetch error:", error.message);
  }
};

export { postData, postData1 };
