import React from "react";
import styles from "../assets/css/contactus.module.css";
import { NavLink } from "react-router-dom";
import { contactbg, whatsBtn } from "../assets/images";
import { useFormik } from "formik";
import * as yup from "yup";
import { emailSchema, mnumberSchema, stringFyName } from "../utils/validation";
import { postData1 } from "../services";
import { ToastContainer, toast } from "react-toastify";

export const Contactus = () => {
  const formik = useFormik({
    initialValues: {
      uName: "",
      uEmail: "",
      uContact: "",
      uLocation: "",
      uMessage: "",
    },
    validationSchema: yup.object().shape({
      uName: stringFyName,
      uLocation: stringFyName,
      uMessage: stringFyName,
      uContact: mnumberSchema,
      uEmail: emailSchema,
    }),
    onSubmit: function (values) {
      handleContactForm(values);
    },
  });

  const handleContactForm = async (val) => {
    const data = {
      name: val.uName,
      email: val.uEmail,
      contact_no: val.uContact,
      location: val.uLocation,
      message: val.uMessage,
    };
    const res = await postData1("contact/add/", data);
    if (res?.status) {
      formik.handleReset();
      toast.success(" Contact successfully Added !");
    }
  };
  return (
    <>
      <ToastContainer />
      <div className={styles.contactCont}>
        <NavLink to="/" className={styles.contactBack}>
          <i class="bi bi-arrow-left-short"></i>
          <span>Back</span>
        </NavLink>
        <div className={styles.contConnatiner}>
          <div className={styles.directContact}>
            <h4>Directly connect with us on</h4>
            <div className={styles.dCont}>
              <img src={whatsBtn} alt="whatsapp" />
              <span>+91 9898877887</span>
            </div>
            <div className={styles.dCont}>
              <a href="mailto:mymediequipp@gmail.com">
                <i class="bi bi-envelope"></i>
                <span>mymediequipp@gmail.com</span>
              </a>
            </div>
          </div>
          <div className={styles.contactD}>
            <img src={contactbg} alt="contactbg" />
            <div className={styles.contactForm}>
              <h1>CONTACT</h1>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.contRow}>
                  <div className={styles.contField}>
                    <span>
                      Name<b className={styles.inputImp}>*</b>
                    </span>
                    <input
                      type="text"
                      name="uName"
                      id="uName"
                      value={formik.values.uName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter your  full name"
                    />
                    {formik.touched.uName && formik.errors.uName ? (
                      <span style={{ color: "red", width: "100%" }}>
                        {formik.errors.uName}
                      </span>
                    ) : null}
                  </div>
                  <div className={styles.contField}>
                    <span>
                      Email Id<b className={styles.inputImp}>*</b>{" "}
                    </span>
                    <input
                      type="email"
                      name="uEmail"
                      id="uEmail"
                      value={formik.values.uEmail}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter your emal id"
                    />
                    {formik.touched.uEmail && formik.errors.uEmail ? (
                      <span style={{ color: "red", width: "100%" }}>
                        {formik.errors.uEmail}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={styles.contRow}>
                  <div className={styles.contField}>
                    <span>
                      Contact No.<b className={styles.inputImp}>*</b>
                    </span>
                    <input
                      type="number"
                      name="uContact"
                      id="uContact"
                      value={formik.values.uContact}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter your contact number"
                    />
                    {formik.touched.uContact && formik.errors.uContact ? (
                      <span style={{ color: "red", width: "100%" }}>
                        {formik.errors.uContact}
                      </span>
                    ) : null}
                  </div>
                  <div className={styles.contField}>
                    <span>
                      Location<b className={styles.inputImp}>*</b>
                    </span>
                    <input
                      type="text"
                      name="uLocation"
                      id="uLocation"
                      value={formik.values.uLocation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter your Location"
                    />
                    {formik.touched.uLocation && formik.errors.uLocation ? (
                      <span style={{ color: "red", width: "100%" }}>
                        {formik.errors.uLocation}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className={styles.contRow}>
                  <div className={styles.contField} id={styles.msgField}>
                    <span>
                      Message<b className={styles.inputImp}>*</b>
                    </span>
                    <textarea
                      placeholder="Enter your message"
                      rows={5}
                      name="uMessage"
                      id="uMessage"
                      value={formik.values.uMessage}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    {formik.touched.uMessage && formik.errors.uMessage ? (
                      <span style={{ color: "red", width: "100%" }}>
                        {formik.errors.uMessage}
                      </span>
                    ) : null}
                  </div>
                </div>
                <input
                  type="submit"
                  className={styles.contSubmit}
                  value="SEND"
                />
              </form>
            </div>
          </div>

          <div className={styles.reachUs}>
            <h4>Reach Us</h4>
            <div className={styles.reacrow}>
              {/* <div>
              <b>Information</b>
              <p>
                <a
                  style={{ color: "gray" }}
                  href="mailto:mymediequipp@gmail.com"
                >
                  mymediequipp@gmail.com
                </a>
              </p>
            </div> */}
              <div>
                <b>Customer Support</b>
                <p>support@equipmedy.com</p>
              </div>
            </div>
            {/* <div className={styles.reacrow} style={{ flexDirection: "column" }}>
            <b>Return</b>
            <p>return@mediequip.com</p>
          </div> */}

            <div
              className={styles.reacrow}
              style={{ borderTop: "1px solid #7C7C7C", paddingTop: "30px" }}
            >
              <div>
                <b>Customer Care</b>
                <p>
                  {" "}
                  <a style={{ color: "gray" }} href="tel:9813602268">
                    +91 8595903939
                  </a>
                </p>
              </div>
              {/* <div>
              <b>Social Media</b>
              <p>support@mediequip.com</p>
            </div> */}
            </div>
            <div style={{ borderTop: "1px solid #7C7C7C", paddingTop: "30px" }}>
              <b>Corporate Office</b>
              <p>
                B-99, 5th Floor, Phase-I Panchsheel Park Malviya Nagar, Near
                Triveni Complex New Delhi - 110017{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
