import React from "react";
import styles from "../../assets/css/buy/thanks.module.css";
import { NavLink, useLocation } from "react-router-dom";

export const ThanksPage = () => {
  const location = useLocation();
  let msg = location?.state?.msg;
  return (
    <div className={styles.thanksPage}>
      <div className={styles.thanksCard}>
        <div className={styles.thanksIcon}>
          <span>
            <i className="bi bi-check-lg"></i>
          </span>
        </div>
        <h4>Thank you</h4>
        <p>{msg}</p>
        <NavLink to="/profile/order-list/">Go to dashbaord</NavLink>
      </div>
    </div>
  );
};
