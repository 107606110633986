export const RenewsDate = (dateString) => {
  // Parse the date in the "dd/mm/yyyy" format
  const [day, month, year] = dateString?.split("/").map(Number);

  // Check if the date components are valid
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return "Invalid Date";
  }

  // Create a new Date object using the parsed components
  const parsedDate = new Date(year, month - 1, day);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getDate())) {
    return "Invalid Date";
  }

  // Format the date
  let n = parsedDate.getDate();
  let monthName = parsedDate.toLocaleDateString("en-US", {
    month: "short",
  });

  let ord = ["th", "st", "nd", "rd"];
  let exceptions = [11, 12, 13];
  let nth =
    ord[(n % 10) - 1] == undefined || exceptions?.includes(n % 100)
      ? "th"
      : ord[(n % 10) - 1];

  let getRenewDate = `${n}${nth} ${monthName}`;

  return getRenewDate;
};
