import React, { useState, useEffect, useRef } from "react";
import styles from "../../../assets/css/user/buyer_seller/ads.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { postData, postData1 } from "../../../services";
import { ImageUpload, postDropdown, editpen } from "../../../assets/images";
import { ToastContainer, toast } from "react-toastify";
import { unableDisableForm } from "../../../utils/purefun";
import { nullToEmptyString } from "../../../utils/purefun";
import { UsersAddress } from "../../../utils/UsersAddress";
import { Loader } from "../../../components/Loader";
import useClickOutside from "../../../customHooks/useClickOutside";

const AdsDetails = () => {
  const formRef = useRef(null);
  const yearRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state.item;
  const [loader, setLoader] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [images, setImages] = useState(item?.product_images);
  const [preImg, setPreImg] = useState([]);
  const [preVideos, setPrevideos] = useState([]);
  const [filterCategoriesName, setFilterCategories] = useState([]);
  const [specalitName, setFilterSpecalityName] = useState([]);
  const [videos, setVideos] = useState(item?.product_video);
  const [yearList, setYearList] = useState([]);
  const initialValues = {
    equip_name: item?.equip_name ? nullToEmptyString(item?.equip_name) : "",
    newEquipName: "",
    equip_location: "",
    prod_des: item?.description ? nullToEmptyString(item?.description) : "",
    prod_brand: item?.brand ? nullToEmptyString(item?.brand) : "",
    prod_model: item?.model ? nullToEmptyString(item?.model) : "",
    prod_negotation: item?.negotiable_type
      ? nullToEmptyString(String(item?.negotiable_type))
      : "",
    prod_condition: item?.equip_condition
      ? nullToEmptyString(String(item?.equip_condition))
      : "",
    prod_price: item?.asking_price
      ? nullToEmptyString(Number(item?.asking_price).toFixed(2))
      : "",
    prod_other_details: item?.other_details
      ? nullToEmptyString(item?.other_details)
      : "",
    purchase_year: item?.year ? nullToEmptyString(item.year) : "",
    prod_warranty: item?.warranty
      ? nullToEmptyString(String(item?.warranty))
      : "0",
    prod_amc: item?.existing_amc
      ? nullToEmptyString(String(item?.existing_amc))
      : "0",
  };
  const [formValue, setFormValue] = useState(initialValues);
  const [EquipName, setEuipName] = useState([]);
  const [selectOther, setSelectOther] = useState(false);
  const [editPrice, setEdit] = useState(false);
  const [categoriess, setCategories] = useState([]);
  const [specalities, setSpecalities] = useState([]);
  const [usersAddress, setUsersAddress] = useState({
    seller: "",
  });

  UsersAddress({
    seller: item?.user,
    setUsersAddress,
  });

  useEffect(() => {
    if (usersAddress?.seller) {
      setFormValue({
        ...formValue,
        equip_location: nullToEmptyString(usersAddress?.seller?.address),
      });
    }
  }, [usersAddress?.seller]);

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== "") {
      setFormValue({ ...formValue, [name]: value });
    } else {
      setFormValue({ ...formValue, [name]: "" });
    }
  };

  const handleFilterEquipName = () => {
    let fill = filterCategoriesName?.filter((el) => {
      const { name } = el;
      if (name?.toLowerCase().includes(formValue?.equip_name?.toLowerCase())) {
        return el;
      }
    });
    setEuipName(fill);
  };

  useEffect(() => {
    handleFilterEquipName();
  }, [formValue?.equip_name]);

  useEffect(() => {
    handleCategoryId();
    handleSpecalityName();
  }, []);

  const handleCategoryId = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("parent", 41);
    const res = await postData("product/category/menulist/", formData);
    if (res?.status) {
      const data = res?.data;
      const filterCategoriesName1 = data?.filter((el) => {
        return el.name !== "adminTest";
      });
      const filterCategoriesName = filterCategoriesName1?.filter((el) => {
        return el.name !== "test1";
      });
      setFilterCategories(filterCategoriesName);
      let newData = [];
      for (let i = 0; i < item?.equipname_list?.length; i++) {
        data?.filter((el) => {
          if (el?.name == item?.equipname_list[i]) {
            newData.push({ id: el?.id, name: el?.name });
          }
        });
      }
      setCategories(newData);
      setLoader(false);
    }
  };

  const handleSpecalityName = async () => {
    const res = await postData("product/speciality/lists/");
    if (res?.status) {
      const data = res?.data;
      const filterCategoriesName1 = data?.filter((el) => {
        return el.name !== "adminTest1";
      });
      const filterCategoriesName = filterCategoriesName1?.filter((el) => {
        return el.name !== "admintTest2";
      });
      setFilterSpecalityName(filterCategoriesName);

      let spec = item?.speciality_name?.split(",");
      let newData = [];
      for (let i = 0; i < spec?.length; i++) {
        data?.filter((el) => {
          if (el?.name == spec[i]) {
            newData.push({ id: el?.id, name: el?.name });
          }
        });
      }
      setSpecalities(newData);
    }
  };

  const handleDateList = () => {
    let date = new Date();
    let currentDate = date.getFullYear();
    let lastDate = date.setFullYear(date.getFullYear() - 15);
    let lastUpdateDate = new Date(lastDate).getFullYear();
    let list = [];
    for (let i = lastUpdateDate; i <= currentDate; i++) {
      list.push(i);
    }
    setYearList(list);
  };

  useEffect(() => {
    handleDateList();
  }, [item]);

  const handleYearChoose = (year) => {
    setFormValue({ ...formValue, purchase_year: year });
    setIsValid(false);
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNum = Math.random() * 10000;
    const uniqueId = `${timestamp}-${randomNum}`;
    return uniqueId;
  }

  const handleImges = (event) => {
    const current = event.target;
    const files = current.files;

    for (let i = 0; i < files?.length; i++) {
      const uid = generateUniqueId();
      const product_images = URL.createObjectURL(files[i]);
      const file = files[i];

      if (file.type.startsWith("image/")) {
        if (current.name === "image") {
          setImages([...images, { uid, product_images, file }]);
          setPreImg([...preImg, { uid, product_images, file }]);
        }
      } else if (file.type.startsWith("video/")) {
        if (current.name === "video") {
          setVideos([...videos, { uid, product_images, file }]);
          setPrevideos([...preVideos, { uid, product_images, file }]);
        }
      }
    }
  };

  const removeImges = async (id, index) => {
    if (item?.product_status != 4) {
      return;
    }
    const data = {
      uid: id,
    };
    const res = await postData1("product/delete_image/", data, true);
    if (res?.status) {
      setImages([...images]);
    } else {
      images.splice(index, 1);
      setImages([...images]);
      let fill = preImg?.filter((el) => {
        return el.uid !== id;
      });
      setPreImg(fill);
    }
  };

  const removeVideo = async (id, index) => {
    if (item?.product_status != 4) {
      return;
    }
    const data = {
      uid: id,
    };
    const res = await postData1("product/delete_video/", data, true);
    if (res?.status) {
      setVideos([...videos]);
    } else {
      videos.splice(index, 1);
      setVideos([...videos]);
      let fill = preVideos?.filter((el) => {
        return el.uid !== id;
      });
      setPrevideos(fill);
    }
  };

  const dropsp = {
    title: "Speciality",
    description: "Select the equipment Specialities",
    dataList: specalitName,
  };

  const dropCat = {
    title: "Category",
    description: "Select the equipment Categories",
    dataList: filterCategoriesName,
  };

  useEffect(() => {
    if (item?.product_status != 4) {
      unableDisableForm(formRef, true);
    }
  }, []);

  const handleClickOutSide = () => {
    setIsValid(false);
  };

  useClickOutside(yearRef, handleClickOutSide);

  const handleProductDetails = async (event) => {
    event.preventDefault();
    if (formValue.prod_price > 0) {
      let images = preImg?.map((el) => el?.file);
      let videos = preVideos?.map((el) => el?.file);
      const formData = new FormData();
      formData.append("post_type", item?.post_type);
      images.forEach((image) => {
        formData.append("images", image);
      });
      videos.forEach((video) => {
        formData.append("videos", video);
      });
      formData.append("equip_name", formValue?.equip_name);
      formData.append("address", item?.address);
      formData.append(
        "category_list",
        categoriess?.map((el) => el?.id)
      );
      formData.append(
        "speciality_name",
        specalities?.map((el) => el?.name)
      );

      if (formValue?.prod_condition) {
        formData.append(
          "equip_condition",
          formValue?.prod_condition ? formValue?.prod_condition : ""
        );
      }
      formData.append(
        "negotiable_type",
        formValue?.prod_negotation ? formValue?.prod_negotation : ""
      );
      formData.append(
        "asking_price",
        formValue?.prod_price
          ? editPrice
            ? `${Math.ceil(
                Number(formValue?.prod_price) +
                  Number(formValue?.prod_price * 5) / 100
              )}`
            : `${Math.ceil(Number(formValue?.prod_price))}`
          : ""
      );
      if (formValue?.prod_des) {
        formData.append("description", formValue?.prod_des);
      }
      if (formValue?.purchase_year) {
        formData.append(
          "year",
          formValue?.purchase_year ? formValue?.purchase_year : ""
        );
      }
      formData.append("brand", formValue?.prod_brand);
      formData.append("model", formValue?.prod_model);
      formData.append(
        "warranty",
        formValue?.prod_warranty ? formValue?.prod_warranty : ""
      );
      formData.append(
        "existing_amc",
        formValue?.prod_amc ? formValue?.prod_amc : ""
      );
      formData.append("other_details", formValue?.prod_other_details);
      formData.append("latitude", item?.latitude);
      formData.append("longitude", item?.longitude);
      formData.append("user", item?.user);
      formData.append("product_id", item?.id);

      const res = await postData("product/add/", formData, true);
      if (res.status) {
        toast.success("Product edit successfully !");
        setTimeout(() => {
          navigate("/profile/ads/");
        }, 2000);
      } else {
        toast.error(res?.msg);
      }
    } else {
      toast.error("Product price not be empty ! ", {
        autoClose: 2000,
        closeButton: false,
      });
    }
  };

  const blockInvalidKey = (e) =>
    ["e", "E", "-", "+", "."].includes(e.key) && e.preventDefault();

  const handleEditPrice = () => {
    setEdit(!editPrice);
  };

  useEffect(() => {
    if (editPrice) {
      setFormValue({ ...formValue, prod_price: "" });
    } else {
      setFormValue({
        ...formValue,
        prod_price: nullToEmptyString(Number(item?.asking_price).toFixed(2)),
      });
    }
  }, [editPrice]);

  return (
    <>
      <ToastContainer />
      <div className={styles.editAddCont}>
        <div
          onClick={() => {
            window.history.back(-1);
          }}
          className={styles.backTab}
        >
          <i class="bi bi-arrow-left-short"></i>
          <span>Back</span>
        </div>
        {loader && <Loader />}
        <form onSubmit={handleProductDetails} ref={formRef}>
          <h4 className={styles.formTitle}>Product Update</h4>
          <div className={styles.eqip_container}>
            <div className={styles.equip_innerContainer}>
              <label htmlFor="equip_name">Equipment Name</label>
              <input
                type="text"
                name="equip_name"
                value={formValue.equip_name}
                onChange={handleChangeValue}
                placeholder="Equip Name"
                autoComplete="off"
                className={styles.text_input}
              />
              {formValue?.equip_name?.length > 0 &&
                item?.equip_name?.length > formValue?.equip_name?.length && (
                  <div
                    className={
                      EquipName?.length > 5
                        ? styles.equipNameDrop
                        : EquipName?.length < 1
                        ? ""
                        : styles.equipNameDrop1
                    }
                  >
                    {EquipName?.length
                      ? EquipName?.slice()
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          ?.map((el) => {
                            return (
                              <>
                                <p
                                  onClick={() => {
                                    setFormValue({
                                      equip_name: el?.name,
                                    });
                                  }}
                                  className={styles.equipnameDropDown}
                                >
                                  {el?.name}
                                </p>
                              </>
                            );
                          })
                      : !selectOther && (
                          <div style={{ marginTop: "20px" }}>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <label>Other</label>
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                onClick={() => setSelectOther(!selectOther)}
                                checked={selectOther}
                              />
                            </span>
                          </div>
                        )}
                  </div>
                )}
              {selectOther && (
                <div style={{ marginTop: "20px" }}>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>Other</label>
                    <input
                      type="checkbox"
                      onClick={() => setSelectOther(!selectOther)}
                      checked={selectOther}
                    />
                  </span>
                  <div
                    className={styles.specifyed}
                    // style={{
                    //   display: "flex",
                    //   width: "100%",
                    //   alignItems: "center",
                    // }}
                  >
                    <label style={{ width: "100%" }}>
                      Please Specify Other
                    </label>
                    <input
                      type="text"
                      id="newEquipName"
                      name="newEquipName"
                      style={{ width: "50%" }}
                      placeholder="Enter New Equipment"
                      onChange={handleChangeValue}
                      value={formValue.newEquipName}
                      className={styles.text_input}
                      autoComplete="off"
                      disabled={selectOther === false}
                    />
                  </div>
                </div>
              )}
            </div>
            {item?.post_type != 3 && (
              <AdvCategories
                data={dropCat}
                prodStatus={item?.product_status}
                item={item}
                setCategories={setCategories}
                categoriess={categoriess}
              />
            )}
          </div>

          <div className={styles.eqip_container}>
            <div className={styles.equip_innerContainer}>
              <label htmlFor="equip_location">Location/City</label>
              <input
                type="text"
                name="equip_location"
                placeholder="Current Location/City"
                value={formValue.equip_location}
                onChange={handleChangeValue}
                className={styles.text_input}
              />
            </div>
            <AdvSpecality
              data={dropsp}
              prodStatus={item?.product_status}
              item={item}
              setSpecalities={setSpecalities}
              specalities={specalities}
            />
          </div>
          <div>
            {item?.post_type != 3 && (
              <h3 className={styles.title1}>Product Specifications :</h3>
            )}
            <div className={styles.eqip_container}>
              {item?.post_type === 1 && (
                <div className={styles.equip_innerContainer}>
                  <label htmlFor="purchase_year">
                    Manufacturing/ Purchase Year
                  </label>
                  <input
                    className={styles.text_input}
                    type="text"
                    name="purchase_year"
                    placeholder="Select the year"
                    value={formValue?.purchase_year}
                    onClick={() => setIsValid(true)}
                    autoComplete="off"
                  />
                  {isValid && (
                    <div ref={yearRef} className={styles.yearListSh}>
                      {yearList
                        ?.sort((a, b) => b - a)
                        ?.map((el) => {
                          return (
                            <p
                              className={styles.yearListDs}
                              onClick={() => handleYearChoose(el)}
                            >
                              {el}
                            </p>
                          );
                        })}
                    </div>
                  )}
                </div>
              )}
              <div className={styles.equip_innerContainer}>
                <label htmlFor="equip_Model">Model number :</label>
                <input
                  type="text"
                  name="prod_model"
                  placeholder="Equip Model"
                  value={formValue.prod_model}
                  onChange={handleChangeValue}
                  className={styles.text_input}
                />
              </div>
            </div>

            <div className={styles.eqip_container}>
              <div className={styles.equip_innerContainer}>
                <label htmlFor="equip_brand">Brand/Company : </label>
                <input
                  type="text"
                  name="prod_brand"
                  placeholder="Brand/company name"
                  value={formValue.prod_brand}
                  onChange={handleChangeValue}
                  className={styles.text_input}
                />
              </div>
              <div className={styles.equip_innerContainer}>
                <div className={styles.totalPrice}>
                  <div className={styles.currSymbol}>
                    <h3 className={styles.askprice}>
                      Asking Price <span className={styles.mandt}>*</span>
                      <span
                        className={styles.editPrice}
                        onClick={handleEditPrice}
                      >
                        <i class="bi bi-pencil-square"></i>
                      </span>
                    </h3>
                    <i className="bi bi-currency-rupee"></i>
                    <input
                      className={styles.text_input}
                      style={{ paddingLeft: "22px" }}
                      type="number"
                      step="0.01"
                      disabled={!editPrice && true}
                      onKeyDown={blockInvalidKey}
                      id={styles.rupee}
                      value={
                        formValue.prod_price !== null
                          ? !editPrice
                            ? Number(formValue.prod_price) -
                              (Number(formValue.prod_price) * 5) / 100
                            : formValue.prod_price
                          : ""
                      }
                      onChange={handleChangeValue}
                      name="prod_price"
                    />
                    <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                      Platform fee including GST*
                    </p>
                  </div>
                  <div
                    className={styles.currSymbol}
                    style={{ marginTop: "4px" }}
                  >
                    <h3 className={styles.askprice}>
                      Platform Fee*{" "}
                      <span className={styles.priceSpan}> (in INR*)</span>
                    </h3>
                    <i className="bi bi-currency-rupee"></i>
                    <input
                      className={styles.text_input}
                      style={{ paddingLeft: "22px" }}
                      type="number"
                      step="0.01"
                      disabled="true"
                      onKeyDown={blockInvalidKey}
                      id={styles.rupee}
                      value={
                        formValue.prod_price !== null
                          ? (Number(formValue.prod_price) * 5) / 100
                          : ""
                      }
                      onChange={handleChangeValue}
                      name="prod_price"
                    />
                  </div>
                  <div
                    className={styles.currSymbol}
                    style={{ marginTop: "4px" }}
                  >
                    <h3 className={styles.askprice}>
                      Total Price*{" "}
                      <span className={styles.priceSpan}> (in INR*)</span>
                    </h3>
                    <i className="bi bi-currency-rupee"></i>
                    <input
                      className={styles.text_input}
                      style={{ paddingLeft: "22px" }}
                      type="number"
                      step="0.01"
                      disabled="true"
                      onKeyDown={blockInvalidKey}
                      id={styles.rupee}
                      value={
                        formValue.prod_price !== null
                          ? !editPrice
                            ? Number(item?.asking_price).toFixed(2)
                            : Number(formValue.prod_price) +
                              Number(formValue.prod_price * 5) / 100
                          : ""
                      }
                      onChange={handleChangeValue}
                      name="prod_price"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {item?.post_type != 3 && (
            <div className={styles.eqip_container}>
              <div className={styles.equip_innerContainer}>
                <h3 className={styles.title}>Negotiable</h3>
                <div>
                  <div className={styles.radios}>
                    <div>
                      <input
                        type="radio"
                        value="1"
                        name="prod_negotation"
                        checked={formValue.prod_negotation === "1"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>Negotiable</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="2"
                        name="prod_negotation"
                        checked={formValue.prod_negotation === "2"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>Slightly Negotiable</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="3"
                        name="prod_negotation"
                        checked={formValue.prod_negotation === "3"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>Non-Negotiable</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {item?.post_type === 1 && (
            <div className={styles.eqip_container}>
              <div className={styles.equip_innerContainer}>
                <h3 className={styles.title}>Equipment Condition</h3>
                <div>
                  <div className={styles.radios}>
                    <div>
                      <input
                        type="radio"
                        value="1"
                        name="prod_condition"
                        checked={formValue.prod_condition === "1"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>Good</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="2"
                        name="prod_condition"
                        checked={formValue.prod_condition === "2"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>Excellent</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="3"
                        name="prod_condition"
                        checked={formValue.prod_condition === "3"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>As Good as New</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {item?.post_type != 3 && (
            <div className={styles.eqip_container}>
              <div className={styles.equip_innerContainer}>
                <h3 className={styles.title}>Under Warranty : </h3>
                <div>
                  <div className={styles.radio_type}>
                    <div>
                      <input
                        type="radio"
                        value="1"
                        name="prod_warranty"
                        checked={formValue.prod_warranty === "1"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>YES</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="0"
                        name="prod_warranty"
                        checked={formValue.prod_warranty === "0"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>NO</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* {item?.post_type === 1 && ( */}
              <div className={styles.equip_innerContainer}>
                <h3 className={styles.title}>Existing AMC/CME :</h3>
                <div>
                  <div className={styles.radio_type}>
                    <div>
                      <input
                        type="radio"
                        value="1"
                        name="prod_amc"
                        checked={formValue.prod_amc === "1"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>YES</label>
                    </div>
                    <div>
                      <input
                        className={styles.rd}
                        type="radio"
                        value="0"
                        name="prod_amc"
                        checked={formValue.prod_amc === "0"}
                        onChange={handleChangeValue}
                      />
                      <label className={styles.rdt}>NO</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          )}

          <div className={styles.medContainer}>
            <MediaComp
              media={images}
              dtype="image"
              accept="image/*"
              setMedia={handleImges}
              removeMedia={removeImges}
              prodStatus={item?.product_status}
            />
            <MediaComp
              media={videos}
              dtype="video"
              accept="video/*"
              prodStatus={item?.product_status}
              setMedia={handleImges}
              removeMedia={removeVideo}
            />
          </div>
          <div className={styles.eqip_container}>
            {item?.post_type === 1 && (
              <div className={styles.equip_innerContainer}>
                <label htmlFor="prod_des">Product Description</label>
                <textarea
                  type="text"
                  name="prod_des"
                  placeholder="Enter Product desc"
                  value={formValue.prod_des}
                  onChange={handleChangeValue}
                  className={styles.textarea_sty}
                />
              </div>
            )}

            <div className={styles.equip_innerContainer}>
              <label htmlFor="prod_other_details">Other Description :</label>
              <textarea
                type="text"
                placeholder="other_des"
                name="prod_other_details"
                value={formValue.prod_other_details}
                onChange={handleChangeValue}
                className={styles.textarea_sty}
              />
            </div>
          </div>
          <p style={{ marginTop: "3rem" }}>
            Please Note : Once Seller Added the details, it will go for the
            admin approval, after admin approved them only this will be visible
            on home page
          </p>
          <input type="submit" className={styles.bttn} value="Submit" />
        </form>
      </div>
    </>
  );
};

export default AdsDetails;

const AdvCategories = (props) => {
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilterData] = useState([]);
  const ref = useRef();

  const customStyle = {
    backgroundColor: props.prodStatus === 4 ? "#FFFFFF" : "#d8dbe0",
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  const handleCategoriesName = (event, val) => {
    let updateVal = { id: val?.id, name: val?.name };
    if (event.target.checked) {
      let prev = props?.categoriess;
      props?.setCategories([...prev, updateVal]);
    } else {
      let data = props?.categoriess;
      let fill = data?.filter((el) => {
        return el?.id !== val?.id;
      });
      props?.setCategories(fill);
    }
  };

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilterData(fil);
  };

  const handleCancelBtn = (id) => {
    let data = props?.categoriess;
    let fill = data?.filter((el) => {
      return el?.id !== id;
    });
    props?.setCategories(fill);
    setFilterName("");
  };

  return (
    <div className={styles.equip_innerContainer} ref={ref}>
      <div className={styles.specTag}>
        <p>{props?.data?.title}</p>
      </div>
      <div
        className={styles.selectEquipDiv}
        onClick={() => setShow(true)}
        style={customStyle}
      >
        {!show ? (
          <>
            <p>
              {props?.categoryName?.category_list
                ? props?.categoryName?.category_list
                : props?.data?.description}
            </p>
            <img
              className={styles.dropDownImage}
              src={postDropdown}
              alt="..."
            />
          </>
        ) : (
          <input
            className={styles.inputField}
            onChange={(e) => setFilterName(e.target.value)}
            type="text"
            placeholder="Enter Categories"
            // disabled={props.prodStatus != 4}
          />
        )}
      </div>

      {show && (
        <div className={styles.checkBox}>
          {filterData?.length > 0
            ? filterData
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value.id}
                        name="category"
                        onChange={(e) => handleCategoriesName(e, value)}
                        checked={
                          props?.categoriess
                            ? props?.categoriess?.some(
                                (el) => el.id === value.id
                              )
                            : false
                        }
                      />
                      <label for="checkbox1">{value.name}</label>
                    </div>
                  );
                })
            : props?.data?.dataList
                ?.slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((value) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value?.id}
                        name="category"
                        checked={
                          props?.categoriess
                            ? props?.categoriess?.some(
                                (el) => el?.id === value?.id
                              )
                            : false
                        }
                        onChange={(e) => handleCategoriesName(e, value)}
                      />
                      <label for="checkbox1">{value.name}</label>
                    </div>
                  );
                })}
        </div>
      )}
      <div className={styles.catSelect}>
        {props?.categoriess?.map((el, index) => {
          return (
            <div className={styles.catSelectClose} key={index}>
              <p>
                {el?.name}
                <span onClick={() => handleCancelBtn(el?.id)}>
                  <i class="bi bi-x" style={{ cursor: "pointer" }}></i>
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AdvSpecality = (props) => {
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterData, setFilterData] = useState([]);
  const ref = useRef();

  const customStyle = {
    backgroundColor: props.prodStatus === 4 ? "#FFFFFF" : "#d8dbe0",
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    });
  }, []);

  const handleCategoriesName = (event, val) => {
    let updateVal = { id: val?.id, name: val?.name };
    if (event.target.checked) {
      let prev = props?.specalities;
      props?.setSpecalities([...prev, updateVal]);
    } else {
      let data = props?.specalities;
      let fill = data?.filter((el) => {
        return el?.id !== val?.id;
      });
      props?.setSpecalities(fill);
    }
  };

  useEffect(() => {
    handleCatInput();
  }, [filterName]);

  const handleCatInput = () => {
    const fil = props?.data?.dataList?.filter((el) => {
      const { name } = el;
      if (name.toLowerCase().includes(filterName.toLowerCase())) {
        return el;
      }
    });
    setFilterData(fil);
  };

  const handleCancelBtn = (id) => {
    let data = props?.specalities;
    let fill = data?.filter((el) => {
      return el?.id !== id;
    });
    props?.setSpecalities(fill);
    setFilterName("");
  };

  return (
    <div className={styles.equip_innerContainer} ref={ref}>
      <div className={styles.specTag}>
        <p>{props?.data?.title}</p>
      </div>
      <div
        className={styles.selectEquipDiv}
        onClick={() => setShow(true)}
        style={customStyle}
      >
        {!show ? (
          <>
            <p>
              {props?.categoryName?.category_list
                ? props?.categoryName?.category_list
                : props?.data?.description}
            </p>
            <img
              className={styles.dropDownImage}
              src={postDropdown}
              alt="..."
            />
          </>
        ) : (
          <input
            className={styles.inputField}
            onChange={(e) => setFilterName(e.target.value)}
            type="text"
            placeholder="Enter Speciality"
            disabled={props.prodStatus != 4}
          />
        )}
      </div>

      {show && (
        <div className={styles.checkBox}>
          {filterData?.length > 0
            ? filterData
                ?.slice()
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((value) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value.id}
                        name="category"
                        onChange={(e) => handleCategoriesName(e, value)}
                        checked={
                          props?.specalities
                            ? props?.specalities?.some(
                                (el) => el.id === value.id
                              )
                            : false
                        }
                      />
                      <label for="checkbox1">{value.name}</label>
                    </div>
                  );
                })
            : props?.data?.dataList
                ?.slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((value) => {
                  return (
                    <div className={styles.checkboxCont}>
                      <input
                        type="checkbox"
                        id="category"
                        value={value?.id}
                        name="category"
                        checked={
                          props?.specalities
                            ? props?.specalities?.some(
                                (el) => el?.id === value?.id
                              )
                            : false
                        }
                        onChange={(e) => handleCategoriesName(e, value)}
                      />
                      <label for="checkbox1">{value.name}</label>
                    </div>
                  );
                })}
        </div>
      )}
      <div className={styles.catSelect}>
        {props?.specalities?.map((el, index) => {
          return (
            <div className={styles.catSelectClose} key={index}>
              <p>
                {el?.name}
                <span onClick={() => handleCancelBtn(el?.id)}>
                  <i class="bi bi-x" style={{ cursor: "pointer" }}></i>
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
const MediaComp = ({
  media,
  setMedia,
  dtype,
  accept,
  removeMedia,
  prodStatus,
}) => {
  const customStyle = {
    backgroundColor: prodStatus === 4 ? "#FFFFFF" : "#d8dbe0",
  };
  return (
    <div>
      <h4 style={{ marginBottom: "10px", textTransform: "capitalize" }}>
        {dtype}
      </h4>
      <div className={styles.mediaDataCont} style={customStyle}>
        <label for={"inputMedi" + dtype}>
          <input
            type="file"
            id={"inputMedi" + dtype}
            multiple
            accept={accept}
            onChange={setMedia}
            name={dtype}
          />
          <img src={ImageUpload} style={{ cursor: "pointer" }} alt="Upload" />
        </label>
        {media?.map((value, index) => {
          if (dtype === "image") {
            return (
              <>
                <div className={styles.medData} key={index}>
                  <img src={value?.product_images} alt="Upload" />
                </div>
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-x"
                  onClick={() => removeMedia(value?.uid, index)}
                ></i>
              </>
            );
          } else {
            return (
              <>
                <div className={styles.medData} key={index}>
                  <video width="320" height="240" controls>
                    <source src={value} type="video/mp4" />
                  </video>
                </div>
                <i
                  style={{ cursor: "pointer" }}
                  className="bi bi-x"
                  onClick={(e) => removeMedia(value?.uid, index)}
                  name={index}
                ></i>
              </>
            );
          }
        })}
      </div>
    </div>
  );
};
