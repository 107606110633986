import React, { useEffect, useState } from "react";
import styles from "../../assets/css/forms.module.css";
import commonCss from "../../assets/css/common.module.css";
import defaultProfile from "../../../assets/images/testimage1.png";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  emailSchema,
  fnameSchema,
  gstinSchema,
  lnameSchema,
  mnumberSchema,
  nationalitySchema,
  pancardSchema,
} from "../../../utils/validation";
import { postData1 } from "../../../services";
import { ToastContainer, toast } from "react-toastify";

export const ScheduleMeetForm = () => {
  const [isDisable, setisDisable] = useState(true);
  const [perm, updatePerm] = useState(true);
  const [pricing, setPricing] = useState({ negotiate: 0, shipping: 0 });
  const [Add, setAdd] = useState(false);
  const location = useLocation();
  const userData = location.state;

  useEffect(() => {
    (async () => {
      const res = await postData1(
        "product/order/get_order_shipping_price/",
        { buyer: userData?.buyer, product: userData?.product },
        true,
        true
      );
      if (res?.status_code === 200) {
        pricing["negotiate"] = res?.data?.negotiable_price;
        pricing["shipping"] = res?.data?.shipping_price;
        setPricing({ ...pricing });
        setAdd(res?.data?.id);
        formik.setValues({
          negotiableDealPrice: res?.data?.negotiable_price,
          shippingPrice: res?.data?.shipping_price,
        });
      }
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      id: userData?.id,
      link: userData?.link,
      prod: userData?.product,
      buyerFname: userData?.buyer_firstname,
      buyerLname: userData?.buyer_lastname,
      sellerFname: userData?.seller_firstname,
      sellerLname: userData?.seller_lastname,
      sDate: userData?.date,
      duration: userData?.duration,
      endTime: userData?.end_time,
      startTime: userData?.start_time,
      title: userData?.title,
      negotiableDealPrice: 0,
      shippingPrice: 0,
    },

    onSubmit: function (values) {
      // handleSubmit(values);
    },
  });

  const updateMeetLink = async () => {
    let value = formik?.values;
    if (value?.link?.length === 0) {
      toast.error("Please Provide Meeting Link !");
      return;
    }

    const res = await postData1(
      "admin/update_schedulemeeting/",
      {
        uid: userData?.uid,
        link: value?.link,
      },
      true,
      true
    );

    if (res?.status_code === 200) {
      toast.success("Link added successful !");
    } else {
      toast.error("Something Went Wrong, Try Again !");
    }
  };

  const updatePrice = async (nego_amt, ship_amt) => {
    let data = {
      buyer: userData?.buyer,
      product: userData?.product,
      negotiable_price: nego_amt,
      shipping_price: ship_amt,
    };
    if (Add) {
      data["uid"] = Add;
    }
    const res = await postData1(
      "product/order/add_order_shipping_price/",
      data,
      true,
      true
    );
    if (res?.status_code === 200) {
      pricing["negotiate"] = res?.data?.negotiable_price;
      pricing["shipping"] = res?.data?.shipping_price;
      setPricing({ ...pricing });
      toast.success("Price update successful!");
    } else {
      toast.error("Something went wrong try again !");
    }
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      <h2>
        Detail Info
        {/* <i onClick={()=>setisDisable(!isDisable)} style={{cursor:"pointer"}} className="bi bi-pencil-square"></i>  */}
      </h2>
      <form
        className={styles.updateForm}
        disabled={true}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formFieds}>
          <div>
            <p>Product Id</p>
            <input
              disabled={true}
              type="text"
              name="id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.id}
            />
          </div>
          <div>
            <p>Product</p>
            <input
              disabled={true}
              type="text"
              name="prod"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.prod}
            />
          </div>
          <div>
            <p>Buyer First Name</p>
            <input
              disabled={true}
              type="text"
              name="buyerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.buyerFname}
            />
          </div>
          <div>
            <p>Buyer Last Name</p>
            <input
              disabled={true}
              type="text"
              name="buyerLname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.buyerLname}
            />
          </div>
          <div>
            <p>Seller First Name</p>
            <input
              disabled={true}
              type="text"
              name="sellerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerFname}
            />
          </div>
          <div>
            <p>Seller Last Name</p>
            <input
              disabled={true}
              type="text"
              name="sellerLname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerLname}
            />
          </div>
          <div>
            <p>Start Date</p>
            <input
              disabled={true}
              type="text"
              name="sDate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sDate}
            />
          </div>
          <div>
            <p>Start Time</p>
            <input
              disabled={true}
              type="text"
              name="startTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.startTime}
            />
          </div>
          <div>
            <p>End Time</p>
            <input
              disabled={true}
              type="text"
              name="endTime"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.endTime}
            />
          </div>

          <div>
            <p>Duration</p>
            <input
              disabled={true}
              type="text"
              name="duration"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.duration}
            />
          </div>
          <div>
            <p>Title</p>
            <input
              disabled={true}
              type="text"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />
          </div>
        </div>
        {/* <input disabled={isDisable} className={styles.submitTab} type="submit" value="Update"/> */}
      </form>

      <div className={styles.ProdActions}>
        <div className={styles.pAct}>Actions</div>
        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Meeting Link</span>
          <input
            name="link"
            onChange={formik.handleChange}
            type="text"
            className={styles.actInput}
            value={formik.values.link}
            placeholder="Enter link"
          />
          <span className={commonCss.applyBtn} onClick={updateMeetLink}>
            Apply
          </span>
        </div>
        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Negotiable Deal Price</span>
          <input
            type="number"
            className={styles.actInput}
            name="negotiableDealPrice"
            onChange={formik.handleChange}
            value={formik.values.negotiableDealPrice}
            placeholder="Enter price"
          />
          <span
            className={commonCss.applyBtn}
            onClick={() =>
              updatePrice(formik.values.negotiableDealPrice, pricing?.shipping)
            }
          >
            Apply
          </span>
        </div>
        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Shipping Price</span>
          <input
            type="number"
            className={styles.actInput}
            placeholder="Enter price"
            name="shippingPrice"
            onChange={formik.handleChange}
            value={formik.values.shippingPrice}
          />
          <span
            className={commonCss.applyBtn}
            onClick={() =>
              updatePrice(pricing?.negotiate, formik.values.shippingPrice)
            }
          >
            Apply
          </span>
        </div>
      </div>
    </section>
  );
};
