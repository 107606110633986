import React, { useEffect, useState } from 'react';

const FilterPanel = ({ data, onFilterChange }) => {
  const [filterData, setFilteredData] = useState(data);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [openParent, setOpenParent] = useState(null);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleCheckboxChange = (id, name, children = []) => {
    const isParent = children.length > 0;
    let updatedFilters;

    const isSelected = selectedFilters.some((item) => item.id === id);

    if (isSelected) {
      updatedFilters = selectedFilters.filter(
        (item) => item.id !== id && !children.some((child) => child.id === item.id)
      );
    } else {
      const newSelections = [{ id, name }];
      if (isParent) {
        children.forEach((child) => {
          newSelections.push({ id: child.id, name: child.name });
        });
      }
      updatedFilters = [...selectedFilters, ...newSelections];
    }

    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const toggleParent = (parentId) => {
    setOpenParent(openParent === parentId ? null : parentId);
  };

  const renderChildren = (children) => {
    return children.map((child) => (
      <li key={child.id} className="flex items-center">
        <input
          type="checkbox"
          checked={selectedFilters.some((item) => item.id === child.id)}
          onChange={() => handleCheckboxChange(child.id, child.name)}
          className="mr-2 text-green-600 focus:ring-green-500 h-4 w-4"
        />
        <span className="text-gray-600 text-sm">{child.name}</span>
      </li>
    ));
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const renderParents = () => {
    return filterData.map((parent) => (
      <div key={parent.id} className="py-2">
        <div className="flex items-center justify-start">
          <button
            onClick={() => toggleParent(parent.id)}
            aria-expanded={openParent === parent.id}
            className="text-sm flex items-center justify-center mr-2 focus:outline-none w-4 h-4"
          >
            {openParent === parent.id ? (
              <span className="text-gray-300 text-xs">▼</span>
            ) : (
              <span className="text-gray-300 text-xs">►</span>
            )}
          </button>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={selectedFilters.some((item) => item.id === parent.id)}
              onChange={() => handleCheckboxChange(parent.id, parent.name, parent.children)}
              className="mr-2 text-green-600 focus:ring-green-500 h-4 w-4"
            />
            <span className="text-gray-700 text-sm">{parent.name}</span>
          </label>
        </div>

        {openParent === parent.id && parent.children.length > 0 && (
          <div className="pl-10 mt-2 border-l border-gray-300">
            <ul className="list-none space-y-2">
              {renderChildren(parent.children)}
            </ul>
          </div>
        )}
      </div>
    ));
  };

  const handleSearchBox = (text) => {
    setSearchText(text);
    
    const filtered = data.reduce((acc, parent) => {
      // Check if the parent matches the search text
      const parentMatches = parent.name.toLowerCase().includes(text.toLowerCase());

      // Filter children based on the search text
      const filteredChildren = parent.children.filter((child) =>
        child.name.toLowerCase().includes(text.toLowerCase())
      );

      // If the parent or any child matches, include the parent in the results
      if (parentMatches || filteredChildren.length > 0) {
        acc.push({
          ...parent,
          children: filteredChildren // Only include matched children
        });
      }

      return acc;
    }, []);

    setFilteredData(filtered);
  };

  return (
    <div className="overflow-y-auto relative">
      <div className="bg-gray-200 p-2 sticky top-0 ">
        <input
          type="text"
          value={searchText}
          onChange={(e) => handleSearchBox(e.target.value)}
          placeholder="Search..."
          className="p-2 rounded w-full border"
        />
      </div>

      {/* <button
        onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}
        className="bg-blue-500 text-white p-2 rounded mt-4 md:hidden"
      >
        {isMobileDrawerOpen ? 'Close Filters' : 'Open Filters'}
      </button> */}

      {/* {isMobileDrawerOpen && (
        <div className="fixed inset-0 bg-white overflow-y-auto p-4 z-50 md:hidden">
          <button
            onClick={() => setIsMobileDrawerOpen(false)}
            className="bg-red-500 text-white p-2 rounded mb-4"
          >
            Close
          </button>
          <h2>Filter Panel</h2>
          {renderParents()}
        </div>
      )} */}

      <div className="">
        <h2>Filter Panel</h2>
        {renderParents()}
      </div>
    </div>
  );
};

export default FilterPanel;
