import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { MyProfile } from "../features/User/buyer_seller/ProfilePage";
import { OrderDetail } from "../features/User/buyer_seller/OrderDetail";
import { ThanksPage } from "../features/Buy/thanks";
import { Page404 } from "../components/Page404";

import {
  ProductDescription,
  ProductImgVideo,
  ProductReview,
} from "../features/Buy/ProductDesc";
import {
  SelectAdvtType,
  PostAdvt,
  AdvtMedia,
  AdvtLocation,
  AdvtPrice,
  AdvtProdData,
  AddServiceType,
} from "../features/Sell/PostAdvt";
import { Manufacturer } from "../features/Distributor_manufacture/manufacturer";
import { Checkout } from "../features/Buy/Checkout";
import {
  DistributorFrom,
  MANUFACTURERForm,
  BussinessWithUs,
} from "../features/Distributor_manufacture/forms";
import { Service } from "../features/Services/Service";
import { Contactus } from "../features/Contactus";
import { Aboutus } from "../features/Aboutus/Aboutus";
import { AboutDetail } from "../features/Aboutus/AboutDetail";
import { TermsCondition } from "../features/mmqDoc/TermsConditon";
import { PrivacyPolicy, RefundPolicy } from "../features/mmqDoc/TermsConditon";
import {
  LoginRegister,
  OtpVervicatonForm,
  Signup,
  Login,
} from "../features/Auth/LoginRegister";
import PrivateRoutes from "../components/PrivateRoute";
import MyAds from "../features/User/buyer_seller/MyAds";
import AllFilterProduct from "../components/AllFilterProduct";
import { Cart } from "../features/Cart";
import OrderList from "../features/User/buyer_seller/OrderList";
import SheduleMeeting from "../features/User/buyer_seller/SheduleMeeting";
import AdsDetails from "../features/User/buyer_seller/AdsDetails";
import { PaymentHistory } from "../features/User/buyer_seller/PaymentHistory";
import { Notification } from "../features/User/buyer_seller/Notification";

//Distributor dashnoard
import { DistributorDash } from "../layouts/DistribuotrLayout";
import { DistDash } from "../features/Distributor_manufacture/distdash";
import { DisNotifiction } from "../features/Distributor_manufacture/disNotification";
import {
  DistListing,
  AddNewListing,
} from "../features/Distributor_manufacture/Listing";
import { DistOrders } from "../features/Distributor_manufacture/distOrder";
import {
  DistPayments,
  DistDownloadInvoice,
} from "../features/Distributor_manufacture/Payments";
import SpareAccessories from "../components/SpareAccessories";
import DistributorAuth from "../components/DistributorAuth";
import Loader from "../utils/Loader";

const HomeLayout = lazy(() => import("../layouts/HomeLayout"));
const DashboardLayout = lazy(() => import("../layouts/DashboardLayout"));
const ContentConatiner = lazy(() => import("../components/ContentContainer"));
const BuySearch = lazy(() => import("../features/Buy/Search"));

export const UserRoute = () => {
  const location = useLocation();
  const blogRef = useRef(null);
  const articleRef = useRef(null);
  const techUpdatesRef = useRef(null);
  const expertTipsRef = useRef(null);
  const [exploreType, setExploreType] = useState("Explore");

  useEffect(() => {
    if (location.pathname !== "/") {
      setExploreType("Explore");
    }
  }, [location.pathname]);

  return (
    <Suspense>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            exact
            path="/"
            element={
              localStorage.getItem("token") ? (
                <Navigate replace to="/profile/" />
              ) : (
                <Navigate replace to="/" />
              )
            }
          />
          {/* dashbaord */}
          <Route path="profile" element={<DashboardLayout />}>
            <Route index element={<MyProfile />} />
            <Route path="ads" element={<MyAds />} />
            <Route path="ads/:id" element={<AdsDetails />} />
            <Route path="order-list" element={<OrderList />} />
            <Route path="paymen-history" element={<PaymentHistory />} />
            <Route path="notifications" element={<Notification />} />
            <Route path="order-detail/:id" element={<OrderDetail />} />
            <Route path="shedule-meeting-list" element={<SheduleMeeting />} />
          </Route>

          {/* manufacture form */}
          <Route path="distributor-form" element={<DistributorFrom />} />
          <Route path="manufacturer-form" element={<MANUFACTURERForm />} />
          <Route path="bussiness-with-us-form" element={<BussinessWithUs />} />
        </Route>

        <Route
          path="/"
          element={
            <HomeLayout
              articleRef={articleRef}
              techUpdatesRef={techUpdatesRef}
              expertTipsRef={expertTipsRef}
              blogRef={blogRef}
              exploreType={exploreType}
              setExploreType={setExploreType}
            />
          }
        >
          <Route
            index
            element={
              <ContentConatiner
                specs={false}
                articleRef={articleRef}
                techUpdatesRef={techUpdatesRef}
                expertTipsRef={expertTipsRef}
                blogRef={blogRef}
              />
            }
          />
          <Route
            path="specialization"
            element={<ContentConatiner specs={true} />}
          />

          {/* search  */}
          <Route path="search" element={<BuySearch />} />
          <Route path="speciality-search" element={<BuySearch />} />

          {/* Authentication */}
          <Route path="/user" element={<LoginRegister />}>
            <Route path="login" element={<Login />} />
            <Route path="registeration" element={<Signup />} />
            <Route path="verifyotp" element={<OtpVervicatonForm />} />
          </Route>

          {/* ProductDescription */}
          <Route path="products/:proddetails" element={<ProductDescription />}>
            <Route index element={<ProductImgVideo />} />
            {/* <Route path='info' element={<ProductMetaData/>}/> */}
            <Route path="review" element={<ProductReview />} />
          </Route>

          {/* search product items  */}
          <Route
            path="search/search-items/:searchitems"
            element={<AllFilterProduct />}
          />

          {/* checkout */}
          <Route path="checkout/:proddetails" element={<Checkout />} />

          {/* post advertisement */}

          <Route path="post" element={<PostAdvt />}>
            <Route index element={<SelectAdvtType />} />
            <Route path="media" element={<AdvtMedia />} />
            <Route path="service" element={<AddServiceType />} />
            <Route path="location" element={<AdvtLocation />} />
            <Route path="pricing" element={<AdvtPrice />} />
            <Route path="specifications" element={<AdvtProdData />} />
          </Route>

          {/* Distributor & Manufacturer */}

          <Route path="manufacturers" element={<Manufacturer mf={true} />} />
          {/* <Route path="distributor-form" element={<DistributorFrom />} />
        <Route path="manufacturer-form" element={<MANUFACTURERForm />} /> */}

          {/* Equpments */}
          <Route
            path="/preowned-equip"
            element={<Manufacturer used={true} />}
          />
          <Route path="/new-equip" element={<Manufacturer new={true} />} />
          <Route
            path="/trending-equip"
            element={<Manufacturer trend={true} />}
          />

          {/* Service */}

          <Route path="services" element={<Service />} />
          <Route path="spare-accessories" element={<SpareAccessories />} />
          <Route path="contact" element={<Contactus />} />
          <Route path="cart" element={<Cart />} />

          <Route path="aboutus" element={<Aboutus />} />
          <Route path="aboutDetail" element={<AboutDetail />} />
          {/* <Route path="notifications" element={<Notifaction />} /> */}
          {/* MmqDoc  */}
          <Route path="/termscondition/" element={<TermsCondition />} />
          <Route path="/privacypolicy/" element={<PrivacyPolicy />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
        </Route>

        {/* distributor dashbaord */}
        <Route element={<DistributorAuth />}>
          <Route path="/distributor/" element={<DistributorDash />}>
            <Route path="dashboard" element={<DistDash />} />
            <Route path="disNotification" element={<DisNotifiction />} />
            <Route path="listing" element={<DistListing />} />
            <Route path="addlist" element={<AddNewListing />} />
            <Route path="orders" element={<DistOrders />} />
            <Route path="payment" element={<DistPayments />} />
            <Route path="paymentinvoce" element={<DistDownloadInvoice />} />
          </Route>
        </Route>

        {/* <Route path="*" element={<Page404 />} />  */}
        <Route path="thanks" element={<ThanksPage />} />
      </Routes>
    </Suspense>
  );
};
