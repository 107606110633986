import jsPDF from "jspdf";
import "jspdf-autotable";

const GenerateInspReport = (amount) => {
  const pdf = new jsPDF();

  pdf.setFontSize(20);
  pdf.text(
    "Inspection Report",
    pdf.internal.pageSize.getWidth() / 2,
    15,
    {
      align: "center",
    }
  );

  pdf.text(`Inspection Amount- ${amount}`, 70, 40);


  // Save the PDF
  pdf.save("invoice.pdf");
};

export default GenerateInspReport;
