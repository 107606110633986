// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: inter, sans-serif;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  
  position: relative;
  margin: auto;
 
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
sup{
  font-size: 12px;
  font-weight: 300;
  color: rgb(36, 36, 36);
}
.Toastify__toast-container--top-right{
  top: 8em !important;
}
@media (max-width: 1200px) {
  .Toastify__toast-container--top-right {
    /* Adjust the position or styling as needed for smaller screens */
    position: fixed;
    top: 50px;
    right: 10px;
   
  }
}


h3,
h4,
h5,
h6{
  color: rgb(107, 107, 107);

}
h1,
h2{
  color:#019c89!important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,SAAS;EACT;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;;EAEE,kBAAkB;EAClB,YAAY;;EAEZ,+FAA+F;AACjG;;AAEA;;EAEE,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,iEAAiE;IACjE,eAAe;IACf,SAAS;IACT,WAAW;;EAEb;AACF;;;AAGA;;;;EAIE,yBAAyB;;AAE3B;AACA;;EAEE,uBAAuB;AACzB","sourcesContent":["* {\r\n  margin: 0px;\r\n  padding: 0px;\r\n  box-sizing: border-box;\r\n  font-family: inter, sans-serif;\r\n}\r\nbody {\r\n  margin: 0;\r\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\r\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\r\n    sans-serif; */\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\n#root {\r\n  \r\n  position: relative;\r\n  margin: auto;\r\n \r\n  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset; */\r\n}\r\n\r\ninput[type=\"number\"]::-webkit-inner-spin-button,\r\ninput[type=\"number\"]::-webkit-outer-spin-button {\r\n  -webkit-appearance: none;\r\n}\r\nsup{\r\n  font-size: 12px;\r\n  font-weight: 300;\r\n  color: rgb(36, 36, 36);\r\n}\r\n.Toastify__toast-container--top-right{\r\n  top: 8em !important;\r\n}\r\n@media (max-width: 1200px) {\r\n  .Toastify__toast-container--top-right {\r\n    /* Adjust the position or styling as needed for smaller screens */\r\n    position: fixed;\r\n    top: 50px;\r\n    right: 10px;\r\n   \r\n  }\r\n}\r\n\r\n\r\nh3,\r\nh4,\r\nh5,\r\nh6{\r\n  color: rgb(107, 107, 107);\r\n\r\n}\r\nh1,\r\nh2{\r\n  color:#019c89!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
