// Service Request Components
import React, { useEffect, useState } from "react";
import styles from "../../assets/css/services/service.module.css";
import { Catogories } from "../../components/Hero";
import ServiceForm from "./ServiceForm";
import SingleSelectDropdown from "./SingleSelectDropdown";
import axios from "axios";
import LeftPanel from "../../components/services/leftPanel";
import FilterPanel from "../../components/services/FilterPanel";
import { toast } from "react-toastify";
import { filterMF } from "../../assets/images";

const fetchSellSpecilityList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/speciality/lists/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status_code === 200) {
      return data.data;
    }
  } catch (err) {
    //  setLoading(false)
    console.log("err", err);
  }
};

const getMenuList = async (payload) => {
  try {
    const response = await fetch(
      "https://mmq.mediquip.in/api/v1/product/category/menulist/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    if (data.status_code === 200) {
      return data.data;
    }
  } catch (err) {
    //  setLoading(false)
    console.log("err", err);
  }
};

const getUserDetail = async (uid, token) => {
  const formData = new FormData();
  formData.append("uid", uid);

  const response = await fetch(
    "https://mmq.mediquip.in/api/v1/users/get_user_detail/",
    {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Token ${token}`,
      },
      body: formData,
    }
  );
  const data = await response.json();
  return data;
};
export const Service = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const obj = {
    name: "",
    email: "",
    contact: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    message: "",
  };
  const [formData, setFormData] = useState(obj);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleTabClick = (value) => {
    setSelectedTab(value);
  };

  const [loading, setLoading] = useState(false);
  const [speciality, setSpeciality] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [menuDataTemp, setMenuDataTemp] = useState([]);
  const [equipmentData, setEquipMentData] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [servicesData, setServicesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [onCheckedFilter, setOnCheckedFilter] = useState([]);
  const [isShowForm, setIsShowForm] = useState(false);
  const [selectedContactData, setSelectedContactData] = useState();

  const fetchServicesData = async () => {
    const url = "https://mmq.mediquip.in/api/services/get/admin";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      console.log(response);
      if (!response.ok) {
        console.log(response);
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setServicesData(data); // Set the fetched data
      const filtered = data.filter(
        (item) =>
          item.otherData &&
          item.otherData.type === "serviceProvider" &&
          item.otherData.isActive === "true"
      );
      setFilteredData(filtered);
      setOnCheckedFilter(filtered);
      console.log("filtered", filtered);
      // Default filter to "AMC"
      console.log("servicesData", data);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };
  // const filterDataByServiceType = (serviceType) => {
  //   setActiveServiceType(serviceType); // Set the current active service type
  //   const filtered = servicesData.filter(service => service.amount === serviceType);
  //   setFilteredData(filtered); // Set filtered data
  // };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetchSellSpecilityList();
        setSpeciality(response);
        const menuData = await getMenuList({ parent: 41 });
        console.log("menuData", menuData);
        const filteredMenuData = menuData
          .filter(
            (item) => !["test1", "Testing", "adminTest"].includes(item.name)
          )
          .map((item) => {
            const { amount, ...rest } = item;
            return rest;
          });

        setMenuData(filteredMenuData);
        setMenuDataTemp(filteredMenuData);
        let filtered = [];
        menuData.forEach((parent) => {
          if (parent.children.length > 0) {
            parent.children.forEach((child) => {
              filtered.push(child);
            });
          }
        });
        console.log("filtered", filtered.length);
        setEquipMentData(filtered);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching speciality or menu data:", error);
      }
    };
    fetchData();
    fetchServicesData();
  }, []);
  const filterData = (data) => {
    console.log(data);
    const filtered = filteredData.filter((item) => {
      return (
        item.otherData &&
        item.otherData.data.equipmentName.toLowerCase() === data.toLowerCase()
      );
    });
    setFilteredData(filtered);
  };
  const handleSubmit = async (values) => {
    const buyerId = localStorage.getItem("uid");
    const token = localStorage.getItem("token");
    const userData = await getUserDetail(buyerId, token);
    const obj = {
      uid: buyerId,
      serviceType:
        selectedTab !== "Delivery" &&
        selectedTab !== "Shipping_and_Installation"
          ? selectedEquipment
          : values["Agency Name"], // From equipment dropdown
      specialityOrProviderName:
        selectedTab !== "Delivery" &&
        selectedTab !== "Shipping_and_Installation"
          ? selectedSpeciality
          : values["Provider Name"], // From speciality dropdown
      description: values.Description,
      amount: selectedTab.replace(/_/g, " "),
      location2: values.Location,
      otherData: { type: "commanRequestService", userData: userData.data },
    };
    console.log(obj);

    setLoading(true);
    try {
      const response = await axios.post(
        "https://mmq.mediquip.in/api/services/add",
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );
      console.log("service data", response.data);
      setServicesData([...servicesData, response.data]);
      fetchServicesData();
      setLoading(false);
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  // dddddddddddddddddd

  const serviceFormFields = {
    AMC: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    CMC: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      { label: "Description", type: "textarea", placeholder: "Description" },
    ],
    Delivery: [
      {
        label: "Agency Name",
        type: "text",
        placeholder: " Enter your Agency Name",
      },
      { label: "Provider Name", type: "text", placeholder: "Provider Name" },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Repairing: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Consumable: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Parts: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Shipping_and_Installation: [
      {
        label: "Agency Name",
        type: "text",
        placeholder: " Enter your Agency Name",
      },
      { label: "Provider Name", type: "text", placeholder: "Provider Name" },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Software_Update: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    Rental_Services: [
      {
        label: "Name",
        type: "dropdown",
        placeholder: "Enter your full name",
        component: (
          <SingleSelectDropdown
            data={equipmentData}
            type="Select Equipment"
            onSelect={(selectedValue) => setSelectedEquipment(selectedValue)}
          />
        ),
      },
      {
        label: "Specialty",
        type: "dropdown",
        placeholder: "Specialty",
        component: (
          <SingleSelectDropdown
            data={speciality}
            type="Select Specialty"
            onSelect={(selectedValue) => setSelectedSpeciality(selectedValue)}
          />
        ),
      },
      {
        label: "Service type",
        type: "autoselect",
        placeholder: "Service type",
      },
      { label: "Location", type: "text", placeholder: "Your location" },
      {
        label: "Description",
        type: "textarea",
        placeholder: " write yourDescription",
      },
    ],
    // Add more services and their respective fields here...
  };

  const handleApplyClick = () => {
    if (selectedTab) {
      // const selectedFormData = serviceFormFields[selectedTab];
      // setFormData(selectedFormData);
      // openPopup();
      openPopup();
    } else {
      alert("Please select a service before applying.");
    }
  };

  const handleFilterChange = (updatedFilters, textSearch) => {
    console.log("Selected Filters:", updatedFilters);
    console.log(filteredData);
    if (updatedFilters.length === 0) {
      setOnCheckedFilter(filteredData);
      return;
    }
    const fil = filteredData.filter((item) =>
      updatedFilters.some(
        (filter) => item.otherData.data.equipmentName === filter.name
      )
    );
    console.log(fil);
    setOnCheckedFilter(fil);
  };
  const handleContactClick = (item) => {
    setSelectedContactData(item)
    setIsShowForm(true);
    // openPopup();
  };

  const sfilters = [
    "AMC",
    "CMC",
    "Delivery",
    "Repairing",
    "Consumable",
    "Parts",
    "Shipping_and_Installation",
    "Software_Update",
    "Rental_Services",
  ];
 const handleInputChange =(e)=>{
formData[e.target.name]=e.target.value 
  setFormData({ ...formData});
  console.log("first,",formData, selectedContactData)
}
 
  const handleServiceRequestClick = async(e) => {
    e.preventDefault()
    console.log("second");
    const url = 'https://mmq.mediquip.in/api/services/address/add';
      const data = {
        from: { name: formData.name, email: formData.email, contact: formData.contact, address: formData.address, 
          city: formData.city, state: formData.state, pincode: formData.pincode, message: formData.message },
        to: selectedContactData,
      };
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const resData = await response.json();
        toast.success(`Your service request added successfully Contact Shortly !`);
        setFormData(obj)
        setIsShowForm(false);
        console.log(resData);
      } catch (error) {
        toast.error(`Error: ${error.message}`);
        console.error('Error adding address:', error);
      }
  };
const [isShowFilter, setIsShowFilter]= useState(false)
const handleMobileSearch = () => {
  setIsShowFilter(!isShowFilter);
}

  return (
    <section className={styles.serviceCont}>
      <div className={styles.serviceSubCont}>
        <div className={styles.serviceRow1}>
          <i className="bi bi-house-door"></i>
          <i style={{ fontSize: "14px" }} className="bi bi-chevron-right"></i>
          <span>Services</span>
        </div>
        <div className=" xl:hidden block">
              <img
                src={filterMF}
                onClick={handleMobileSearch}
                width="120px"
                alt="filter"
              />
              {/* <img src={sortMf} width="120px" alt="sortby" /> */}
            </div>
        <div className={styles.serviceRow2}>
          <div className={styles.servCat}>
            <div className=" xl:block hidden">
            <FilterPanel data={menuData} onFilterChange={handleFilterChange} />
            </div>
            </div>

            {isShowFilter &&  <div className=" block xl:hidden h-screen overflow-y-scroll">
              <div  onClick={handleMobileSearch} class={`absolute overflow-y-scroll bg-black opacity-80 inset-0 ${isShowFilter === false ? "hidden" : "z-[400]"} h-screen`}></div>
        <div className={isShowFilter ? "bg-gray-100 z-[500] h-screen overflow-y-scroll absolute left-0 top-0" : styles.manureSearch}>
            <FilterPanel data={menuData} onFilterChange={handleFilterChange} />
            </div>
            </div>
          }

          <div>
            <div
              style={{
                background: "none",
                boxShadow: "none",
                position: "relative",
              }}
              className={styles.serviceContent}
            >
              <div
                className=" xl:absolute xl:-top-10 "
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginBottom: "24px",
                  textAlign: "center",
                  color: "#14b8a6",
                }}
              >
                Create Service
              </div>

              <div className={styles.serviceFilters}>
                {sfilters.map((value, index) => (
                  <span
                    style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    key={index}
                    className={`${styles.sFilter} ${
                      selectedTab === value ? styles.selected : styles.unselect
                    }`}
                    onClick={() => handleTabClick(value)}
                  >
                    {value.replace(/_/g, " ")}
                  </span>
                ))}

                <span
                  onClick={handleApplyClick}
                  className={styles.sFilterApply}
                >
                  Apply
                </span>
              </div>
              <div className={styles.serviceData}></div>
            </div>

            <div>
              <div
                className=" xl:block hidden md:hidden"
                style={{ overflowX: "auto" }}
              >
                <table
                  style={{
                    width: "100%",
                    // minWidth: "550px",
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                  }}
                >
                  <thead style={{ backgroundColor: "#f2f2f2" }}>
                    <tr
                      style={{
                        border: "1px solid #ddd",
                        borderCollapse: "collapse",
                      }}
                    >
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Name
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Service Type
                      </th>
                      {/* <th style={{ padding: "8px", textAlign: "left" }}>
                        Description
                      </th> */}

                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Specility
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Location
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {onCheckedFilter.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                          border: "1px solid #ddd",
                          borderCollapse: "collapse",
                        }}
                      >
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.otherData.data.equipmentName}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.serviceType}
                          </div>
                        </td>
                        {/* <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.description}
                          </div>
                        </td> */}
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.amount}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.location2}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <button
                            style={{
                              background: "#14b8a6",
                              borderRadius: "8px",
                              padding: "8px",
                              border: "none",
                              color: "#fff",
                              cursor: "pointer",
                              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                            }}
                            onClick={() => handleContactClick(item)}
                          >
                            Contact
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
                className=" xl:hidden block md:block"
                style={{ overflowX: "auto" }}
              >
                <table
                  style={{
                    width: "100%",
                    // minWidth: "550px",
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                  }}
                >
                  <thead style={{ backgroundColor: "#f2f2f2" }}>
                    <tr
                      style={{
                        border: "1px solid #ddd",
                        borderCollapse: "collapse",
                      }}
                    >
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Name
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Service Type
                      </th>
                      {/* <th style={{ padding: "8px", textAlign: "left" }}>
                        Description
                      </th> */}

                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Specility
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Location
                      </th>
                      <th style={{ padding: "8px", textAlign: "left" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {onCheckedFilter.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                          border: "1px solid #ddd",
                          borderCollapse: "collapse",
                        }}
                      >
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.otherData.data.equipmentName}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.serviceType}
                          </div>
                        </td>
                        {/* <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.description}
                          </div>
                        </td> */}
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.amount}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.location2}
                          </div>
                        </td>
                        <td style={{ padding: "8px", textAlign: "left" }}>
                          <button
                            style={{
                              background: "#14b8a6",
                              borderRadius: "8px",
                              padding: "8px",
                              border: "none",
                              color: "#fff",
                              cursor: "pointer",
                              boxShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                            }}
                            onClick={() => handleContactClick(item)}
                          >
                            Contact
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
        </div>
      </div>

      {isPopupOpen && (
        <div className={styles.popupOverlay}>
          <ServiceForm
            onClose={closePopup}
            fields={serviceFormFields[selectedTab]}
            serviceName={selectedTab.replace(/_/g, " ")}
            handleClick={handleSubmit}
          />
        </div>
      )}
      {isShowForm && (
        <div className="fixed top-0 left-0 z-[999] overflow-hidden w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 relative rounded-lg shadow-lg w-1/3">
            <h2 className="text-2xl font-bold mb-4">Request For Service</h2>
            <form onSubmit={handleServiceRequestClick}>
              <div className=" flex flex-row items-center justify-between space-x-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="Name"
                  >
                    Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={(e)=>handleInputChange(e)}
                    id="Name"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={(e)=>handleInputChange(e)}
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="contact"
                >
                  Contact
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="contact"
                  value={formData.contact}
                  onChange={(e)=>handleInputChange(e)}
                  id="contact"
                  required
                />
              </div>
              <div className=" flex flex-row items-center justify-between space-x-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="address"
                  >
                    Address
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={(e)=>handleInputChange(e)}
                    id="address"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="address"
                  >
                    City
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={(e)=>handleInputChange(e)}
                    id="address"
                    required
                  />
                </div>
              </div>

              <div className=" flex flex-row items-center justify-between space-x-4">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="address"
                  >
                    State
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={(e)=>handleInputChange(e)}
                    id="address"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="pincode"
                  >
                    PinCode
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"name="pincode"
                    value={formData.pincode}
                    onChange={(e)=>handleInputChange(e)}
                    id="pincode"
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  rows={4}
                  className="shadow resize-none appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="message"
                    value={formData.message}
                    onChange={(e)=>handleInputChange(e)}
                  id="message"
                  required
                />
              </div>

              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </form>
            <div className="absolute top-0 right-0 p-4">
              <button
                className="text-gray-700 hover:text-gray-900 focus:outline-none"
                onClick={() => setIsShowForm(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
