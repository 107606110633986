import React from "react";
import styles from "../assets/css/dashboard.module.css";

import {
  rupee,
  question,
  user2,
  chart,
  rupees,
  shipping,
  distributors,
  inspections,
  orders,
  speciality,
} from "../assets/img/index";

export const DashboardKpi = () => {
  const kpiData = [
    { title: "Total Product", img: rupee, fact: "22.25" },
    { title: "Total Users", img: user2, fact: "22" },
    { title: "Distributors and Manufacturer", img: distributors, fact: "22" },
    { title: "Total Requirement received", img: question, fact: "22" },
    { title: "Total Earning", img: rupees, fact: "22" },
    { title: "Inspection Request", img: inspections, fact: "22" },
    { title: "Shiping Request", img: shipping, fact: "22" },
    { title: "Total Orders", img: orders, fact: "22" },
    { title: "Total Speciality", img: speciality, fact: "22" },
  ];
  return (
    <section className={styles.dashBoardAnalytics}>
      <div className={styles.dashboardKpi}>
        {kpiData.map((value, key) => {
          return <KPICard data={value} key={key} />;
        })}
      </div>

      <div className={styles.AnalyticsData}>
        <AnalyticsChart />
        <InspectionRequestData />
      </div>
    </section>
  );
};

const KPICard = (props) => {
  return (
    <div className={styles.dashboardKpiCard}>
      <div className={styles.dashboardKpiCont}>
        <div>
          <span>
            <img src={props.data.img} alt="..." />
          </span>
        </div>
        <div>
          <h4>{props.data.title}</h4>
          <h1>{props.data.fact}</h1>
        </div>
      </div>
    </div>
  );
};

const AnalyticsChart = () => {
  return (
    <section className={styles.analytics}>
      <div>
        <h4>Analytics (Users base) </h4>
        <img src={chart} alt="chart" />
      </div>
    </section>
  );
};

const InspectionRequestData = () => {
  return (
    <div className={styles.inspectionData}>
      <h4>Inspection Request</h4>
      <div className="">
        <table>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Created</th>
            <th>Action</th>
          </tr>
          <tr>
            <td>Peter</td>
            <td>Griffin</td>
            <td>Peter</td>
            <td>$100</td>
          </tr>
          <tr>
            <td>Lois</td>
            <td>Griffin</td>
            <td>$150</td>
            <td>$150</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
