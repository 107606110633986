import React, { useEffect, useState } from "react";
import { cancel, nextArow, swipetestleft } from "../assets/images";

const LargePreviewImage = ({
  image,
  setImg,
  img,
  item,
  toggle,
  setOpenImageCard,
  openImageCard,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedData, setDisplayedData] = useState({});

  const handleClick = () => {
    if (img) {
      setImg(false);
    } else {
      setOpenImageCard(false);
    }
  };
  const handleLeft = () => {
    setCurrentIndex(
      (prev) =>
        (prev - 1 + item?.product_images?.length) % item?.product_images?.length
    );
  };
  const handleRight = () => {
    setCurrentIndex((prev) => prev + (1 % item?.product_images?.length));
  };

  const handleImageChage = (image) => {
    setDisplayedData(image);
  };

  useEffect(() => {
    const updateDisplayedData = () => {
      const displayed =
        item?.product_images[currentIndex % item?.product_images?.length];
      setDisplayedData(displayed);
    };
    updateDisplayedData();
  }, [currentIndex, item]);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={
            displayedData?.product_images
              ? displayedData?.product_images
              : image?.product_images
          }
          alt="no img"
          style={{ width: "100vw", height: "89vh", objectFit: "fill" }}
        />
      </div>
      {!img && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {item?.product_images?.length > 3 && (
            <img
              src={swipetestleft}
              onClick={handleLeft}
              alt="..."
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
            />
          )}
          {item?.product_images?.length > 0 &&
            item?.product_images?.slice(0, 4)?.map((images) => {
              return (
                <img
                  onClick={() => handleImageChage(images)}
                  src={images?.product_images}
                  alt="no image"
                  style={{
                    cursor: "pointer",
                    width: "50px",
                    height: "50px",
                    margin: "10px",
                    padding: "10px",
                    border:
                      images?.id !== displayedData?.id
                        ? displayedData?.id
                        : image?.id && "2px solid #6E7171",
                  }}
                />
              );
            })}
          {item?.product_images?.length > 3 && (
            <img
              src={nextArow}
              onClick={handleRight}
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
              alt="..."
            />
          )}
        </div>
      )}
    </>
  );
};

export default LargePreviewImage;
