// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --text-colo2: rgb(97, 97, 97);
  --text-color: rgb(119, 119, 119);
}
.page404_errorPage__H9mjS {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 99%;
  height: 100vh;
}
.page404_page404__wSiBs {
  width: 500px;
  padding: 20px;
}
.page404_erro404__W9bxe span i {
  font-size: 64px;
  border-radius: 50%;
  padding: 90px;
  background-color: rgba(243, 247, 250, 0.815);
  color: var(--text-colo2);
}
.page404_erro404__W9bxe {
  position: relative;
}
.page404_erro404Content__EDkez {
  width: 440px;
  position: absolute;
  top: 0px;
  left: 180px;
}
.page404_erro404Content__EDkez h1 {
  font-size: 40px;
  color: var(--text-colo2);
}
.page404_erro404Content__EDkez h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--text-colo2);
}
.page404_erro404Content__EDkez p {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--text-color);
}
.page404_erro404Content__EDkez h5 {
  color: teal;
  font-size: 18px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/page404.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,4CAA4C;EAC5C,wBAAwB;AAC1B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb;AACA;EACE,eAAe;EACf,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB","sourcesContent":[":root {\r\n  --text-colo2: rgb(97, 97, 97);\r\n  --text-color: rgb(119, 119, 119);\r\n}\r\n.errorPage {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 99%;\r\n  height: 100vh;\r\n}\r\n.page404 {\r\n  width: 500px;\r\n  padding: 20px;\r\n}\r\n.erro404 span i {\r\n  font-size: 64px;\r\n  border-radius: 50%;\r\n  padding: 90px;\r\n  background-color: rgba(243, 247, 250, 0.815);\r\n  color: var(--text-colo2);\r\n}\r\n.erro404 {\r\n  position: relative;\r\n}\r\n.erro404Content {\r\n  width: 440px;\r\n  position: absolute;\r\n  top: 0px;\r\n  left: 180px;\r\n}\r\n.erro404Content h1 {\r\n  font-size: 40px;\r\n  color: var(--text-colo2);\r\n}\r\n.erro404Content h3 {\r\n  font-size: 18px;\r\n  font-weight: 600;\r\n  text-transform: uppercase;\r\n  margin-top: 5px;\r\n  margin-bottom: 5px;\r\n  color: var(--text-colo2);\r\n}\r\n.erro404Content p {\r\n  font-size: 15px;\r\n  margin-top: 5px;\r\n  margin-bottom: 5px;\r\n  color: var(--text-color);\r\n}\r\n.erro404Content h5 {\r\n  color: teal;\r\n  font-size: 18px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `page404_errorPage__H9mjS`,
	"page404": `page404_page404__wSiBs`,
	"erro404": `page404_erro404__W9bxe`,
	"erro404Content": `page404_erro404Content__EDkez`
};
export default ___CSS_LOADER_EXPORT___;
