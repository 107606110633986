import React, { useState, useEffect } from "react";
import styles from "../assets/css/UserList.module.css";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { postData1 } from "../../services";
import { Pagination } from "../Common";

export const ContactList = () => {
  const [requestlistData, setRequestListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [paging, setPaging] = useState({});
  const [prevPage, setPrevPage] = useState(1);
  const navigate = useNavigate();

  const updatePage = (e) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      setCurrPage(currPage === 1 ? 1 : currPage - 1);
      setPrevPage(currPage);
    } else if (name === "next") {
      setCurrPage(currPage === paging?.total_pages ? currPage : currPage + 1);
      setPrevPage(currPage);
    } else {
      setCurrPage(parseInt(e.currentTarget.innerHTML));
    }
  };

  useEffect(() => {
    (async () => {
      const config = { _page: currPage, _limit: 8 };
      const res = await postData1("contact/lists/", config, true, true);
      setRequestListData(res?.data?.data);
      setPaging({
        total: res?.data?.total,
        total_pages: res?.data?.total_pages,
      });
      setLoader(false);
    })();
  }, [currPage]);

  return (
    <section className={styles.userListCont}>
      <div className={styles.listHead}>
        <h1>CONTACTS MANAGEMENT</h1>
      </div>
      <div className={styles.dataItems}>
        <div className={styles.itemHead}>
          <span>ID</span>
          <span>Date</span>
          <span>Name</span>
          <span>Email</span>
          <span>Phone</span>
          <span>DETAIL</span>
        </div>

        {requestlistData.map((data, index) => {
          return (
            <div className={styles.itemData} key={index}>
              <span>{data?.id}</span>
              <span>{new Date(data?.created_date).toLocaleDateString()}</span>
              <span>{data?.name}</span>
              <span>{data?.email}</span>
              <span>{data?.contact_no}</span>
              <span
                onClick={() =>
                  navigate("/admin/contactlist/view/", { state: data })
                }
                style={{ cursor: "pointer", color: "blue" }}
              >
                View
              </span>
            </div>
          );
        })}
      </div>
      <Pagination
        total={paging?.total}
        perPage={8}
        currPage={currPage}
        prevPage={prevPage}
        tPage={paging?.total_pages}
        updatePage={updatePage}
      />

      {loader && <Loader />}
    </section>
  );
};
