

export const companyName = "myMedieQuip.in";
export const CompanyTitle = "EquipMedy";

  





