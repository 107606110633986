import React, { useEffect, useState } from "react";
import styles from "../../assets/css/forms.module.css";
import commonCss from "../../assets/css/common.module.css";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { postData1 } from "../../../services";
import prevIcon from "../../../assets/images/previous_arrow.png";
import nextIcon from "../../../assets/images/next_arrow.png";
import { productStatus } from "../../MappedData";
import { UsersAddress } from "../../../utils/UsersAddress";

export const EquipForm = () => {
  const [formDisabled, setFormDisabled] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const userData = location.state;
  const [currStatus, setStatus] = useState(userData?.product_status);
  const [imgs, setImgs] = useState(userData?.product_images);
  const [imgPointer, setImgPointer] = useState(0);
  const [videoPointer, setVideoPointer] = useState(0);
  const [videos, setVideos] = useState(userData?.product_video);
  const [usersAddress, setUsersAddress] = useState({
    seller: "",
  });

  UsersAddress({
    seller: userData?.user,
    setUsersAddress,
  });

  const formik = useFormik({
    initialValues: {
      equpName: userData?.equip_name,
      catog: userData?.equipname_list,
      address: userData?.address,
      cond: userData?.equip_condition,
      askPrice: Number(userData?.asking_price).toFixed(2),
      negotiableType: userData?.negotiable_type,
      desc: userData?.description,
      mfYear: userData?.date,
      brand: userData?.brand,
      model: userData?.model,
      warranty: userData?.warranty,
      amc: userData?.existing_amc,
      other: userData?.other_details,
      spec: userData?.speciality_name,
      sellerFname: userData?.seller_firstname,
      sellerLname: userData?.seller_lastname,
      shipingFrom: usersAddress?.seller?.address,
      userNumber: usersAddress?.seller?.mobile,
      installation_price: userData?.installation_price,
    },
  });

  useEffect(() => {
    if (usersAddress?.seller) {
      formik.setValues({
        shipingFrom: usersAddress?.seller?.address,
        userNumber: usersAddress?.seller?.mobile,
      });
    }
  }, [usersAddress?.seller]);

  const handleProdStatus = (e, key) => {
    let value = e.currentTarget.innerHTML;
    setStatus(key);
    setOpen(false);
  };

  const moveImg = (e, isVideo) => {
    let name = e.currentTarget.name;
    if (name === "prev") {
      isVideo
        ? setVideoPointer(videoPointer === 0 ? 0 : videoPointer - 1)
        : setImgPointer(imgPointer === 0 ? 0 : imgPointer - 1);
    } else {
      isVideo
        ? setVideoPointer(
            videoPointer === videos?.length - 1
              ? videos?.length - 1
              : videoPointer + 1
          )
        : setImgPointer(
            imgPointer === imgs?.length - 1 ? imgs?.length - 1 : imgPointer + 1
          );
    }
  };

  const updateProdcutStatus = async () => {
    const res = await postData1(
      "admin/update_product_status/",
      { product_id: userData?.uid, product_status: currStatus },
      true,
      true
    );
    if (res?.status_code === 200) {
      toast.success("Status update successfully !");
    } else {
      toast.error("Something went wrong, try again !");
    }
  };

  const updateInstallationPrice = async () => {
    let amount = formik.values.installation_price;
    let data = {
      product_id: userData?.id,
      post_type: userData?.post_type,
      installation_price: amount,
    };
    const res = await postData1("product/add/", data, true, true);
    if (res?.status_code === 200) {
      toast.success("Price update successfully !");
    } else {
      toast.error("Something went wrong try again !");
    }
  };

  return (
    <section className={styles.formCont}>
      <ToastContainer />
      {/* <h2>Detail Info <i onClick={()=>setFormDisabled(!formDisabled)} className="bi bi-pencil-square"></i> </h2> */}
      <form className={styles.updateForm} disabled={true}>
        {/* <div className={styles.formProfile}>
                    <label for="image" style={{backgroundImage:`url(${defaultProfile})`}} />
                    <input disabled={formDisabled}  type="file" name="image" id="image" accept="image/*"/>
                </div> */}
        <div className={styles.formFieds}>
          <div>
            <p>Equipment Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="equpName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.equpName}
            />
          </div>
          <div>
            <p>Seller First Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="sellerFname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerFname}
            />
          </div>
          <div>
            <p>Seller Last Name</p>
            <input
              disabled={formDisabled}
              type="text"
              name="sellerLname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sellerLname}
            />
          </div>
          <div>
            <p>Mobile Number</p>
            <input
              disabled={formDisabled}
              type="text"
              name="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userNumber}
            />
          </div>
          <div>
            <p>Address</p>
            <input
              disabled={formDisabled}
              type="text"
              name="shipingFrom"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.shipingFrom}
            />
          </div>
          <div>
            <p>Category</p>
            <input
              disabled={formDisabled}
              type="text"
              name="catog"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.catog}
            />
          </div>
          <div>
            <p>Speciality</p>
            <input
              disabled={formDisabled}
              type="text"
              name="spec"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.spec}
            />
          </div>
          <div>
            <p>Condition</p>
            <input
              disabled={formDisabled}
              type="text"
              name="cond"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.cond === 1
                  ? "Good"
                  : formik.values.cond === 2
                  ? "Excellent"
                  : "As Good as New"
              }
            />
          </div>
          <div>
            <p>Asking Price</p>
            <input
              disabled={formDisabled}
              type="number"
              name="askPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.askPrice}
            />
          </div>
          <div>
            <p>Price Type</p>
            <input
              disabled={formDisabled}
              type="text"
              name="negotiableType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={
                formik.values.negotiableType === 1
                  ? "Negotiable"
                  : formik.values.negotiableType === 2
                  ? "Slightly Negotiable"
                  : "Non-Negotiable"
              }
            />
          </div>

          {userData?.post_type === 1 && (
            <div>
              <p>Manufacturing Year</p>
              <input
                disabled={formDisabled}
                type="text"
                name="mfYear"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mfYear}
              />
            </div>
          )}
          <div>
            <p>Brand/Company</p>
            <input
              disabled={formDisabled}
              type="text"
              name="brand"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brand}
            />
          </div>
          <div>
            <p>Model Number</p>
            <input
              disabled={formDisabled}
              type="text"
              name="model"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.model}
            />
          </div>
          <div>
            <p>Under Warranty</p>
            <input
              disabled={formDisabled}
              type="text"
              name="warranty"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.warranty ? "Yes" : "No"}
            />
          </div>
          <div>
            <p>Existing AMC/CMC</p>
            <input
              disabled={formDisabled}
              type="text"
              name="amc"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.amc ? "Yes" : "No"}
            />
          </div>
          {/* {userData?.post_type === 1 && (
            <div>
              <p>Location</p>
              <textarea
                disabled={formDisabled}
                type="text"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              ></textarea>
            </div>
          )} */}

          <div>
            <p>Other Details</p>
            <textarea
              disabled={formDisabled}
              type="text"
              name="other"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.other}
            ></textarea>
          </div>
          {userData?.post_type === 1 && (
            <div>
              <p>Description</p>
              <textarea
                disabled={formDisabled}
                type="text"
                name="desc"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.desc}
              ></textarea>
            </div>
          )}

          {/* images && videos */}
          <div className={styles.eqipFomrImg}>
            <p>Images</p>
            <div
              style={{
                backgroundImage: `url(${imgs[imgPointer]?.product_images})`,
              }}
              className={styles.imgeCont}
            ></div>
            <div className={styles.vidMover}>
              <img
                src={prevIcon}
                onClick={(e) => moveImg(e, 0)}
                alt="Prev"
                name="prev"
              />
              <p style={{ textAlign: "center" }}>
                {imgs?.length === 0 ? 0 : imgPointer + 1}/{imgs?.length}
              </p>
              <img
                src={nextIcon}
                onClick={(e) => moveImg(e, 0)}
                alt="Next"
                name="next"
              />
            </div>
          </div>

          {videos?.length != 0 && (
            <div>
              <p>Videos</p>
              <video className={styles.imgeCont} controls>
                <source src={videos[videoPointer]} type="video/mp4" />
              </video>
              <div className={styles.vidMover}>
                <img
                  src={prevIcon}
                  onClick={(e) => moveImg(e, 1)}
                  alt="Prev"
                  name="prev"
                />
                <p style={{ textAlign: "center" }}>
                  {videos?.length === 0 ? 0 : videoPointer + 1}/{videos?.length}
                </p>
                <img
                  src={nextIcon}
                  onClick={(e) => moveImg(e, 1)}
                  alt="Next"
                  name="next"
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <div className={styles.ProdActions}>
        <div className={styles.pAct}>Actions</div>
        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Installation Cost</span>
          <input
            className={styles.actInput}
            placeholder="Enter cost"
            name="installation_price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.installation_price}
          />
          <span
            onClick={updateInstallationPrice}
            className={commonCss.applyBtn}
          >
            Apply
          </span>
        </div>

        <div className={styles.statusDrop}>
          <span className={styles.prodTag}>Product Status</span>
          <div>
            <p onClick={() => setOpen(!isOpen)} className={styles.selOpt}>
              {productStatus[currStatus]}
              <i className="bi bi-chevron-down"></i>
            </p>
            {isOpen && (
              <div className={styles.dropOptions}>
                {Object.keys(productStatus).map((key, index) => {
                  return (
                    <p onClick={(e) => handleProdStatus(e, key)} key={index}>
                      {productStatus[key]}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <span onClick={updateProdcutStatus} className={commonCss.applyBtn}>
            Apply
          </span>
        </div>
      </div>
    </section>
  );
};
