import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/css/buy/meeting.module.css";
import { BackgroundBlur, TextModel, MSGBox } from "../../utils/Popups";
import { Calander } from "../../utils/Calanders";
import { Modal } from "antd";
import {
  clearMeetingData,
  handleAmcCms,
  handleHandling,
  handleOnloadService,
  handleShipping,
} from "../../app/Slices/UserData";
import {
  meetingImg,
  scheduleBtn,
  meetSuccess,
  meetIssued,
} from "../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postData1 } from "../../services";
import { toast } from "react-toastify";
import GenerateInspReport from "../../Pdfgenerator/GenerateInspReport";

export const ScheduleMeeting = (props) => {
  const buyerId = localStorage.getItem("uid");
  const [isSuccess, setSuccess] = useState(false);
  const [isMetScheduled, setMetScheduled] = useState(false);
  const [isInspected, setInpection] = useState(false);
  const [isMeetIssue, setMeetIssue] = useState(false);
  const [isBuyIssue, setBuyIssue] = useState(false);
  const [isBuyOption, setBuyOption] = useState(false);
  const [sheduleLis, setSheduleLis] = useState("");
  const [rescheduled, setRescheduled] = useState("");
  const [scheduleMeeting, setScheduleMeeting] = useState(false);
  const item = props?.data;

  const handleGetMeetingList = async () => {
    props.setMeeting(false);
    const data = {
      buyer_id: buyerId,
      product_id: item?.uid,
    };
    const res = await postData1("product/get_schedule_meeting/", data, true);
    if (res?.status) {
      setSheduleLis(res?.data[0]?.uid);
    } else {
      setScheduleMeeting(true);
    }
  };

  const handleInspection = (e) => {
    if (isMetScheduled) {
      setInpection(!isInspected);
    } else if (isInspected) {
      setInpection(false);
    } else {
      props.setMeeting(false);
    }
  };
  const handleBuyIssue = () => {
    let popup = false;
    if (isMetScheduled) {
      setBuyOption(!isBuyOption);
      popup = isBuyOption;
    } else if (isBuyOption) {
      setBuyOption(false);
      popup = true;
    } else {
      setBuyIssue(!isBuyIssue);
      popup = isBuyIssue;
    }
  };

  return (
    <React.Fragment>
      {/* schedule meeting modal start */}
      <Modal
        title="Meeting Scheduled"
        open={props.openMeeting}
        onOk={() => props.setMeeting(false)}
        onCancel={() => props.setMeeting(false)}
        centered
        footer={null}
      >
        <>
          <div className={styles.meetSchedule}>
            <img src={meetingImg} alt="..." />
            <b>Meeting Scheduler</b>
            <p>
              Schedule a online meeting with the seller to get equipment
              Information.
            </p>
            <img
              style={{ cursor: "pointer" }}
              alt="..."
              src={scheduleBtn}
              height="55px"
              name="meetSC"
              onClick={handleGetMeetingList}
            />
          </div>
        </>
      </Modal>
      {/* schedule meeting modal end */}

      {/* Buy Product 1st step*/}
      <Modal
        title="Ready to Checkout?"
        open={props.buyClick}
        onOk={() => props.setBuyClick(false)}
        onCancel={() => props.setBuyClick(false)}
        centered
        footer={null}
        style={{ maxWidth: window.innerWidth <= 786 ? "100%" : "22%" }}
      >
        <InspectionReport
          handleInspection={handleInspection}
          item={item}
          setBuyOption={setBuyOption}
          setInpection={setInpection}
          setBuyClick={props.setBuyClick}
          address={props?.address}
          setMeeting={props.setMeeting}
          insp={props?.insp}
        />
      </Modal>
      {/* Buy Product 1st step*/}

      {/* select add on services */}
      <Modal
        title="Select Add On Services"
        open={isBuyOption}
        onOk={() => setBuyOption(false)}
        onCancel={() => setBuyOption(false)}
        centered
        footer={null}
      >
        <SelectServices
          setBuyOption={setBuyOption}
          setMeeting={props.setMeeting}
          handleBuyIssue={handleBuyIssue}
          item={item}
          address={props.address}
          insp={props?.insp}
        />
      </Modal>
      {/* select add on services */}

      {/* don't need of isMeetIssue component */}
      {/* {isMeetIssue && (
        <MeetingIssue
          handleInspection={handleInspection}
          setInpection={setInpection}
          setMeetIssue={setMeetIssue}
        />
      )} */}
      {/* {isBuyIssue && (
        <BuyIssue
          setBuyIssue={setBuyIssue}
          handleBuyIssue={handleBuyIssue}
          setBuyOption={setBuyOption}
        />
      )} */}
      {/* {isBuyOption && (
        <SelectServices
          setMeeting={props.setMeeting}
          handleBuyIssue={handleBuyIssue}
          item={item}
          address={props.address}
          insp={props?.insp}
        />
      )} */}
      {/* metting sucess start */}
      <Modal
        title="Meeting Scheduled"
        open={isSuccess}
        onOk={() => setSuccess(false)}
        onCancel={() => setSuccess(false)}
        centered
        footer={null}
      >
        <MeetingSuccess
          setSuccess={setSuccess}
          isMetScheduled={isMetScheduled}
          details={item}
          setMeeting={props.setMeeting}
        />
      </Modal>
      {/* metting sucess start */}

      {/* metting already scheduled start */}
      <Modal
        open={sheduleLis !== ""}
        onOk={() => setSheduleLis("")}
        onCancel={() => setSheduleLis("")}
        centered
        footer={null}
      >
        <TextModel
          setRescheduled={setRescheduled}
          setSheduleLis={setSheduleLis}
          setMeeting={props.setMeeting}
          msg={"Your Meeting is already scheduled !"}
        />
      </Modal>
      {/* metting already scheduled end */}

      {/* show calender start */}
      <Modal
        title="Schedule meeting with seller"
        open={scheduleMeeting || rescheduled !== ""}
        onOk={() => {
          setScheduleMeeting(false);
          setRescheduled("");
        }}
        onCancel={() => {
          setScheduleMeeting(false);
          setRescheduled("");
        }}
        centered
        footer={null}
        style={{ minWidth: "550px" }}
      >
        <Calander
          setScheduleMeeting={setScheduleMeeting}
          setRescheduled={setRescheduled}
          setSuccess={setSuccess}
          success={isSuccess}
          setMetScheduled={setMetScheduled}
          openMeeting={props.setMeeting}
          item={item}
        />
      </Modal>
      {/* show calender end */}
    </React.Fragment>
  );
};

const MeetingSuccess = (props) => {
  const dispatch = useDispatch();
  const meetingDetails = useSelector(
    (state) => state.profileData.meeting_details
  );
  const buyer_id = localStorage.getItem("uid");
  const items = props?.details;
  const [terms, setTerms] = useState(true);

  const handleSheduleMeeting = async () => {
    setTerms(false);
    if (items?.user !== buyer_id) {
      const data = {
        buyer: buyer_id,
        seller: items?.user,
        product: items?.uid,
        title: meetingDetails.title,
        date: meetingDetails.date,
        start_time: meetingDetails.start_time,
        end_time: meetingDetails.end_time,
        duration: meetingDetails.duration,
        remind_me: meetingDetails.remind_me,
        link: "1",
        status: items?.status,
      };
      const res = await postData1("product/schedule_meeting/", data, true);
      if (res?.status) {
        setTerms(true);
        props.setSuccess(false);
        dispatch(clearMeetingData());
        toast.success("Meeting scheduled successfuly !");
        setTimeout(() => {
          props.setMeeting(false);
        }, 1000);
      }
    } else {
      props.setMeeting(false);
      toast.error("You can't shedule meeting with own prouduct");
      setTerms(true);
    }
  };
  return (
    <div className={styles.meetSuccessCont}>
      <img alt="..." src={meetSuccess} style={{ marginBottom: "10px" }} />
      <b style={{ marginBottom: "10px" }}>Meeting Scheduled</b>
      <p className={styles.meetNotify}>
        Meeting request is sent to the seller . You will receive the meeting
        link shortly on your registered number.
      </p>
      {terms ? (
        <span
          className={styles.submitBtn}
          name="meetCNF"
          onClick={handleSheduleMeeting}
        >
          CONTINUE
        </span>
      ) : (
        <span
          className={styles.submitBtn}
          style={{ backgroundColor: "#019c8938" }}
        >
          CONTINUE
        </span>
      )}
    </div>
  );
};

const InspectionReport = (props) => {
  const prodDetails = props?.item;
  const navigate = useNavigate();
  let insp_price =
    props?.insp !== undefined ? props?.insp[props?.insp?.length - 1] : 0;

  const handlePayment = async (e) => {
    props.setMeeting(false);
    const data = {
      product_id: prodDetails?.uid,
      order_type: "2",
    };
    const res = await postData1("product/order_review/", data, true);
    if (res?.status) {
      navigate(
        `/checkout/${encodeURIComponent(props?.item?.equip_name)}/?id=${
          props?.item?.id
        }/`,
        {
          state: { details: res?.data, address: props?.address },
        }
      );
      window.scrollTo(0, 0);
    }
  };

  const handleSkip = (e) => {
    if (prodDetails?.post_type === 2) {
      navigate(
        `/checkout/${encodeURIComponent(prodDetails?.equip_name)}/?id=${
          prodDetails?.id
        }/`,
        {
          state: { details: prodDetails, insp_price: insp_price?.amount },
        }
      );
    } else {
      props.setBuyOption(true);
      props.setBuyClick(false);
    }
  };

  const handleDownloadInsp = (amount) => {
    GenerateInspReport(amount);
  };
  return (
    <div className={styles.inspectCont}>
      <div className={styles.submitBtnMain}>
        <div
          style={{ marginTop: "0.85rem" }}
          className={styles.submitBtn}
          onClick={handleSkip}
        >
          Proceed with payment
        </div>
        {insp_price?.amount ? (
          <div
            onClick={() => handleDownloadInsp(insp_price?.amount)}
            className={styles.submitBtn}
          >
            {`Token amount paid ${insp_price?.amount}`}
          </div>
        ) : (
          prodDetails?.post_type !== 2 && (
            <div className={styles.submitBtn} onClick={handlePayment}>
              Get it inspected by our professionals
            </div>
          )
        )}
      </div>
    </div>
  );
};

const MeetingIssue = (props) => {
  const handleContinue = () => {
    props.setMeetIssue(false);
    props.setInpection(true);
  };
  return (
    <div
      className={styles.meetSuccessCont}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "useClickOutsidelate(-50%, -50%)",
      }}
    >
      <div className={styles.successCross}>
        <i class="bi bi-x" onClick={props.handleInspection}></i>
      </div>
      <img alt="..." src={meetIssued} style={{ marginBottom: "10px" }} />
      <b style={{ marginBottom: "10px" }}>Meeting Issue</b>
      <p className={styles.meetNotify}>
        Do you want to continue without scheduling a meeting with the seller.
      </p>
      <span className={styles.submitBtn} onClick={handleContinue}>
        CONTINUE
      </span>
    </div>
  );
};
const BuyIssue = (props) => {
  const handleContinue = () => {
    props.setBuyIssue(false);
    props.setBuyOption(true);
  };
  return (
    <div
      className={styles.meetSuccessCont}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div className={styles.successCross}>
        <i className="bi bi-x" onClick={props.handleBuyIssue}></i>
      </div>
      <img alt="..." src={meetIssued} style={{ marginBottom: "10px" }} />
      {/* <b style={{ marginBottom: "10px" }}>Issue</b> */}
      <p className={styles.meetNotify}>
        Do you want to buy without scheduling a meeting with the seller &
        without getting insepection report !
      </p>
      <span className={styles.submitBtn} onClick={handleContinue}>
        CONTINUE
      </span>
    </div>
  );
};

// select add on services
const SelectServices = (props) => {
  const shipping = useSelector((state) => state.profileData.shipping_eq);
  const handling = useSelector((state) => state.profileData.handling_in);
  const amc = useSelector((state) => state.profileData.amc_cmc_service);
  let insp_price =
    props?.insp !== undefined ? props?.insp[props?.insp?.length - 1] : 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prodDetails = props?.item;

  const handlePayment = async (e) => {
    if (shipping || amc || handling) {
      navigate("/thanks", {
        state: {
          msg: `Your order request is submitted successfully . Proforma invoice and payment link is being shared with you . For assistance please call on 8595903939`,
        },
        // state: {
        //   msg: `Thanks for choosing ${CompanyTitle} services, we are working on your order and your payment link will be shared on your registered number.`,
        // },
      });
      return;
    }
    const data = {
      product_id: prodDetails?.uid,
      order_type: "1",
    };
    const res = await postData1("product/order_review/", data, true);
    if (res?.status) {
      navigate(
        `/checkout/${encodeURIComponent(props?.item?.equip_name)}/?id=${
          props?.item?.id
        }/`,
        {
          state: {
            details: res?.data,
            address: props?.address,
            insp_price: insp_price?.amount,
          },
        }
      );
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    dispatch(handleOnloadService());
  }, []);
  return (
    <div className={styles.buyServiceCont}>
      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={shipping}
          onClick={() => dispatch(handleShipping(!shipping))}
        />
        <span>Shipping Equipment</span>
      </div>
      {shipping && (
        <div className={styles.serviceNote}>
          * The shipping cost will be based on actuals.
        </div>
      )}
      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={handling}
          onClick={() => dispatch(handleHandling(!handling))}
        />
        <span>Handling & Installation</span>
      </div>
      {handling && (
        <div className={styles.serviceNote}>
          * The Handling & Installation cost will be based on actuals.
        </div>
      )}

      <div className={styles.buyServiceOpt}>
        <input
          type="checkbox"
          checked={amc}
          onClick={() => dispatch(handleAmcCms(!amc))}
        />
        <span>AMC/CMC Service</span>
      </div>
      {amc && (
        <div className={styles.serviceNote}>
          * The AMC/CMC Service cost will be based on actuals.
        </div>
      )}

      <p
        className={styles.submitBtn}
        onClick={handlePayment}
        id={styles.servCont}
      >
        CONTINUE
      </p>
    </div>
  );
};
