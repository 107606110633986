import React, { useState } from "react";
import styles from "../../assets/css/content.module.css";
import commonCss from "../../assets/css/common.module.css";
import camera from "../../assets/img/camera.png";
import { useFormik } from "formik";
import { blogDeschema, blogTitleSchema } from "../../../utils/validation";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { postData } from "../../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { nullToEmptyString } from "../../../utils/purefun";
import JoditEditor from "jodit-react";
const IMGURL = process.env.REACT_APP_IMAGE_PREVIEW;

export const AddContentForm = (props) => {
  const apiData = props?.data;
  const location = useLocation();
  const blogData = location?.state;
  const blogPrevImg = `${IMGURL}/mmq_apps/static/upload/content/${blogData?.image}`;

  const [img, setImg] = useState(blogData ? blogPrevImg : false);
  const [imgFile, setImgFile] = useState(false);
  const [imgError, setImgError] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: nullToEmptyString(blogData?.name),
      description: nullToEmptyString(blogData?.description),
    },
    validationSchema: yup.object().shape({
      title: blogTitleSchema,
      description: blogDeschema,
    }),
    onSubmit: function (values) {
      handleForm(values);
    },
  });

  const handleForm = async (values) => {
    if (!img) {
      setImgError(true);
      return;
    }

    const formData = new FormData();
    formData.append("name", values.title);
    if (imgFile) {
      formData.append("image", imgFile);
    }
    if (blogData) {
      formData.append("uid", blogData?.uid);
    }
    formData.append("description", values.description);
    formData.append("content_type", apiData?.type);

    // calling api
    const res = await postData("master/add_content/", formData, true, true);
    if (res?.status_code === 200) {
      navigate(apiData?.listURL);
    } else {
      toast.error("Something went wrong try again !");
    }
  };

  const handleImg = (event) => {
    let imgFile = event.target.files[0];
    let imgObj = URL?.createObjectURL(imgFile);
    setImg(imgObj);
    setImgError(false);
    setImgFile(imgFile);
  };

  return (
    <form className={styles.contentForm} onSubmit={formik.handleSubmit}>
      <ToastContainer />
      <h1>{apiData?.title}</h1>
      <div className={styles.contFields}>
        <p>Title/Name</p>
        <input
          type="text"
          placeholder="Title or Name"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
        />
        {formik.errors.title && (
          <span style={{ color: "red" }}>{formik.errors.title}</span>
        )}
      </div>

      <div className={styles.contImg}>
        <label for="editImg">
          <i className="bi bi-pencil-square" for="editImg"></i>
          <input
            type="file"
            accept="image/*"
            id="editImg"
            onChange={handleImg}
          />
        </label>
        <img src={img ? img : camera} alt="Upload" />
        {imgError && (
          <div style={{ color: "red" }}>Image is required for your post !</div>
        )}
      </div>
      <div className={styles.contFields}>
        <JoditEditor
          onChange={(newContent) =>
            formik.setFieldValue("description", newContent)
          }
          value={formik.values.description}
          tabIndex={1}
          onBlur={(e) => formik.handleBlur("description")}
          dangerouslySetInnerHTML={{ __html: formik.values.description }}
          config={{
            uploader: {
              insertImageAsBase64URI: true,
            },
          }}
        />
      </div>

      <div className={styles.postCont}>
        <input
          className={commonCss.applyBtn}
          type="submit"
          value={blogData ? "Update" : "Post"}
        />
      </div>
    </form>
  );
};
