import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../assets/css/utils.module.css";
const Toaster = () => {
  return (
    <ToastContainer
    
      // position="top-right"
      className={styles.custom_toast_container}
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  );
};

export { Toaster, toast };
