import * as yup from "yup";

const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

export const fnameSchema = yup
  .string()
  .min(2)
  .max(30)
  .required("Please enter your name");

export const blogTitleSchema = yup
  .string()
  .max(200)
  .required("Title is required for your post !");

export const blogDeschema = yup.string().required("Description is required !");

export const lnameSchema = yup.string().min(2).max(30).required();
export const stringFyName = yup
  .string()
  .matches(/^[^\s!@#$%^&*()_+=[\]{}|;:'",<>/?\\].*$/, "Enter valid  string!")
  .required("Field is required !");

export const pnumberSchema = yup
  .string()
  .matches(/^[0-9]{12}$/, "Please Enter Mobile Number with code")
  .required("Please Enter Mobile Number with code");
export const mnumberSchema = yup
  .string()
  .matches(/^[0-9]{10}$/, "Please enter 10  digits mobile number")
  .notOneOf(
    ["--", "++", ".."],
    'Phone number cannot contain "--", "++", or "..'
  )
  .matches(/^[^+\-.]+$/, 'Phone number cannot contain "+", "-", or "."')
  .required("Field is required !");

export const emailSchema = yup
  .string()
  .matches(
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    "Invalid email address"
  )
  .required("Email address is required");
export const passwordSchema = yup.string().required("Password is required");

export const nationalitySchema = yup
  .string()
  .required("Please enter your location name");

export const gstinSchema = yup.string().matches(gstinRegex);

export const pancardSchema = yup.string().matches(panRegex).required();

export const equipmentName = yup.string().required("Equipment Name Required !");

export const pincodeSchema = yup
  .string()
  .matches(/^[0-9]{6}$/, "PIN code must be exactly 6 digits")
  .required("PinCode is Required");

export const addressSchema = yup
  .string()
  .required("Please enter your full address");

export const stateSchema = yup.string().required("Please enter your state");

export const citySchema = yup.string().required("Please enter your city");

export const addressTypeSchema = yup
  .string()
  .required("Please Choose Address Type");

export const typeValidation = yup.string().required("Field is required !");

export const prodYearSchema = yup
  .string()
  .matches(/^\d{4}$/, "Please enter a valid year (e.g., 2023)")
  .test(
    "valid-year",
    "Year should not be after the current year",
    function (value) {
      const currentYear = new Date().getFullYear();
      const inputYear = parseInt(value, 10);
      return inputYear <= currentYear;
    }
  )
  .required("Please enter a valid year (e.g., 2023)");

export const minYear = yup
  .string()
  .matches(/^\d{4}$/, "Please enter a valid year (e.g., 2015)")
  .test(
    "Min-Yaer",
    "Min Year should be greater then or equal to 2015",
    function (value) {
      const minYear = new Date("2015").getFullYear();
      const currentYear = new Date().getFullYear();
      const inputYear = parseInt(value, 10);
      return inputYear >= minYear && inputYear <= currentYear;
    }
  )
  .required("Please enter a year");

export const minPrice = yup
  .number()
  .typeError("Please enter a valid number'")
  .positive("Price must be greater than 0")
  .required("Please enter a price");
