import React from "react";
import { Login, OtpVervicatonForm } from "../features/Auth/LoginRegister";
import styles from "../assets/css/utils.module.css";
import { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  linkdinLogin,
  fbllogin,
  whatsApp2,
  twitter2,
  email2,
} from "../assets/images/index";
import { useLocation } from "react-router-dom";

export const SocialShare = ({ item }) => {
  const product_id = item?.id;
  const product_name = encodeURIComponent(item?.equip_name);
  let shareUr = `https://mediquip.in/products/${product_name}/?id=${product_id}/`;
  let title = "Check out this awesome website!";
  const emailBody = encodeURIComponent(
    `Hey, I found this awesome website : ${shareUr}`
  );
  const emailSubject = encodeURIComponent("Check out this website");
  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${emailSubject}&body=${emailBody}`;

  const handleShare = () => {
    const message = `${title}: ${shareUr}`;
    // const shareUrlWithId = `${shareUr}?id=${product_id}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <React.Fragment>
      <WhatsappShareButton url={shareUr} title={title} onClick={handleShare}>
        <img alt="..." src={whatsApp2} />
      </WhatsappShareButton>
      <a href={gmailUrl} target="_blank" rel="noopener noreferrer">
        <img alt="Email" src={email2} />
      </a>
      {/* <EmailShareButton url={gmailUrl} subject={emailSubject} body={emailBody} >
        <img alt="..." src={email2} />
      </EmailShareButton> */}
      <TwitterShareButton url={shareUr} title={title}>
        <img alt="..." src={twitter2} />
      </TwitterShareButton>
      <FacebookShareButton url={shareUr} quote={title}>
        <img alt="..." src={fbllogin} />
      </FacebookShareButton>
      <LinkedinShareButton url={shareUr} title={title}>
        <img alt="..." src={linkdinLogin} />
      </LinkedinShareButton>
    </React.Fragment>
  );
};

export const GetStarted = (props) => {
  const [otpForm, setOtpForm] = useState(false);
  const [otp, setotp] = useState("");
  const [number, setNumber] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");

  const handleClose = () => {
    props.setGetStart(false);
    props.setBlur(false);
  };
  const title = "Get Started";
  const title2 = "Sign In with your mobile number To access your account";

  return (
    <div className={styles.getStartContainer}>
      <div>
        {otpForm ? (
          ""
        ) : (
          <React.Fragment>
            <div className={styles.getStartTop}>
              <b>
                <i onClick={handleClose} class="bi bi-x-lg"></i>
              </b>
              <h2> {title} </h2>
            </div>
            <p className={styles.getStartInd}> {title2}</p>
          </React.Fragment>
        )}
        {otpForm ? (
          <OtpVervicatonForm
            getOtp={otp}
            setotp={setotp}
            number={number}
            firstName={fname}
            lastName={lname}
            setGetStart={props.setGetStart}
            setBlur={props.setBlur}
          />
        ) : (
          <Login
            setOtpForm={setOtpForm}
            setotp={setotp}
            setNumber={setNumber}
            setFirstName={setfname}
            setLastName={setlname}
          />
        )}
      </div>
    </div>
  );
};

export const TextModel = (props) => {
  let msg = props.msg;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100px",
      }}
    >
      <p className={styles.pText}>{msg}</p>
      <div className={styles.buttonDec}>
        <button
          onClick={() => {
            props.setRescheduled("Rescheduled");
            // cancelBtn(false);
            props.setSheduleLis("");
          }}
          className={styles.btnDec}
        >
          Rescheduled
        </button>
        <button
          onClick={() => props.setSheduleLis("")}
          className={styles.btnDec1}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export const MSGBox = (props) => {
  const handleClick = () => {
    props.handlePop(!props.msg);
  };
  return (
    <div className={styles.msgBoxCont}>
      <p>
        <i onClick={handleClick} className="bi bi-x-lg"></i>
      </p>
      <span>{props.msgCont}</span>
    </div>
  );
};

export const BackgroundBlur = () => {
  return <div className={styles.bgBlur}></div>;
};
