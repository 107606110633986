import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SearchBox = (props) => {
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search-products`);
  };
    return (
        <div className="relative w-full">
            <input
                onClick={handleSearch}
                type="text"
                placeholder="Find Medical Instruments..."
                className="w-full h-12 pl-4 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                <FaSearch className="text-gray-400" />
            </span>
        </div>
    );
  };
  
  export default SearchBox;