// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thanks_thanksPage__hj7cQ {
  background-color: #019c89;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.thanks_thanksCard__QRhG\\+ {
  width: 320px;
  background-color: white;
  text-align: center;
  min-height: 220px;
  padding: 25px;
  border-radius: 5px;
}
.thanks_thanksCard__QRhG\\+ h4 {
  font-weight: 800;
  color: #4d4d4d;
  font-size: 26px;
  margin: 20px 0px 10px 0px;
}
.thanks_thanksCard__QRhG\\+ p {
  font-weight: 600;
  color: #16266d;
  font-size: 15px;
  line-height: 22px;
  margin: 10px 0px 25px 0px;
}
.thanks_thanksIcon__7P3Ga i {
  background-color: rgb(88, 188, 228);
  border-radius: 50%;
  font-size: 45px;
  padding: 6px 9px 6px 9px;
  text-align: center;
  color: white;
  outline: 1px solid rgb(89, 174, 207);
}

.thanks_thanksCard__QRhG\\+ a {
  border: none;
  background-color: #43bdb7;
  padding: 9px 18px 9px 18px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/buy/thanks.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,aAAa;AACf;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,eAAe;EACf,wBAAwB;EACxB,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,0BAA0B;EAC1B,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".thanksPage {\r\n  background-color: #019c89;\r\n  display: flex;\r\n  text-align: center;\r\n  align-items: center;\r\n  justify-content: center;\r\n  width: 100%;\r\n  height: 100vh;\r\n}\r\n.thanksCard {\r\n  width: 320px;\r\n  background-color: white;\r\n  text-align: center;\r\n  min-height: 220px;\r\n  padding: 25px;\r\n  border-radius: 5px;\r\n}\r\n.thanksCard h4 {\r\n  font-weight: 800;\r\n  color: #4d4d4d;\r\n  font-size: 26px;\r\n  margin: 20px 0px 10px 0px;\r\n}\r\n.thanksCard p {\r\n  font-weight: 600;\r\n  color: #16266d;\r\n  font-size: 15px;\r\n  line-height: 22px;\r\n  margin: 10px 0px 25px 0px;\r\n}\r\n.thanksIcon i {\r\n  background-color: rgb(88, 188, 228);\r\n  border-radius: 50%;\r\n  font-size: 45px;\r\n  padding: 6px 9px 6px 9px;\r\n  text-align: center;\r\n  color: white;\r\n  outline: 1px solid rgb(89, 174, 207);\r\n}\r\n\r\n.thanksCard a {\r\n  border: none;\r\n  background-color: #43bdb7;\r\n  padding: 9px 18px 9px 18px;\r\n  color: #fff;\r\n  font-weight: 500;\r\n  font-size: 16px;\r\n  border-radius: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thanksPage": `thanks_thanksPage__hj7cQ`,
	"thanksCard": `thanks_thanksCard__QRhG+`,
	"thanksIcon": `thanks_thanksIcon__7P3Ga`
};
export default ___CSS_LOADER_EXPORT___;
