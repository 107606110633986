import React, { useEffect } from "react";
import styles from "../assets/css/card.module.css";
import { GetStarted, BackgroundBlur } from "../utils/Popups";
import {
  relatedImg,
  pngwing,
  favouriate,
  cart,
  newBanner,
  expert,
  suffix,
  line2,
  testimage2,
  prev_arrow,
  next_arrow,
} from "../assets/images/index";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  fetchAddToCart,
  setAddCartLength,
} from "../app/Slices/UserData";
import { ToastContainer, toast } from "react-toastify";
import { postData, postData1 } from "../services";

export const ClientCard = ({ clientList }) => {
  return (
    <>
      <ToastContainer />
      <div className={styles.testContainer} key={clientList?.id}>
        <img className={styles.suffixMark} src={suffix} />
        <p className={styles.testCardDesc}>{clientList?.description}</p>
        <img src={line2} alt="..." />
        <img
          className={styles.clientImage}
          src={
            clientList?.client_image
              ? `${clientList?.client_image}`
              : testimage2
          }
          alt="..."
        />
        <p className={styles.clientName}>{clientList?.name}</p>
      </div>
    </>
  );
};

export const ExpertCard = (props) => {
  return (
    <div className={styles.expertComp} key={props.expertise?.id}>
      <img
        src={prev_arrow}
        alt="prev"
        className={styles.arrowImgPrev}
        onClick={props.handleScrollLeft}
        style={{ marginBottom: "45px" }}
      />
      <img
        className={styles.expImage}
        src={
          props.expertise?.expertise_image
            ? `${props.expertise?.expertise_image}`
            : expert
        }
        // alt="expert"
      />
      <p className={styles.expTitle}>
        {props.expertise?.name ? props.expertise?.name : "Sale Lead"}
      </p>
      <p className={styles.expDesc}>
        {" "}
        {props.expertise?.description
          ? props.expertise?.description
          : "Sale Lead Description"}{" "}
      </p>
      <img
        src={next_arrow}
        alt="next"
        className={styles.arrowImgNext}
        onClick={props.handleScrollRight}
        style={{ marginBottom: "45px" }}
      />
    </div>
  );
};

export const NewProductsCard = (props) => {
  const [getStart, setGetStart] = useState(false);
  const isLogin = localStorage.getItem("token");
  const buyerId = localStorage.getItem("uid");

  const [isBlur, setBlur] = useState(false);
  const addCart = useSelector((state) => state.profileData.cartItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productClick = (item) => {
    const encodedEquipName = encodeURIComponent(item?.equip_name);
    navigate(`/products/${encodedEquipName}/?id=${item?.id}/`, {
      state: { prodDetails: item },
    });
    window.scrollTo(0, 0);
  };

  const sellarClick = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    if (isLogin) {
      const encodedEquipName = encodeURIComponent(item?.equip_name);
      navigate(`/products/${encodedEquipName}/?id=${item?.id}/`, {
        state: { prodDetails: item, c_seller: true },
      });
    } else {
      setBlur(true);
      window.scrollTo(0, 0);
      setGetStart(!getStart);
    }
  };

  useEffect(() => {
    const storedCart = localStorage.getItem("add_to_cart");
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
    }
  }, []);
  // useEffect(() => {
  //   dispatch(fetchAddToCart(isLogin));
  // }, [isLogin]);

  const handleATC = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isLogin) {
      const updateData = addCart?.filter(
        (el) => el?.product === props?.data?.uid
      );
      if (updateData?.length > 0) {
        dispatch(fetchAddToCart(isLogin));
        toast.info(`${props?.data?.equip_name} is already added in the cart`, {
          autoClose: 1800,
        });
      } else {
        const data = {
          buyer: buyerId,
          product: props?.data?.uid,
          seller: props?.data?.user,
          asking_price: Number(props?.data?.asking_price),
        };
        const res = await postData1("product/order/addcart/", data, true);
        if (res?.status) {
          dispatch(fetchAddToCart(isLogin));
          toast.success(`${props?.data?.equip_name} is added to the cart`, {
            autoClose: 1800,
          });
        }
      }
    } else {
      const storedCart = JSON.parse(localStorage.getItem("add_to_cart")) || [];
      const storedCartId = props?.data?.id;
      const isStoredCard = storedCart.some((item) => item.id === storedCartId);
      if (isStoredCard) {
        toast.info(`${props?.data?.equip_name} is already added in the cart`, {
          autoClose: 1800,
        });
      } else {
        storedCart.push(props?.data);
        localStorage.setItem("add_to_cart", JSON.stringify(storedCart));
        dispatch(setAddCartLength(storedCart?.length));
        toast.success(`${props?.data?.equip_name} is added to the cart`, {
          autoClose: 1800,
        });
      }
    }
  };

  const backgoundImgStyle = {
    backgroundImage: `url(${
      props?.data?.product_images?.length > 0
        ? `${props?.data?.product_images[0]?.product_images}`
        : pngwing
    })`,
    backgroundSize: "cover",
  };

  return (
    <React.Fragment>
      <div
        className={styles.cardContainer}
        onClick={() => productClick(props?.data)}
      >
        <div>
          <div className={styles.equipImage}>
            <div className={styles.productImage} style={backgoundImgStyle}>
              {props.isNew && (
                <img
                  src={newBanner}
                  alt="banner"
                  className={styles.newBanner}
                />
              )}
              <div className={styles.favCart}>
                <a href="#">
                  <img src={favouriate} alt=".." />
                </a>
                <a href="#" onClick={handleATC}>
                  <img src={cart} alt=".." />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.title_desk}>
            <h5>{props?.data?.equip_name}</h5>
            <p className={styles.carddesc} >
              {props?.data?.description?.length > 30
                ? `${props?.data?.description?.slice(0, 30)}... `
                : props?.data?.description?.length}
            </p>
          </div>
          <div className={styles.price}>
            <div className={styles.priceTag}>
              <h5>₹ {Number(props?.data?.asking_price).toFixed(2)}</h5>
              <p>₹{props?.data?.asking_price}</p>
            </div>
            <div>
              <NavLink
                className={styles.PriceBtn}
                onClick={(e) => sellarClick(e, props?.data)}
              >
                Buy now
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {getStart ? (
        <GetStarted setGetStart={setGetStart} setBlur={setBlur} />
      ) : (
        ""
      )}
      {isBlur ? <BackgroundBlur /> : ""}
    </React.Fragment>
  );
};

export const RelatedProdCard = (props) => {
  const navigate = useNavigate();

  const handleProdClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const encodedEquipName = encodeURIComponent(props?.data?.equip_name);
    navigate(`/products/${encodedEquipName}/?id=${props?.data?.id}/`, {
      state: { prodDetails: props?.data },
    });
    
  };

  return (
    <div className={styles.column} onClick={handleProdClick}>
      <div className={styles.imagess}>
        <img
          className={styles.images}
          src={
            props?.data?.product_images[0]?.product_images
              ? props?.data?.product_images[0]?.product_images
              : relatedImg
          }
          alt="Jane"
        />
      </div>
      <div className={styles.contain}>
        <h5 className={styles.prodtitle}>{props?.data?.equip_name}</h5>
        <p className={styles.containDis}>
          {" "}
          {props?.data?.description !== "null" ? props?.data?.description?.length > 40
            ? `${props?.data?.description?.slice(0, 40)}... `
            : props?.data?.description?.length : 
            props?.data?.other_details?.length > 40
            ? `${props?.data?.other_details?.slice(0, 40)}... `
            : props?.data?.other_details?.length
            }
        </p>
        <h4 className={styles.pprice}>₹ {Number(props?.data?.asking_price)}</h4>
      </div>
    </div>
  );
};
