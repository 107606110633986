import React from "react";
import { DashboardAdvt } from "../../components/Advt";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styles from "../../assets/css/buy/prod_desc.module.css";
import { RelatedProdCard } from "../../components/Cards";
import { GetStarted, BackgroundBlur } from "../../utils/Popups";
import * as yup from "yup";
import { SocialShare } from "../../utils/Popups";
import { emailSchema, fnameSchema } from "../../utils/validation";
import { useRef, useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Modal } from "antd";
import {
  findE,
  inspection,
  closeDeal,
  handling,
  amc,
  shipped,
  location,
  contBtn,
  atcBtn,
  whatsBtn,
  unfilStar,
  rightMove,
  homeIcon,
  pngwing,
  nextArow,
  swipetestleft,
  pdShare,
  star,
  filledStar,
  testimage2,
  schedule,
} from "../../assets/images/index";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ScheduleMeeting } from "./Meeting";
import { setCurrBuyStatus } from "../../app/Slices/UserData";
import { postData, postData1 } from "../../services";
import ReactImageMagnify from "react-image-magnify";
import { CompanyTitle } from "../../assets/data/data";
import ProdAddress from "../../utils/ProdAddress";
import LargePreviewImage from "../../utils/LargePreviewImage";
import { Loader } from "../../components/Loader";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const ProductDescription = () => {
  return (
    <div className={styles.pd_container}>
      <div className={styles.prod_advtsment}>
        <DashboardAdvt />
        <MMQprocess />
      </div>
      <ProductData />
      <RelatedProd />
    </div>
  );
};

const ProductData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let pathname = location?.search?.split("=");
  let splitEqual = pathname[1]?.split("/");
  const c_seller = location?.state?.c_seller;
  const navigate = useNavigate();
  let isLogin = localStorage.getItem("token");
  let buyerId = localStorage.getItem("uid");
  const [getStart, setGetStart] = useState(false);
  const [isBlur, setBlur] = useState(false);
  const [click, setClick] = useState("photo");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedData, setDisplayedData] = useState({});
  const [profile, setProfle] = useState({});
  const [inspection, setInspection] = useState([]);
  const [openSocial, setOpenSocial] = useState(false);
  const [openImageCard, setOpenImageCard] = useState(false);
  const [item, setItem] = useState([]);
  console.log("item", item);
  const [toggle, setToggle] = useState(true);
  const phoneNumber = "+919813602268";
  const encodedPhoneNumber = encodeURIComponent(phoneNumber);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [openMeeting, setMeeting] = useState(c_seller);
  const [buyClick, setBuyClick] = useState(false);

  const contRef = useRef(null);

  const handleDecodeProduct = async () => {
    if (splitEqual && splitEqual?.length > 0) {
      const formData = new FormData();
      formData.append("id", splitEqual[0]);
      const res = await postData(
        "product/filter_list/",
        formData,
        false,
        false
      );
      if (res?.status) {
        setToggle(false);
        setItem(res?.data?.new_products[0]);
      }
    }
  };

  useEffect(() => {
    handleDecodeProduct();
  }, [splitEqual[0]]);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const res = await postData1(
        "product/details/",
        { product_id: item?.uid },
        true
      );
      if (res?.status_code === 200) {
        let cstate = 0;
        if (res?.data?.schedule_meeting?.length > 0) {
          cstate = 1;
        }
        if (res?.data?.inspection?.length > 0) {
          cstate = 2;
        }
        dispatch(setCurrBuyStatus({ curr: cstate }));
      }
    })();
  }, []);

  const arrangedDemo = async () => {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    var day = currentDate.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;

    let checkData = {
      product_id: item?.uid,
      buyer_id: buyerId,
    };
    let data = {
      buyer: buyerId,
      seller: item?.user,
      product: item?.uid,
      title: `Request to arrange demo`,
      date: formattedDate,
    };

    const resDemo = await postData1(
      "product/get_arrange_demo/",
      checkData,
      true
    );
    if (resDemo?.status_code === 200) {
      if (resDemo?.data?.length === 0) {
        const res = await postData1("product/add_arrange_demo/", data, true);
        if (res?.status_code === 200) {
          toast.success(
            `Thanks for choosing ${CompanyTitle} services, we are working on your request and will revert shortly on your registered number !`
          );
        } else {
          toast.error("Something went wrong try again !");
        }
      } else {
        toast.info(
          "You have already requested for demo, we will contact you for the same !"
        );
      }
    } else {
      toast.error("Something went wrong try again !");
    }
  };
  const sellarClick = (event, check) => {
    event.preventDefault();
    if (isLogin) {
      if (item?.post_type === 2 && !check) {
        arrangedDemo();
        return;
      }
      contRef.current.scrollIntoView();
      check ? setBuyClick(true) : setMeeting(true);
    } else {
      setBlur(true);
      setGetStart(!getStart);
      navigate(`${location?.pathname}${location?.search}`, {
        state: {
          navigateTo: `${location?.pathname}${location?.search}`,
          prodDetails: item,
        },
      });
    }
  };

  const handleSocial = (e) => {
    setOpenSocial(!openSocial);
  };

  const ref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpenSocial(false);
      }
    });
  }, []);

  const handleLeft = () => {
    setCurrentIndex(
      (prev) =>
        (prev - 1 + item?.product_images?.length) % item?.product_images?.length
    );
  };
  const handleRight = () => {
    setCurrentIndex((prev) => prev + (1 % item?.product_images?.length));
  };

  useEffect(() => {
    const updateDisplayedData = () => {
      if (item?.product_images?.length > 0) {
        const displayed =
          item?.product_images[currentIndex % item?.product_images?.length];
        setDisplayedData(displayed);
      }
    };
    updateDisplayedData();
  }, [currentIndex, item]);

  useEffect(() => {
    handleUserProfile();
    handleGetInspection();
  }, []);

  const handleUserProfile = async () => {
    const formData = new FormData();
    formData.append("uid", item?.address);
    const res = await postData("users/get_user_detail/", formData, true);
    if (res?.status) {
      setProfle(res?.data?.profile);
    }
  };

  const handleGetInspection = async () => {
    const data = {
      buyer_id: buyerId,
      product_id: item?.uid,
    };
    const res = await postData1("product/get_inspection/", data, true);
    setInspection(res?.data);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  console.log(windowWidth, "windowWidth");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageChage = (image, index) => {
    setDisplayedData(image);
    setPhotoIndex(index);
  };

  const handleClosePop = () => {
    setOpenImageCard(true);
  };

  return (
    <React.Fragment>
      {toggle && <Loader />}
      <ToastContainer />
      <div className={styles.prod_path}>
        <NavLink to="/">
          <FaArrowLeft
            style={{ paddingTop: "5px", paddingRight: "5px", fontSize: "15px" }}
          />
        </NavLink>
        <img src={homeIcon} alt="..." />
        <img src={rightMove} alt="..." />
        <NavLink to="/">{item?.equip_name}</NavLink>
      </div>
      <div ref={contRef} className={styles.prod_data}>
        <div className={styles.prod_imgs}>
          <div onClick={() => handleClosePop()}>
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "No Image ",

                  isFluidWidth: false,
                  src: displayedData?.product_images
                    ? displayedData?.product_images
                    : pngwing,
                  width: windowWidth >= 320 ? 280 : windowWidth - 70,
                  height: 220,
                },
                largeImage: {
                  src: displayedData?.product_images
                    ? displayedData?.product_images
                    : pngwing,
                  width: 1000,
                  height: 1000,
                },

                enlargedImageContainerStyle: {
                  // zIndex: "1500",
                  backgroundColor: "#ebedf0",
                  borderRadius: "3px",
                },
                enlargedImageContainerDimensions: {
                  width: "200%",
                  height: "150%",
                },
              }}
            />
          </div>
          {openImageCard && (
            <Lightbox
              mainSrc={item?.product_images[photoIndex].product_images}
              nextSrc={
                item?.product_images[
                  (photoIndex + 1) % item?.product_images.length
                ]
              }
              prevSrc={
                item?.product_images[
                  (photoIndex + item?.product_images.length - 1) %
                    item?.product_images.length
                ]
              }
              onCloseRequest={() => setOpenImageCard(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex + item?.product_images.length - 1) %
                    item?.product_images.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % item?.product_images.length)
              }
            />
          )}
          <div className={styles.imgSlider}>
            <img
              src={swipetestleft}
              onClick={handleLeft}
              alt="..."
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
            />
            {item?.product_images?.length > 0 ? (
              item?.product_images?.slice(0, 4)?.map((image, index) => {
                return (
                  <img
                    key={image?.id}
                    onClick={() => handleImageChage(image, index)}
                    src={image?.product_images}
                    alt="no image"
                    style={{ cursor: "pointer" }}
                  />
                );
              })
            ) : (
              <>
                <img src={pngwing} alt="..." />
                <img src={pngwing} alt="..." />
                <img src={pngwing} alt="..." />
                <img src={pngwing} alt="..." />
              </>
            )}
            <img
              src={nextArow}
              onClick={handleRight}
              style={{ width: "25px", height: "25px", cursor: "pointer" }}
              alt="..."
            />
          </div>
        </div>
        <div className={styles.p_data}>
          <div className={styles.p_head}>
            <div>
              <div className={styles.newProd}>
                <h3 style={{ marginBottom: "0px" }}>{item?.equip_name}</h3>
                <span>
                  {item?.post_type === 1
                    ? "PRE OWNED"
                    : item?.post_type === 2
                    ? "NEW"
                    : item?.post_type === 3
                    ? "SPARE & ACCESSORIES"
                    : "SERVICES"}
                </span>
              </div>
              {/* <p>{item?.uid?.slice(0, 8)}</p> */}
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <img
                src={pdShare}
                ref={ref}
                alt="..."
                onClick={handleSocial}
                style={{ width: "80px", cursor: "pointer" }}
              />
              {openSocial && (
                <div className={styles.socialShare}>
                  <SocialShare item={item} />
                </div>
              )}
            </div>
          </div>
          {/* {openMeeting && ( */}
          <ScheduleMeeting
            openMeeting={openMeeting}
            buyClick={buyClick}
            setBuyClick={setBuyClick}
            setMeeting={setMeeting}
            sellarClick={sellarClick}
            data={item}
            insp={inspection}
            address={item?.address}
          />
          {/* // )} */}
          <ProductMeta info={item} data={profile} />
          <div className={styles.prodActLinks}>
            <NavLink
              className={styles.contactSellar}
              onClick={(e) => {
                sellarClick(e, false);
              }}
            >
              <img src={contBtn} height="15px" alt="..." />
              <span>
                {item?.post_type === 2 ? "ARRANGE DEMO" : "CONTACT SELLER"}
              </span>
            </NavLink>
            {isLogin ? (
              <NavLink
                to={`https://wa.me/${encodedPhoneNumber}`}
                target="_blank"
                style={{ backgroundColor: "#2EB943" }}
                className={styles.contactSellar}
              >
                <img src={whatsBtn} height="15px" alt="..." />
                <span>CHAT ON WHATSAPP</span>
              </NavLink>
            ) : (
              <NavLink
                style={{ backgroundColor: "#2EB943" }}
                onClick={sellarClick}
                className={styles.contactSellar}
              >
                <img src={whatsBtn} height="15px" alt="..." />
                <span>CHAT ON WHATSAPP</span>
              </NavLink>
            )}
            <NavLink
              style={{ backgroundColor: "#FFDD75", color: "black" }}
              onClick={(e) => {
                sellarClick(e, true);
              }}
              className={styles.contactSellar}
            >
              <img src={atcBtn} height="15px" alt="..." />
              <span>CLICK TO BUY NOW</span>
            </NavLink>
          </div>
          <div className={styles.prodDesclaimer}>
            <b style={{ fontSize: "12px" }}>Disclaimer : </b>
            <span style={{ fontSize: "12px" }}>
              Product details are submitted by the seller. {CompanyTitle} will
              carry out inspection and give you inspection report
              before you purchase.
            </span>
          </div>
        </div>
      </div>
      {getStart ? (
        <GetStarted setGetStart={setGetStart} setBlur={setBlur} />
      ) : (
        ""
      )}
      {isBlur ? <BackgroundBlur /> : ""}
      <div className={styles.pd_info}>
        <div className={styles.pd_info_links}>
          <div
            style={{ marginRight: "20px" }}
            onClick={() => setClick("details")}
            className={`${
              click == "details" ? styles.isActive : styles.isDeactive
            }`}
          >
            Specifications
          </div>
          <div
            onClick={() => setClick("photo")}
            className={`${
              click == "photo" ? styles.isActive : styles.isDeactive
            }`}
          >
            PHOTOS
          </div>
          {/* <div
            style={{ marginLeft: "20px" }}
            onClick={() => setClick("review")}
            className={`${
              click == "review" ? styles.isActive : styles.isDeactive
            }`}
          >
            REVIEWS
          </div> */}
        </div>
      </div>
      <div>
        {click === "details" ? (
          <ProductMetaData info={item} />
        ) : click === "photo" ? (
          <ProductImgVideo info={item} />
        ) : click === "review" ? (
          <ProductReview />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export const ProductMeta = ({ info, data }) => {
  let negotaiblity = [
    "",
    "Negotiable",
    "Slightly Negotiable",
    "Non-Negotiable",
  ];
  return (
    <React.Fragment>
      <div>
        <div className={styles.pd_links}>
          <div className={styles.sellerName}>
            <img
              src={data?.profile_image ? `${data?.profile_image}` : testimage2}
              alt="..."
            />
            {`${info?.seller_firstname} ${info?.seller_lastname}` && (
              <p>
                {info?.seller_firstname} {info?.seller_lastname}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              width: "40%",
              fontSize: "10px",
            }}
          >
            <span>
              {info?.address && <ProdAddress address={info?.address} />}
            </span>
            <img src={location} alt="..." />
          </div>
        </div>
      </div>

      <div>
        <p style={{ color: "#019C89" }}>Product Details</p>
        <p>
          {info?.description === "null"
            ? info?.other_details
            : info?.description}
        </p>
      </div>

      <div>
        <h3>₹ {Number(info?.asking_price).toFixed(2)}</h3>
        <p>Including GST</p>
        {info?.post_type !== 2 && (
          <p>({negotaiblity[info?.negotiable_type]})</p>
        )}
      </div>
    </React.Fragment>
  );
};

export const ProductImgVideo = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexImg, setCurrentIndexImg] = useState(0);
  const [displayVideo, setDisplayVideo] = useState([]);
  const [displayImg, setDisplayImg] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [toggel, setToggle] = useState(false);
  const ref = useRef();
  const [openImageCard, setOpenImageCard] = useState(false);

  const handleScrollRight = () => {
    setCurrentIndex((prev) => (prev % props?.info?.product_video?.length) + 1);
  };

  const handleScrollLeft = () => {
    setCurrentIndex(
      (prev) =>
        (prev - 1 + props?.info?.product_video?.length) %
        props?.info?.product_video?.length
    );
  };

  const handleScrollRightImg = () => {
    setCurrentIndexImg(
      (prev) => (prev % props?.info?.product_images?.length) + 1
    );
  };

  const handleScrollLeftImg = () => {
    setCurrentIndexImg(
      (prev) =>
        (prev - 1 + props?.info?.product_images?.length) %
        props?.info?.product_images?.length
    );
  };

  const handleShowImg = () => {
    if (props?.info?.product_images?.length > 0) {
      let startIndex = currentIndexImg % props?.info.product_images?.length;
      let display = [
        props?.info?.product_images[startIndex],
        props?.info?.product_images[
          (startIndex + 1) % props?.info?.product_images?.length
        ],
        props?.info?.product_images[
          (startIndex + 2) % props?.info?.product_images?.length
        ],
      ];
      setDisplayImg(display);
    }
  };

  useEffect(() => {
    handleShowImg();
  }, [currentIndexImg, props?.info?.product_images?.length > 0]);

  const handleShowVideo = () => {
    if (props?.info?.product_video?.length > 0) {
      let display = [
        props?.info?.product_video[
          currentIndex % props?.info?.product_video?.length
        ],
      ];
      setDisplayVideo(display);
    }
  };

  useEffect(() => {
    handleShowVideo();
  }, [currentIndex, props?.info?.product_video?.length > 0]);

  const handleImgPreview = (url) => {
    setImgUrl(url);
    setToggle(true);
  };

  const handleOk = () => {
    setToggle(false);
  };

  const handleCancel = () => {
    setToggle(false);
  };

  return (
    <>
      {displayVideo[0] !== undefined ? (
        <div className={styles.prodAsset}>
          <img
            src={swipetestleft}
            alt="..."
            onClick={handleScrollLeft}
            name="prev"
            className={styles.rlatedVideoPrev}
          />
          {displayVideo?.map((video) => {
            return (
              <>
                <video
                  src={video?.product_video}
                  controls
                  className={styles.videoView}
                ></video>
              </>
            );
          })}
          <img
            src={nextArow}
            onClick={handleScrollRight}
            className={styles.rlatedVideoNext}
            name="next"
            alt="..."
          />
        </div>
      ) : (
        ""
      )}
      <div className={styles.prodsImg}>
        <img
          src={swipetestleft}
          alt="..."
          onClick={handleScrollLeftImg}
          name="prev"
          className={styles.imgPrev}
        />
        {displayImg?.map((image) => {
          return (
            <img
              key={image?.id}
              ref={ref}
              className={styles.imgPreview}
              src={image?.product_images}
              alt="..."
              onClick={() => handleImgPreview(image)}
            />
          );
        })}
        <img
          src={nextArow}
          onClick={handleScrollRightImg}
          className={styles.imgNext}
          name="next"
          alt="..."
        />
      </div>
      {toggel && (
        <Lightbox mainSrc={imgUrl?.product_images} onCloseRequest={handleOk} />
      )}
    </>
  );
};

export const ProductMetaData = ({ info }) => {
  const productdedtails = [
    { pname: "Brand", pvalue: info?.brand },
    { pname: "Model", pvalue: info?.model },
    {
      pname: "Condition",
      pvalue:
        info?.equip_condition == 1
          ? "Good"
          : info?.equip_condition == 2
          ? "Excellent"
          : "As Good as New",
    },
    { pname: "Warranty", pvalue: info?.warranty == 1 ? "YES" : "NO" },
    {
      pname: "Shipping From",
      pvalue: <ProdAddress address={info?.address} />,
    },
    {
      pname: "Category",
      pvalue: info?.equipname_list
        ? info?.equipname_list
        : "Ultrasound Machine",
    },
    { pname: "Posted", pvalue: info?.date },
    { pname: "Visits", pvalue: info?.visit ? info?.visit : "0" },
  ];
  return (
    <div className={styles.desTable}>
      <h3 className={styles.ptitle}>Product Details</h3>
      <table className={styles.prodMetaContainer}>
        <tbody>
          {productdedtails.map((values, index) => (
            <tr key={index}>
              <td
                style={{ fontWeight: "500", color: "black" }}
                className={styles.data}
              >
                {values.pname}
              </td>
              <td className={styles.data}>{values.pvalue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const ProductReview = () => {
  const reviewData = new Array(4).fill(7);
  return (
    <React.Fragment>
      <div className={styles.prod_reive_continer}>
        <h4>4 RIVIEWS FOR VARIABLE PRODUCT</h4>
        <div className={styles.allReeviewCards}>
          {reviewData.map((values, index) => {
            return <ProductReviewCard key={index} />;
          })}
        </div>
      </div>
      <ReviewForm />
    </React.Fragment>
  );
};

export const ProductReviewCard = () => {
  return (
    <div className={styles.reviewParentCont}>
      <div className={styles.allReviewCont}>
        <div className={styles.userRevImg}>
          <img className={styles.userPic} src={testimage2} alt="" />
        </div>

        <div className={styles.userRevDetails}>
          <div className={styles.nameRating}>
            <div className={styles.dateName}>
              <p className={styles.reviewUserName}>ISHA SHARMA</p>
              <p className={styles.reviewUserDate}>17 JUNE 2023</p>
            </div>
            <div className={styles.userRating}>
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <img className={styles.ratingStar} src={star} alt="" />
              <p className={styles.finalRating}>4.5</p>
            </div>
          </div>
          <div className={styles.reviewDesc}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            ut libero odio. Nam elementum orci ut enim rutrum fringilla. Integer
            pellentesque semper erat id vestibulum. Vestibulum ultrices sapien
            orci, ut auctor ipsum maximus in.
          </div>
        </div>
      </div>
    </div>
  );
};

const RelatedProd = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    fetchRelatedProducts();
  }, []);

  const fetchRelatedProducts = async () => {
    const res = await postData1("product/filter_list/", { product_status: 1 });
    setRelatedProducts(res?.data?.featured_products);
  };

  const handleScrollLeft = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + relatedProducts?.length) % relatedProducts?.length
    );
  };

  const handleScrollRight = () => {
    setCurrentIndex((prev) => (prev + 1) % relatedProducts?.length);
  };

  useEffect(() => {
    const updateDisplayedData = () => {
      const displayed = [
        relatedProducts[currentIndex % relatedProducts?.length],
        relatedProducts[(currentIndex + 1) % relatedProducts?.length],
        relatedProducts[(currentIndex + 2) % relatedProducts?.length],
        relatedProducts[(currentIndex + 3) % relatedProducts?.length],
      ];
      setDisplayedData(displayed);
    };

    updateDisplayedData();
  }, [currentIndex, relatedProducts]);

  return (
    <React.Fragment>
      <div className={styles.hzline}>
        <hr className={styles.line1} />
        <h3 className={styles.relprod}>RELATED PRODUCTS</h3>
        <hr className={styles.line2} />
      </div>
      <div style={{ position: "relative", marginBottom: "40px" }}>
        <img
          src={swipetestleft}
          alt="..."
          onClick={handleScrollLeft}
          name="prev"
          className={styles.rlatedProdPrev}
        />
        <div className={styles.rowws}>
          {displayedData?.map((value, id) => {
            return <RelatedProdCard data={value} key={id} />;
          })}
        </div>
        <img
          src={nextArow}
          onClick={handleScrollRight}
          className={styles.rlatedProdNext}
          name="next"
          alt="..."
        />
      </div>
    </React.Fragment>
  );
};

const ReviewForm = () => {
  const [ratinsStars, setRatingStar] = useState(new Array(5).fill(false));
  const [ratingErr, setRatingErr] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      review: "",
    },
    validationSchema: yup.object({
      name: fnameSchema,
      email: emailSchema,
      review: fnameSchema,
    }),
    onSubmit: function (values) {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    let rating = (() => {
      let count = 0;
      for (let i = 0; i < 5; i++) {
        if (ratinsStars[i]) {
          count += 1;
        }
      }
      return count;
    })();

    if (rating === 0) {
      setRatingErr(true);
      return;
    }
    setRatingErr(false);

    toast.success("Rating Added successfully", { autoClose: 2000 });
  };

  const handleStars = (e) => {
    let curr = parseInt(e.currentTarget.name);
    if (ratinsStars[curr]) {
      for (let i = curr; i <= 4; i++) {
        ratinsStars[i] = false;
      }
    } else {
      for (let i = 0; i <= curr; i++) {
        ratinsStars[i] = true;
      }
    }
    setRatingStar([...ratinsStars]);
  };
  return (
    <div>
      <div className={styles.reviewFormCont}>
        <h2 className={styles.ratingHeading}>ADD YOUR REVIEW HERE</h2>
        {ratingErr && (
          <p style={{ color: "red", marginBottom: "10px" }}>
            Rating not provided
          </p>
        )}
        <div className={styles.giveRatingCont}>
          <p className={styles.onlyStarCol}>STAR RATING</p>
          <div className={styles.giveRatingImg}>
            {ratinsStars.map((value, index) => {
              return (
                <img
                  onClick={handleStars}
                  key={index}
                  name={index}
                  className={styles.ratingStar}
                  src={value ? filledStar : unfilStar}
                  alt="..."
                />
              );
            })}
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate>
          <div
            className={
              styles.form_group +
              " " +
              styles.forOneLine +
              " " +
              styles.rateFormCol
            }
          >
            <div className={styles.rateFormName}>
              <label for="name">NAME</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                placeholder="Enter your name"
                required
              />
            </div>
            {formik.errors.name && formik.touched.name && (
              <div style={{ color: "red" }}>{formik.errors.name}</div>
            )}
            <div className={styles.form_group + " " + styles.rateFormName}>
              <label for="email">EMAIL ID</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="Enter your email id"
                required
              />
            </div>
            {formik.errors.email && formik.touched.email && (
              <div style={{ color: "red" }}>{formik.errors.email}</div>
            )}
          </div>

          <div className={styles.form_group + " " + styles.rateFormCol}>
            <label for="review">REVIEW</label>
            <textarea
              className={styles.DescPlaceholder}
              id={styles.review}
              name="review"
              rows="4"
              placeholder="Enter your review in this box"
              onChange={formik.handleChange}
              value={formik.values.review}
              required
            ></textarea>
          </div>
          {formik.errors.review && formik.touched.review && (
            <div style={{ color: "red" }}>{formik.errors.review}</div>
          )}
          <input
            className={styles.reviewSubmit}
            type="submit"
            value="Submit Response"
          />
        </form>
      </div>
    </div>
  );
};

const MMQprocess = () => {
  const processData = [
    { name: "Find Equipment", img: findE },
    { name: "Schedule meeting with seller", img: schedule },
    { name: "Get Inspection Report", img: inspection },
    { name: "Close The Deal", img: closeDeal },
    { name: "Get it Shipped", img: shipped },
    { name: "Handling & Installation", img: handling },
    { name: "AMC/ CMC, Services", img: amc },
  ];
  return (
    <div className={styles.processCont}>
      {processData.map((value, index) => {
        return <ProcessCard key={index} curr={index} data={value} />;
      })}
    </div>
  );
};
const ProcessCard = (props) => {
  const currBuyStatus = useSelector((state) => state.profileData.currBuyStatus);
  const cardActiveStyle = {};
  if (currBuyStatus >= props.curr) {
    cardActiveStyle["backgroundColor"] = "aquamarine";
  } else {
    cardActiveStyle["boxShadow"] = "rgba(0, 0, 0, 0.16) 0px 1px 4px";
  }
  return (
    <div className={styles.processCard}>
      <div className={styles.procImg} style={cardActiveStyle}>
        <img src={props.data.img} alt="..." />
      </div>
      <span>{props.data.name}</span>
    </div>
  );
};
