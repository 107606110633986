import React, { useEffect, useRef, useState } from "react";
import { Logout } from "../components/Navigation";
import styles from "../assets/css/manufacture/distLayout.module.css";
import { testimage2 } from "../assets/images";

import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails } from "../app/Slices/UserData";

export const DistributorDash = () => {
  const userId = localStorage.getItem("uid");
  const profileDetails = useSelector((state) => state.profileData.UserLists);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sideRef = useRef(null);

  useEffect(() => {
    dispatch(fetchUserDetails(userId));
  }, []);
  const MenuData = [
    {
      title: "Home",
      img: "bi bi-building-dash",
      to: "/distributor/dashboard/",
      children: [],
    },
    {
      title: "Listing",
      img: "bi bi-card-checklist",
      to: "/",
      children: [
        { title: "My Listing", to: "/distributor/listing/" },
        { title: "Add New Listing", to: "/distributor/addlist/" },
      ],
    },
    {
      title: "Orders",
      img: "bi bi-box",
      to: "/distributor/orders/",
      children: [],
    },
    {
      title: "Payment",
      img: "bi bi-credit-card-2-back",
      to: "/",
      children: [
        { title: "Payment Orders", to: "/distributor/payment/" },
        { title: "Download Invoice", to: "/distributor/paymentinvoce" },
      ],
    },
  ];

  const handleNav = (event) => {
    let sidebar = sideRef.current;
    if (sidebar.style.display === "block") {
      sidebar.style.display = "none";
    } else {
      sidebar.style.display = "block";
    }
  };

  return (
    <section className={styles.distDashLayout}>
      <div className={styles.distSideBar} ref={sideRef}>
        <h3 className={styles.distTop}>
          Distributor Dashboard
          <i className="bi bi-x-lg" onClick={handleNav}></i>
        </h3>
        <div className={styles.distMenu}>
          <p onClick={() => navigate("/")} className={styles.userPanel}>
            User Panel
          </p>
          {MenuData.map((value, index) => {
            return (
              <div key={index}>
                <DisOptions data={value} />
              </div>
            );
          })}
          <span className={styles.logutDist}>
            <i className="bi bi-box-arrow-right"></i>
            <Logout />
          </span>
        </div>
      </div>

      <div className={styles.distContentBar}>
        <div style={{ width: "100%", backgroundColor: "#FFFFFF" }}>
          <div className={styles.distHeader}>
            <span onClick={handleNav} className={styles.humberg}>
              <i className="bi bi-list"></i>
            </span>
            {profileDetails && (
              <p>
                {profileDetails?.first_name} {profileDetails?.last_name},
              </p>
            )}
            <div className={styles.subHeader}>
              <div className={styles.distSearch}>
                <i class="bi bi-search"></i>
                <input type="text" placeholder="Search... " />
              </div>
              <i className="bi bi-bell"></i>
              <img
                src={
                  profileDetails?.profile_image
                    ? profileDetails?.profile_image
                    : testimage2
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.distAllComponents}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

const DisOptions = ({ data }) => {
  const [isopen, setOpen] = useState(false);
  const handleDown = (e) => {
    if (data.to === "/") {
      e.preventDefault();
    }
    setOpen(!isopen);
  };
  return (
    <div className={styles.distMCont}>
      <NavLink
        className={styles.distP}
        style={activeLink}
        to={data.to}
        onClick={handleDown}
      >
        <i className={data.img}></i>
        {data.title}
      </NavLink>

      {isopen && (
        <div className={styles.distSubMcont}>
          {data.children.map((value, index) => {
            return (
              <NavLink style={activeLink} to={value.to} key={index}>
                {value.title}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

const activeLink = ({ isActive }) => {
  return {
    backgroundColor: isActive ? "#E9FFF4" : "#FFFFFF",
    color: isActive ? "#13C670" : "#646464",
  };
};
