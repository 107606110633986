import React from "react";

const SpareAccessories = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor : "#BFC9CA ",
        color : "white",
        fontWeight : "bolder",
        fontSize : "50px"
      }}
    >
      <h1>COMING SOON !</h1>
    </div>
  );
};

export default SpareAccessories;
