export const orderStatus = {
  1: "Pending",
  2: "InProcess",
  3: "Completed",
  4: "Canceled",
  5: "Order Confirmed",
  6: "Shipped",
  7: "Delivered",
  8: "Installed",
};
export const productStatus = {
  1: "OPEN",
  2: "LOCK",
  3: "SELLED",
  4: "PENDING",
};
