import React from "react";
import styles from "../../assets/css/manufacture/distributorHome.module.css";
import distributor2 from "../../assets/images/manufacture/distributor2.png";
import distributor3 from "../../assets/images/manufacture/distributor3.png";
import distributor4 from "../../assets/images/manufacture/distributor4.png";
import distributor6 from "../../assets/images/manufacture/distributor6.png";
import distributor7 from "../../assets/images/manufacture/bar_chart.png";
import distributor8 from "../../assets/images/manufacture/graph.png";

export const DistDash = () => {
  return (
    <div className={styles.distributorMain}>
      <DistributorDashKpi />
    </div>
  );
};

export function DistributorDashKpi() {
  const cardsData = [
    {
      imgIcon: distributor2,
      title: "Units sold",
      numberData: "222.",
    },
    {
      imgIcon: distributor2,
      title: "Sales",
      numberData: "12233.00",
    },
    {
      imgIcon: distributor3,
      title: "New Order",
      numberData: "122",
    },
    {
      imgIcon: distributor4,
      title: "Active Listing",
      numberData: "122",
    },
  ];
  return (
    <div className={styles.distributorDashKpi}>
      <div className={styles.DistributorRight}>
        <div className={styles.disCardChild}>
          {cardsData.map((value, index) => {
            return <DistributorCard data={value} key={index} />;
          })}
        </div>
        <div className={styles.Statistics}>
          <StatisticsMonth />
        </div>

        <div className={styles.StatisticsChart}>
          <StatisticsChartPm />
          <StatisticsChartAm />
        </div>
      </div>
      {/*  */}
      <div className={styles.distributorSnapshot}>
        <h4>SnapShot</h4>

        <div className={styles.snapShotCont1}>
          <div>
            <h5>Prev Close</h5>
            <h1>12,951.50</h1>
          </div>
          <div>
            <h5>Open</h5>
            <h1>12051.50</h1>
          </div>
        </div>

        <div className={styles.snapShotCont}>
          <div>
            <h3>12,051.50</h3>
            <h5>Day Low</h5>
          </div>
          <div>
            <h3>12051.50</h3>
            <h5>Day High</h5>
          </div>
        </div>

        <TradeRange />

        <div className={styles.snapShotCont}>
          <div>
            <h3>12,051.50</h3>
            <h5>52 Week Low</h5>
          </div>
          <div>
            <h3>12051.50</h3>
            <h5>52 Week High</h5>
          </div>
        </div>
        <TradeRange />

        <div className={styles.snapShotDate}>
          <div>
            <h5>Trade Time</h5>
            <h1>05:06</h1>
          </div>
          <div>
            <h5>Trade Date</h5>
            <h1>20/05/23</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

const TradeRange = () => {
  return (
    <div className={styles.tradeRange}>
      <hr />
      <div className={styles.tradeRangeCont}>
        <span></span>
        <h1>12.77.88</h1>
      </div>
    </div>
  );
};

const DistributorCard = (props) => {
  return (
    <div className={styles.distributorCard}>
      <div className={styles.distImg}>
        <img src={props.data.imgIcon} alt="" />
      </div>
      <div className={styles.distCardCont}>
        <h3>{props.data.title}</h3>
        <h1>{props.data.numberData}</h1>
      </div>
    </div>
  );
};

const StatisticsMonth = () => {
  return (
    <div className={styles.statisticsMonth}>
      <img src={distributor6} alt="" />
    </div>
  );
};
const StatisticsChartPm = () => {
  return (
    <div className={styles.statisticsPm}>
      <img src={distributor7} alt="" />
    </div>
  );
};
const StatisticsChartAm = () => {
  return (
    <div className={styles.statisticsAm}>
      <img src={distributor8} alt="" />
    </div>
  );
};
